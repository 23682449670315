// src/contexts/ImageAIServiceContext.js
import React, {
  useEffect,
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from 'react';
import useDebug from '@hooks/useDebug';
import useImageGeneration from '@hooks/ai/image/useImageGeneration';
import useImageAIState from '@hooks/ai/image/useImageAIState';

// Context 생성: 전역 상태를 저장하고 전달하는 컨테이너 역할
// - Context는 Provider, Consumer 컴포넌트를 포함한다.
// - Provider는 Context의 값을 제공하는 컴포넌트
// - Consumer는 Context의 값을 사용하는 컴포넌트
const ImageAIServiceContext = createContext(null);

/**
 * ImageAIServiceProvider 컴포넌트
 *
 * @component
 * @param {Object} props - 컴포넌트 props
 * @param {React.ReactNode} props.children - 자식 컴포넌트들
 * @param {Object} props.initialConfig - 초기 설정 객체
 *
 * @description
 * 이 컴포넌트는 ImageAI 기능에 필요한 모든 상태와 함수를 제공합니다.
 * Context API를 사용하여 하위 컴포넌트들에게 전역 상태를 제공합니다.
 *
 * 주요 기능:
 * 1. Context 생성: createContext를 사용하여 ImageAIServiceContext를 생성합니다.
 *    이는 전역 상태를 저장하고 전달하는 컨테이너 역할을 합니다.
 * 2. Provider 컴포넌트: ImageAIServiceProvider는 Context의 값을 제공하는 컴포넌트입니다.
 *    이 컴포넌트 내에서 모든 상태 관리와 로직이 이루어집니다.
 * 3. 상태 관리: useState를 사용하여 apiType을 관리합니다.
 *    이는 현재 사용 중인 API 타입을 나타냅니다.
 * 4. useImageGeneration 훅: 이 커스텀 훅을 사용하여 이미지 생성 관련 기능을 가져옵니다.
 *    useMemo를 사용하여 apiType이 변경될 때만 새로운 인스턴스를 생성합니다.
 * 5. 메모이제이션: useMemo와 useCallback을 사용하여 불필요한 리렌더링을 방지하고 성능을 최적화합니다.
 *
 * 사용 방법:
 * 이 Provider를 애플리케이션의 최상위 또는 ImageAIChat 기능이 필요한 부분을 감싸서 사용합니다.
 *
 * @example
 * <ImageAIServiceProvider initialConfig={config}>
 *   <App />
 * </ImageAIServiceProvider>
 */
export const ImageAIServiceProvider = ({ children, value }) => {
  const { debug } = useDebug('ImageAIServiceProvider');
  const {
    pageId, // 페이지 ID
    callbacks, // 콜백 함수들
    initialConfig, // 초기 설정
  } = value;

  const [apiType, setApiType] = useState(
    initialConfig.apiType.image || 'fluxFal',
  );

  const {
    // pageId, // 페이지 ID
    setPageId, // 페이지 ID 설정
    prepareImageGeneration, // 이미지 생성 준비
    generateImage, // 이미지 생성
    isProcessing, // 이미지 생성 중 여부
    setIsProcessing, // 이미지 생성 중 여부 설정
    error, // 이미지 생성 중 발생한 에러
    generatedImages, // 생성된 이미지
    currentApiType, // fluxFal
    changeApiType, // image apitype이 변경된 경우
    defaultApiConfig, // currentApiType에 따라 변경된 기본 설정
  } = useImageGeneration(apiType);

  // API 타입을 변경하는 함수. 새 API 타입을 받아 상태를 업데이트
  const changeApiTypeAndUpdate = useCallback(
    (newApiType) => {
      debug('Changing API type', { from: apiType, to: newApiType });
      setApiType(newApiType);
      changeApiType(newApiType);
    },
    [apiType, changeApiType, debug],
  );

  useEffect(() => {
    debug('initialConfig', initialConfig);
    if (pageId) {
      setPageId(pageId);
      debug('i:*:페이지 ID 설정됨', { pageId });
    }
  }, [initialConfig, pageId, setPageId, debug]);

  // Context에 제공할 값들을 메모이제이션
  // apiInstance, apiType, changeApiType이 변경될 때만 새로운 객체 생성
  const contextValue = useMemo(() => {
    const newValue = {
      pageId,
      setPageId,
      callbacks, // callbacks
      prepareImageGeneration,
      generateImage,
      isProcessing,
      setIsProcessing,
      error,
      generatedImages,
      apiType: currentApiType,
      changeApiType: changeApiTypeAndUpdate,
      defaultApiConfig,
    };

    return newValue;
  }, [
    generateImage,
    isProcessing,
    setIsProcessing,
    error,
    generatedImages,
    currentApiType,
    changeApiTypeAndUpdate,
    defaultApiConfig,
    prepareImageGeneration,
    debug,
    callbacks,
  ]);

  // Provider를 통해 value를 하위 컴포넌트에 전달
  return (
    <ImageAIServiceContext.Provider value={contextValue}>
      {children}
    </ImageAIServiceContext.Provider>
  );
};

/**
 * useImageAIServiceContext 커스텀 훅
 *
 * @returns {Object} ImageAIServiceContext의 현재 값
 * @throws {Error} Provider 외부에서 사용될 경우 에러 발생
 *
 * @description
 * 이 훅은 ImageAIServiceContext의 값을 사용하기 위한 커스텀 훅입니다.
 * ImageAIServiceProvider 내부의 컴포넌트에서만 사용할 수 있습니다.
 *
 * 주요 기능:
 * 1. Context 값 접근: API 타입, 이미지 생성 함수, 상태 등에 접근 가능
 * 2. 타입 안정성: TypeScript 사용 시 자동 완성 및 타입 체크 지원
 * 3. 에러 처리: Provider 외부 사용 시 명확한 에러 메시지 제공
 *
 * * 동작 원리
 * * 1) Provider 설정: ImageAIServiceProvider 컴포넌트에서 ImageAIServiceContext.Provider를 사용하여 value prop에 상태와 함수들을 전달합니다.
 * * 2) React의 내부 동작: React는 내부적으로 이 Provider의 value를 추적합니다. Provider 하위의 모든 컴포넌트는 이 value에 접근할 수 있게 됩니다.
 * * 3) useContext 훅: React의 useContext 훅은 가장 가까운 상위 Provider의 현재 context 값을 반환합니다.
 * * 4) 커스텀 훅: useImageAIServiceContext는 useContext(ImageAIServiceContext)를 호출하여 Provider에서 설정한 value를 가져옵니다.
 * * 5) 자식 컴포넌트에서의 사용: 자식 컴포넌트에서 useImageAIServiceContext()를 호출하면, 이 커스텀 훅이 context 값을 반환하고, 컴포넌트는 이 값들을 사용할 수 있게 됩니다.
 * ! Provider읠 value prop에 직접 접근은?
 * ! 1) Context의 동작 원리: React의 Context는 컴포넌트 트리를 통해 데이터를 "전파"하는 메커니즘입니다.
 * ! - Provider의 value prop은 이 데이터의 원천이 되지만, 자식 컴포넌트들은 이 prop에 직접 접근할 수 없습니다.
 * ! 2) useContext의 필요성:
 * ! - 자식 컴포넌트가 Context의 값을 사용하려면 반드시 useContext 훅(또는 클래스 컴포넌트의 경우 Context.Consumer)을 사용해야 합니다. 이는 React가 의도적으로 설계한 방식입니다.
 *
 * Context 사용을 위한 커스텀 훅:
 * useImageAIServiceContext는 Context의 값을 안전하게 사용할 수 있게 해주는 커스텀 훅입니다.
 * Provider 외부에서 사용 시 에러를 발생시켜 잘못된 사용을 방지합니다.
 *
 * 사용 방법:
 * ImageAIServiceProvider 하위의 컴포넌트에서 이 훅을 호출하여 Context 값을 가져옵니다.
 *
 * @example
 * const { apiType, generateImage } = useImageAIServiceContext();
 */
export const useImageAIServiceContext = () => {
  //  React의 useContext 훅은 가장 가까운 상위 Provider의 현재 context 값을 반환합니다.
  const context = useContext(ImageAIServiceContext);
  if (!context) {
    throw new Error(
      'useImageAIServiceContext must be used within an ImageAIServiceProvider',
    );
  }
  return context;
};
