// react-src/src/plugins/ImageAIChat/config/lang/i18n.js

import createDebugger from '@utils/debug';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { API_CONFIG, getCdnUrl } from '@api/apiConfig';

const createImageAIAgentI18n = () => {
  const pluginI18n = i18n.createInstance();
  const cdnBase = getCdnUrl('/pkg/v1');

  const { debug, setDebugMode } = createDebugger('createImageAIAgentI18n');
  const debugMode = API_CONFIG.debug;
  setDebugMode(debugMode);

  // 플러그인 전용 languageDetector 설정
  const languageDetector = new LanguageDetector();
  languageDetector.init({
    order: ['localStorage', 'querystring', 'navigator', 'htmlTag'],
    lookupLocalStorage: 'supereasyaiAgentLng', // localStorage 키
    convertDetectedLanguage: (lng) => {
      if (lng === 'zh') return 'zh-CN';
      return lng;
    },
    caches: ['localStorage'],
  });

  // 개발 환경용 네임스페이스
  const namespaces = [
    'components/service/imageAI/layout/sidebar/sidebar',
    'components/service/imageAI/layout/navigation/navigation',
    'components/service/imageAI/components/create',
    'components/service/imageAI/components/imageActionButton',
    'components/service/imageAI/components/imageViewer',
    'components/service/imageAI/components/loginGuide',
    'components/service/imageAI/components/prompt/prompt',
    'components/service/imageAI/components/prompt/promptInput',
    'components/service/imageAI/components/prompt/promptSettings',
    'components/service/imageAI/components/pricing',
    'components/service/imageAI/components/aiInfrastructure',
  ];

  // 플러그인 전용 i18n 설정
  const pluginI18nConfig = {
    fallbackLng: 'en',
    // lng: localStorage.getItem('supereasyaiAgentLng') || 'en', // localStorage 값을 우선적으로 사용
    supportedLngs: [
      'en',
      'ko',
      'ja',
      'fr',
      'de',
      'ru',
      'zh-CN',
      'zh-TW',
      'hi',
      'ar',
      'es',
      'pt',
      'id',
      'bn',
      'vi',
      'th',
      'tr',
      'it',
      'pl',
      'hu',
      'uk',
      'nl',
      'ms',
      'cs',
      'el',
      'fi',
    ],
    detection: {
      order: ['localStorage', 'querystring', 'navigator'],
      lookupLocalStorage: 'supereasyaiAgentLng', // localStorage 키
      caches: ['localStorage'],
    },
    debug: debugMode,
    ns: namespaces,
    defaultNS: 'common/common',
    backend: {
      loadPath: `${cdnBase}/locales/{{lng}}/{{ns}}.json`,
      allowMultiLoading: true,
      crossDomain: true,
      requestOptions: {
        mode: 'cors',
        credentials: 'same-origin',
        cache: 'default',
      },
      parse: (data) => {
        const parsedData = JSON.parse(data);

        // 플러그인 빌드에서는 이미 올바른 구조로 데이터가 있으므로
        // 추가 처리 없이 반환
        return parsedData;
      },
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  };

  debug('i:*:pluginI18nConfig', pluginI18nConfig);

  pluginI18n
    .use(HttpBackend)
    .use(languageDetector) // 커스텀 languageDetector 사용
    .use(initReactI18next)
    .init(pluginI18nConfig);

  // 디버그 모드에서 번역 키 누락 모니터링
  if (debug) {
    pluginI18n.on('missingKey', (lng, ns, key) => {
      debug('e:*:pluginI18n:missingKey??', { lng, ns, key });
      console.warn(
        `[Agent] pluginI18n: missing translation - Language: ${lng}, Namespace: ${ns}, Key: ${key}`,
      );
    });

    i18n.on('loaded', (loaded) => {
      debug('s:*:pluginI18n:loaded', loaded);
    });

    i18n.on('failedLoading', (lng, ns, msg) => {
      debug('e:*:i18n:failedLoading', { lng, ns, msg });
    });
  }

  return pluginI18n;
};

export default createImageAIAgentI18n;
