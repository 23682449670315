/**
 * 주어진 날짜를 현재 시간을 기준으로 얼마나 전인지 계산합니다.
 * @param {string|number} dateInput - ISO 8601 형식의 날짜 문자열 또는 타임스탬프
 * @param {boolean} fixedToDay - true이면 'day' 단위로 고정하여 반환
 * @returns {Object} 기간과 값이 포함된 객체
 */
export const calculateAgo = (dateInput, fixedToDay = false) => {
  const now = new Date();
  const pastDate =
    typeof dateInput === 'string' ? new Date(dateInput) : new Date(dateInput);
  const diffInMs = now - pastDate;
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  if (fixedToDay) {
    return { period: 'day', value: diffInDays };
  }

  if (diffInDays < 7) {
    return { period: 'day', value: diffInDays };
  } else if (diffInDays < 30) {
    return { period: 'week', value: Math.floor(diffInDays / 7) };
  } else {
    return { period: 'month', value: Math.floor(diffInDays / 30) };
  }
};

// 사용 예시
// console.log(calculateAgo('2023-09-08T12:34:56.789Z')); // 예: { period: 'day', value: 3 }
// console.log(calculateAgo(1694428496789)); // 예: { period: 'day', value: 3 } (타임스탬프 예시)
// console.log(calculateAgo('2023-09-08T12:34:56.789Z', true)); // 예: { period: 'day', value: 3 } (고정된 day 단위)
// console.log(calculateAgo(1694428496789, true)); // 예: { period: 'day', value: 3 } (타임스탬프 예시, 고정된 day 단위)
