import { useContext, useCallback } from 'react';
// import DebugContext from '../contexts/DebugContext';
import { DebugContext } from '@contexts/debug/DebugContext';

/**
 * 디버그 기능을 사용하기 위한 커스텀 훅
 * @param {string} [name] - 함수 또는 컴포넌트 이름 (선택적)
 * @returns {{debug: function, isDebugMode: boolean, setDebugMode: function}}
 */
const useDebug = (name = '') => {
  const { isDebugMode, setDebugMode } = useContext(DebugContext);

  /**
   * 구분선을 생성하는 함수
   * @param {string} type - 구분선 타입 ('s', '=', '*')
   * @returns {string} 구분선
   */
  const createSeparator = (type) => {
    const length = 50;
    switch (type) {
      case 's':
      case 'i':
      case 'e':
      case 'w':
        return '='.repeat(length);
      case '=':
        return '='.repeat(length);
      case '*':
        return '*'.repeat(length);
      case '>':
        return '>'.repeat(length);
      default:
        return '';
    }
  };

  /**
   * 디버그 로그를 출력하는 함수
   * @param {string} message - 로그 메시지
   * @param {...any} args - 추가 데이터 (여러 개 가능)
   */
  const debug = useCallback(
    (message, ...args) => {
      if (!isDebugMode) return;

      let separator = '';
      let endSeparator = '';

      const logSettings = {
        s: { logType: 'SUCCESS', color: 'green' },
        i: { logType: 'INFO', color: 'blue' },
        e: { logType: 'ERROR', color: 'red' },
        w: { logType: 'WARN', color: 'orange' },
      };

      // 정규식을 사용하여 구분자 추출
      const separatorMatch = message.match(/^([siew*>=]):(?:([*>=]):)?/i);

      if (separatorMatch) {
        const separatorType = separatorMatch[1];
        const flagSeparatorType = separatorMatch[2];

        // ! for TEST
        // console.log('separatorType: ', separatorType);
        // console.log('flagSeparatorType: ', flagSeparatorType);

        message = message.slice(separatorMatch[0].length);
        separator = createSeparator(flagSeparatorType || separatorType);
        const { logType, color } = logSettings[separatorType] || {};

        if (!flagSeparatorType) {
          // ! 단일 separatorType 인 경우
          if (
            separatorType === 's' ||
            separatorType === 'i' ||
            separatorType === 'e' ||
            separatorType === 'w'
          ) {
            if (logType) {
              console.log(`\n%cSTART`, `color: ${color}`);
              console.log(`%c${separator}`, `color: ${color}`);
              console.log(`[${logType}${name ? ` - ${name}` : ''}] ${message}`);
              args.forEach((arg) => console.log(arg));
              console.log(`%c${separator}`, `color: ${color}`);
              console.log(`%cEND\n`, `color: ${color}`);
              return;
            }
          } else {
            // * 단일 separatorType인 경우 + >, =, * 인 경우
            console.log(`\n${separator}`);
            if (message) {
              console.log(`[DEBUG${name ? `- ${name}` : ''}] ${message}`);
            }
            if (args.length > 0) {
              args.forEach((arg) => console.log(arg));
            }
            // ! 하단의 종료를 위한 separator는 message 또는 args가 존재할 때만 출력한다.
            if (message || args.length > 0) {
              console.log(`${separator}\n`);
            }
          }
        } else {
          // * 추가적인 구분자가 존재하는 경우,
          // ! 추가적인 구분자가 존재하는 경우: s, i, e, w 외의 구분자가 존재하는 경우에만 색깔을 적용한다.
          if (logType) {
            console.log(`\n%c${separator}`, `color: ${color}`);
            if (message) {
              console.log(`[${logType}${name ? `- ${name}` : ''}] ${message}`);
            }
            if (args.length > 0) {
              args.forEach((arg) => console.log(arg));
            }
            // ! 하단의 종료를 위한 separator는 message 또는 args가 존재할 때만 출력한다.
            if (message || args.length > 0) {
              console.log(`%c${separator}\n`, `color: ${color}`);
            }
            return;
          }
        }
      }

      // * separator 가 없는 경우: 일반적인 메시지 출력인 경우
      if (message) {
        console.log(`[DEBUG${name ? `- ${name}` : ''}] ${message}`);
      }
      if (args.length > 0) {
        args.forEach((arg) => console.log(arg));
      }
    },
    [isDebugMode, name],
  );

  return { debug, isDebugMode, setDebugMode };
};

export { useDebug as default };
