import React from 'react';
import * as Switch from '@radix-ui/react-switch';

const sizeStyles = {
  xs: {
    root: 'h-[18px] w-[32px]',
    thumb:
      'h-[14px] w-[14px] translate-x-0.5 data-[state=checked]:translate-x-[16px]',
  },
  sm: {
    root: 'h-[20px] w-[36px]',
    thumb:
      'h-[16px] w-[16px] translate-x-0.5 data-[state=checked]:translate-x-[18px]',
  },
  md: {
    root: 'h-[22px] w-[40px]',
    thumb:
      'h-[18px] w-[18px] translate-x-0.5 data-[state=checked]:translate-x-[20px]',
  },
  lg: {
    root: 'h-[24px] w-[44px]',
    thumb:
      'h-[20px] w-[20px] translate-x-0.5 data-[state=checked]:translate-x-[22px]',
  },
  xl: {
    root: 'h-[26px] w-[48px]',
    thumb:
      'h-[22px] w-[22px] translate-x-0.5 data-[state=checked]:translate-x-[24px]',
  },
};

const SwitchInput = ({ checked, onChange, size = 'sm' }) => {
  const sizeStyle = sizeStyles[size] || sizeStyles.sm;

  return (
    <Switch.Root
      checked={checked}
      onCheckedChange={onChange}
      className={`relative cursor-default rounded-full bg-light-300 shadow-[0_2px_10px] shadow-light-400/20 outline-none transition-colors duration-200 focus:shadow-[0_0_0_2px] focus:shadow-light-600 data-[state=checked]:bg-light-600 dark:bg-dark-700 dark:shadow-dark-950/40 dark:focus:shadow-dark-400 dark:data-[state=checked]:bg-dark-400 ${sizeStyle.root}`}
      style={{ WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)' }}
    >
      <Switch.Thumb
        className={`block rounded-full bg-light-50 shadow-[0_2px_2px] shadow-light-400/30 transition-transform duration-200 will-change-transform dark:bg-dark-50 dark:shadow-dark-950/50 ${sizeStyle.thumb}`}
      />
    </Switch.Root>
  );
};

export { SwitchInput as default };
