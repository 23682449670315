// react-src/src/plugins/ImageAIChat/utils/imageUtils.js

export const getAspectRatio = (imageSize) => {
  switch (imageSize) {
    case 'landscape_4_3':
      return 4 / 3;
    case 'landscape_16_9':
      return 16 / 9;
    case 'square_hd':
      return 1;
    case 'portrait_4_3':
      return 3 / 4;
    case 'portrait_16_9':
      return 9 / 16;
    default:
      return 1; // Default to square if unknown
  }
};
