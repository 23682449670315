import React, { createContext, useState, useContext, useCallback } from 'react';
import useDebug from '@hooks/useDebug';
const ImageViewerContext = createContext();

export const ImageViewerProvider = ({ children, modalOptions = {} }) => {
  const { debug } = useDebug('ImageViewerProvider');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const openImageViewerModal = useCallback((data) => {
    debug('i:*:openImageViewerModal', data);
    setModalData(data);
    setIsModalOpen(true);
  }, []);

  const closeImageViewerModal = useCallback(() => {
    setIsModalOpen(false);
    setModalData(null);
  }, []);

  return (
    <ImageViewerContext.Provider
      value={{
        isModalOpen,
        modalData,
        modalOptions,
        openImageViewerModal,
        closeImageViewerModal,
      }}
    >
      {children}
    </ImageViewerContext.Provider>
  );
};

export const useImageViewer = () => {
  const context = useContext(ImageViewerContext);
  if (!context) {
    throw new Error(
      'useImageViewer must be used within an ImageViewerProvider',
    );
  }
  return context;
};
