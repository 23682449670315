/*!
 * useImageActions
 * - 이미지 액션 버튼들을 관리하는 훅
 * - 이미지 액션 버튼들을 표시하고, 클릭 이벤트를 처리한다
 * - imageAI service에서 한정하여 사용하는 훅
 * @author JB
 * @license all rights reserved to JB
 */
import React, { useCallback, useState } from 'react';
import useDebug from '@hooks/useDebug';
import { useImageAIContext } from '@contexts/ai/image/ImageAIContext';
// ! Create.jsx 내에서 이미지 생성 목록 관련 상태 및 액션을 관리하는 훅
import { useCreatedImagesContext } from '@contexts/ai/service/imageAI/Create/CreatedImagesContext';
import { useExploreImagesContext } from '@contexts/ai/service/imageAI/Explore/ExploreImagesContext';

// fullsceen ImageViewer 컴포넌트
import { useImageViewer } from '@contexts/ai/service/imageAI/ImageViewer/ImageViewerContext';
const useImageActions = ({
  pageId = null,
  promptId, // 현재 메시지의 prompt id
  messageId, // 현재 메시지의 id
  imageIndex, // 이미지 목록 내에서의 현재 이미지의 index
  imageInfo, // 현재 이미지의 정보
  imageEl, // 이미지 DOM 요소
  // selectedImageIndex, // 선택된 이미지의 index
  onSubmit = null, // 이미지 생성을 위한 함수를 전달한다
  onViewImage = null, // 이미지 보기를 위한 함수를 전달한다
  actionProps = null, // INFO: 이미지 액션 버튼에 대한 추가 props, copy, edit인 경우, finalPrompt, originalPrompt인지를 구분하기 위해서 전달된다.
}) => {
  const { debug } = useDebug('useImageActions');
  const {
    handleRegenerateImage, // 이미지 재생성
    handleEditImage, // 이미지 편집
    handleImageUpload, // 이미지 업로드
    handleImageAutoPost, // 이미지 자동 포스트
    handleImageDownload, // 이미지 다운로드
    handleApplyImage, // 이미지 적용
    handleViewImage, // 이미지 전체 화면 보기
    handleDeleteImage, // 이미지 삭제
  } = useImageAIContext();

  const {
    // ! 필요한 항목만 추가를 한다.
    createdImages, // 생성된 이미지 목록
    setCreatedImages, // 생성된 이미지 목록 설정
    isGenerating, // 생성 중 상태
    setIsGenerating, // 생성 중 상태 설정
    fetchCreatedImages, // 생성된 이미지 목록 가져오기
    currentCreatedImage, // 현재 생성된 이미지 가져오기
    addImageGenerationList, // 생성된 이미지 목록 추가
    updateCreatedImageList, // 생성된 이미지 목록 업데이트
    removeCreatingImageByPromptId, // 생성된 이미지 목록 삭제
    updateCreatedImagesAfterDelete, // 서버 삭제 성공 후 클라이언트 상태 업데이트
  } = useCreatedImagesContext();

  // fullsceen ImageView를 위한 모달 open
  const { openImageViewerModal } = useImageViewer();

  const getDataIndex = () => {
    return imageEl ? imageEl.getAttribute('data-index') : 0;
  };

  /**
   * 이미지 재생성
   * - 이미지 재생성 버튼을 클릭하는 경우
   * @returns {void}
   */
  const regenerateImage = useCallback(() => {
    // ! 생성된 이미지 중, 현재 선택된 이미지의 정보를 가지고 온다.
    // ! - api_metadata, imags, prompt_metadata, tags 정보를 포함하고 있다.
    const currentImageInfo = currentCreatedImage(promptId);
    debug('regenerateImage 함수 호출됨', {
      promptId,
      currentImageInfo,
    });

    return handleRegenerateImage({
      promptId, // 현재 메시지의 prompt id
      currentImageInfo,
      app: {
        type: 'service',
        name: 'imageAI',
      },
      onSubmit, // ! 이미지 생성 함수를 반드시 전달한다.
    });
  }, [promptId, handleRegenerateImage, currentCreatedImage, debug, onSubmit]);

  /**
   * 이미지 편집
   * - 이미지 편집 버튼을 클릭하는 경우
   * - 현재 이미지의 설정을 적용한 후, 이미지를 편집한다
   * - 선택한 이미지의 prompt, model, apiOptions를 적용한다
   * @returns {void}
   */
  const editImage = useCallback(
    (actionProps) => {
      // ! 생성된 이미지 중, 현재 선택된 이미지의 정보를 가지고 온다.
      // ! - api_metadata, imags, prompt_metadata, tags 정보를 포함하고 있다.
      const currentImageInfo = currentCreatedImage(promptId);
      debug('editImage 함수 호출됨', {
        promptId,
        currentImageInfo,
        actionProps,
      });

      // return false;

      return handleEditImage({
        promptId, // 현재 메시지의 prompt id
        currentImageInfo,
        app: {
          type: 'service',
          name: 'imageAI',
        },
        promptInfo: actionProps,
      });
    },
    [
      promptId, // 현재 메시지의 prompt id
      handleEditImage, // 이미지 편집을 위한 상위 훅 함수
      currentCreatedImage, // 현재 생성된 이미지 가져오기
      debug, // 디버그 함수
    ],
  );

  /**
   * 이미지 다운로드
   * - 이미지 다운로드 버튼을 클릭하는 경우
   * @returns {void}
   */
  const downloadImage = useCallback(() => {
    let message = '';
    debug('downloadImage 함수 호출됨', {
      promptId,
      messageId,
      imageEl,
    });
    if (imageEl) {
      // useImageAIActions에서 이미지 다운로드 함수 호출
      const result = handleImageDownload({
        promptId,
        messageId,
        imageEl,
        imageIndex,
      });
      return result;
    } else {
      message = '이미지를 다운로드 할 수 없습니다.';
      return {
        status: 'error',
        message: message,
        details: 'No image element',
      };
    }
  }, [promptId, messageId, imageEl, debug, handleImageDownload]);

  /**
   * 이미지 업로드
   * - 이미지 업로드 버튼을 클릭하는 경우
   * @returns {void}
   */
  const uploadImage = useCallback(() => {
    debug('uploadImage 함수 호출됨', {
      promptId,
      messageId,
      imageIndex,
      imageInfo,
      imageEl,
      // selectedImageIndex,
    });
    // 현재 이미지 정보를 전달하여 업로드
    return handleImageUpload({
      promptId,
      messageId,
      imageIndex,
      currentImageInfo: imageInfo,
      currentChatHistory: getCurrentChatHistory(messageId),
    });
  }, [
    handleImageUpload,
    promptId,
    messageId,
    imageInfo,
    imageEl,
    imageIndex,
    // selectedImageIndex,
    debug,
  ]);
  /**
   * 이미지 자동 포스트
   * - 이미지 자동 포스트 버튼을 클릭하는 경우
   * @returns {void}
   */
  const autoPostImage = useCallback(() => {
    const currentImageInfo = currentCreatedImage(promptId);
    const selectedImageInfo = currentImageInfo.images[imageIndex];
    debug('autoPostImage 함수 호출됨', {
      promptId,
      imageIndex,
      imageInfo,
      imageEl,
      currentImageInfo,
    });

    return handleImageAutoPost({
      promptId, // 현재 메시지의 prompt id
      imageIndex,
      currentImageInfo,
      app: {
        type: 'service',
        name: 'imageAI',
      },
    });
  }, [
    handleImageAutoPost,
    promptId,
    messageId,
    imageInfo,
    imageEl,
    imageIndex,
    // selectedImageIndex,
    debug,
  ]);

  /**
   * 이미지 적용
   * - 이미지 적용 버튼을 클릭하는 경우
   * @returns {void}
   */
  const applyImage = useCallback(() => {
    const currentImageInfo = currentCreatedImage(promptId);
    const selectedImageInfo = currentImageInfo.images[imageIndex];
    debug('i:*:applyImage 함수 호출됨', {
      promptId,
      imageIndex,
      imageInfo,
      imageEl,
      currentImageInfo,
    });

    return handleApplyImage({
      promptId, // 현재 메시지의 prompt id
      imageIndex,
      currentImageInfo,
      imageInfo,
      app: {
        type: 'service',
        name: 'imageAI',
      },
    });
  }, [promptId, imageIndex, imageInfo, imageEl, handleApplyImage]);

  /**
   * 이미지 전체 화면 보기
   * - 이미지 전체 화면 보기 버튼을 클릭하는 경우
   * @returns {void}
   */
  const viewFullscreen = useCallback(() => {
    const currentImageInfo = currentCreatedImage(promptId);
    debug('viewFullscreen 함수 호출됨', {
      promptId,
      imageIndex,
      imageInfo,
      imageEl,
      currentImageInfo,
    });
    let selectedImageInfo = currentImageInfo.images[imageIndex];

    // snake_case를 camelCase로 변환하는 함수
    const snakeToCamel = (str) =>
      str.replace(/_([a-z])/g, (g) => g[1].toUpperCase());

    // selectedImageInfo의 속성을 순회하며 snake_case를 camelCase로 변환
    const convertedImageInfo = Object.entries(selectedImageInfo).reduce(
      (acc, [key, value]) => {
        const camelKey = snakeToCamel(key);
        acc[camelKey] = value;
        return acc;
      },
      {},
    );

    // 변환된 정보로 selectedImageInfo 업데이트
    selectedImageInfo = convertedImageInfo;

    // ImageViewerContext에서 사용하는 모달 데이터 설정
    if (openImageViewerModal && typeof openImageViewerModal === 'function') {
      openImageViewerModal({
        promptId,
        apiMetadata: currentImageInfo.api_metadata, // 현재 이미지 정보
        imageMetadata: { ...imageInfo, ...selectedImageInfo }, // 현재 이미지 정보
        promptMetadata: currentImageInfo.prompt_metadata, // 현재 이미지 정보
        imageEl: imageEl, // 현재 이미지 엘리먼트
        imageIndex, // 현재 이미지 인덱스
      });
    } else {
      debug('e:openImageViewerModal 함수가 정의되어 있지 않습니다.');
    }
  }, [
    promptId,
    imageIndex,
    imageInfo,
    imageEl,
    openImageViewerModal,
    currentCreatedImage,
    debug,
  ]);

  const copyPrompt = useCallback(
    (actionProps = null) => {
      debug('copyPrompt 함수 호출됨', {
        actionProps,
      });
      let copyPromptText = '';
      if (actionProps) {
        const { isOriginalPrompt, promptText } = actionProps;
        // INFO: 현재 이미지에서 사용자 선택한 promptText가 전달이 된다.
        copyPromptText = promptText;
      } else {
        // INFO: 현재 이미지의 fianl 프롬프트를 복사
        const currentImageInfo = currentCreatedImage(promptId);
        copyPromptText = currentImageInfo.prompt_metadata.final_prompt;
      }
      navigator.clipboard.writeText(copyPromptText);
      return true;
    },
    [promptId, currentCreatedImage, debug],
  );

  /**
   * 이미지 전체 화면 보기
   * - 이미지 전체 화면 보기 버튼을 클릭하는 경우
   * @returns {void}
   */
  const deleteImage = useCallback(() => {
    const currentImageInfo = currentCreatedImage(promptId);
    let deleteImageInfo = currentImageInfo.images[imageIndex];

    // snake_case를 camelCase로 변환하는 함수
    const snakeToCamel = (str) =>
      str.replace(/_([a-z])/g, (g) => g[1].toUpperCase());

    // selectedImageInfo의 속성을 순회하며 snake_case를 camelCase로 변환
    const convertedImageInfo = Object.entries(deleteImageInfo).reduce(
      (acc, [key, value]) => {
        const camelKey = snakeToCamel(key);
        acc[camelKey] = value;
        return acc;
      },
      {},
    );

    // 변환된 정보로 selectedImageInfo 업데이트
    deleteImageInfo = convertedImageInfo;
    debug('i:*:deleteImage 함수 호출됨', {
      promptId,
      imageIndex,
      imageInfo,
      imageEl,
      currentImageInfo,
      deleteImageInfo,
    });

    return handleDeleteImage({
      promptId,
      // NOTICE: imageId를 전달을 하면, promptId 내의 개별 이미지만 삭제를 한다.
      imageId: deleteImageInfo.imageId,
      deleteImageInfo,
    }).then((result) => {
      if (result.status === 'success') {
        // 서버 삭제 성공 후 클라이언트 상태 업데이트
        updateCreatedImagesAfterDelete({
          promptId,
          imageId: deleteImageInfo.imageId,
        });
      }
      return result;
    });
  }, [
    promptId,
    imageIndex,
    currentCreatedImage,
    handleDeleteImage,
    updateCreatedImagesAfterDelete,
  ]);

  return {
    regenerateImage,
    editImage,
    downloadImage,
    uploadImage,
    autoPostImage,
    applyImage,
    viewFullscreen,
    copyPrompt,
    deleteImage,
  };
};

export default useImageActions;
