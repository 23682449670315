// shortcutKeyValidator.js
const VALID_KEYS = [
  // a-z (알파벳)
  ...[...Array(26)].map((_, i) => String.fromCharCode(97 + i)),
  // 0-9 (숫자)
  ...[...Array(10)].map((_, i) => i.toString()),
];

export class ShortcutKeyValidator {
  /**
   * 키 유효성 검사
   * @param {string} key - 검사할 키
   * @returns {boolean} 유효성 여부
   */
  static validateKey(key) {
    if (!key || typeof key !== 'string') {
      return false;
    }
    return VALID_KEYS.includes(key.toLowerCase());
  }

  /**
   * 단축키 설정 전체 유효성 검사
   * @param {Object} shortcutKeys - 검사할 단축키 설정
   * @throws {Error} 유효하지 않은 설정일 경우
   * @returns {boolean} 유효성 여부
   */
  static validateShortcutKeys(shortcutKeys) {
    if (!shortcutKeys || typeof shortcutKeys !== 'object') {
      throw new Error('단축키 설정은 객체 형태여야 합니다.');
    }

    const { key, ctrlKey, metaKey, shiftKey, altKey } = shortcutKeys;

    // key 검증
    if (!key || !this.validateKey(key)) {
      throw new Error('key는 알파벳(a-z) 또는 숫자(0-9)여야 합니다.');
    }

    // Ctrl/Cmd 필수
    if (ctrlKey === false && metaKey === false) {
      throw new Error('Ctrl(Windows) 또는 Cmd(Mac) 키는 필수입니다.');
    }

    // shift, alt는 선택적이지만 설정된 경우 boolean이어야 함
    if (shiftKey !== undefined && typeof shiftKey !== 'boolean') {
      throw new Error('shiftKey는 boolean 값이어야 합니다.');
    }

    if (altKey !== undefined && typeof altKey !== 'boolean') {
      throw new Error('altKey는 boolean 값이어야 합니다.');
    }

    return true;
  }

  /**
   * 단축키 설정 정규화
   * @param {Object} shortcutKeys - 정규화할 단축키 설정
   * @returns {Object} 정규화된 단축키 설정
   */
  static normalizeShortcutKeys(shortcutKeys) {
    const normalized = {
      key: shortcutKeys.key.toLowerCase(),
      ctrlKey: true, // Windows용
      metaKey: true, // Mac용
      shiftKey: Boolean(shortcutKeys.shiftKey),
      altKey: Boolean(shortcutKeys.altKey),
    };

    return normalized;
  }

  /**
   * 기본 단축키 설정 반환
   * @returns {Object} 기본 단축키 설정
   */
  static getDefaultShortcutKeys() {
    return {
      key: 'u',
      ctrlKey: true, // Windows용
      metaKey: true, // Mac용
      shiftKey: true,
      altKey: false,
    };
  }

  /**
   * 현재 플랫폼이 Mac인지 확인
   * @returns {boolean} Mac 플랫폼 여부
   */
  static isMacPlatform() {
    return /Mac|iPod|iPhone|iPad/.test(navigator.platform);
  }

  /**
   * 단축키 설정을 사람이 읽기 쉬운 형태로 변환
   * @param {Object} shortcutKeys - 변환할 단축키 설정
   * @returns {string} 읽기 쉬운 형태의 단축키 문자열
   */
  static getReadableShortcut(shortcutKeys) {
    const isMac = this.isMacPlatform();
    const parts = [];

    if (isMac) {
      if (shortcutKeys.metaKey) parts.push('⌘');
    } else {
      if (shortcutKeys.ctrlKey) parts.push('Ctrl');
    }

    if (shortcutKeys.shiftKey) parts.push(isMac ? '⇧' : 'Shift');
    if (shortcutKeys.altKey) parts.push(isMac ? '⌥' : 'Alt');
    parts.push(shortcutKeys.key.toUpperCase());

    return parts.join(isMac ? '' : ' + ');
  }
}
