// PopoverTrigger.jsx
import React, { forwardRef, useCallback, useMemo, useEffect } from 'react';
import { isDevelopment } from '@api/apiConfig';

const PopoverTrigger = forwardRef(
  (
    {
      children,
      triggerOn,
      colorMode,
      onToggle,
      onMouseEnter,
      onMouseLeave,
      isOpen,
      popoverId, // 새로 추가된 prop
    },
    ref,
  ) => {
    // 개발 모드에서만 로그 출력
    useEffect(() => {
      if (isDevelopment()) {
        console.log(`PopoverTrigger ${popoverId} - triggerOn:`, triggerOn);
      }
    }, [popoverId, triggerOn]);

    const handleKeyDown = useCallback(
      (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault();
          onToggle();
          if (isDevelopment()) {
            console.log(`PopoverTrigger ${popoverId} - Triggered by keyboard`);
          }
        }
      },
      [onToggle, popoverId],
    );

    const handleClick = useCallback(
      (event) => {
        event.preventDefault();
        event.stopPropagation();
        onToggle();
        if (isDevelopment()) {
          console.log(`PopoverTrigger ${popoverId} - Triggered by click`);
        }
      },
      [onToggle, popoverId],
    );

    const handleTrigger = useMemo(() => {
      if (triggerOn === 'hover') {
        return {
          onMouseEnter: () => {
            onMouseEnter();
            if (isDevelopment()) {
              console.log(`PopoverTrigger ${popoverId} - Mouse entered`);
            }
          },
          onMouseLeave: () => {
            onMouseLeave();
            if (isDevelopment()) {
              console.log(`PopoverTrigger ${popoverId} - Mouse left`);
            }
          },
        };
      }
      return {};
    }, [triggerOn, onMouseEnter, onMouseLeave, popoverId]);

    const buttonClass = useMemo(
      () =>
        colorMode === 'dark'
          ? 'text-gray-200 hover:text-gray-300'
          : 'text-gray-400 hover:text-gray-500',
      [colorMode],
    );

    return (
      <div
        ref={ref}
        role='button'
        tabIndex={0}
        className={`${buttonClass} cursor-pointer transition-opacity duration-200 focus:outline-none`}
        onKeyDown={handleKeyDown}
        onClick={triggerOn === 'click' ? handleClick : undefined}
        aria-haspopup='true'
        aria-expanded={isOpen}
        data-popover-trigger-id={popoverId} // 여기에 data 속성 추가
        {...handleTrigger}
      >
        {children}
      </div>
    );
  },
);

export default React.memo(PopoverTrigger);
