import React, { createContext, useState, useEffect } from 'react';

// 현재 환경 확인을 위한 함수
const getEnvironment = () => {
  try {
    return typeof __MODE__ !== 'undefined' ? __MODE__ : 'production';
  } catch {
    return 'production';
  }
};

export const DebugContext = createContext({
  isDebugMode: false,
  setDebugMode: () => {},
});

export const DebugProvider = ({ children }) => {
  const environment = getEnvironment();
  const [isDebugMode, setDebugMode] = useState(() => {
    const savedMode = localStorage.getItem(
      environment === 'development' ? 'devDebugMode' : 'prodDebugMode',
    );
    return savedMode !== null
      ? savedMode === 'true'
      : environment === 'development';
  });

  useEffect(() => {
    window.toggleDebugMode = () => {
      const newMode = !isDebugMode;
      setDebugMode(newMode);
      localStorage.setItem(
        environment === 'development' ? 'debugMode' : 'prodDebugMode',
        newMode.toString(),
      );
      console.log(`Debug mode ${newMode ? 'enabled' : 'disabled'}`);
    };

    return () => {
      delete window.toggleDebugMode;
    };
  }, [isDebugMode, environment]);

  useEffect(() => {
    localStorage.setItem(
      environment === 'development' ? 'devDebugMode' : 'prodDebugMode',
      isDebugMode.toString(),
    );
  }, [isDebugMode, environment]);

  return (
    <DebugContext.Provider value={{ isDebugMode, setDebugMode }}>
      {children}
    </DebugContext.Provider>
  );
};

export default DebugContext;
