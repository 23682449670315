import React, { forwardRef, memo } from 'react';

const ActionButton = forwardRef(
  (
    {
      pageId,
      name,
      icon: Icon,
      action,
      onClick,
      disabled,
      selectedImageIndex,
      showCopiedMessage,
    },
    ref,
  ) => {
    const isDarkMode = false; // 다크 모드 여부를 결정하는 로직을 추가하세요

    const fontColor =
      selectedImageIndex !== null
        ? isDarkMode
          ? 'text-white'
          : 'text-black'
        : 'text-gray-500';

    // !icon이 두꺼워진 효과
    // const hoverStyle =
    //   selectedImageIndex !== null
    //     ? 'hover:stroke-2 hover:stroke-red-800 hover:text-red-800'
    //     : '';

    // ! icon이 커지는 효과
    // const hoverStyle =
    //   selectedImageIndex !== null
    //     ? 'hover:scale-110 transition-transform duration-200 hover:stroke-red-800 hover:text-red-800'
    //     : '';

    // ! icon이 둥글게 변하는 효과: 배경색 추가
    // const hoverStyle =
    //   selectedImageIndex !== null
    //     ? 'hover:bg-red-100 hover:stroke-red-800 hover:text-red-800 rounded-full p-1'
    //     : '';

    // ! icon이 그림자 효과
    // const hoverStyle =
    //   selectedImageIndex !== null
    //     ? 'hover:opacity-70 hover:stroke-red-800 hover:text-red-800'
    //     : '';

    // ! 배경 색상 전환 효과
    const hoverStyle =
      selectedImageIndex !== null
        ? pageId === 'CreatePromptArea'
          ? 'justify-center bg-transparent text-light-900 dark:text-dark-100 px-1.5 py-1.5 rounded-md transition-color duration-200 ease-in-out hover:bg-light-100 hover:dark:bg-dark-700/50 hover:text-black hover:dark:text-white'
          : 'justify-center bg-transparent text-light-900 dark:text-dark-100 px-1.5 py-1.5 rounded-md transition-color duration-200 ease-in-out hover:bg-light-bg hover:dark:bg-dark-700 hover:text-black hover:dark:text-white'
        : '';

    return (
      <button
        ref={ref}
        onClick={onClick}
        disabled={disabled}
        className={`flex items-center ${fontColor} ${hoverStyle} relative`}
      >
        {Icon}
        {name === 'Copy' && showCopiedMessage && (
          // 버튼 위 중앙
          <span className='absolute left-1/2 top-0 -translate-x-1/2 -translate-y-full transform whitespace-nowrap rounded-md bg-gray-800 px-2 py-1 text-xs text-white'>
            Copied!
          </span>
          // INFO: 버튼 오른쪽에 출력
          // <span className='absolute left-full top-1/2 ml-2 -translate-y-1/2 whitespace-nowrap rounded-md bg-gray-800 px-2 py-1 text-xs text-white'>
          //   Copied!
          // </span>
        )}
      </button>
    );
  },
);

ActionButton.displayName = 'ActionButton';

export default ActionButton;
