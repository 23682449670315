import {
  API_CONFIG,
  apiInstance,
  imageAIInstance,
  translationInstance,
  getApiPath,
} from './apiConfig';

/**
 * 에러 처리 함수
 * @param {Object} errorResponse - 서버에서 반환한 에러 응답
 * @returns {Object} 처리된 에러 객체
 */
const handleError = (errorResponse) => {
  // console.error('API Error:', errorResponse);
  return {
    status: errorResponse.status || 'error',
    message:
      errorResponse.message || errorResponse.msg || 'An unknown error occurred',
    details: errorResponse.details || null,
    data: errorResponse.data || null,
    error_code: errorResponse.error_code || '',
  };
};

/**
 * API 요청 함수
 * @param {Object} params - 요청 파라미터
 * @returns {Promise<Object>} 요청 결과
 */
export const sendRequest = async ({
  instance,
  method,
  url,
  data = null,
  config = {},
}) => {
  try {
    // ! for TEST
    // console.log('sendRequest - Instance baseURL:', instance.defaults.baseURL);
    // console.log('sendRequest - Request URL:', url);
    // console.log(
    //   'sendRequest - Full URL:',
    //   `${instance.defaults.baseURL}${url}`,
    // );
    const response = await instance({
      method,
      url,
      data,
      ...config,
    });

    let responseData;
    if (typeof response.data === 'string') {
      try {
        // JSON 문자열 정규화
        const normalizedJson = response.data.replace(/}{/g, '},{');
        responseData = JSON.parse(`[${normalizedJson}]`);
        // 배열의 모든 객체를 병합
        responseData = Object.assign({}, ...responseData);
      } catch (jsonError) {
        console.error('JSON 파싱 오류:', jsonError);
        throw new Error('서버 응답을 파싱하는 데 실패했습니다.');
      }
    } else {
      responseData = response.data;
    }

    // ! for TEST: responseData를 확인한다.
    console.log('========================');
    console.log('responseData:', responseData);
    console.log('========================');

    if (responseData.status === 'success') {
      return responseData;
    } else {
      throw handleError(responseData);
    }
  } catch (error) {
    if (error.response) {
      // ! 서버 응답이 있는 에러 (예: 400, 500 등의 HTTP 상태 코드)
      console.error('sendRequest - Error response:', error.response);
      throw handleError(error.response.data);
    } else if (error.request) {
      // ! 요청은 보냈지만 응답을 받지 못한 경우
      throw handleError({
        status: 'error',
        message: 'No response received from server',
        details: error.request,
      });
    } else {
      // ! 요청 설정 중에 문제가 발생한 경우
      throw handleError({
        status: 'error',
        data: error.data || null,
        message: error.message || 'Request error',
        details: error.details || 'Request setup failed',
        error_code: error.error_code || 'REQUEST_SETUP_FAILED',
      });
    }
  }
};

/**
 * POST 요청 함수
 * @param {Object} params - 요청 파라미터
 * @param {string} params.url - 요청 URL
 * @param {Object} params.data - POST 데이터
 * @param {boolean} [params.useImageAI=false] - 이미지 AI API 사용 여부
 * @param {Object} [params.config={}] - 추가 설정
 * @returns {Promise<Object>} 요청 결과
 */
export const sendPost = async ({
  instance,
  url,
  data,
  useImageAI = false,
  config = {},
}) => {
  // 적절한 인스턴스 선택 (일반 API 또는 이미지 AI API)
  let selectedInstance;
  if (useImageAI) {
    selectedInstance = imageAIInstance;
  } else if (instance) {
    selectedInstance = instance;
  } else {
    selectedInstance = apiInstance;
  }

  console.log(
    'Using instance with baseURL:',
    selectedInstance.defaults.baseURL,
  );

  return await sendRequest({
    instance: selectedInstance,
    method: 'POST',
    url,
    data,
    config,
  });
};

/**
 * GET 요청 함수
 * @param {Object} params - 요청 파라미터
 * @param {string} params.url - 요청 URL
 * @param {Object} [params.params] - GET 쿼리 파라미터
 * @param {boolean} [params.useImageAI=false] - 이미지 AI API 사용 여부
 * @param {Object} [params.config={}] - 추가 설정
 * @returns {Promise<Object>} 요청 결과
 */
export const sendGet = async ({
  instance,
  url,
  params,
  useImageAI = false,
  config = {},
}) => {
  // 적절한 인스턴스 선택 (일반 API 또는 이미지 AI API)
  let selectedInstance;
  if (useImageAI) {
    selectedInstance = imageAIInstance;
  } else if (instance) {
    selectedInstance = instance;
  } else {
    selectedInstance = apiInstance;
  }

  return await sendRequest({
    instance: selectedInstance,
    method: 'GET',
    url,
    config: { ...config, params },
  });
};

/**
 * 텍스트 번역 함수
 * @param {string} text - 번역할 텍스트
 * @returns {Promise<Object>} 번역 결과
 */
export const translateText = async (text) => {
  console.log(
    'translateText - Instance baseURL:',
    translationInstance.defaults.baseURL,
  );
  try {
    const response = await sendPost({
      instance: translationInstance,
      url: getApiPath('translate'),
      data: { text },
    });

    if (response.status === 'success') {
      return response.data;
    } else {
      throw handleError(response);
    }
  } catch (error) {
    console.error('Translation error:', error);
    throw error;
  }
};

// 필요한 경우 다른 HTTP 메서드 (PUT, DELETE 등)에 대한 함수도 여기에 추가할 수 있습니다.
