import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';

const ModalBody = ({
  children,
  options,
  onClose,
  isFullHeight,
  modalHeight,
  bodyClassName = '',
  // contentClassName,
  darkMode,
  darkModeClasses,
  contentTextClass, // 컨텐츠 텍스트에 추가될 text sizeclass
}) => {
  return (
    <div
      className={`modal-body p-0 overflow-y-auto${
        isFullHeight ? ' h-full' : ''
      } ${bodyClassName} ${contentTextClass}`}
      style={{
        maxHeight: isFullHeight ? '100%' : `calc(${modalHeight} - 4rem)`,
      }}
    >
      {children}
      {options.cb_in_body !== 0 &&
        options.cb !== 0 &&
        options.h === 0 &&
        options.cb_out === 0 && (
          <button
            type='button'
            className='absolute right-4 top-4 z-10 flex h-8 w-8 items-center justify-center rounded-full bg-transparent transition duration-200 ease-in-out hover:bg-light-100 hover:dark:bg-dark-700/75'
            onClick={onClose}
          >
            <XMarkIcon className='h-5 w-5 text-light-500 dark:text-dark-300' />
          </button>
        )}
    </div>
  );
};

export default ModalBody;
