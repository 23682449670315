// src/components/common/Popover/Popover.jsx
import React, { useState, useRef, useEffect, useMemo } from 'react';
import useDebug from '@hooks/useDebug';
import PopoverTrigger from './PopoverTrigger';
import PopoverContent from './PopoverContent';
import { getPlacementClasses, getOffsetStyle } from './popoverStyleUtils';

const defaultOptions = {
  triggerOn: 'click',
  colorMode: 'light',
  placement: 'bottom-start',
  offset: 2,
  style: {
    fontSize: 'sm',
  },
  panel: {
    header: {
      use: true,
      title: '',
      icon: '',
      close: true,
    },
    content: '',
    isContentList: false,
    contentList: {
      listItem: {
        title: '',
        content: '',
      },
    },
    footer: {
      use: false,
      buttons: {
        close: {
          use: true,
          label: 'Close',
        },
        confirm: {
          use: true,
          label: 'Confirm',
        },
      },
    },
  },
};

let popoverCounter = 0;

const Popover = ({ children, options = {}, identifier = '' }) => {
  const { debug } = useDebug();
  const [isOpen, setIsOpen] = useState(false);
  const [isMouseInside, setIsMouseInside] = useState(false);
  const popoverRef = useRef(null);
  const triggerRef = useRef(null);

  const [popoverId] = useState(() => {
    popoverCounter += 1;
    return identifier || `popover_${popoverCounter}`;
  });

  const mergedOptions = useMemo(
    () => ({
      ...defaultOptions,
      ...options,
      offset: Math.max(
        1,
        Math.min(10, options.offset || defaultOptions.offset),
      ),
      panel: {
        ...defaultOptions.panel,
        ...options.panel,
        header: {
          ...defaultOptions.panel.header,
          ...options.panel?.header,
        },
        footer: {
          ...defaultOptions.panel.footer,
          ...options.panel?.footer,
          buttons: {
            ...defaultOptions.panel.footer.buttons,
            ...options.panel?.footer?.buttons,
          },
        },
      },
    }),
    [options],
  );

  // 개발 모드에서만 로그 출력
  useEffect(() => {
    debug(`Popover ${popoverId} options:`, mergedOptions);
  }, [mergedOptions, popoverId, debug]);

  const colorModeClass =
    mergedOptions.colorMode === 'dark' ? 'text-white' : 'text-black';

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        triggerRef.current &&
        !triggerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
        setIsMouseInside(false);
      }
    };

    if (isOpen && mergedOptions.triggerOn === 'click') {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, mergedOptions.triggerOn]);

  const handleToggle = () => {
    setIsOpen((prevState) => !prevState);
    setIsMouseInside(false);
  };

  const handleMouseEnter = () => {
    if (
      mergedOptions.triggerOn === 'hover' ||
      (mergedOptions.triggerOn === 'click' && isOpen)
    ) {
      setIsOpen(true);
      setIsMouseInside(true);
    }
  };

  const handleMouseLeave = () => {
    if (
      mergedOptions.triggerOn === 'hover' ||
      (mergedOptions.triggerOn === 'click' && isMouseInside)
    ) {
      setIsOpen(false);
      setIsMouseInside(false);
    }
  };

  const placementClasses = useMemo(
    () => getPlacementClasses(mergedOptions.placement),
    [mergedOptions.placement],
  );

  const offsetStyle = useMemo(
    () => getOffsetStyle(mergedOptions.placement, mergedOptions.offset),
    [mergedOptions.placement, mergedOptions.offset],
  );

  return (
    <div className='relative'>
      <PopoverTrigger
        ref={triggerRef}
        triggerOn={mergedOptions.triggerOn}
        colorMode={mergedOptions.colorMode}
        onToggle={handleToggle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        isOpen={isOpen}
        popoverId={popoverId}
      >
        {children}
      </PopoverTrigger>
      {isOpen && (
        <PopoverContent
          ref={popoverRef}
          options={mergedOptions.panel}
          onClose={() => {
            setIsOpen(false);
            setIsMouseInside(false);
          }}
          colorMode={mergedOptions.colorMode}
          placementClasses={placementClasses}
          offsetStyle={offsetStyle}
          isOpen={isOpen}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={mergedOptions.style}
          popoverId={popoverId}
        />
      )}
    </div>
  );
};

export default Popover;
