import React, { useState, useEffect, useCallback, useMemo } from 'react';
import useDebug from '@hooks/useDebug';
// contexts
import { useImageAIContext } from '@contexts/ai/image/ImageAIContext';
// imageViewerContext
import { useImageViewer } from '@contexts/ai/service/imageAI/ImageViewer/ImageViewerContext';
// components
import Modal from '@components/common/Modal/Modal';
import ImageArea from './ImageArea';
import PromptArea from './PromptArea';

/**
 * 이미지 뷰어
 * @param {boolean} isOpen - 모달이 열려있는지 여부
 * @param {function} onClose - 모달을 닫는 함수
 * @param {object} data - 이미지 뷰어에 필요한 데ータ
 * - data 객체 정보
 * - messageId: 메시지 ID
 * - imageEl: 이미지 엘리먼트
 * - imageMetadata: 이미지 정보
 * - apiMetadata: 이미지 생성 정보
 * - imageIndex: 이미지 인덱스
 * @returns {React.ReactElement|null}
 */
const ImageViewer = React.memo(() => {
  const { debug } = useDebug('ImageViewer');
  const { isModalOpen, modalData, modalOptions, closeImageViewerModal } =
    useImageViewer();
  const { isDarkMode } = useImageAIContext();
  const [zoomLevel, setZoomLevel] = useState(0);

  const handleZoom = useCallback(() => {
    setZoomLevel((prevZoom) => (prevZoom + 1) % 3);
  }, []);

  const handleClose = useCallback(() => {
    setZoomLevel(0); // 모달을 닫을 때 zoomLevel을 초기화합니다.
    // onClose();
    closeImageViewerModal();
  }, [closeImageViewerModal]);

  const modalContent = useMemo(() => {
    if (!isModalOpen || !modalData) return null;

    return (
      <div
        className={`flex h-full ${
          zoomLevel === 0 ? 'flex-col md:flex-row' : ''
        }`}
      >
        <ImageArea
          imageEl={modalData.imageEl}
          imageMetadata={modalData.imageMetadata}
          onClose={handleClose}
          zoomLevel={zoomLevel}
          onZoom={handleZoom}
        />
        {zoomLevel === 0 && (
          <PromptArea
            imageMetadata={modalData.imageMetadata}
            apiMetadata={modalData.apiMetadata}
            promptMetadata={modalData.promptMetadata}
            imageIndex={modalData.imageIndex}
          />
        )}
      </div>
    );
  }, [isModalOpen, modalData, zoomLevel, handleClose, handleZoom, debug]);

  if (!isModalOpen || !modalData) return null;

  debug('i:*:Check modalParams of ImageViewer: ', {
    modalData,
    modalOptions,
    isDarkMode,
  });

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={handleClose}
      fullscreen={true}
      duration='fast'
      modalOptions={{
        h: 0,
        f: 0,
        cb: 0,
        cb_in_body: 0,
        ...modalOptions,
      }}
      darkMode={isDarkMode}
    >
      {modalContent}
    </Modal>
  );
});

ImageViewer.displayName = 'ImageViewer';

export default ImageViewer;
