import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import useDebug from '@hooks/useDebug';
import { imageApisConfig } from '@config/api/ai/image/apiConfig';

// API 모듈을 동적으로 임포트하기 위한 객체
const apiModules = {
  fluxFal: () => import('@aiImageApi/flux/fal/fluxFalApi'),
  // 여기에 다른 API 모듈들을 추가할 수 있습니다.
  // replicate: () => import('@aiImageApi/replicate/replicateApi'),
  // penai: () => import('@aiImageApi/openai/openaiApi'),
};

/**
 * 이미지 API를 관리하는 커스텀 훅
 *
 * @param {string} initialApiType - 초기 API 타입 (기본값: 'fluxFal')
 * @returns {Object} API 관련 상태 및 함수
 */
const useImageApi = (initialApiType = 'fluxFal') => {
  const { debug } = useDebug(); // 디버그 유틸리티 훅
  const [apiType, setApiType] = useState(initialApiType); // 현재 API 타입 상태

  // * initialApiType에 따라 초기화된 defaultApiConfig를 생성
  // - config/api/ai/image/apiConfig.js에서 설정된 기본 설정을 가져온다.
  const [defaultApiConfig, setDefaultApiConfig] = useState(
    imageApisConfig[apiType],
  );

  const [isLoading, setIsLoading] = useState(true); // API 로딩 상태
  const apiInstanceRef = useRef(null); // API 인스턴스를 저장하는 ref
  const apiTypeRef = useRef(apiType); // 현재 API 타입을 추적하는 ref
  const [apiInstance, setApiInstance] = useState(null);

  /**
   * API 인스턴스를 생성하는 함수
   *
   * @param {string} type - API 타입
   * @returns {Promise<Object|null>} API 인스턴스 또는 null
   */
  const createApiInstance = useCallback(async (type) => {
    // 지원하지 않는 API 타입 체크
    if (!apiModules.hasOwnProperty(type)) {
      console.error(`Unsupported API type: ${type}`);
      return null;
    }

    try {
      // 동적으로 API 모듈 로드
      const module = await apiModules[type]();
      const ApiClass = module.default;

      // 기존 인스턴스가 없거나 다른 타입의 인스턴스인 경우에만 새로 생성
      if (
        !apiInstanceRef.current ||
        !(apiInstanceRef.current instanceof ApiClass)
      ) {
        const newInstance = new ApiClass();
        apiInstanceRef.current = newInstance;
        // const newConfig = newInstance.getDefaultConfig();
        // latestConfigRef.current = newConfig;
        // setDefaultApiConfig(newConfig);
        debug('New API instance created:', type);
      } else {
        debug('Reusing existing API instance:', type);
      }

      return apiInstanceRef.current;
    } catch (error) {
      console.error(`Error loading API module: ${type}`, error);
      return null;
    }
  }, []);

  // API 초기화 및 타입 변경 시 실행되는 효과
  useEffect(() => {
    let isMounted = true; // 컴포넌트 마운트 상태 추적

    const initializeApi = async () => {
      // API 타입이 변경되었거나 인스턴스가 없는 경우에만 초기화
      if (apiTypeRef.current !== apiType || !apiInstanceRef.current) {
        setIsLoading(true);
        const instance = await createApiInstance(apiType);

        if (isMounted) {
          apiTypeRef.current = apiType;
          setApiInstance(instance); // 생성한 API 인스턴스를 저장
          setIsLoading(false);

          // ! apiInstance로 부터 apiConfig를 가져온다.
          // ! - 해당 방법으로 defaultApiConfig를 설정하지 않는다. 추후 영향이 없을 때 아래의 코드 삭제
          // if (instance) {
          //   const newConfig = instance.getDefaultConfig();
          //   latestConfigRef.current = newConfig;
          //   setDefaultApiConfig(newConfig);
          //   debug('API instance initialized:', apiType);
          // }
        }
      }
    };

    initializeApi();

    // 클린업 함수: 컴포넌트 언마운트 시 실행
    return () => {
      isMounted = false;
    };
  }, [apiType, createApiInstance, debug]);

  /**
   * API 타입을 변경하는 함수
   *
   * @param {string} newApiType - 새로운 API 타입
   */
  const changeApiType = useCallback(
    (newApiType) => {
      if (
        apiModules.hasOwnProperty(newApiType) &&
        newApiType !== apiTypeRef.current
      ) {
        setApiType(newApiType);
        debug('API type changed:', newApiType);
      } else if (newApiType === apiTypeRef.current) {
        debug('API type unchanged:', newApiType);
      } else {
        console.error(`Unsupported API type: ${newApiType}`);
      }
    },
    [debug],
  );

  // ! for TEST
  // defaultApiConfig 변경을 추적하는 효과
  // useEffect(() => {
  //   if (defaultApiConfig) {
  //     debug('useImageApi() > defaultApiConfig updated:', defaultApiConfig);
  //   }
  // }, [defaultApiConfig, debug]);

  // useEffect(() => {
  //   if (apiInstance) {
  //     debug('useImageApi() > apiInstance updated:', apiInstance);
  //   }
  // }, [apiInstance, debug]);

  // 훅의 반환값: API 관련 상태 및 함수
  return {
    apiType,
    // apiInstance: apiInstanceRef.current,
    apiInstance, // apiInstanceRef.current 값이 아닌 apiInstance 값을 전달
    defaultApiConfig,
    changeApiType,
    isLoading,
  };
};

export default useImageApi;
