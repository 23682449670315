import React from 'react';

const ModalFooter = ({
  options,
  onClose,
  onConfirm,
  confirmText = '',
  cancelText = '',
  footerClassName = '',
  buttonTextClass,
  buttonShapeClass,
  footerSizeClass,
}) => {
  if (options.f === 0) return null;
  const borderClass = options.f_b ? 'border-t border-light-100' : '';
  confirmText = options.fb_confirm_text || confirmText;
  cancelText = options.fb_cancel_text || cancelText;

  return (
    <div
      className={`modal-footer flex items-center justify-end ${borderClass} ${footerSizeClass} ${footerClassName}`}
    >
      {options.fb !== 0 && (
        <>
          {options.fb_cancel !== 0 && (
            <button
              type='button'
              className={`mr-2 ${buttonShapeClass} border border-gray-300 bg-white px-4 py-2 ${buttonTextClass} hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
              onClick={onClose}
            >
              {cancelText}
            </button>
          )}
          {options.fb_confirm !== 0 && (
            <button
              type='button'
              className={`${buttonShapeClass} border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
              onClick={onConfirm}
            >
              {confirmText}
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default ModalFooter;
