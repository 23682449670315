// src/components/ImageAIChat/components/ImageAIChatModal.jsx
import React, { useEffect } from 'react';
import useDebug from '@hooks/useDebug';
import { useImageAIContext } from '@contexts/ai/image/ImageAIContext';
import Modal from '@components/common/Modal/Modal';
import ImageAIChatUI from './ImageAIChatUI';
import PluginWrapper from './PluginWrapper'; // 추가된 import

// NOTICE: ImageAI 플러그인 내의 ChatCreatedImagesProvider를 사용한다.
// - ChatCreatedImagesProvider는, CreatedImagesProvider를 상속받아 사용한다.
import { ChatCreatedImagesProvider } from '../contexts/ChatCreatedImagesContext';

// NOTICE: ImageAI 플러그인 내의 ImageViewer 모달을 위한 컨텍스트, 공통 컨텍스트를 사용한다.
// INFO: ImageViewer 컴포넌트는 프로젝트 전체에서 공통으로 사용한다.
import { ImageViewerProvider } from '@contexts/ai/service/imageAI/ImageViewer/ImageViewerContext';
import ImageViewer from '@components/ImageAI/ImageViewer';

/**
 * 이미지 AI 채팅 모달 컴포넌트
 * - Context Providers를 적절한 순서로 구성
 * - ImageAIChatUI를 모달 내부에 렌더링
 * - ImageViewer를 전역적으로 사용할 수 있도록 구
 */
const ImageAIChatModal = ({ isOpen, onClose, modalOptions = {} }) => {
  const { debug } = useDebug('ImageAIChatModal');
  const { isDarkMode } = useImageAIContext();

  useEffect(() => {
    debug('i:*:ImageAIChatModal > isDarkMode changed: ', { isDarkMode });
  }, [isDarkMode]);

  return (
    <PluginWrapper>
      <ChatCreatedImagesProvider>
        <ImageViewerProvider modalOptions={modalOptions}>
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            title='AI Chat'
            width='xl'
            height='full'
            position='right'
            duration='fast'
            modalOptions={{
              h: 0,
              f: 0,
              cb: 1,
              cb_in_body: 1,
              ...modalOptions,
            }}
            darkMode={isDarkMode}
            // NOTICE: 모달 이름 접두사: supereasy-imageaiagent-dialog, supereasy-imageaiagent-panel, supereasy-imageaiagent-container
            modalNamePrefix='supereasy-imageaiagent'
            // NOTICE: 모달 내부 요소에 적용되는 CSS 접두사: imageaiagent
            // - CSS 충돌 방지: 다른 플러그인이나 컴포넌트의 스타일과 충돌을 방지
            // - 스타일 스코핑: 이 모달 내부에서만 적용되는 스타일을 격리
            // - 예시: .button -> .imageaiagent .button
            // - PostCSS 플러그인에 의해 모든 CSS 선택자에 자동으로 prefix가 추가됨
            cssPrefixSelector='imageaiagent'
          >
            <ImageAIChatUI onClose={onClose} />
          </Modal>
          <ImageViewer />
        </ImageViewerProvider>
      </ChatCreatedImagesProvider>
    </PluginWrapper>
  );
};

export default ImageAIChatModal;
