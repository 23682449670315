import { initializeModule } from './initialization.js';
import { InstanceManager } from './InstanceManager.js';
import { CallbackManager } from './CallbackManager.js';
import { UIManager } from './UIManager.jsx';
import { ConfigManager } from './ConfigManager.js';
import { defaultOptions } from '../config/defaultOptions.js';
import createDebugger from '@utils/debug';
import { defaultImageAIChatConfig } from '@plugins/ImageAIChat/config/imageAIChatConfig';
import { exchangeAuthCode } from '@api/authService';

class ImageAIAgent {
  static _instances = new InstanceManager();

  constructor(element, options = {}) {
    // 기존 인스턴스 체크 로직
    if (element) {
      const existingInstance = ImageAIAgent.getInstanceByElement(element);
      if (existingInstance) {
        existingInstance.updateOptions(options);
        return existingInstance;
      }
    } else {
      const pageId = options.pageId || 'default';
      const existingInstance = ImageAIAgent._instances.findByPageId(pageId);
      if (existingInstance) {
        existingInstance.updateOptions(options);
        return existingInstance;
      }
    }

    initializeModule();

    this._id = Math.round(Math.random() * 99999);
    this.pluginName = `ImageAIAgent_${this._id}`;
    this.element = element || null;
    this.$element = element ? $(element) : null;

    // defaultImageAIChatConfig 추가
    this.defaultImageAIChatConfig = defaultImageAIChatConfig;

    // 설정 초기화
    this.configManager = new ConfigManager(this, element, options);
    this.options = this.configManager.getOptions();

    // 디버그 초기화
    const debug = createDebugger(this.pluginName);
    debug.setDebugMode(this.options.debugMode);
    this.debug = debug.debug;

    // 매니저 초기화
    this.callbackManager = new CallbackManager(this);
    this.uiManager = new UIManager(this);

    // 인스턴스 등록
    ImageAIAgent._instances.add(this._id, this);

    // External API 초기화
    this._externalAPI = {
      /**
       * ImageAIAgent을 특정 컨텍스트로 열기
       * @param {Object} context - 컨텍스트 정보
       */
      openWithContext: (context) => {
        this.debug('i:*:Opening ImageAIAgent with context:', context);
        this._externalContext = context;
        this.openAgent();
      },

      /**
       * 이미지 처리 결과를 받을 콜백 등록
       * @param {Function} callback - 콜백 함수
       */
      registerCallback: (callback) => {
        this.callbackManager.registerExternalCallback(callback);
      },

      /**
       * 에이전트 닫기
       */
      close: () => {
        this.closeAgent();
      },
    };

    this.init();
  }

  /**
   * 플러그인 재초기화
   */
  reinitialize() {
    this.debug('i:*:Reinitializing ImageAIAgent Plugin');

    // 현재 옵션 저장
    const currentOptions = { ...this.options };

    // UI 매니저 재초기화
    this.uiManager.cleanup();

    // 설정 매니저 재초기화 (기존 옵션 유지)
    this.configManager = new ConfigManager(this, this.element, currentOptions);
    this.options = this.configManager.getOptions();

    // UI 매니저 재생성 (새로운 설정으로)
    this.uiManager = new UIManager(this);
    this.uiManager.initialize();

    // 콜백 매니저 재초기화
    this.callbackManager.initialize();

    // 설정 재검증
    this.configManager.validateShortcutKeys();

    this.debug(
      's:*:ImageAIAgent Plugin reinitialized with options:',
      this.options,
    );
  }

  /**
   * auth_code 처리를 위한 메서드
   * @returns {Promise<void>}
   */
  async handleAuthCode() {
    try {
      const params = new URLSearchParams(window.location.search);
      const authCode = params.get('auth_code');

      if (authCode) {
        this.debug('i:*:Processing auth_code:', authCode);

        try {
          // auth_code를 토큰으로 교환
          const tokenData = await exchangeAuthCode(authCode);

          if (tokenData) {
            this.debug('s:*:Auth code processed successfully');

            // 콜백 실행
            this.callbackManager.executeCallback(
              this.options.callbacks.onAuthSuccess,
              tokenData,
            );

            // 인증 성공 후 플러그인 재초기화
            this.reinitialize();

            // 재초기화 후 자동으로 모달 열기
            setTimeout(() => {
              this.debug('i:*:Auto opening agent after successful auth');
              this.openAgent();
            }, 100);

            return true;
          }
        } catch (error) {
          this.debug('e:*:Error processing auth_code:', error);

          // 에러 메시지 alert
          const errorMsg = `Authentication failed. [${error.error_code}]`;
          alert(errorMsg);

          // 콜백 실행
          this.callbackManager.executeCallback(
            this.options.callbacks.onAuthError,
            error,
          );

          throw error;
        } finally {
          // 성공/실패 상관없이 URL에서 auth_code 파라미터 제거
          const newUrl =
            window.location.pathname +
            window.location.search.replace(/[?&]auth_code=[^&]+/, '');
          window.history.replaceState({}, '', newUrl);
        }
      }
      return false;
    } catch (error) {
      this.debug('e:*:Unexpected error in handleAuthCode:', error);
      throw error;
    }
  }

  init() {
    this.debug('i:*:Initializing ImageAIAgent Plugin:', {
      options: this.options,
      environment: this.configManager.getEnvironmentInfo(),
    });

    // auth_code 처리 추가
    this.handleAuthCode().catch((error) => {
      console.error('Failed to process auth_code:', error);
    });

    this.uiManager.initialize();
    this.callbackManager.initialize();
    this.configManager.validateShortcutKeys();
  }

  /**
   * 에이전트 상태 토글
   * @returns {boolean} 토글 후 에이전트 상태
   */
  toggleAgent = () => {
    return this.isModalOpen ? this.closeAgent() : this.openAgent();
  };

  /**
   * 키보드 단축키 트리거 핸들러
   */
  handleKeyboardTrigger = () => {
    this.toggleAgent();
  };

  /**
   * 에이전트 열기
   * @returns {boolean} 성공 여부
   */
  openAgent = () => {
    if (this.uiManager.isModalOpen) {
      return false;
    }

    // 모달을 열기 전에 상태 재확인을 위해 재초기화
    // this.reinitialize();

    this.uiManager.isModalOpen = true;
    this.uiManager.renderReactComponent();

    this.callbackManager.executeCallback(
      this.options.callbacks.onImageAIAgentOpen,
    );
    this.callbackManager.executeCallback(
      this.options.callbacks.onImageAIAgentToggle,
      true,
    );

    return true;
  };

  /**
   * 에이전트 닫기
   * @returns {boolean} 성공 여부
   */
  closeAgent = () => {
    if (!this.uiManager.isModalOpen) {
      return false;
    }

    this.uiManager.isModalOpen = false;
    this.uiManager.renderReactComponent();

    this.callbackManager.executeCallback(
      this.options.callbacks.onImageAIAgentClose,
    );
    this.callbackManager.executeCallback(
      this.options.callbacks.onImageAIAgentToggle,
      false,
    );

    return true;
  };

  /**
   * 이미지 결과 처리 핸들러
   * @param {Object} result - 이미지 처리 결과
   */
  handleImageResult = (result) => {
    if (this.options.onImageResult) {
      this.options.onImageResult(result);
    }
  };

  /**
   * 외부 API 접근을 위한 정적 getter
   */
  static get externalAPI() {
    // 현재 활성화된 인스턴스를 찾음
    const activeInstance = ImageAIAgent._instances.getActiveInstance();

    if (!activeInstance) {
      console.warn('ImageAIAgent instance not initialized');
      return null;
    }
    return activeInstance._externalAPI;
  }

  /**
   * 인스턴스 정리
   */
  destroy() {
    if (this.$element) {
      this.$element.off();
    }

    this.uiManager.cleanup();
    ImageAIAgent._instances.remove(this._id);

    this.debug('s:*:ImageAIAgent instance destroyed:', {
      id: this._id,
      remainingInstances: ImageAIAgent._instances.size(),
    });
  }

  /**
   * 옵션 업데이트
   * @param {Object} newOptions - 새로운 옵션
   */
  updateOptions(newOptions = {}) {
    this.configManager.updateOptions(newOptions);
    this.uiManager.renderReactComponent();

    this.debug('s:*:Options updated for instance:', {
      id: this._id,
      pageId: this.options.pageId,
      options: this.options,
    });
  }

  /**
   * 현재 인스턴스 식별자 가져오기
   * @returns {Object} 인스턴스 식별 정보
   */
  getIdentifier() {
    return {
      id: this._id,
      pageId: this.options.pageId,
      element: this.element,
    };
  }

  /**
   * 정적 인스턴스 관리 메서드들
   */
  static hasInstance(id) {
    return ImageAIAgent._instances.get(id) !== null;
  }

  static getInstance(id) {
    return ImageAIAgent._instances.get(id);
  }

  static getInstanceByElement(element) {
    return ImageAIAgent._instances.findByElement(element);
  }

  static getAllInstances() {
    return ImageAIAgent._instances.getAllInstances();
  }
}

// 전역 객체에 추가
if (typeof window !== 'undefined') {
  window.ImageAIAgent = ImageAIAgent;
}

// CommonJS, AMD 모듈 지원
if (typeof module === 'object' && module.exports) {
  module.exports = ImageAIAgent;
} else if (typeof define === 'function' && define.amd) {
  define(() => ImageAIAgent);
}

export default ImageAIAgent;
