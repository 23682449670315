import axios from 'axios';
import { API_CONFIG, isDevelopment } from './apiConfig';

// const instance = axios.create({
//   baseURL:
//     API_CONFIG.url.base[
//       process.env.NODE_ENV === 'development' ? 'dev' : 'prod'
//     ],
// });

// axios 인스턴스 생성
const instance = axios.create({
  baseURL: API_CONFIG.url.base[isDevelopment() ? 'dev' : 'prod'],
  timeout: 30000, // 30초
  headers: {
    'Content-Type': 'application/json',
  },
});

export const setAuthToken = (token) => {
  console.log('setAuthToken called with token:', token);
  if (token) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    console.log(
      'Authorization header set:',
      instance.defaults.headers.common['Authorization'],
    );
  } else {
    delete instance.defaults.headers.common['Authorization'];
    console.log('Authorization header removed');
  }
};

export const removeAuthToken = () => {
  delete instance.defaults.headers.common['Authorization'];
};

let refreshTokenPromise = null;

export const setRefreshTokenFunction = (refreshFunction) => {
  refreshTokenPromise = refreshFunction;
};

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!refreshTokenPromise) {
        return Promise.reject(new Error('Refresh token function not set'));
      }

      try {
        await refreshTokenPromise();
        return instance(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  },
);

export default instance;
