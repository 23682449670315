import React, { useState, useCallback, useMemo } from 'react';
import useDebug from '@hooks/useDebug';
import { AlignLeft, Globe } from 'lucide-react';

// NOTICE: ImageAI Service에서 사용하는 ImageActionButtons 컴포넌트를 공통으로 사용한다.
import ImageActionButtons from '@components/ImageAI/ImageActionButtons';

// NOTICE: ImageAIChat에서의 프롬프트 최대 포인트 길이
// ! - ImageAI Service에서 보다는 짧게 설정
const MAX_VISIBLE_POINTS = 150;

// 문자열의 '포인트' 길이를 계산하는 함수
const getTextPoints = (str) => {
  return Array.from(str).reduce((points, char) => {
    const code = char.charCodeAt(0);
    return points + (code < 128 ? 1 : 2);
  }, 0);
};

// 문자열을 주어진 포인트 길이로 자르는 함수
const truncateTextByPoints = (text, maxPoints) => {
  let points = 0;
  for (let i = 0; i < text.length; i++) {
    const char = text[i];
    const charPoints = char.charCodeAt(0) < 128 ? 1 : 2;
    if (points + charPoints > maxPoints) {
      return text.slice(0, i) + '...';
    }
    points += charPoints;
  }
  return text;
};

/**
 * 사용자 프롬프트 메시지 컴포넌트
 * INFO: ImageAI > Create > CreatedImageList > PromptArea 컴포넌트와 동일한 기능
 * - 채팅 UI에 맞게 스타일링
 * - 프롬프트 확장/축소 기능
 * - 원본 프롬프트 표시 기능
 * - 프롬프트 복사 기능
 * - 프롬프트 언어 변경 기능
 */
const PromptMessage = ({
  apiMetadata,
  promptMetadata,
  imageCount,
  isGeneratingImage,
  onToggleExpand,
  onSubmit,
  lang,
  currentLang,
}) => {
  const { debug } = useDebug('PromptMessage');
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOriginalExpanded, setIsOriginalExpanded] = useState(false);
  const [showOriginalPrompt, setShowOriginalPrompt] = useState(false);

  const promptId = promptMetadata?.external_prompt_id;
  const isNonEnglishPrompt = promptMetadata?.original_language !== 'en';

  const finalPrompt = promptMetadata?.final_prompt || '';
  const originalPrompt = promptMetadata?.original_prompt || '';
  const originalPromptLanguage = promptMetadata?.original_language || 'en';

  const finalPromptPoints = useMemo(
    () => getTextPoints(finalPrompt),
    [finalPrompt],
  );

  const originalPromptPoints = useMemo(
    () => getTextPoints(originalPrompt),
    [originalPrompt],
  );

  const showMoreButton = finalPromptPoints > MAX_VISIBLE_POINTS;
  const showMoreOriginalButton = originalPromptPoints > MAX_VISIBLE_POINTS;

  const toggleExpand = useCallback(
    (isOriginal = false) => {
      if (isOriginal) {
        setIsOriginalExpanded(!isOriginalExpanded);
      } else {
        setIsExpanded(!isExpanded);
      }
      onToggleExpand(isOriginal);
    },
    [isExpanded, isOriginalExpanded, onToggleExpand],
  );

  const toggleOriginalPrompt = useCallback(
    (e) => {
      e.preventDefault();
      setShowOriginalPrompt(!showOriginalPrompt);
    },
    [showOriginalPrompt],
  );

  const copyPrompt = useCallback(
    (text) => (e) => {
      e.preventDefault();
      navigator.clipboard.writeText(text);
    },
    [],
  );

  // lang 버튼의 클래스를 결정하는 함수
  const getLangButtonClass = useCallback(() => {
    const baseClass =
      'inline-flex items-center gap-1.5 text-xs tracking-wide leading-tight px-2 py-1 rounded-md transition-all duration-200 ease-in-out';
    const inactiveClass =
      'bg-light-50 dark:bg-dark-800 text-light-500 dark:text-dark-300 hover:bg-light-100 hover:dark:bg-dark-700/50 hover:text-light-600 hover:dark:text-dark-100';
    const activeClass =
      'text-red-600 bg-red-600/10 dark:bg-red-600/20 shadow-lg shadow-red-600/5 dark:shadow-red-600/10';

    return `${baseClass} ${showOriginalPrompt ? activeClass : inactiveClass}`;
  }, [showOriginalPrompt]);

  // ImageActionButtons에 전달할 props
  const actionProps = {
    promptText: finalPrompt,
    isOriginalPrompt: false,
    originalPromptLanguage,
  };

  // originalPrompt용 actionProps
  const originalActionProps = useMemo(
    () => ({
      promptText: originalPrompt,
      isOriginalPrompt: true,
      originalPromptLanguage,
    }),
    [originalPrompt, originalPromptLanguage],
  );

  return (
    <div className='ml-auto flex w-full max-w-[85%] flex-col items-end gap-2 lg:max-w-[75%]'>
      <div className='rounded-lg bg-light-primary/5 p-3 dark:bg-dark-primary/10'>
        <p className='text-sm text-light-900 dark:text-dark-50'>
          {isExpanded
            ? finalPrompt
            : truncateTextByPoints(finalPrompt, MAX_VISIBLE_POINTS)}
        </p>
        <div className='mt-2 flex flex-wrap items-center justify-end gap-2'>
          {showMoreButton && (
            <button
              onClick={() => toggleExpand(false)}
              className='inline-flex items-center gap-1.5 rounded-md bg-light-50/50 px-2 py-1 text-xs leading-tight tracking-wide text-light-700 transition-colors duration-200 hover:bg-light-100/50 dark:bg-dark-800/30 dark:text-dark-200 hover:dark:bg-dark-700/40'
            >
              <AlignLeft size={14} strokeWidth={1.5} />
              <span>{isExpanded ? lang('간략히보기') : lang('더보기')}</span>
            </button>
          )}
          {isNonEnglishPrompt && (
            // <div className='flex items-center gap-1 text-xs text-light-600 dark:text-dark-300'>
            //   <Globe size={14} strokeWidth={1.5} />
            //   <span>{promptMetadata.original_language?.toUpperCase()}</span>
            // </div>
            <button
              className={getLangButtonClass()}
              onClick={toggleOriginalPrompt}
            >
              <Globe size={14} strokeWidth={1.5} />
              <span>{promptMetadata.original_language?.toUpperCase()}</span>
            </button>
          )}
          {/* <ImageActionButtons
            pageId='ChatMessage'
            promptId={promptId}
            actions={['edit', 'copy']}
            onSubmit={onSubmit}
            isInline={true}
            buttonSize='14'
            actionProps={actionProps}
          /> */}
          {!isGeneratingImage && (
            // NOTICE: 프롬프트 영역에 버튼 그룹 출력
            <ImageActionButtons
              pageId='PromptMessage'
              promptId={promptId}
              actions={['edit', 'copy']}
              onSubmit={onSubmit}
              isInline={true}
              buttonSize='14'
              actionProps={actionProps}
            />
          )}
        </div>
        {/* originalPrompt 영역 */}
        {isNonEnglishPrompt && showOriginalPrompt && (
          <div className='relative mb-6 mt-2'>
            <p className='text-xs text-light-600 dark:text-dark-200 lg:text-sm'>
              {isOriginalExpanded
                ? originalPrompt
                : truncateTextByPoints(originalPrompt, MAX_VISIBLE_POINTS)}
            </p>
            <div className='mt-2 flex flex-wrap gap-1'>
              {showMoreOriginalButton && (
                <button
                  className='transition-color inline-flex items-center gap-1.5 rounded-md bg-light-50 px-2 py-1 text-xs leading-tight tracking-wide text-light-500 duration-200 ease-in-out hover:bg-light-100 hover:text-light-600 dark:bg-dark-800 dark:text-dark-300 hover:dark:bg-dark-700/50 hover:dark:text-dark-100'
                  onClick={() => toggleExpand(true)}
                >
                  <AlignLeft size={16} strokeWidth={1.5} />
                  <span>
                    {isOriginalExpanded ? lang('간략히보기') : lang('더보기')}
                  </span>
                </button>
              )}
              {/* 프롬프트 영역에 버튼 그룹 출력 */}
              <ImageActionButtons
                pageId='CreatePromptArea'
                promptId={promptId}
                actions={['edit', 'copy']}
                onSubmit={onSubmit}
                isInline={true}
                buttonSize='14'
                actionProps={originalActionProps}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PromptMessage;
