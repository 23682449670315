import { API_CONFIG } from '@api/apiConfig';

export const debugAgentTranslation = (key, pluginResult, mainResult) => {
  if (API_CONFIG.debug) {
    console.group('Agent Translation Debug');
    console.log('Key:', key);
    console.log('Plugin translation:', pluginResult);
    console.log('Main translation:', mainResult);
    console.groupEnd();
  }
};
