// src/components/common/Popover/popoverStyleUtils.js

export const getFontSizeClasses = (element, fontSize = 'md') => {
  const baseClasses = {
    md: {
      header: 'text-base',
      content: 'text-sm',
      icon: 'h-5 w-5',
      padding: {
        header: 'py-3 px-4',
        content: 'py-5 px-4',
        footer: 'py-3 px-4',
      },
    },
    sm: {
      header: 'text-sm',
      content: 'text-xs',
      icon: 'h-4 w-4',
      padding: {
        header: 'py-3 px-4',
        content: 'py-5 px-4',
        footer: 'py-3 px-4',
      },
    },
    xs: {
      header: 'text-xs',
      content: 'text-[14px] leading-[19px]',
      icon: 'h-3.5 w-3.5',
      padding: {
        header: 'py-3 px-3',
        content: 'py-4 px-3',
        footer: 'py-3 px-3',
      },
    },
  };

  if (element.startsWith('padding-')) {
    const part = element.split('-')[1];
    return baseClasses[fontSize].padding[part];
  }

  return baseClasses[fontSize][element];
};

export const getIconSize = (fontSize = 'md') => {
  const sizes = {
    lg: 24,
    md: 20,
    sm: 16,
    xs: 12,
  };
  return sizes[fontSize] || sizes.md;
};

export const getPlacementClasses = (placement) => {
  const [mainPlacement, subPlacement] = placement.split('-');

  const baseClasses = {
    bottom: 'top-full',
    top: 'bottom-full',
    left: 'right-full',
    right: 'left-full',
  };

  const alignmentClasses = {
    start: {
      bottom: 'left-0',
      top: 'left-0',
      left: 'top-0',
      right: 'top-0',
    },
    end: {
      bottom: 'right-0',
      top: 'right-0',
      left: 'bottom-0',
      right: 'bottom-0',
    },
  };

  let classes = baseClasses[mainPlacement];

  if (subPlacement) {
    classes += ` ${alignmentClasses[subPlacement][mainPlacement]}`;
  } else {
    // Center alignment for main placements without subplacement
    classes +=
      mainPlacement === 'left' || mainPlacement === 'right'
        ? ' top-1/2 -translate-y-1/2'
        : ' left-1/2 -translate-x-1/2';
  }

  return classes;
};

export const getOffsetStyle = (placement, offset) => {
  const [mainPlacement] = placement.split('-');
  const pixelOffset = offset * 4;

  switch (mainPlacement) {
    case 'top':
      return { marginBottom: `${pixelOffset}px` };
    case 'bottom':
      return { marginTop: `${pixelOffset}px` };
    case 'left':
      return { marginRight: `${pixelOffset}px` };
    case 'right':
      return { marginLeft: `${pixelOffset}px` };
    default:
      return {};
  }
};
