// src/pages/ai/ImageAI/useImageAIState.js
import { useState, useCallback, useEffect } from 'react';
import useDebug from '@hooks/useDebug';
import { useThemeContext } from '@contexts/theme/ThemeContext';

import { modelConfigs } from '@config/api/ai/image/modelConfig';

// ! 샘플 이미지 정보
// imageInfo: {
//   // sample image info
//   'ai-image-message-1725357999461': {
//     apiMetadata: {
//       api_provider: 'fal',
//       model: 'fal-ai/flux/dev',
//       prompt:
//         'A world of fashion, luxury and glamour, the most beautiful top model with brown hair in a black leather jacket. She poses for an Instagram post with multiple rings on her fingers. She has blue eyes, the photo was taken on a neutral background in natural light, linen background, taken with a Sony camera.',
//       image_size: 'landscape_4_3',
//       num_inference_steps: 28,
//       seed: 1725357988101,
//       guidance_scale: 7,
//       sync_mode: false,
//       num_images: 1,
//       enable_safety_checker: true,
//       isBase64: true,
//     },
//     imageInfo: {
//       url: 'https://fal.media/files/penguin/9GARLl-ZxS3N3SSeXinMb.png',
//       fullFilename: '9GARLl-ZxS3N3SSeXinMb.png',
//       filename: '9GARLl-ZxS3N3SSeXinMb',
//       extension: 'png',
//     },
//     s3Metadata: {
//       s3_key: 'ai/image/se_ai_e75f7b43_2eaa_4c96_abc9_0bc94fc1e38e.png',
//       bucket_name: 'supereasy-ai',
//       e_tag: '613d812bd9d261fbe700982f5584ae1b',
//       size: 786153,
//       content_type: 'image/png',
//       last_modified: '2024-09-04T12:03:44.000Z',
//       region: 'ap-northeast-2',
//       s3_uri:
//         's3://supereasy-ai/ai/image/se_ai_e75f7b43_2eaa_4c96_abc9_0bc94fc1e38e.png',
//       arn: 'arn:aws:s3:::supereasy-ai/ai/image/se_ai_e75f7b43_2eaa_4c96_abc9_0bc94fc1e38e.png',
//       object_url:
//         'https://supereasy-ai.s3.ap-northeast-2.amazonaws.com/ai/image/se_ai_e75f7b43_2eaa_4c96_abc9_0bc94fc1e38e.png',
//       is_cdn: true,
//       cdn_url:
//         'https://cdn.supereasy.co.kr/ai/image/se_ai_e75f7b43_2eaa_4c96_abc9_0bc94fc1e38e.png',
//     },
//   },
// },

/**
 * 이미지 AI 상태를 관리하는 훅
 * @param {*} initialConfig
 * @returns
 */
const useImageAIState = (initialConfig = null) => {
  const { debug } = useDebug('useImageAIState');
  // ThemeContext가 존재하는 경우에만 사용
  const themeContext = useThemeContext ? useThemeContext() : null;
  // 상위 테마 정보의 다크 모드 설정 함수
  const setThemeDarkMode = themeContext ? themeContext.setThemeDarkMode : null;
  // localStorage key for dark mode
  const DARK_MODE_KEY =
    initialConfig && initialConfig.darkMode?.storageKey
      ? initialConfig.darkMode.storageKey
      : 'imageAIChat_darkMode';

  // Initialize dark mode state from localStorage
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem(DARK_MODE_KEY);
    return savedMode ? JSON.parse(savedMode) : false;
  });

  // 프롬프트: 이미지 생성을 위한 짧은 설명
  const [prompt, setPrompt] = useState('');
  // 프롬프트 설정: 이미지 생성을 위한 상세 설정
  const [promptSettings, setPromptSettings] = useState({});
  // 사용 가능한 api 모델 리스트
  const [modelList, setModelList] = useState([]);
  // 선택된 api 모델
  const [selectedModel, setSelectedModel] = useState('');
  // 이미지 정보: 이미지 생성 결과를 저장
  const [imageInfo, setImageInfo] = useState({});

  const setDefaultSettings = useCallback(
    ({ defaultApiConfig }) => {
      debug('i:*:setDefaultSettings', { defaultApiConfig });
      setPrompt('');
      setModelList(defaultApiConfig.model_options);
      const defaultModel = defaultApiConfig.model_options.find(
        (model) => model.default,
      );

      // 기본 모델을 선택된 모델로 설정을 한다.
      setSelectedModel(defaultModel);

      // 기본 설정을 promptSettings에 설정하고, 모델 설정을 확장합니다.
      const initialSettings = defaultApiConfig.api_options;
      const modelConfig = modelConfigs[defaultModel.key];

      if (modelConfig) {
        Object.keys(modelConfig).forEach((key) => {
          if (key !== 'constraints') {
            initialSettings[key] = modelConfig[key].default;
          }
        });
      }

      debug('i:*:Check initialSettings: ', { initialSettings });

      setPromptSettings(initialSettings);
    },
    [
      setPrompt,
      setPromptSettings,
      setModelList,
      setSelectedModel,
      modelConfigs,
    ],
  );

  /**
   * 선택된 모델에 따라 설정을 업데이트하는 함수
   *
   * @function updateSettingsForModel
   * @param {Object} model - 선택된 모델 객체
   *
   * @description
   * 이 함수는 선택된 모델에 따라 설정을 동적으로 업데이트합니다.
   * 모델이 변경될 때마다 호출되어, 해당 모델에 적합한 기본 설정값으로 업데이트합니다.
   *
   * 동작 원리:
   * 1. modelConfigs 객체에서 선택된 모델의 설정을 가져옵니다.
   * 2. 현재 설정을 복사하여 새로운 설정 객체를 생성합니다.
   * 3. 모델 설정의 각 키에 대해 반복하며:
   *    - 'constraints'가 아닌 키에 대해서만 처리합니다.
   *    - 각 설정 항목의 기본값(default)을 새 설정 객체에 할당합니다.
   * 4. 업데이트된 설정을 상태로 설정합니다.
   *
   * 주의사항:
   * - 이 함수는 useCallback으로 메모이제이션되어 있어, dependencies([settings, setSettings])가
   *   변경될 때만 재생성됩니다.
   * - modelConfigs에 정의되지 않은 모델이 선택된 경우, 설정이 변경되지 않습니다.
   *
   * @returns {void} 이 함수는 값을 반환하지 않고, 내부적으로 상태를 업데이트합니다.
   */
  const updateSettingsForModel = useCallback(
    (model) => {
      // 선택된 모델의 설정을 modelConfigs에서 가져옵니다.
      const modelConfig = modelConfigs[model.key];

      // 모델 설정이 존재하는 경우에만 처리합니다.
      if (modelConfig) {
        // 현재 설정을 복사하여 새로운 설정 객체를 생성합니다.
        const newSettings = { ...promptSettings };

        // 모델 설정의 각 키에 대해 반복합니다.
        Object.keys(modelConfig).forEach((key) => {
          // 'constraints'가 아닌 키에 대해서만 처리합니다.
          if (key !== 'constraints') {
            // 각 설정 항목의 기본값을 새 설정 객체에 할당합니다.
            newSettings[key] = modelConfig[key].default;
          }
        });

        debug('i:*:updateSettingsForModel', {
          newSettings,
          model,
          modelConfig,
        });

        // 업데이트된 설정을 상태로 설정합니다.
        setPromptSettings(newSettings);
      }
    },
    [promptSettings, setPromptSettings],
  );

  const toggleDarkModeHandler = useCallback(() => {
    const newMode = !isDarkMode;
    setIsDarkMode(newMode);

    // 테마의 다크모드를 설정한다.
    if (setThemeDarkMode && typeof setThemeDarkMode === 'function') {
      setThemeDarkMode(newMode);
    }
    localStorage.setItem(DARK_MODE_KEY, JSON.stringify(newMode));
  }, [isDarkMode, setIsDarkMode, setThemeDarkMode]);

  // 다크 모드 적용
  // - darkMode.targetElementId 가 존재하는 경우, 해당 요소에 다크 모드 클래스를 적용
  useEffect(() => {
    if (initialConfig && initialConfig.darkMode?.targetElementId) {
      const targetElement = document.getElementById(
        initialConfig.darkMode.targetElementId,
      );
      if (targetElement) {
        targetElement.classList.toggle('dark', isDarkMode);
      }
    }

    debug('i:*:useImageAIState > darkMode changed: ', {
      isDarkMode,
      initialConfig,
      targetElementId: initialConfig?.darkMode?.targetElementId,
    });
    // 테마의 다크모드를 설정한다.
    if (setThemeDarkMode && typeof setThemeDarkMode === 'function') {
      setThemeDarkMode(isDarkMode);
    }
  }, [isDarkMode, initialConfig, setThemeDarkMode]);

  const addImageInfo = useCallback((messageId, apiMetadata) => {
    setImageInfo((prev) => ({
      ...prev,
      [messageId]: {
        apiMetadata,
        isProcessing: true,
      },
    }));
  }, []);

  const updateImageInfo = useCallback(({ messageId, promptId, ...data }) => {
    setImageInfo((prev) => ({
      ...prev,
      [messageId]: {
        ...prev[messageId],
        ...data,
        promptId,
        isProcessing: false,
      },
    }));
  }, []);

  const removeImageInfo = useCallback((messageId) => {
    setImageInfo((prev) => {
      const { [messageId]: _, ...rest } = prev;
      return rest;
    });
  }, []);

  // NOTICE: ImageAIState 주요 상태 변경을 확인할 때, 디버깅 로그를 출력한다.
  // ! for TEST
  // useEffect(() => {
  //   debug('i:*:ImageAIState > imageInfo changed: ', { imageInfo });
  // }, [imageInfo]);

  // // prompt 변경 debug
  // useEffect(() => {
  //   debug('i:*:ImageAIState > prompt changed: ', { prompt });
  // }, [prompt]);

  // // promptSettings 변경 debug
  // useEffect(() => {
  //   debug('i:*:ImageAIState > promptSettings changed: ', { promptSettings });
  // }, [promptSettings]);

  // // modelList 변경 debug
  // useEffect(() => {
  //   debug('i:*:ImageAIState > modelList changed: ', { modelList });
  // }, [modelList]);

  return {
    isDarkMode, // 다크 모드 여부
    toggleDarkModeHandler, // 다크 모드 토글 함수
    prompt, // 프롬프트: 이미지 생성을 위한 짧은 설명
    setPrompt, // 프롬프트 설정: 이미지 생성을 위한 상세 설정
    promptSettings, // 프롬프트 설정: 이미지 생성을 위한 상세 설정
    setPromptSettings, // 프롬프트 설정: 이미지 생성을 위한 상세 설정
    modelList, // 사용 가능한 api 모델 리스트
    setModelList, // 사용 가능한 api 모델 리스트
    selectedModel, // 선택된 api 모델
    setSelectedModel, // 선택된 api 모델
    setDefaultSettings, // 기본 설정 설정
    updateSettingsForModel, // 선택된 모델에 따라 설정을 업데이트
    imageInfo, // 이미지 정보: 이미지 생성 결과를 저장
    setImageInfo, // 이미지 정보: 이미지 생성 결과를 저장
    addImageInfo, // 이미지 정보: 이미지 생성 결과를 저장
    updateImageInfo, // 이미지 정보: 이미지 생성 결과를 저장
    removeImageInfo, // 이미지 정보: 이미지 생성 결과를 저장
  };
};

export default useImageAIState;
