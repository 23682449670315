import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ImagePlus, ArrowUpFromLine, Link, CircleArrowUp, SlidersHorizontal } from 'lucide-react';

const PromptInput = ({
  prompt,
  setPrompt,
  onSubmit,
  isProcessing,
  isSettingsVisible,
  toggleSettings,
  settingsButtonRef,
}) => {
  const [imageUploadType, setImageUploadType] = useState(null);
  const [isImageWrapperVisible, setIsImageWrapperVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const menuRef = useRef(null);
  const iconRef = useRef(null);
  const timeoutRef = useRef(null);
  const promptRef = useRef(null);

  // 다국어 지원
  const { t } = useTranslation([
    'components/service/imageAI/components/prompt/promptInput',
  ]);
  const lang = t;

  const adjustTextareaHeight = useCallback(() => {
    if (promptRef.current) {
      promptRef.current.style.height = 'auto';
      promptRef.current.style.height = `${promptRef.current.scrollHeight}px`;
    }
  }, []);

  // ! prompt가 변경될 때마다 높이를 조절합니다.
  // ! - 예를 들어서) regenerateImage, editImage 등의 action을 통해서 프롬프트가 변경될 때, prompt의 높이를 조절합니다.
  useEffect(() => {
    adjustTextareaHeight();
    setIsExpanded(true);
  }, [prompt, adjustTextareaHeight]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        onSubmit();
      }
    },
    [onSubmit],
  );

  const handlePromptChange = useCallback(
    (e) => {
      setPrompt(e.target.value);
      setIsExpanded(true);
      adjustTextareaHeight();
    },
    [setPrompt, adjustTextareaHeight],
  );

  const handleTextareaClick = useCallback(() => {
    if (prompt) {
      setIsExpanded(true);
      adjustTextareaHeight();
    }
  }, [prompt, adjustTextareaHeight]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (promptRef.current && !promptRef.current.contains(event.target)) {
        setIsExpanded(false);
        if (promptRef.current) {
          promptRef.current.style.height = '24px';
        }
      }
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !iconRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isExpanded) {
      adjustTextareaHeight();
    }
  }, [prompt, isExpanded, adjustTextareaHeight]);

  const handleIconClick = () => {
    if (imageUploadType) {
      setIsImageWrapperVisible(!isImageWrapperVisible);
    } else if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };

  const handleIconMouseEnter = () => {
    clearTimeout(timeoutRef.current);
    if (!isImageWrapperVisible) {
      setIsMenuOpen(true);
    }
  };

  const handleIconMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      if (!menuRef.current?.matches(':hover')) {
        setIsMenuOpen(false);
      }
    }, 100);
  };

  const handleMenuMouseEnter = () => {
    clearTimeout(timeoutRef.current);
  };

  const handleMenuMouseLeave = () => {
    setIsMenuOpen(false);
  };

  const handleImageTypeSelect = (type) => {
    setImageUploadType(type);
    setIsImageWrapperVisible(true);
    setIsMenuOpen(false);
  };

  return (
    <div 
      id='image-prompt-content' 
      className='bg-light-bg dark:bg-dark-bg py-3.5 px-4 rounded-xl border border-light-100 dark:border-dark-700/30 shadow-[0px_7px_21px_0px_rgba(51,_51,_51,_0.05)] dark:shadow-[0px_7px_21px_0px_rgba(0,_0,_0,_0.25)]'>

      <div
        id='image-prompt-wrapper'
        className='flex shrink-0 gap-3'
      >
        {/* Add image */}
        <div className='group relative inline-block text-left'>
          <div
            ref={iconRef}
            className={`flex items-center justify-center rounded cursor-pointer ${
              isImageWrapperVisible
                ? 'text-red-600 dark:text-red-600' // isImageWrapperVisible이 true일 때 적용
                : 'text-light-600 dark:text-dark-200 hover:text-light-700 hover:dark:text-dark-100' // isImageWrapperVisible이 false일 때 적용
            }`}
            onClick={handleIconClick}
            onMouseEnter={handleIconMouseEnter}
            onMouseLeave={handleIconMouseLeave}
          >
            <ImagePlus size={22} />
          </div>

          {isMenuOpen && !isImageWrapperVisible && (
            <div
              ref={menuRef}
              className='absolute top-10 -left-4 z-10 mt-3 w-52 origin-top-left bg-light-bg dark:bg-dark-bg text-sm text-light-800 dark:text-dark-200 p-2 rounded-xl border border-light-100 dark:border-dark-700/30 focus:outline-none'
              onMouseEnter={handleMenuMouseEnter}
              onMouseLeave={handleMenuMouseLeave}
            >
              {/* Image upload */}
              <button
                className={`
                  ${
                    imageUploadType === 'upload'
                      ? 'bg-light-blue-100 dark:bg-dark-blue-800'
                      : ''
                  }
                  group w-full flex items-center gap-2 px-3 py-1.5 rounded-lg transition duration-200 ease-in-out hover:bg-light-100 hover:dark:bg-dark-700/30
                `}
                onClick={() => handleImageTypeSelect('upload')}
              >
                <ArrowUpFromLine size={16} />
                <span>{lang('이미지업로드')}</span>
              </button>

              {/* image link */}
              <button
                className={`
                  ${
                    imageUploadType === 'link'
                      ? 'bg-light-blue-100 dark:bg-dark-blue-800'
                      : ''
                  }
                  group w-full flex items-center gap-2 px-3 py-1.5 rounded-lg transition duration-200 ease-in-out hover:bg-light-100 hover:dark:bg-dark-700/30
                `}
                onClick={() => handleImageTypeSelect('link')}
              >
                <Link size={16} />
                <span>{lang('이미지링크')}</span>
              </button>
            </div>
          )}
        </div>

        {/* Prompt input */}
        <textarea
          ref={promptRef}
          id='image-prompt'
          className='w-full bg-transparent text-light-primary dark:text-dark-primary placeholder:text-light-300 dark:placeholder:text-dark-500 outline-none resize-none overflow-hidden'
          placeholder={lang('어떤것을상상하시겠어요')}
          rows='1'
          style={{ minHeight: '24px', height: isExpanded ? 'auto' : '24px' }}
          value={prompt}
          onChange={handlePromptChange}
          onClick={handleTextareaClick}
          onKeyDown={handleKeyDown}
        />

        {/* Submit button */}
        <button
          id='image-submit'
          className='w-[24px] h-[24px] flex shrink-0 items-center justify-center text-light-600 dark:text-dark-200 outline-none cursor-pointer transition-colors duration-200 ease-in-out hover:text-light-700 hover:dark:text-dark-100'
          onClick={onSubmit}
          disabled={isProcessing}
        >
          <CircleArrowUp />
        </button>
        
        {/* Settings button */}
        <button
          ref={settingsButtonRef}
          onClick={toggleSettings}
          id='image-settings'
          className={`flex size-6 items-center justify-center rounded outline-none cursor-pointer transition-transform ${
            isSettingsVisible
              ? 'text-red-600 dark:text-red-600' // isSettingsVisible이 true일 때 적용
              : 'text-light-600 dark:text-dark-200 hover:text-light-700 hover:dark:text-dark-100' // isSettingsVisible이 false일 때 적용
          }`}
        >
          <SlidersHorizontal size={22} />
         </button>

      </div>

      {/* Image options */}
      {isImageWrapperVisible && (
        <div id='image-wrapper' className='mt-4 flex flex-col items-start'>

          {/* Image upload option */}
          {imageUploadType === 'upload' && (
            <div id='image-upload-option' className='w-full'>
              <div id='drop-zone' className='flex-grow cursor-pointer'>
                <div className='relative flex items-center justify-center p-4 border-2 border-dashed border-light-200 dark:border-dark-400 rounded-lg transition duration-200 ease-in-out hover:border-light-300 hover:dark:border-dark-300'>
                  <div className='space-y-1 overflow-hidden text-center'>
                    <p className='text-xs lg:text-sm font-medium text-light-600 dark:text-dark-200'>
                      {lang('이미지를업로드하거나끌어서놓기')}
                    </p>
                    <p className='text-xs text-light-400 dark:text-dark-400'>
                      {lang('업로드상세정보')}
                    </p>
                  </div>
                </div>
              </div>
              <input
                id='image-upload'
                name='image-upload'
                type='file'
                accept='image/*'
                className='sr-only'
              />
            </div>
          )}

          {/* Image link input */}
          {imageUploadType === 'link' && (
            <div id='image-link-option' className='w-full'>
              <input
                type='text'
                id='image-link-input'
                className='w-full bg-light-input dark:bg-dark-input text-light-800 dark:text-dark-200 px-2.5 py-2 border border-light-100 dark:border-dark-700/30 rounded-lg outline-none focus:border-light-200 focus:dark:border-dark-700'
                placeholder={lang('여기에이미지URL을입력하세요')}
              />
            </div>
          )}

        </div>
      )}

    </div>
  );
};

export default PromptInput;
