import React from 'react';
import { createRoot } from 'react-dom/client';
import ImageAIChatModal from '@plugins/ImageAIChat/components/ImageAIChatModal';
import { DebugProvider } from '@contexts/debug/DebugContext';
import { TooltipProvider } from '@components/common/Tooltip';
import { ModalProvider } from '@contexts/modal/ModalContext';
import { AuthProvider } from '@contexts/auth/AuthContext';
import { ThemeProvider } from '@contexts/theme/ThemeContext';
import { S3ImageHandlerProvider } from '@contexts/image/S3ImageHandlerContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ImageAIProvider } from '@contexts/ai/image/ImageAIContext';
import { ImageAIServiceProvider } from '@contexts/ai/service/imageAI/ImageAIServiceContext';
import { ChatHistoryProvider } from '@contexts/ai/chat/ChatHistoryContext';
import { AgentTranslationProvider } from '@plugins/ImageAIChat/contexts/AgentTranslationContext';
import KeyboardShortcutHandler from '@components/common/KeyboardShortcut/KeyboardShortcutHandler';
import { defaultImageAIChatConfig } from '@plugins/ImageAIChat/config/imageAIChatConfig';

export class UIManager {
  constructor(instance) {
    this.instance = instance;
    this.modalRoot = document.createElement('div');
    this.modalRoot.id = 'imageaiagent-portal-root';
    this.isModalOpen = false;

    document.body.appendChild(this.modalRoot);
    this.root = createRoot(this.modalRoot);

    this.queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: 1,
          staleTime: 5 * 60 * 1000,
        },
      },
    });
  }

  initialize() {
    this.setupEventListeners();
    this.renderReactComponent();
  }

  setupEventListeners() {
    document
      .getElementById('testButton')
      ?.addEventListener('click', this.instance.toggleAgent);

    if (this.instance.$element) {
      this.instance.$element.on('click', (e) => {
        e.preventDefault();
        this.instance.toggleAgent();
      });
    }
  }

  renderReactComponent() {
    const imageAIContextValue = {
      pageId: this.instance.options.pageId,
      initialConfig:
        this.instance.defaultImageAIChatConfig || defaultImageAIChatConfig,
      callbacks: this.instance.callbackManager.callbacks,
    };

    const shortcutKeys = this.instance.configManager.getShortcutKeys();

    this.instance.debug('i:*:Rendering with context:', {
      contextValue: imageAIContextValue,
      defaultConfig: this.instance.defaultImageAIChatConfig,
      shortcutKeys,
    });

    this.root.render(
      <React.StrictMode>
        <DebugProvider>
          <TooltipProvider>
            <ModalProvider>
              <AuthProvider>
                <ThemeProvider>
                  <S3ImageHandlerProvider
                    initialOptions={this.instance.options.S3ImageHandlerConfig}
                  >
                    <QueryClientProvider client={this.queryClient}>
                      <ImageAIProvider value={imageAIContextValue}>
                        <ImageAIServiceProvider value={imageAIContextValue}>
                          <AgentTranslationProvider>
                            <ChatHistoryProvider
                              pageId={this.instance.options.pageId}
                            >
                              <KeyboardShortcutHandler
                                shortcutKeys={shortcutKeys}
                                onTrigger={this.instance.handleKeyboardTrigger}
                              />
                              <ImageAIChatModal
                                pageId={this.instance.options.pageId}
                                isOpen={this.isModalOpen}
                                onImageResult={this.instance.handleImageResult}
                                onClose={this.instance.closeAgent}
                                modalOptions={
                                  this.instance.options.styleOptions
                                }
                              />
                            </ChatHistoryProvider>
                          </AgentTranslationProvider>
                        </ImageAIServiceProvider>
                      </ImageAIProvider>
                    </QueryClientProvider>
                  </S3ImageHandlerProvider>
                </ThemeProvider>
              </AuthProvider>
            </ModalProvider>
          </TooltipProvider>
        </DebugProvider>
      </React.StrictMode>,
    );
  }

  cleanup() {
    if (this.root) {
      this.root.unmount();
    }
    if (this.modalRoot) {
      this.modalRoot.remove();
    }
    this.queryClient.clear();
  }
}
