import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import useDebug from '@hooks/useDebug';
import { getThemeInfo } from '@api/theme/themeService';
import { useAuth } from '@contexts/auth/AuthContext';

const ThemeContext = createContext(null);

export const ThemeProvider = ({ children }) => {
  const { debug } = useDebug('ThemeContext');
  // const [themeInfo, setThemeInfo] = useState(null);
  const [themeIs, setThemeIs] = useState(false);
  const [themeConfig, setThemeConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const {
    user,
    setUser,
    themeInfo,
    setThemeInfo,
    isLoggedIn,
    setIsLoggedIn,
    logout,
    invalidateAuth,
    authInitialized, // 사용자 인증 처리 완료 flag
  } = useAuth();

  // * themeInfo 가져오기
  // ! 의존성 배열에 authInitialized를 추가하여, authContext에서 사용자 인증 처리 완료 후에, themeInfo를 가지고 온다.
  // ! if 조건문을 수정하여 themeInfo가 없을 때만 fetchThemeInfo를 실행하도록 처리. 이렇게 하면 비회원이든 회원이든 상관없이 themeInfo가 없을 때만 정보를 가지고 온다.
  // ! - themeInfo는 회원 정보가 변경이 될 때마 가져올 필요가 없고, logout / login 시에만 가져오도록 한다.
  useEffect(() => {
    const fetchThemeInfo = async () => {
      try {
        const response = await getThemeInfo();
        debug('s:테마 정보를 성공적으로 수신', response);
        setThemeInfo(response);
      } catch (error) {
        debug('e:테마 정보 수신 실패', error);
        if (error.error_code === 'INVALID_USER') {
          handleInvalidUser();
        }
      } finally {
        setLoading(false);
      }
    };

    // NOTICE: 인증 절차가 끝나고, themeInfo가 없으면 themeInfo를 가지고 온다.
    // ! - 비회원의 경우에도 themeInfo를 가지고 온다.
    // - 브라우저를 refresh 하지 않으면, 한번 themeInfo를 가지고 온 후에는 또 다시 가지고 오지 않는다.
    // - 만일, themeInfo를 다시 가지고 올 때에는 themeInfo를 null로 초기화 한 후에 가지고 온다.
    if (authInitialized && !themeInfo) {
      debug('i:*:themeInfo가 없음. fetchThemeInfo() 실행: ', {
        authInitialized,
      });
      fetchThemeInfo();
    }
  }, [authInitialized, themeInfo, debug]);

  const setThemeDarkMode = useCallback((isDarkMode) => {
    setIsDarkMode(isDarkMode);
  }, []);

  /**
   * token 정보는 유효하나, 서버에서 사용작 검증이 되지 않은 사용자
   */
  const handleInvalidUser = useCallback(() => {
    if (isLoggedIn) {
      // 현재 로그인된 사용자라면 로그아웃 처리를 한다.
      debug('로그인된 사용자: ', user.mb_id);
      debug('로그아웃 처리');
      logout();
    } else {
      // 로그인하지 않은 사용자인 경우, token 정보를 초기화
      debug('로그인하지 않은 사용자');
      invalidateAuth();
    }
  }, [isLoggedIn, setIsLoggedIn, setUser, setThemeInfo, logout]);

  useEffect(() => {
    if (themeInfo) {
      debug('i:*:Check themeInfo', { themeInfo, user });
      // * themeConfig
      if (themeInfo.config) {
        setThemeConfig(themeInfo.config);
      }

      // * themeIs
      if (themeInfo.is) {
        setThemeIs(themeInfo.is);
      }

      // * user 확장
      // - 회원 정보가 존재하는 경우, themeInfo.member를 이용하여 user 정보를 확장한다.
      if (
        user &&
        user.mb_id &&
        themeInfo.member &&
        Object.keys(themeInfo.member).length > 0
      ) {
        const newUserInfo = {
          ...user,
          ...themeInfo.user,
          ...themeInfo.member,
        };

        debug('i:*:themeInfo.member를 이용하여 user 정보를 확장', newUserInfo);

        // ! 현재 user 상태와 새로운 userInfo가 다를 때만 setUser 호출
        if (JSON.stringify(user) !== JSON.stringify(newUserInfo)) {
          if (typeof setUser === 'function') {
            debug('i:*:Updating user info', newUserInfo);
            setUser(newUserInfo);
          } else {
            console.error('setUser is not defined');
          }
        }
      }
    } else {
      // Theme관련 상태 초기화
      setThemeIs(false);
      setThemeConfig(null);
    }
  }, [themeInfo, user, setUser, setThemeIs, setThemeConfig, debug]);

  // ! for TEST
  // - themeConfig, themeIs 상태 확인
  useEffect(() => {
    if (themeConfig) {
      debug('i:*:themeConfig: ', themeConfig);
    }
  }, [themeConfig]);

  useEffect(() => {
    if (themeInfo) {
      debug('i:*:themeIs: ', themeIs);
    }
  }, [themeIs]);

  return (
    <ThemeContext.Provider
      value={{
        themeInfo,
        loading,
        themeIs,
        themeConfig,
        setThemeDarkMode,
        isDarkMode,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);
