/*!
 * ImageAIAgent Plugin 1.0.0
 * @ update date: 2024.01.08
 * @ author JB, GTGF
 */

/**
 * ImageAIAgent External API Documentation
 *
 * 1. API 아키텍처 개요
 * -----------------------------
 * ImageAIAgent 모듈은 싱글톤 패턴을 기반으로 한 외부 API를 제공합니다.
 * 이는 다음과 같은 구조적 이점을 제공합니다:
 * - 단일 진입점을 통한 일관된 API 접근
 * - 상태 관리의 중앙화
 * - 메모리 효율성
 * - 콜백 관리의 단순화
 *
 * 2. 핵심 컴포넌트
 * -----------------------------
 * a) Static Instance (_instance)
 *    - 단일 ImageAIAgent 인스턴스를 전역적으로 관리
 *    - 모든 외부 요청의 중앙 처리점 역할
 *
 * b) External API (_externalAPI)
 *    - 외부 플러그인과의 인터페이스 정의
 *    - 주요 메서드: openWithContext, registerCallback
 *
 * c) Callback System
 *    - 내부 콜백과 외부 콜백의 통합 관리
 *    - 이벤트 기반 통신 지원
 *
 * 3. 사용 예시
 * -----------------------------
 * // API 초기화
 * const imageAIAgent = new ImageAIAgent(element, options);
 *
 * // 외부에서 콜백 등록
 * ImageAIAgent.externalAPI.registerCallback((imageData) => {
 *   console.log('Received image:', imageData);
 *   return true; // 성공적인 처리 표시
 * });
 *
 * // ImageAIAgent 열기
 * ImageAIAgent.externalAPI.openWithContext({
 *   source: 'external-plugin',
 *   currentImage: 'image-url'
 * });
 */
import './style.css';
import ImageAIAgent from './core/ImageAIAgent';

// NOTICE: 통신 흐름 예시
// -----------------------------
// 1) 외부 플러그인 초기화:
//    - ImageAIAgent.externalAPI.registerCallback 호출하여 콜백 등록
//
// 2) 이미지 편집 요청:
//    - ImageAIAgent.externalAPI.openWithContext 호출하여 에디터 열기
//
// 3) 이미지 처리 완료:
//    - ImageAIAgent 내부에서 onApplyImage 콜백 호출
//    - 등록된 외부 콜백으로 결과 전달
//
// 4) 결과 처리:
//    - 외부 플러그인의 콜백에서 이미지 데이터 수신 및 처리

// 전역 객체에 추가
if (typeof window !== 'undefined') {
  window.ImageAIAgent = ImageAIAgent;
}

// CommonJS, AMD 모듈 지원
if (typeof module === 'object' && module.exports) {
  module.exports = ImageAIAgent;
} else if (typeof define === 'function' && define.amd) {
  define(() => ImageAIAgent);
}

export default ImageAIAgent;
