import React, { useCallback, useMemo } from 'react';
// import image utils
import {
  extractImageInfoWithUrl,
  base64ToBlob, // base64 이미지를 가져오는 함수
} from '@src/js/utils/image/ImageUtilFunctions.js';
import useDebug from '@hooks/useDebug';
import useImageAIState from '@hooks/ai/image/useImageAIState';
import useImageGeneration from '@hooks/ai/image/useImageGeneration';

// s3로 이미지 업로드를 위한 Context
// - Ref: useS3ImageHandler.js hook을 참ㅈ한다.
import { useS3ImageHandlerContext } from '@contexts/image/S3ImageHandlerContext';

import { API_CONFIG, isDevelopment, getApiPath } from '@api/apiConfig';
import { sendPost } from '@api/apiService';
import { getAccessTokenFromLocalStorage } from '@/api/authService';
// 사용자 인증 context
import { useAuth } from '@contexts/auth/AuthContext';
// import { imageAIChatConfig } from '@plugins/ImageAIChat/config/ImageAIChatConfig';
// imageInfo 등 플러그인 전역적인 상태 관리
// import { useImageAIContext } from '@contexts/ai/image/ImageAIContext';

// CreatedImagesContext 추가
// import { useCreatedImagesContext } from '@contexts/ai/service/imageAI/Create/CreatedImagesContext';

export const useImageAIActions = (state = null, callbacks) => {
  const { debug } = useDebug('useImageAIActions');
  const { user, authInitialized } = useAuth() || {};
  const { generateImage } = useImageGeneration();
  // S3 이미지 업로드
  const { isS3ImageUpload, uploadImageToS3, isAutoPost } =
    useS3ImageHandlerContext();

  // CreatedImagesContext에서 updateCreatedImagesAfterDelete 함수 가져오기
  // const { updateCreatedImagesAfterDelete } = useCreatedImagesContext();

  const getCurrentMessageInfo = useCallback(
    ({
      promptId,
      messageId,
      imageIndex,
      currentImageInfo,
      currentChatHistory,
    }) => {
      const { imageInfo, modelList, setSelectedModel } = state;
      const currentApiOptions = currentChatHistory.apiOptions || {};
      const currentPrompt = currentApiOptions.prompt || '';
      let currentModel = currentApiOptions.model || '';
      let currentModelInfo = currentModel
        ? modelList.find((model) => model.model === currentModel)
        : null;

      // ! 현재 이미지의 모델 정보를 가져온다.
      // ! - 현재 이미지의 모델 정보가 없을 경우, default 모델 정보를 가져온다.
      // ! - default 모델 정보가 없을 경우, 첫 번째 모델 정보를 가져온다.
      // ! - 모델 정보가 없을 경우, 모델 정보를 가져오지 않는다.
      if (!currentModel) {
        currentModelInfo = modelList.find((model) => model.default === true);
        currentModel = currrentModelInfo.model;
      }

      return {
        messageId,
        imageIndex,
        currentPrompt,
        currentApiOptions,
        currentModelInfo, // selectedModel
      };
    },
    [state],
  );

  const applyCurrentMessageSettings = useCallback(
    ({
      state,
      chatHistory,
      getCurrentChatHistory,
      promptId,
      messageId,
      currentImageInfo,
      imageIndex,
    }) => {
      const { setPrompt, setPromptSettings, modelList, setSelectedModel } =
        state;
      const currentChatHistory = getCurrentChatHistory(messageId);
      debug('i:*:applyCurrentMessageSettings 함수 호출됨:', {
        state,
        chatHistory,
        getCurrentChatHistory,
        promptId,
        messageId,
        imageIndex,
        currentImageInfo,
        currentChatHistory,
      });
      const currentApiOptions = currentChatHistory.apiOptions || {};
      const currentPrompt = currentApiOptions.prompt || '';
      let currentModel = currentApiOptions.model || '';
      let currentModelInfo = currentModel
        ? modelList.find((model) => model.model === currentModel)
        : null;

      // ! 현재 이미지의 모델 정보를 가져온다.
      // currentModel이 '', null, undefined인 경우 default 모델 설정
      if (!currentModel) {
        currentModelInfo = modelList.find((model) => model.default === true);
        currentModel = currrentModelInfo.model;
      }

      // 선택된 이미지 생성 시 사용했던 prompt 설정
      setPrompt(currentPrompt);
      // 선택된 이미지 생성 시 사용했던 promptSettings 설정
      setPromptSettings(currentApiOptions);
      // 선택된 이미지 생성 시 선택된 모델 설정
      // ! selectedModel은 객체이다. 따라서 객체 정보를 설정해야 한다.
      setSelectedModel(currentModelInfo);

      return {
        currentPrompt,
        currentApiOptions,
        currentModelInfo, // selectedModel
      };
    },
    [state, debug],
  );

  /**
   * ImageAI > Create > CreatedImageList > regenerateImage / editImage > 현재 이미지의 설정을 적용한다
   * INFO: promptInfo는 현재 이미지의 프롬프트 정보이다. 이미지 편집 또는 이미지 프롬프트 복사 시, 해당 값이 전달된다.
   * ! - 따라서, 해당 함수에서는 editImage에서 해당 함수를 호출하는 경우에 존재하고, regenerateImage에서 해당 함수를 호출하는 경우에는 존재하지 않는다.
   * @param {object} state - 현재 상태
   * @param {string} promptId - 현재 메시지의 prompt id
   * @param {object} currentImageInfo - 현재 이미지의 정보
   * @param {object} promptInfo - 현재 이미지의 프롬프트 정보:
   * @returns {object} - 현재 이미지의 설정
   */
  const applyCurrentImageSettings = useCallback(
    ({ promptId, currentImageInfo, promptInfo = null, type = null }) => {
      const { setPrompt, setPromptSettings, modelList, setSelectedModel } =
        state;

      // INFO: promptInfo는 현재 이미지의 프롬프트 정보이다. 이미지 편집 또는 이미지 프롬프트 복사 시, 해당 값이 전달된다.
      // ! - 따라서, 해당 함수에서는 editImage에서 해당 함수를 호출하는 경우에 존재하고, regenerateImage에서 해당 함수를 호출하는 경우에는 존재하지 않는다.
      const promptText = promptInfo ? promptInfo.promptText : null;

      const apiMetadata = currentImageInfo.api_metadata || {};
      // sync_mode와 enable_safety_checker 처리
      if ('sync_mode' in apiMetadata) {
        apiMetadata.sync_mode = apiMetadata.sync_mode === 1;
      }

      if ('enable_safety_checker' in apiMetadata) {
        apiMetadata.enable_safety_checker =
          apiMetadata.enable_safety_checker === 1;
      }

      // id 값이 존재하는 경우, id 값을 삭제한다.
      delete apiMetadata.id;

      // WARN: type이 'regenerate'인 경우, seed 값을 초기화한다.
      if (type === 'regenerate') {
        apiMetadata.seed = 0;
      }

      // isBase64 처리
      if ('isBase64' in apiMetadata) {
        apiMetadata.isBase64 = Boolean(apiMetadata.isBase64);
      } else {
        apiMetadata.isBase64 = false;
      }

      const currentApiOptions = apiMetadata;
      // INFO: 다국어 프롬프트를 지원하는 경우,
      // ! - 다국어를 지원하는 경우, promptText에 사용자가 선택한 언어의 프롬프트 text가 전달이 된다.
      // ! - promptText가 존재하는 경우, promptText를 사용한다.
      // ! - promptText가 존재하지 않는 경우, currentImageInfo.prompt_metadata.final_prompt를 사용한다.
      const currentPrompt =
        promptText || currentImageInfo.prompt_metadata.final_prompt;
      let currentModel = currentApiOptions.model || '';
      let currentModelInfo = currentModel
        ? modelList.find((model) => model.model === currentModel)
        : null;

      // 현재 이미지의 모델 정보를 가져온다.
      // - currentModel이 '', null, undefined인 경우 default 모델 설정
      if (!currentModel) {
        currentModelInfo = modelList.find((model) => model.default === true);
        currentModel = currrentModelInfo.model;
      }

      debug('i:*:applyCurrentImageSettings() 설정 값 확인:', {
        state,
        promptId,
        currentImageInfo,
        promptInfo,
        promptText,
        type,
        currentPrompt,
        currentApiOptions,
        currentModelInfo,
      });

      // 선택된 이미지 생성 시 사용했던 prompt 설정
      setPrompt(currentPrompt);
      // 선택된 이미지 생성 시 사용했던 promptSettings 설정
      setPromptSettings(currentApiOptions);
      // 선택된 이미지 생성 시 선택된 모델 설정
      // ! selectedModel은 객체이다. 따라서 객체 정보를 설정해야 한다.
      setSelectedModel(currentModelInfo);

      return {
        currentPrompt,
        currentApiOptions,
        currentModelInfo, // selectedModel
      };
    },
    [state, debug],
  );

  /**
   * ImageAI 서비스용 이미지 편집 함수
   * - 현재 이미지의 설정을 적용한 후, 이미지를 편집한다
   * - 선택한 이미지의 prompt, model, apiOptions를 적용한다
   * @param {object} editParams - 편집 매개변수
   * @returns {object} - 편집 결과
   */
  const handleRegenerateImageImageAIService = useCallback(
    async (regenerateParams) => {
      debug('handleRegenerateImageImageAIService 함수 호출됨:', {
        regenerateParams,
      });
      // WARN: applyCurrentImageSettings()을 통해서 이미지 생성 정보를 설정 시, seed 값을 초기화해야 한다.
      // ! - 'edit'인 경우에는 seed값을 초기화하지 않는다.
      // ! applyCurrentImageSettings() 함수는 'edit'에서도 공통적으로 호출되는 함수로써,
      // !- 'regenerate'일 때, 함수 내부에서 seed 을 초기화하기 위해서 type 값을 달한다.
      regenerateParams.type = 'regenerate';
      // currentPrompt, currentApiOptions, currentModelInfo 값을 return 된다.
      const result = applyCurrentImageSettings(regenerateParams);
      return result;
    },
    [applyCurrentImageSettings, debug],
  );

  /**
   * ImageAIChat 플러그인용 이미지 편집 함수
   * - 현재 이미지의 설정을 적용한 후, 이미지를 편집한다
   * - 선택한 이미지의 prompt, model, apiOptions를 적용한다
   * @param {object} editParams - 편집 매개변수
   * @returns {object} - 편집 결과
   */
  const handleRegenerateImageImageAIChat = useCallback(
    async (regenerateParams) => {
      return applyCurrentMessageSettings(regenerateParams);
    },
    [applyCurrentMessageSettings, debug],
  );

  /**
   * 이미지 재생성 함수
   * - 현재 이미지의 설정을 적용한 후, 이미지를 재생성한다
   * - 선택한 이미지의 prompt, model, apiOptions를 적용한다
   * @param {string} messageId - 현재 이미지의 messageId
   * @param {number} imageIndex - 현재 이미지의 인덱스
   * @param {object} currentImageInfo - 현재 이미지의 정보
   * @param {function} onSubmit - 이미지 생성 함수
   */
  const handleRegenerateImage = useCallback(
    async (regenerateParams) => {
      const { app, onSubmit } = regenerateParams;
      if (!onSubmit || typeof onSubmit !== 'function') {
        throw new Error('onSubmit 함수를 전달해야 합니다.');
      }

      debug('이미지 재생성 중:', regenerateParams);

      let result = null;
      try {
        if (app && app.type === 'service' && app.name === 'imageAI') {
          result = await handleRegenerateImageImageAIService(regenerateParams);
        } else if (
          !app ||
          (app.type === 'plugin' && app.name === 'ImageAIChat')
        ) {
          result = await handleRegenerateImageImageAIChat(regenerateParams);
        } else {
          throw new Error('지원되지 않는 앱 타입 또는 이름');
        }

        const { currentPrompt, currentApiOptions, currentModelInfo } = result;
        debug('Check the current image params:', {
          result,
          currentPrompt,
          currentApiOptions,
          currentModelInfo,
        });

        // onSubmit 함수 호출
        await onSubmit({
          selectedModel: currentModelInfo,
          prompt: currentPrompt,
          settings: currentApiOptions,
        });

        return {
          status: 'success',
          message: '이미지 재생성 설정 완료',
          data: {
            currentPrompt,
            currentApiOptions,
            currentModelInfo,
          },
        };
      } catch (error) {
        debug('이미지 재생성 중 오류 발생:', error);
        return {
          status: 'error',
          message: error.message || '이미지 재생성 중 오류가 발생했습니다.',
        };
      }
    },
    [
      handleRegenerateImageImageAIChat,
      handleRegenerateImageImageAIService,
      debug,
    ],
  );

  /**
   * ImageAI 서비스용 이미지 편집 함수
   * - 현재 이미지의 설정을 적용한 후, 이미지를 편집한다
   * - 선택한 이미지의 prompt, model, apiOptions를 적용한다
   * @param {object} editParams - 편집 매개변수
   * @returns {object} - 편집 결과
   */
  const handleEditImageImageAIService = useCallback(
    async (editParams) => {
      const { promptId, currentImageInfo, promptInfo } = editParams;

      debug('i:*:handleEditImageImageAIService 함수 호출됨:', {
        promptId,
        currentImageInfo,
        // INFO: finalPrompt, originalPrompt, originalPromptLanguage 에 대한 정보가 전달된다.
        promptInfo,
      });

      const { currentPrompt, currentApiOptions, currentModelInfo } =
        applyCurrentImageSettings({
          state,
          promptId,
          currentImageInfo,
          promptInfo,
        });

      return {
        status: 'success',
        message: 'ImageAI 서비스 이미지 편집 설정 완료',
        data: {
          currentPrompt,
          currentApiOptions,
          currentModelInfo,
        },
      };
    },
    [applyCurrentImageSettings, state, debug],
  );

  /**
   * ImageAIChat 플러그인용 이미지 편집 함수
   * - 현재 이미지의 설정을 적용한 후, 이미지를 편집한다
   * - 선택한 이미지의 prompt, model, apiOptions를 적용한다
   * @param {object} editParams - 편집 매개변수
   * @returns {object} - 편집 결과
   */
  const handleEditImageImageAIChat = useCallback(
    async (editParams) => {
      const {
        chatHistory,
        getCurrentChatHistory,
        promptId,
        messageId,
        imageIndex,
        currentImageInfo,
      } = editParams;

      const { currentPrompt, currentApiOptions, currentModelInfo } =
        applyCurrentMessageSettings({
          state,
          chatHistory,
          getCurrentChatHistory,
          promptId,
          messageId,
          imageIndex,
          currentImageInfo,
        });
      debug('handleEditImageImageAIChat - 현재 이미지 매개변수 확인: ', {
        currentPrompt,
        currentApiOptions,
        currentModelInfo,
      });
      return {
        status: 'success',
        message: 'ImageAIChat 플러그인 이미지 편집 설정 완료',
      };
    },
    [applyCurrentMessageSettings, state, debug],
  );

  /**
   * 이미지 편집 함수
   * - 현재 이미지의 설정을 적용한 후, 이미지를 편집한다
   * - 선택한 이미지의 prompt, model, apiOptions를 적용한다
   * @param {object} chatHistory - 채팅 기록
   * @param {function} getCurrentChatHistory - 현재 채팅 기록을 가지고 오는 함수
   * @param {string} promptId - 현재 메시지의 prompt id
   * @param {string} messageId - 현재 이미지의 messageId
   * @param {number} imageIndex - 현재 이미지의 인덱스
   * @param {object} currentImageInfo - 현재 이미지의 정보
   */
  const handleEditImage = useCallback(
    async (editParams) => {
      const { app } = editParams;

      debug('i:*:handleEditImage 함수 호출됨', editParams);

      if (app && app.type === 'service' && app.name === 'imageAI') {
        return handleEditImageImageAIService(editParams);
      } else if (
        !app ||
        (app.type === 'plugin' && app.name === 'ImageAIChat')
      ) {
        return handleEditImageImageAIChat(editParams);
      } else {
        debug('지원되지 않는 앱 타입 또는 이름:', app);
        return {
          status: 'error',
          message: '지원되지 않는 앱 타입 또는 이름',
        };
      }
    },
    [handleEditImageImageAIChat, handleEditImageImageAIService, debug],
  );

  /**
   * 이미지 업로드 함수
   * @param {string} messageId - 현재 이미지의 messageId
   * @param {HTMLImageElement} imageEl - 현재 이미지의 엘리먼트
   * @param {object} currentImageInfo - 현재 이미지의 정보
   * @param {number} imageIndex - 현재 이미지의 인덱스
   * @param {object} currentChatHistory - 현재 채팅 히스토리
   */
  const handleImageUpload = useCallback(
    async ({
      promptId,
      messageId,
      imageIndex,
      currentImageInfo,
      currentChatHistory,
    }) => {
      debug('이미지 업로드 중:', {
        promptId,
        messageId,
        imageIndex,
        currentImageInfo,
        currentChatHistory,
      });

      if (!isS3ImageUpload) {
        debug('S3 이미지 업로드가 지원되지 않습니다');
        console.error('S3 이미지 업로드가 지원되지 않습니다');
        return;
      }

      try {
        const result = await sendPost({
          // url: API_CONFIG.paths.imageAi,
          url: getApiPath('imageAi'),
          data: {
            // metadata: imageAIChatConfig.sampleMetadata.apiMetadata,
            // s3_meta: imageAIChatConfig.sampleMetadata.s3Metadata,
            request_type: 'update',
          },
          useImageAI: true,
        });
        console.log('Upload image successful:', result);

        // const { currentApiOptions } = getCurrentMessageInfo(
        //   state,
        //   messageId,
        //   imageIndex,
        //   currentImageInfo,
        //   currentChatHistory,
        // );

        // debug('업로드 이미지 매개변수 확인:', {
        //   currentImageInfo,
        //   currentApiOptions,
        // });

        // const result = await uploadImageToS3({
        //   img: imageEl,
        //   generatedImageInfo: extractImageInfoWithUrl(currentImageInfo.src),
        //   apiMetadata: currentApiOptions,
        // });

        // debug('uploadImageToS3 결과 확인: ', result);
        // 여기에 업로드 성공 후 처리 로직 추가
      } catch (error) {
        console.error('이미지 업로드 실패:', error);
        // 여기에 에러 처리 로직 추가 (예: 사용자에게 에러 메시지 표시)
      }
    },
    [debug, state, isS3ImageUpload, uploadImageToS3, getCurrentMessageInfo],
  );

  /**
   * ImageAIChat 플러그인용 이미지 자동 포스트 함수
   * @param {object} autoPostParams - 자동 포스트 매개변수
   * @returns {object} - 자동 포스트 결과
   */
  const handleImageAutoPostImageAIService = useCallback(
    async (autoPostParams) => {
      const { promptId, imageId, imageIndex, queryKey, currentImageInfo } =
        autoPostParams;

      // ! for TEST
      // debug('handleImageAutoPostImageAIService:', {
      //   promptId,
      //   imageId,
      //   imageIndex,
      //   currentImageInfo,
      // });

      // 현재 이미지 정보에서 api_metadata를 추출
      return {
        api_metadata: currentImageInfo.api_metadata || {},
        prompt_id: promptId,
        image_id: imageId,
        image_index: imageIndex,
      };
    },
    [debug],
  );

  /**
   * ImageAIChat 플러그인용 이미지 자동 포스트 함수
   * @param {object} autoPostParams - 자동 포스트 매개변수
   * @returns {object} - 자동 포스트 결과
   */
  const handleImageAutoPostImageAIChat = useCallback(
    async (autoPostParams) => {
      const { currentApiOptions } = getCurrentMessageInfo(autoPostParams);
      return {
        currentApiOptions,
      };
    },
    [getCurrentMessageInfo, debug],
  );
  /**
   * 이미지 자동 포스트
   * @param {string} messageId - 현재 이미지의 messageId
   * @param {HTMLImageElement} imageEl - 현재 이미지의 엘리먼트
   * @param {object} currentImageInfo - 현재 이미지의 정보
   * @param {number} imageIndex - 현재 이미지의 인덱스
   * @param {object} currentChatHistory - 현재 채팅 히스토리
   */
  const handleImageAutoPost = useCallback(
    async (autoPostParams) => {
      const { promptId, imageIndex, app } = autoPostParams;
      let message = '';
      debug('이미지 자동 포스트 중:', {
        autoPostParams,
      });

      let result = {};
      try {
        if (app && app.type === 'service' && app.name === 'imageAI') {
          result = await handleImageAutoPostImageAIService(autoPostParams);
        } else if (
          !app ||
          (app.type === 'plugin' && app.name === 'ImageAIChat')
        ) {
          result = await handleImageAutoPostImageAIChat(autoPostParams);
        } else {
          debug('지원되지 않는 앱 타입 또는 이름:', app);
          return {
            status: 'error',
            message: '지원되지 않는 앱 타입 또는 이름',
          };
        }

        // ! api_metadata 추출
        // ! - 모든 서비스 및 플러그인에서 promptId 및 api_metadata가 필수적이다.
        if (!result.api_metadata) {
          message = '이미지 자동 포스트 실패';
          return {
            status: 'error',
            message: message,
            details: 'api_metadata가 없습니다.',
          };
        }

        const autoPostData = {
          request_type: 'insert',
          user_id: user?.mb_id || '',
          ...result,
        };

        debug('autoPostData:', autoPostData);

        const accessToken = getAccessTokenFromLocalStorage();
        const headers = {};
        // ! POOS, GET 방식에 상관없이, token을 전달할 때, header에 token을 포함하여 전달한다.
        // INFO:
        // - 요청에서 Authorization 헤더를 사용합니다.(MUST)
        // - 1. 보안: URL 파라미터로 토큰을 전송하는 것보다 헤더를 통해 전송하는 것이 더 안전합니다. URL은 서버 로그나 브라우저 히스토리에 남을 수 있기 때문입니다.
        // - 2. RESTful 관행: Authorization 헤더를 사용하는 것은 RESTful API의 일반적인 관행입니다.
        // - 3. 유연성: 헤더를 사용하면 GET, POST, PUT 등 모든 HTTP 메소드에서 일관된 방식으로 인증을 처리할 수 있습니다.
        if (accessToken) {
          headers['Authorization'] = `Bearer ${accessToken}`;
        } else {
          errorMsg = '포스트를 발행할  없습니다: ';
          errorMsg += '[ NO_ACCESS_TOKEN ]';
          throw new Error(errorMsg);
        }

        try {
          const result = await sendPost({
            url: getApiPath('autoPost'),
            data: autoPostData,
            config: {
              withCredentials: true,
              headers: headers,
            },
          });
          return {
            status: 'success',
            message: '이미지 자동 포스트 성공',
          };
        } catch (error) {
          message = '이미지 자동 포스트 실패';
          console.error(message, error);
          return {
            status: 'error',
            message: message,
            details: error.message,
          };
        }
      } catch (error) {
        console.error('이미지 자동 포스트 중 오류 발생:', error);
        return {
          status: 'error',
          message:
            error.message || '이미지 자동 포스트 중 오류가 발생했습니다.',
        };
      }
    },
    [user, getCurrentMessageInfo, debug],
  );

  /**
   * 이미지 다운로드 함수
   * @param {string} promptId - 현재 이미지의 promptId
   * @param {string} messageId - 현재 이미지의 messageId
   * @param {HTMLImageElement} imageEl - 현재 이미지의 엘리먼트
   */
  const handleImageDownload = useCallback(
    async ({ promptId, messageId, imageEl }) => {
      let message = '';

      const { url, fullFilename, filename, extension } =
        extractImageInfoWithUrl(imageEl.src);

      debug('i:*:handleImageDownload 함수 호출됨:', {
        promptId,
        imageEl,
        url,
        fullFilename,
        filename,
        extension,
      });

      const accessToken = getAccessTokenFromLocalStorage();
      const headers = {};
      // ! POOS, GET 방식에 상관없이, token을 전달할 때, header에 token을 포함하여 전달한다.
      // INFO:
      // - 요청에서 Authorization 헤더를 사용한다(MUST)
      // - 1. 보안: URL 파라미터로 토큰을 전송하는 것보다 헤더를 통해 전송하는 것이 더 안전합니다. URL은 서버 로그나 브라우저 히스토리에 남을 수 있기 때문입니다.
      // - 2. RESTful 관행: Authorization 헤더를 사용하는 것은 RESTful API의 일반적인 관행입니다.
      // - 3. 유연성: 헤더를 사용하면 GET, POST, PUT 등 모든 HTTP 메소드서 일관된 방식으로 인증을 처리할 수 있습니다.
      if (accessToken) {
        headers['Authorization'] = `Bearer ${accessToken}`;
      } else {
        message = '이미지를 다운로드 할 수 없습니다: ';
        errorMsg += '[ NO_ACCESS_TOKEN ]';
        throw new Error(errorMsg);
      }

      const result = await sendPost({
        url: getApiPath('imageDownload'),
        data: {
          url: imageEl.src,
        },
        config: {
          withCredentials: true,
          headers: headers,
        },
      });
      // Base64 데이터를 Blob으로 변환
      if (result.status === 'success') {
        const data = result.data;
        const base64Data = data.base64;
        const mimeType = data.mime_type || 'image/png'; // MIME 타입이 없으면 기본값으로 'image/png' 사용
        const blob = base64ToBlob(base64Data, mimeType);

        // Blob을 URL로 변환하고, 이를 이용해 다운로드 링크 생성
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = fullFilename; // 파일 이름 설정
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        // 사용이 끝난 URL을 해제
        window.URL.revokeObjectURL(blobUrl);
      } else {
        message = '이미지를 다운로드 할 수 없습니다.';
        return {
          status: 'error',
          message: message,
        };
      }

      return {
        status: 'success',
        message: '이미지 다운로드 성공',
      };
    },
    [isDevelopment, sendPost, debug],
  );

  const handleApplyImage = useCallback(
    async (applyParams) => {
      debug('i:*:handleApplyImage 함수 호출됨', applyParams);
      const { promptId, imageIndex, currentImageInfo, imageInfo, app } =
        applyParams;
      const image = currentImageInfo.images[0];

      const imageUrl = image.cdn_url || image.object_url;
      const contentType = image.content_type;
      const imageSize = image.size;
      const isPublic = image.is_public;
      const createdAt = image.created_at;
      const aspectRatio = imageInfo.aspectRatio;
      const applyImageInfo = {
        imageUrl,
        contentType,
        imageSize,
        isPublic,
        createdAt,
        aspectRatio,
      };

      debug('s:*:applyImageInfo:', applyImageInfo);
      if (
        callbacks?.onApplyImage &&
        typeof callbacks.onApplyImage === 'function'
      ) {
        // NOTICE: 이미지 적용은 callbacks.onApplyImage 함수만 호출을 한다.
        callbacks.onApplyImage(applyImageInfo);
      }

      // 이미지 적용 로직 구현
    },
    [callbacks, debug],
  );

  const handleViewImage = useCallback(
    (messageId, imageEl) => {
      debug('Viewing image:', messageId);
      // 전체 화면 보기 로직 구현
    },
    [debug],
  );

  const handleDeleteImage = useCallback(
    async (deleteParams) => {
      debug('i:*:handleDeleteImage:', deleteParams);
      let errorMsg = '';

      const accessToken = getAccessTokenFromLocalStorage();
      const headers = {};
      // ! POOS, GET 방식에 상관없이, token을 전달할 때, header에 token을 포함하여 전달한다.
      // INFO:
      // - 요청에서 Authorization 헤더를 사용하는 것이 좋습니다.
      // - 1. 보안: URL 파라미터로 토큰을 전송하는 것보다 헤더를 통해 전송하는 것이 더 안전합니다. URL은 서버 로그나 브라우저 히스토리에 남을 수 있기 때문입니다.
      // - 2. RESTful 관행: Authorization 헤더를 사용하는 것은 RESTful API의 일반적인 관행입니다.
      // - 3. 유연성: 헤더를 사용하면 GET, POST, PUT 등 모든 HTTP 메소드서 일관된 방식으로 인증을 처리할 수 있습니다.
      if (accessToken) {
        headers['Authorization'] = `Bearer ${accessToken}`;
      } else {
        errorMsg = '이미지를 삭제할 수 없습니다: ';
        errorMsg += '[ NO_ACCESS_TOKEN ]';
        throw new Error(errorMsg);
      }

      debug('i:handleDeleteImage(): 이미지 삭제를 시작합니다.: ', deleteParams);

      try {
        const result = await sendPost({
          // url: API_CONFIG.paths.imageAi,
          url: getApiPath('imageAi'),
          data: {
            request_type: 'delete',
            prompt_id: deleteParams.promptId,
            image_id: deleteParams.imageId,
            user_id: user?.mb_id || '',
          },
          config: {
            withCredentials: true,
            headers: headers,
          },
          useImageAI: true,
        });

        if (result.status === 'success') {
          debug('s:*:handleDeleteImage() - 이미지 삭제 결과: ', result);

          // 성공 결과만 반환
          return {
            status: 'success',
            message: '이미지가 성공적으로 삭제되었습니다.',
            data: result.data,
          };
        } else {
          throw new Error(
            '이미지 삭제 실패: ' + (result.message || '알 수 없는 오류'),
          );
        }
      } catch (error) {
        debug('e:*:handleDeleteImage(): Error: ', error);
        throw error;
      }
    },
    [user, debug],
  );

  const actions = useMemo(
    () => ({
      handleRegenerateImage,
      handleEditImage,
      handleImageUpload,
      handleImageAutoPost,
      handleImageDownload,
      handleApplyImage,
      handleViewImage,
      handleDeleteImage,
    }),
    [
      handleRegenerateImage,
      handleEditImage,
      handleImageUpload,
      handleImageAutoPost,
      handleImageDownload,
      handleApplyImage,
      handleViewImage,
      handleDeleteImage,
    ],
  );

  return actions;
};
