// src/plugins/modules/ImageAIChat/components/PluginWrapper.jsx
import React from 'react';

/**
 * 플러그인의 스타일을 격리하고 16px 기준의 rem을 적용하는 래퍼 컴포넌트
 */
const PluginWrapper = ({ children }) => {
  return (
    <div
      className='supereasy-imageaiagent-root'
      style={{
        // 플러그인 root에서 font-size를 16px로 고정
        fontSize: '16px',
        // 부모로부터의 스타일 상속 방지
        isolation: 'isolate',
        // 스타일 스코프 설정
        contain: 'strict',
      }}
    >
      {children}
    </div>
  );
};

export default PluginWrapper;
