import { v4 as uuidv4 } from 'uuid';

export function encodeHTML(html) {
  return html.replace(/[&<>"']/g, function (m) {
    switch (m) {
      case '&':
        return '&amp;';
      case '<':
        return '&lt;';
      case '>':
        return '&gt;';
      case '"':
        return '&quot;';
      default:
        return '&#039;';
    }
  });
}

// 줄바꿈과 따옴표를 이스케이프 처리하는 함수
function escapeForJSON(str) {
  return str.replace(/\n/g, '\\n').replace(/"/g, '\\"');
}

/**
 * 특정 요소의 속성 중 주어진 문자열로 시작하는 속성을 찾아, 그 속성의 집합을 반환하거나 속성의 존재 여부를 확인합니다.
 *
 * @param {string} startWithStr - 속성 이름이 시작해야 하는 문자열
 * @param {string} type - 반환할 값의 유형 ('is'는 속성의 존재 여부, 그 외는 속성 집합 반환)
 * @param {HTMLElement} el - 속성을 검사할 DOM 요소. 제공되지 않으면 this.element 사용
 * @returns {Object|boolean} 'is' 타입일 경우 속성의 존재 여부(boolean), 그 외는 속성 집합(Object)
 */
export const getAttributeSetStartingWith = (el, startWithStr, type = 'set') => {
  // 대상 요소 설정: 제공된 요소가 없을 경우 this.element 사용
  const element = el;

  // 반환할 속성 집합 초기화
  const attrSet = {};
  // 속성 존재 여부를 확인할 경우 사용하는 플래그
  let isAttr = false;

  // 요소의 모든 속성을 순회
  Array.from(element.attributes).forEach((attribute) => {
    // 주어진 시작 문자열로 속성 이름이 시작하는지 확인
    if (attribute.name.startsWith(startWithStr)) {
      if (type === 'is') {
        // 'is' 타입인 경우 존재 여부만 확인하고 순회 중단
        isAttr = true;
        return;
      } else {
        // 속성 이름에서 시작 문자열을 제외한 부분과 그 속성의 값을 attrSet에 추가
        const property = attribute.name.substring(startWithStr.length);
        const value = element.getAttribute(attribute.name);
        attrSet[property] = value;
      }
    }
  });

  // 요청된 타입에 따라 속성 집합 또는 속성의 존재 여부 반환
  return type === 'is' ? isAttr : attrSet;
};

export const identifyTarget = function (target) {
  let type = '';
  let isError = false;

  if (target instanceof jQuery) {
    // jQuery 객체인 경우
    if (!target.length) {
      isError = true;
    }
    type = 'jquery';
  } else if (target instanceof Element) {
    if (!$(target).length) {
      isError = true;
    }
    // DOM 요소인 경우
    type = 'dom';
  } else if (typeof target === 'string') {
    // 문자열 (CSS 선택자 또는 다른 문자열)인 경우
    type = 'selector';
    const $queryResult = $(target);
    if ($queryResult.length === 0) {
      // jQuery 선택자가 일치하는 요소를 찾지 못한 경우
      isError = true;
      type = 'noMatchingSelector';
    }
  } else {
    // 그 외의 타입
    isError = true;
  }

  if (!isError) {
    const el = $(target).get(0); // DOM 엘리먼트
    const $el = $(target); // jQuery 객체
    return { isError, type, el, $el };
  } else {
    // 오류 상황에서 'target'과 해당 'type' 출력
    console.error('Check the selector & type of selector: ', {
      target: target,
      type: type,
    });
    return { isError, type, el: null, $el: null }; // 오류 반환 시 키 명시
  }
};

export function getActualDimensions(element, includeMargin = false) {
  // 요소를 복제하고 스타일을 설정합니다.
  const clone = element.cloneNode(true);
  clone.style.visibility = 'hidden';
  clone.style.position = 'absolute';
  clone.style.display = 'block';

  // 마진을 포함할지 여부를 설정합니다.
  if (includeMargin) {
    clone.style.margin = 'auto';
  } else {
    clone.style.margin = '0';
  }

  // 복제된 요소를 문서에 추가합니다.
  document.body.appendChild(clone);

  // 요소의 차원을 측정합니다.
  const dimensions = {
    width: clone.offsetWidth,
    height: clone.offsetHeight,
    outerWidth: includeMargin ? clone.offsetWidth : clone.clientWidth,
    outerHeight: includeMargin ? clone.offsetHeight : clone.clientHeight,
  };

  // 복제된 요소를 제거합니다.
  document.body.removeChild(clone);

  return dimensions;
}

export function generateUniqueFilename() {
  let uuid = uuidv4();
  let uniqueFilename = uuid.replace(/-/g, '_');
  return uniqueFilename;
}

// 추가적인 유틸리티 함수들을 여기에 추가할 수 있습니다.
