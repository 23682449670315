import React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import '../../../styles/tooltip.css';
import { useThemeContext } from '@/contexts/theme/ThemeContext';

export const TooltipProvider = TooltipPrimitive.Provider;

const fontSizeClasses = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl',
};

export const Tooltip = ({
  children,
  content,
  forcedContent,
  size = 'xs',
  delayDuration = 300,
  skipDelayDuration = 200,
  disabled = false,
  ...props
}) => {
  const { isDarkMode } = useThemeContext();

  const tooltipContentClass = `
    se-tooltip-content
    rounded p-2 shadow-lg
    z-[10200]
    ${fontSizeClasses[size]}
    ${
      isDarkMode
        ? 'bg-dark-200 text-dark-900 border-dark-300'
        : 'bg-light-800 text-light-50 border-light-700'
    }
  `;

  const arrowClass = `
    se-tooltip-arrow
    ${
      isDarkMode
        ? 'fill-dark-200 stroke-dark-300'
        : 'fill-light-800 stroke-light-700'
    }
  `;

  if (disabled) {
    return children;
  }

  return (
    <TooltipPrimitive.Root
      delayDuration={delayDuration}
      skipDelayDuration={skipDelayDuration}
    >
      <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
      <TooltipPrimitive.Portal>
        <TooltipPrimitive.Content
          className={tooltipContentClass}
          sideOffset={5}
          {...props}
        >
          {forcedContent || content}
          <TooltipPrimitive.Arrow className={arrowClass} />
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Portal>
    </TooltipPrimitive.Root>
  );
};
