import React, { createContext, useContext } from 'react';
import { useS3ImageHandler } from '@hooks/image/useS3ImageHandler';

const S3ImageHandlerContext = createContext(null);

export function S3ImageHandlerProvider({ children, initialOptions = {} }) {
  const s3HandlerValue = useS3ImageHandler(initialOptions);

  return (
    <S3ImageHandlerContext.Provider value={s3HandlerValue}>
      {children}
    </S3ImageHandlerContext.Provider>
  );
}

export function useS3ImageHandlerContext() {
  const context = useContext(S3ImageHandlerContext);
  if (!context) {
    throw new Error(
      'useS3ImageHandlerContext는 S3ImageHandlerProvider 내에서 사용해야 합니다.',
    );
  }
  return context;
}
