import { CircleCheckBig } from 'lucide-react';

export const getPromptSettingsGuideConfig = (lang) => ({
  image_size: {
    title: lang('이미지사이즈'),
    description: lang('생성할이미지의크기와비율을선택합니다'),
  },
  num_inference_steps: {
    title: lang('추론단계수'),
    description: lang(
      '이미지생성에사용될추론단계의수입니다높을수록더세밀한이미지가생성되지만시간이오래걸립니다',
    ),
  },
  guidance_scale: {
    title: lang('가이던스스케일(CFG)'),
    description: lang(
      '이미지생성시프롬프트에대한가중치를조절합니다높을수록프롬프트에더충실한이미지가생성됩니다',
    ),
  },
  safety_tolerance: {
    title: lang('안전성허용치'),
    description: lang('안전성허용치'),
  },
  sync_mode: {
    title: lang('동기모드'),
    description: lang(
      '동기모드에서는이미지생성이완료될때까지다른작업을할수없습니다',
    ),
  },
  num_images: {
    title: lang('이미지수'),
    description: '',
    descriptions: [
      { content: lang('한번에생성할이미지의수를선택합니다') },
      { content: lang('한번에최대4개의이미지를생성할수있습니다') },
      { divider: true, type: 'dotted' },
      {
        title: lang('확인해주세요'),
        content: lang('Expert모델에서는한번에하나의이미지만생성할수있습니다'),
        icon: CircleCheckBig,
      },
    ],
  },
  seed: {
    title: lang('시드'),
    description: lang(
      '이미지생성에사용될랜덤시드값입니다같은시드로생성하면동일한이미지가생성됩니다',
    ),
  },
  // strength: {
  //   title: lang('strengthTitle'),
  //   description: lang('strengthDescription'),
  // },
});
