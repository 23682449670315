// src/utils/debug.js

const createDebugger = (pluginName = null) => {
  let isDebugMode = false;

  const createSeparator = (type) => {
    const length = 50;
    switch (type) {
      case 's':
      case 'i':
      case 'e':
      case 'w':
        return '='.repeat(length);
      case '=':
        return '='.repeat(length);
      case '*':
        return '*'.repeat(length);
      case '>':
        return '>'.repeat(length);
      default:
        return '';
    }
  };

  const debug = (message, ...args) => {
    if (!isDebugMode) return;

    let separator = '';
    let endSeparator = '';

    const logSettings = {
      s: { logType: 'SUCCESS', color: 'green' },
      i: { logType: 'INFO', color: 'blue' },
      e: { logType: 'ERROR', color: 'red' },
      w: { logType: 'WARN', color: 'orange' },
    };

    // 정규식을 사용하여 구분자 추출
    const separatorMatch = message.match(/^([siew*>=]):(?:([*>=]):)?/i);

    if (separatorMatch) {
      const separatorType = separatorMatch[1];
      const flagSeparatorType = separatorMatch[2];

      message = message.slice(separatorMatch[0].length);
      separator = createSeparator(flagSeparatorType || separatorType);
      const { logType, color } = logSettings[separatorType] || {};

      if (!flagSeparatorType) {
        // 단일 separatorType인 경우
        if (['s', 'i', 'e', 'w'].includes(separatorType)) {
          if (logType) {
            console.log(`\n%cSTART`, `color: ${color}`);
            console.log(`%c${separator}`, `color: ${color}`);
            console.log(
              `[${logType}${pluginName ? ` - ${pluginName}` : ''}] ${message}`,
            );
            args.forEach((arg) => console.log(arg));
            console.log(`%c${separator}`, `color: ${color}`);
            console.log(`%cEND\n`, `color: ${color}`);
            return;
          }
        } else {
          // 단일 separatorType + >, =, * 인 경우
          console.log(`\n${separator}`);
          if (message) {
            console.log(
              `[DEBUG${pluginName ? ` - ${pluginName}` : ''}] ${message}`,
            );
          }
          if (args.length > 0) {
            args.forEach((arg) => console.log(arg));
          }
          if (message || args.length > 0) {
            console.log(`${separator}\n`);
          }
        }
      } else {
        // 추가적인 구분자가 존재하는 경우
        if (logType) {
          console.log(`\n%c${separator}`, `color: ${color}`);
          if (message) {
            console.log(
              `[${logType}${pluginName ? ` - ${pluginName}` : ''}] ${message}`,
            );
          }
          if (args.length > 0) {
            args.forEach((arg) => console.log(arg));
          }
          if (message || args.length > 0) {
            console.log(`%c${separator}\n`, `color: ${color}`);
          }
          return;
        }
      }
    }

    // separator가 없는 경우: 일반적인 메시지 출력
    if (message) {
      console.log(`[DEBUG${pluginName ? ` - ${pluginName}` : ''}] ${message}`);
    }
    if (args.length > 0) {
      args.forEach((arg) => console.log(arg));
    }
  };

  const setDebugMode = (mode) => {
    isDebugMode = mode;
  };

  const getDebugMode = () => isDebugMode;

  return { debug, setDebugMode, getDebugMode };
};

export default createDebugger;
