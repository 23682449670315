import { useState, useEffect, useCallback } from 'react';
import useDebug from '@hooks/useDebug';
import { isDevelopment, getDevHost } from '@api/apiConfig';
import S3ImageHandler from '@src/js/plugins/image/S3ImageHandler';

export function useS3ImageHandler(initialOptions = {}) {
  const [s3ImageHandler, setS3ImageHandler] = useState(null);
  const [isS3ImageUpload, setIsS3ImageUpload] = useState(false);
  const [isAutoPost, setIsAutoPost] = useState(false);
  const [isS3UploadWithMetadata, setIsS3UploadWithMetadata] = useState(false);
  const { debug } = useDebug('useS3ImageHandler');

  /**
   * S3ImageHandler 초기화
   * - s3ImageHandler 인스턴스를 생성하고, 이를 전역 상태로 저장한다.
   * - Ref: AWSS3Fileuploader.js 참조, AWSS3Fileuploader.js는 S3로 파일 / 이미지 업로드 및 s3에서의 파일을 관리하기 위한 공통 모듈이다.
   * @param {object} context - S3ImageHandler 컨텍스트
   */
  const initializeS3ImageHandler = useCallback((context) => {
    // context.options가 없는 경우 빈 객체로 초기화
    if (!context.options) {
      context.options = {};
    }

    // mode와 devHost 설정
    const mode = isDevelopment() ? 'dev' : 'prod';
    const devHost = getDevHost() || 'prod';

    // context.options에 mode와 devHost 값 설정
    context.options.s3UploadMode = mode;
    context.options.s3UploadDevHost = devHost;

    debug('i:initializeS3ImageHandler: ', context);

    const handler = new S3ImageHandler(context);
    setS3ImageHandler(handler);
  }, []);

  // NOTICE: initialOptions가 있는 경우 자동 초기화
  // - 예) ImageAIChat 플러그인에서 초기화를 하는 경우, initializeS3ImageHandler() 함수를 호출하지 않고,
  // - useS3ImageHandler에 initialOptions를 전달하여 초기화를 진행한다.
  // WARN: 개발 환경과 프로덕션 환경에서 이미지 생성 및 저장되는 서버를 다르게 설정하기 위해서
  // -- mode, devHost 값을 설정한다.
  useEffect(() => {
    if (initialOptions?.options?.isS3Upload) {
      setIsS3ImageUpload(true);
      setIsS3UploadWithMetadata(initialOptions.options.isS3UploadWithMetadata);
      setIsAutoPost(initialOptions.options.isAutoPost);
      const mode = isDevelopment() ? 'dev' : 'prod';
      const devHost = getDevHost() || 'prod';

      debug('i:*:initialOptions && mode: ', { initialOptions, mode, devHost });

      initializeS3ImageHandler({
        ...initialOptions,
        options: {
          ...initialOptions.options,
          s3UploadMode: mode,
          s3UploadDevHost: devHost,
        },
      });
    }
  }, [initialOptions, debug]);

  /**
   * @description S3에 이미지를 업로드하는 함수
   * @param {File} img - 업로드할 이미지 element
   * @param {Object} generatedImageInfo - 생성된 이미지 정보
   * @param {Object} apiMetadata - API 메타데이터
   * @param {boolean} isS3UploadWithMetadata - S3에 메타데이터를 포함하여 업로드할지 여부
   * @param {boolean} isAutoPost - 자동 게시 여부
   * @returns {Promise<Object>} - 업로드 결과
   */
  const uploadImageToS3 = useCallback(
    async (uploadImageParams) => {
      if (!s3ImageHandler) {
        throw new Error('S3ImageHandler가 초기화되지 않았습니다.');
      }
      const { img, generatedImageInfo, apiMetadata } = uploadImageParams;
      debug('Check the uploadImageToS3 parameters: ', {
        img,
        generatedImageInfo,
        apiMetadata,
        isS3UploadWithMetadata,
        isAutoPost,
      });

      // s3ImageHandler.uploadImageToS3 함수 호출
      const result = await s3ImageHandler.uploadImageToS3(
        img,
        generatedImageInfo,
        apiMetadata,
        isS3UploadWithMetadata,
        isAutoPost,
      );
      debug('Check the result of uploadImage: ', result);
      return result;
    },
    [s3ImageHandler, isS3UploadWithMetadata, isAutoPost, debug],
  );

  /**
   * @description S3에 이미지를 업로드하는 함수
   * @param {File} img - 업로드할 이미지 element
   * @param {Object} generatedImageInfo - 생성된 이미지 정보
   * @param {Object} apiMetadata - API 메타데이터
   * @param {boolean} isS3UploadWithMetadata - S3에 메타데이터를 포함하여 업로드할지 여부
   * @param {boolean} isAutoPost - 자동 게시 여부
   * @returns {Promise<Object>} - 업로드 결과
   */
  const uploadMultipleImagesToS3 = useCallback(
    async (uploadMultipleImagesParams) => {
      if (!s3ImageHandler) {
        throw new Error('S3ImageHandler가 초기화되지 않았습니다.');
      }
      const {
        promptId,
        images,
        imageInfo,
        apiMetadata,
        userId,
        promptMetadata,
      } = uploadMultipleImagesParams;
      debug('*:Check the uploadMultipleImagesToS3 parameters: ', {
        promptId,
        images,
        apiMetadata,
        isS3UploadWithMetadata,
        isAutoPost,
        userId, // ! userId 값이 전달되는 경우, bucket 이름에 userId가 포함된다.
        promptMetadata,
      });

      // ! s3ImageHandler.uploadMultipleImagesToS3 함수 호출
      // - 다중 이미지를 업로드를 하는 경우, s3ImageHandler.uploadMultipleImagesToS3 함수를 호출한다.
      const result = await s3ImageHandler.uploadMultipleImagesToS3({
        promptId,
        images, // 1 ~ 4개의 이미지 리스트
        apiMetadata, // api 메타데이터
        isS3UploadWithMetadata, // s3에 메타데이터를 포함하여 업로드할지 여부
        isAutoPost, // 자동 게시 여부
        userId, // userId 값이 전달되는 경우, bucket 이름에 userId가 포함된다.
        promptMetadata, // prompt 메타데이터
      });

      debug('*:Check the result of uploadMultipleImagesToS3: ', result);
      return result;
    },
    [s3ImageHandler, isS3UploadWithMetadata, isAutoPost, debug],
  );

  const generateImageFilename = useCallback(
    (imageFilename, userId = '') => {
      if (!isS3ImageUpload || !s3ImageHandler) {
        throw new Error('S3ImageHandler가 초기화되지 않았습니다.');
      }
      return s3ImageHandler.generateImageFilename(imageFilename, userId);
    },
    [s3ImageHandler],
  );

  // ! for TEST`
  useEffect(() => {
    if (isS3ImageUpload && s3ImageHandler) {
      debug('s3ImageHandler instance has been created: ', s3ImageHandler);
    }
  }, [isS3ImageUpload, s3ImageHandler, debug]);

  useEffect(() => {
    debug('isAutoPost: ', isAutoPost);
  }, [isAutoPost, debug]);

  return {
    s3ImageHandler,
    isS3ImageUpload,
    setIsS3ImageUpload,
    initializeS3ImageHandler,
    uploadImageToS3, // 단일 이미지를 업로드
    uploadMultipleImagesToS3, // 다중 이미지를 업로드
    generateImageFilename,
    isAutoPost,
    setIsAutoPost,
    isS3UploadWithMetadata,
    setIsS3UploadWithMetadata,
  };
}
