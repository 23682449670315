import { ShortcutKeyValidator } from '@utils/shortcutKeyValidator';
import { defaultOptions } from '../config/defaultOptions';
import {
  API_CONFIG,
  isDevelopment,
  getDevHost,
  getBaseUrl,
  getCurrentServiceUrl,
} from '@api/apiConfig';

export class ConfigManager {
  constructor(instance, element, options) {
    this.instance = instance;
    this.element = element;
    this.initialOptions = options;
    this.shortcutKeys = ShortcutKeyValidator.getDefaultShortcutKeys();
  }

  getOptions() {
    const dataSet = this.element ? this.element.dataset : {};
    const options = $.extend(
      true,
      {},
      defaultOptions,
      this.initialOptions,
      dataSet,
    );

    this.validateShortcutKeys(options.shortcutKeys);

    return options;
  }

  validateShortcutKeys(shortcutKeys = null) {
    try {
      if (shortcutKeys) {
        ShortcutKeyValidator.validateShortcutKeys(shortcutKeys);
        this.shortcutKeys =
          ShortcutKeyValidator.normalizeShortcutKeys(shortcutKeys);
      } else {
        this.shortcutKeys = ShortcutKeyValidator.getDefaultShortcutKeys();
      }

      if (this.instance.options?.debugMode) {
        const readableShortcut = ShortcutKeyValidator.getReadableShortcut(
          this.shortcutKeys,
        );
        this.instance.debug('i:*:Current shortcut configuration:', {
          raw: this.shortcutKeys,
          readable: readableShortcut,
        });
      }
    } catch (error) {
      console.warn(
        `[${this.instance.pluginName}] Shortcut configuration error:`,
        error.message,
      );
      console.info(
        `[${this.instance.pluginName}] Falling back to default shortcuts`,
      );
      this.shortcutKeys = ShortcutKeyValidator.getDefaultShortcutKeys();
    }

    return this.shortcutKeys;
  }

  getEnvironmentInfo() {
    return {
      isDevelopment: isDevelopment(),
      devHost: getDevHost(),
      baseUrl: getBaseUrl(),
      serviceUrl: getCurrentServiceUrl(),
      apiConfig: {
        mode: API_CONFIG.mode,
        debug: API_CONFIG.debug,
        currentEnv: API_CONFIG.currentEnv,
        paths: API_CONFIG.paths,
        isCrossOrigin: API_CONFIG.isCrossOrigin,
      },
      environment: {
        env: import.meta.env,
        mode: import.meta.env.MODE,
        base: import.meta.env.BASE_URL,
        prod: import.meta.env.PROD,
        dev: import.meta.env.DEV,
      },
    };
  }

  updateOptions(newOptions = {}) {
    this.instance.options = $.extend(
      true,
      {},
      this.instance.options,
      newOptions,
    );
    this.validateShortcutKeys(this.instance.options.shortcutKeys);
  }

  getShortcutKeys() {
    return this.shortcutKeys || ShortcutKeyValidator.getDefaultShortcutKeys();
  }
}
