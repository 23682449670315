import React from 'react';
import { getCdnAssetImagesPath } from '@utils/assetsPath';

const EmptyMessage = ({
  lang,
}) => {
  
  // 카드 섹션 데이터를 배열로 정의
  const cards = [
    // 첫 번째 열 (xl에서만 보임)
    // [
    //   {
    //     src: getCdnAssetImagesPath('temp/cat-sunlight-portrait-2x3.jpg'),
    //     alt: "Card Image 1",
    //     display: "xl", // xl에서만 보임
    //   },
    //   {
    //     src: getCdnAssetImagesPath('temp/paris-snow-eiffel-tower-9x16.jpg'),
    //     alt: "Card Image 2",
    //     display: "xl", // xl에서만 보임
    //   },
    // ],
    // 두 번째 열 (md부터 보임)
    [
      {
        src: getCdnAssetImagesPath('temp/portrait-glasses-woman-9x16.jpg'),
        alt: "Card Image 3",
        display: "md", // md부터 보임
      },
      {
        src: getCdnAssetImagesPath('temp/car-clouds-1x1.jpg'),
        alt: "Card Image 4",
        display: "md", // md부터 보임
      },
    ],
    // 세 번째 열
    [
      {
        src: getCdnAssetImagesPath('temp/burger-floating-9x16.jpg'),
        alt: "Card Image 5",
      },
    ],
    // 네 번째 열
    [
      {
        src: getCdnAssetImagesPath('temp/flowers-table-beach-morning-1x1.jpg'),
        alt: "Card Image 6",
      },
      {
        src: getCdnAssetImagesPath('temp/coastal-cliff-sunset-16x9.jpg'),
        alt: "Card Image 7",
      },
    ],
    // 다섯 번째 열
    [
      {
        src: getCdnAssetImagesPath('temp/car-wheat-field-portrait-9x16.jpg'),
        alt: "Card Image 8",
      },
    ],
    // 여섯 번째 열 (md부터 보임)
    [
      {
        src: getCdnAssetImagesPath('temp/woman-closeup-natural-look-9x16.jpg'),
        alt: "Card Image 9",
        display: "md", // md부터 보임
      },
      {
        src: getCdnAssetImagesPath('temp/pets-cat-dog-1x1.jpg'),
        alt: "Card Image 10",
        display: "md", // md부터 보임
      },
    ],
    // 일곱 번째 열 (xl에서만 보임)
    // [
    //   {
    //     src: getCdnAssetImagesPath('stadium-supercar-night-9x16.jpg'),
    //     alt: "Card Image 11",
    //     display: "xl", // xl에서만 보임
    //   },
    //   {
    //     src: getCdnAssetImagesPath('diving-blue-water-2x3.jpg'),
    //     alt: "Card Image 12",
    //     display: "xl", // xl에서만 보임
    //   },
    // ],
  ];

  return (
    <div className="relative h-full flex flex-col items-center justify-between overflow-hidden bg-light-bg dark:bg-dark-bg">

      {/* 섹션 타이틀 */}
      <div className="w-full flex-grow flex items-center justify-center mt-20 px-6 text-center">
        <p className="text-base text-light-800 dark:text-dark-100 break-keep">
          {lang('현재대화내용이없습니다')}<br />
          {lang('이미지를생성하려면프롬프트를입력해보세요')}
        </p>
      </div>

      {/* 이미지 그리드 레이아웃 */}
      <div className="mt-auto -mx-16 -mb-2 opacity-30">
        <div className="flex items-end gap-2">
          {cards.map((column, index) => (
            <div
              key={index}
              className={`flex-1 flex flex-col gap-2 ${column[0].display ? `hidden ${column[0].display}:flex` : ''}`}
            >
              {column.map((card, cardIndex) => (
                <img
                  key={cardIndex}
                  src={card.src}
                  alt={card.alt}
                  className="w-full rounded-lg"
                />
              ))}
            </div>
          ))}
        </div>
      </div>

    </div>
  );
};

export default EmptyMessage;
