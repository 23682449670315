// 인스턴스 관리 클래스
export class InstanceManager {
  constructor() {
    this.instances = new Map();
    this.activeInstanceId = null;
  }

  add(id, instance) {
    this.instances.set(id, instance);
    this.setActiveInstance(id);
  }

  setActiveInstance(id) {
    if (this.instances.has(id)) {
      this.activeInstanceId = id;
    }
  }

  getActiveInstance() {
    if (!this.activeInstanceId) {
      return null;
    }
    return this.instances.get(this.activeInstanceId);
  }

  get(id) {
    return this.instances.get(id) || null;
  }

  remove(id) {
    return this.instances.delete(id);
  }

  findByElement(element) {
    if (!element) return null;

    for (const instance of this.instances.values()) {
      if (instance.element === element) {
        return instance;
      }
    }
    return null;
  }

  findByPageId(pageId) {
    for (const instance of this.instances.values()) {
      if (instance.options.pageId === pageId) {
        return instance;
      }
    }
    return null;
  }

  getAllInstances() {
    return Array.from(this.instances.values());
  }

  size() {
    return this.instances.size;
  }

  clear() {
    this.instances.clear();
  }

  // ... 기타 인스턴스 관리 메서드들
}
