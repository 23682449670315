export const defaultModalOptions = {
  m_shape: '2xl', // modal 모양: rounded-{size}
  h: 1, // header 출력 여부
  ht: 1, // header title
  ht_prefix: 0, // header title prefix
  cb: 1, // close button 출력 여부
  cb_in_body: 1, // close btn in body: no header 경우에만 사용, modal body 영역에 close button 출력
  cb_out: 0, // close button이 modal 밖에 출력할 때
  f: 1, // footer 출력 여부
  fb: 1, // footer button 출력 여부, f: 1 일 때만 유효
  fb_cancel: 1, // footer cancel button 출력 여부, fb: 1 일 때만 유효
  fb_cancel_text: '', // footer cancel button 텍스트, cancelText
  fb_confirm: 1, // footer confirm button 출력 여부, fb: 1 일 때만 유효
  fb_confirm_text: '', // footer confirm button 텍스트, confirmText
  bd: 0, // backdrop
  bd_static: 1, // backdrop static mode
  fs: 0, // fullscreen
  if: 0, // iframe modal
  dr: 0, // draggable
  dr_handle: '.modal-header', // draggable handle
  sticky: 0, // sticky mode 지원
  de: 0, // destroy
  ajax_load: 1, // ajax load the modal content
  // 텍스트, 사이즈 관련 옵션들
  ht_size: '', // header title 크기
  mct_size: '', // modal content 텍스트 크기
  mbt_size: '', // modal button 텍스트 크기
  // 버튼 몽양
  mb_shape: '', // modal button 모양

  // ! header, footer size
  // ! - header, footer size는 padding 값으로 설정한다.
  h_size: 'md',
  f_size: 'md',
  // header, footer border
  h_b: 1,
  f_b: 1,
  // z-index
  z_index: 1060,
};

export const mergeOptions = (defaultOptions, userOptions) => {
  return { ...defaultOptions, ...userOptions };
};

export const sizeConfig = {
  xs: '320px', // 20rem
  sm: '384px', // 24rem
  md: '448px', // 28rem
  lg: '512px', // 32rem
  xl: '576px', // 36rem
  '2xl': '672px', // 42rem
  '3xl': '768px', // 48rem
  '4xl': '896px', // 56rem
  '5xl': '1024px', // 64rem
  '6xl': '1152px', // 72rem
  '7xl': '1280px', // 80rem
  full: '100%',
};

// 높이 설정을 수정합니다
export const heightConfig = {
  xs: 'h-[300px]',
  sm: 'h-[400px]',
  md: 'h-[500px]',
  lg: 'h-[600px]',
  xl: 'h-[700px]',
  xxl: 'h-[800px]',
  full: 'h-screen',
};

export const shapeConfig = {
  none: 'rounded-none',
  sm: 'rounded-sm',
  md: 'rounded-md',
  lg: 'rounded-lg',
  xl: 'rounded-xl',
  '2xl': 'rounded-2xl',
  '3xl': 'rounded-3xl',
  full: 'rounded-full',
};

export const getSize = (size, config, fullscreen) => {
  if (fullscreen) return config.full;
  return config[size] || config.md;
};

// 모달 모양 설정을 가져오는 함수
// ! - 기본값: lg
export const getModalShape = (shape, fullscreen) => {
  if (fullscreen) return 'rounded-none';
  return shape ? shapeConfig[shape] : shapeConfig.xl; // 기본값을 lg로 설정
};

/*
 * 버튼 크기 옵션
 */

export const textSizeConfig = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl',
  '2xl': 'text-2xl',
};

export const buttonShapeConfig = {
  none: 'rounded-none',
  sm: 'rounded-sm',
  md: 'rounded-md',
  lg: 'rounded-lg',
  xl: 'rounded-xl',
  '2xl': 'rounded-2xl',
  '3xl': 'rounded-3xl',
  full: 'rounded-full',
};

export const headerSizeConfig = {
  sm: 'px-4 py-2',
  md: 'px-5 py-3',
  lg: 'px-5 py-4',
  xl: 'px-6 py-5',
  '2xl': 'px-8 py-6',
  '3xl': 'px-9 py-7',
  full: 'px-10 py-8',
};

export const footerSizeConfig = {
  sm: 'px-4 py-3',
  md: 'px-5 py-3',
  lg: 'px-5 py-4',
  xl: 'px-6 py-5',
  '2xl': 'px-8 py-6',
  '3xl': 'px-9 py-7',
  full: 'px-10 py-8',
};

// 버튼 모양을 가져오는 함수
export const getButtonShape = (shape) => {
  return buttonShapeConfig[shape] || buttonShapeConfig.md;
};

// 텍스트 크기를 가져오는 함수
export const getTextSize = (size) => {
  if (!size) return '';
  return textSizeConfig[size] || textSizeConfig.md;
};

// 버튼 크기를 가져오는 함수
export const getButtonSize = (size) => {
  if (!size) return '';
  return buttonSizeConfig[size] || buttonSizeConfig.md;
};

export const getHeaderSize = (size) => {
  return size ? headerSizeConfig[size] : headerSizeConfig.md;
};

export const getFooterSize = (size) => {
  return size ? footerSizeConfig[size] : footerSizeConfig.md;
};

export const positionConfig = {
  center: 'flex items-center justify-center',
  right: 'flex items-center justify-end',
  left: 'flex items-center justify-start',
  top: 'flex items-start justify-center',
  bottom: 'flex items-end justify-center',
  'top-right': 'flex items-start justify-end',
  'top-left': 'flex items-start justify-start',
  'bottom-right': 'flex items-end justify-end',
  'bottom-left': 'flex items-end justify-start',
};

export const animationConfig = {
  fade: {
    in: {
      enter: 'ease-out',
      enterFrom: 'opacity-0',
      enterTo: 'opacity-100',
    },
    out: {
      leave: 'ease-in-out',
      leaveFrom: 'opacity-100',
      leaveTo: 'opacity-0',
    },
  },
  slideFromRight: {
    in: {
      enter: 'transform transition ease-in-out',
      enterFrom: 'translate-x-full',
      enterTo: 'translate-x-0',
    },
    out: {
      leave: 'transform transition ease-in-out',
      leaveFrom: 'translate-x-0',
      leaveTo: 'translate-x-full',
    },
  },
  slideFromLeft: {
    in: {
      enter: 'transform transition ease-in-out',
      enterFrom: '-translate-x-full',
      enterTo: 'translate-x-0',
    },
    out: {
      leave: 'transform transition ease-in-out',
      leaveFrom: 'translate-x-0',
      leaveTo: '-translate-x-full',
    },
  },
  slideFromTop: {
    in: {
      enter: 'transform transition ease-in-out',
      enterFrom: '-translate-y-full',
      enterTo: 'translate-y-0',
    },
    out: {
      leave: 'transform transition ease-in-out',
      leaveFrom: 'translate-y-0',
      leaveTo: '-translate-y-full',
    },
  },
  slideFromBottom: {
    in: {
      enter: 'transform transition ease-in-out',
      enterFrom: 'translate-y-full',
      enterTo: 'translate-y-0',
    },
    out: {
      leave: 'transform transition ease-in-out',
      leaveFrom: 'translate-y-0',
      leaveTo: 'translate-y-full',
    },
  },
};

export const getAnimation = (animationName, direction) => {
  const animation = animationConfig[animationName] || animationConfig.fade;
  return animation[direction] || animation.in;
};

export const durationConfig = {
  fastest: 'duration-75',
  fast: 'duration-150',
  default: 'duration-300',
  slow: 'duration-500',
  slowest: 'duration-700',
};

export const getDuration = (speed) => {
  return durationConfig[speed] || durationConfig.default;
};
