// ImageAIContext.js
import React, { createContext, useContext, useMemo, useEffect } from 'react';
import useDebug from '@hooks/useDebug';
// imageAI를 위한 상태 관리를 위한 훅
import useImageAIState from '@hooks/ai/image/useImageAIState';
// imageAI를 위한 액션 관리를 위한 훅
import { useImageAIActions } from '@hooks/ai/image/useImageAIActions';

const ImageAIContext = createContext(null);

export const useImageAIContext = () => {
  const context = useContext(ImageAIContext);
  if (context === undefined) {
    throw new Error('useImageAIContext must be used within a ImageAIProvider');
  }
  return context;
};

export const ImageAIProvider = ({ children, value }) => {
  const { debug } = useDebug('ImageAIProvider');

  // value에는 모든 설정과 콜백이 포함됩니다
  const {
    pageId,
    callbacks = {}, // 콜백 함수들
    initialConfig, // 나머지 설정들
  } = value;

  // 내부에서 상태와 액션을 생성합니다.
  const imageAIState = useImageAIState(initialConfig);
  const imageAIActions = useImageAIActions(imageAIState, callbacks);

  // 컨텍스트에 전달할 값을 생성합니다.
  const contextValue = useMemo(
    () => ({
      pageId,
      callbacks,
      ...imageAIState,
      ...imageAIActions,
    }),
    [imageAIState, imageAIActions, pageId, callbacks],
  );

  return (
    <ImageAIContext.Provider value={contextValue}>
      {children}
    </ImageAIContext.Provider>
  );
};
