import React, { createContext, useContext, useCallback } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { API_CONFIG, getApiPath } from '@/api/apiConfig';
import { sendPost } from '@/api/apiService';
import useDebug from '@hooks/useDebug';

const ExploreImagesContext = createContext();

export const useExploreImagesContext = () => useContext(ExploreImagesContext);

export const ExploreImagesProvider = ({ children }) => {
  const { debug } = useDebug('ExploreImagesContext');

  /**
   * 이미지 목록을 가져오는 API 호출 함수
   * @param {Object} params - API 호출 파라미터
   * @param {number} params.pageParam - 현재 페이지 번호 (기본값: 1)
   * @param {Array} params.queryKey - useInfiniteQuery의 queryKey
   * @returns {Promise<Object>} API 응답 데이터
   */
  const fetchExploreImages = async ({ pageParam = 1 }) => {
    try {
      debug('fetchExploreImages > START', { pageParam });
      const result = await sendPost({
        // url: API_CONFIG.paths.imageAi,
        url: getApiPath('imageAi'),
        data: {
          request_type: 'list',
          list_type: 'explore_list',
          page: pageParam,
          limit: 20,
        },
        useImageAI: true,
      });

      if (result.status === 'success') {
        debug('fetchExploreImages success', result.data);

        const responseData = {
          list: result.data.list,
          current_page: pageParam,
        };

        // has_more 처리
        if ('has_more' in result.data) {
          responseData.has_more = result.data.has_more;
        } else {
          debug('Warning: has_more is missing in the API response');
          // 리스트의 길이가 요청한 limit보다 작으면 더 이상 페이지가 없다고 가정
          responseData.has_more = result.data.list.length === 20;
        }

        // ! total_pages 처리
        // ! -total_pages 값은 생략 가능
        if ('total_pages' in result.data) {
          responseData.total_pages = result.data.total_pages;
        } else {
          debug('Warning: total_pages is missing in the API response');
        }

        // has_more와 total_pages가 모두 없는 경우
        if (!('has_more' in result.data) && !('total_pages' in result.data)) {
          debug(
            'Warning: Both has_more and total_pages are missing in the API response. Pagination might not work correctly.',
          );
          // 기본값 설정 (예: 리스트가 비어있지 않으면 더 많은 페이지가 있다고 가정)
          responseData.has_more = result.data.list.length === 20;
        }

        debug('fetchExploreImages > END', responseData);

        return responseData;
      } else {
        throw new Error(
          'Fetch explore images failed: ' + (result.message || 'Unknown error'),
        );
      }
    } catch (error) {
      debug('Error in fetchExploreImages:', error);
      throw error;
    }
  };

  /**
   * useInfiniteQuery 훅 사용
   * - 무한 스크롤을 위한 데이터 fetching 관리
   */
  const {
    data, // 페이지별로 fetching된 데이터
    fetchNextPage, // 다음 페이지 데이터를 가져오는 함수
    hasNextPage, // 다음 페이지가 있는지 여부
    isFetchingNextPage, // 다음 페이지를 가져오는 중인지 여부
    status, // 쿼리 상태 ('loading', 'error', 'success')
    error, // 에러 발생 시 에러 객체
    refetch, // 쿼리를 재실행하는 함수
    isFetching, // 쿼리가 실행 중인지 여부
  } = useInfiniteQuery({
    queryKey: ['exploreImages', '', {}], // 쿼리 키 (검색어와 필터가 변경될 때 사용)
    queryFn: fetchExploreImages, // 데이터를 가져오는 함수
    getNextPageParam: (lastPage, allPages) => {
      const currentPage = allPages.length;
      const hasMore = lastPage.has_more;
      const totalPages = lastPage.total_pages;

      // has_more와 total_pages가 모두 없는 경우
      if (hasMore === undefined && totalPages === undefined) {
        debug(
          'Warning: Both has_more and total_pages are missing. Assuming no more pages.',
        );
        return undefined;
      }

      // has_more만 있는 경우
      if (hasMore !== undefined && totalPages === undefined) {
        return hasMore ? currentPage + 1 : undefined;
      }

      // total_pages만 있는 경우
      if (hasMore === undefined && totalPages !== undefined) {
        return currentPage < totalPages ? currentPage + 1 : undefined;
      }

      // has_more와 total_pages가 모두 있는 경우
      if (hasMore !== undefined && totalPages !== undefined) {
        if (!hasMore) {
          return undefined;
        }
        return currentPage < totalPages ? currentPage + 1 : undefined;
      }

      // 여기까지 오면 뭔가 잘못된 것이므로 안전하게 undefined 반환
      debug(
        'Warning: Unexpected state in getNextPageParam. Returning undefined.',
      );
      return undefined;
    },
    cacheTime: 0,
    refetchInterval: 0, // 매 렌더링마다 리페치
  });

  /**
   * 추가 이미지를 로드하는 함수
   */
  const loadMoreImages = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  /**
   * 특정 이미지 정보를 가져오는 함수
   * @param {Object} params - 파라미터 객체
   * @param {string|null} params.imageId - 이미지 ID
   * @param {string|null} params.promptId - 프롬프트 ID
   * @returns {Object|null} 찾은 이미지 정보 또는 null
   */
  const getCurrentImageInfo = useCallback(
    ({ imageId = null, promptId = null }) => {
      if (!data) return null;
      for (const page of data.pages) {
        const foundImage = page.list.find((imageInfo) => {
          if (imageId) {
            return imageInfo.image.image_id === imageId;
          } else if (promptId) {
            return imageInfo.prompt_metadata.external_prompt_id === promptId;
          }
          return false;
        });
        if (foundImage) return foundImage;
      }
      return null;
    },
    [data],
  );

  /**
   * 이미지 필터링 함수
   * @param {Object} filters - 적용할 필터 객체
   */
  const filterImages = useCallback(
    (filters) => {
      refetch({ queryKey: ['exploreImages', '', filters] });
    },
    [refetch],
  );

  // Context에 제공할 값들
  const value = {
    exploreImages: data ? data.pages.flatMap((page) => page.list) : [],
    isLoading: status === 'loading',
    isError: status === 'error',
    error,
    hasNextPage,
    loadMoreImages,
    fetchNextPage,
    filterImages,
    getCurrentImageInfo,
    refetchExploreImages: refetch,
    isFetchingNextPage,
    isFetching,
  };

  return (
    <ExploreImagesContext.Provider value={value}>
      {children}
    </ExploreImagesContext.Provider>
  );
};
