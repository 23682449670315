import { API_CONFIG, getApiPath } from '../apiConfig';
import { sendGet } from '../apiService';
import { getAccessTokenFromLocalStorage } from '../authService';

/**
 * 테마 정보를 가져오는 함수
 * @returns {Promise<Object>} 테마 정보
 */
export const getThemeInfo = async () => {
  try {
    const accessToken = getAccessTokenFromLocalStorage();

    const headers = {};
    // ! GET 방식으로 token을 전달할 때, header에 token을 포함하여 전달한다.
    // INFO:
    // - GET 요청에서 Authorization 헤더를 사용하는 것이 좋습니다:
    // - 1. 보안: URL 파라미터로 토큰을 전송하는 것보다 헤더를 통해 전송하는 것이 더 안전합니다. URL은 서버 로그나 브라우저 히스토리에 남을 수 있기 때문입니다.
    // - 2. RESTful 관행: Authorization 헤더를 사용하는 것은 RESTful API의 일반적인 관행입니다.
    // - 3. 유연성: 헤더를 사용하면 GET, POST, PUT 등 모든 HTTP 메소드에서 일관된 방식으로 인증을 처리할 수 있습니다.
    if (accessToken) {
      console.log('Check access token for getThemeInfo: ', accessToken);
      headers['Authorization'] = `Bearer ${accessToken}`;
    } else {
      console.log('getThemeInfo() without access token');
    }

    const response = await sendGet({
      url: getApiPath('getThemeInfo'),
      config: {
        withCredentials: true,
        headers: headers,
      },
      useImageAI: false,
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching theme info:', error);
    throw error;
  }
};
