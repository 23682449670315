// hooks/useKeyboardShortcut.js
import { useEffect, useCallback, useRef } from 'react';

const useKeyboardShortcut = (shortcutKeys, callback) => {
  // 최신 callback 참조 유지
  const callbackRef = useRef(callback);
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // 최신 shortcutKeys 참조 유지
  const shortcutKeysRef = useRef(shortcutKeys);
  useEffect(() => {
    shortcutKeysRef.current = shortcutKeys;
  }, [shortcutKeys]);

  // 플랫폼 체크는 한 번만 수행
  const isMac = useRef(/Mac|iPod|iPhone|iPad/.test(navigator.platform));

  // 키보드 이벤트 핸들러를 useCallback으로 메모이제이션
  const handleKeyPress = useCallback((event) => {
    const currentShortcuts = shortcutKeysRef.current;

    // 기본 키 체크
    if (
      !currentShortcuts?.key ||
      event.key.toLowerCase() !== currentShortcuts.key.toLowerCase()
    ) {
      return;
    }

    // 수정자 키 체크
    const modifiersMatch =
      ((isMac.current && event.metaKey) || (!isMac.current && event.ctrlKey)) &&
      event.shiftKey === !!currentShortcuts.shiftKey &&
      event.altKey === !!currentShortcuts.altKey;

    if (!modifiersMatch) {
      return;
    }

    event.preventDefault();

    // 안전한 콜백 실행
    if (typeof callbackRef.current === 'function') {
      callbackRef.current();
    }
  }, []); // 의존성 배열이 비어있음 - 내부에서 ref 사용

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);
};

export default useKeyboardShortcut;
