// hooks/useImageAIChatHistory.js
import { useCallback, useContext, useEffect } from 'react';
import useDebug from '@hooks/useDebug';
import { useChatHistoryContext } from '@contexts/ai/chat/ChatHistoryContext';
import { getAspectRatio } from '../utils/imageUtils';

/**
 * * 채팅 히스토리의 각 항목 구조
 * - 채팅 히스토리는 image, text, prompt, error 메시지를 가질 수 있다.
 * ! - 채팅 히스토리는 메시지 그룹을 가질 수 있다.
 * ! - group을 위한 기준 id값을 promptId로 사용한다.
 * ! - 메시지 그룹은 prompt, images, errors 메시지를 가질 수 있다.
 * ! - Ref: ImageAIChatUI.jsx에서 groupMessages 함수 참고
[
    {
        "id": "image-message-1726977437355",
        "type": "image",ㅁdf
        "promptId": "prompt-1726977437361",
        "content": {
            "images": [
                {
                    "id": "image-message-1726977437355-0",
                    "src": "https://fal.media/files/zebra/HZw4R6tsiOaXC_vVjzT5p.png",
                    "width": 1024,
                    "height": 768,
                    "aspectRatio": 1.3333333333333333,
                    "createdTime": 1726977443646,
                    "createdAt": "2024-09-22T03:57:23.646Z",
                    "metadata": {
                        "mimeType": "image/png",
                        "isBase64": true,
                        "contentType": "image/png",
                        "attributes": {
                            "data-mime-type": "image/png",
                            "data-is-base64": "true",
                            "data-index": "0",
                            "data-message-id": "image-message-1726977437355"
                        }
                    }
                }
            ],
            "aspectRatio": 1.3333333333333333,
            "generationTime": 0,
            "isProcessing": false
        },
        "timestamp": "2024-09-22T03:57:17.355Z",
        "apiOptions": {
            "api_provider": "fal",
            "model": "fal-ai/flux/schnell",
            "image_size": "landscape_4_3",
            "num_inference_steps": 28,
            "guidance_scale": 3.5,
            "sync_mode": false,
            "num_images": 1,
            "enable_safety_checker": true,
            "isBase64": true,
            "seed": 1726977437355,
            "prompt": "A beautiful asian girl DJ with headphones plays the turntable in an exotic city at night, surrounded by bright lights and colors, creating a dynamic atmosphere. The style of digital painting is detailed and vibrant, capturing her energetic movements as she dj's on stage. Her long hair flows gracefully around her face, adding to his charming appearance. In the background, illuminated skyscrapers create a dazzling backdrop that enhances the overall ambiance of this lively scene., focus on sharpness"
        }
    },
    {
        "id": "message-1726977437361",
        "type": "text",
        "promptId": "prompt-1726977437361",
        "content": "A beautiful asian girl DJ with headphones plays the turntable in an exotic city at night, surrounded by bright lights and colors, creating a dynamic atmosphere. The style of digital painting is detailed and vibrant, capturing her energetic movements as she dj's on stage. Her long hair flows gracefully around her face, adding to his charming appearance. In the background, illuminated skyscrapers create a dazzling backdrop that enhances the overall ambiance of this lively scene., focus on sharpness",
        "isUser": true,
        "timestamp": "2024-09-22T03:57:17.361Z"
    },
    {
        "id": "error-1727072620049",
        "type": "error",
        "promptId": "prompt-1726977437361",
        "content": "이미지 생성 실패: imageUrl is not defined",
        "timestamp": "2024-09-23T06:23:40.049Z"
    },
    // 다른 메시지들...
]
*/

/**
 * 채팅 히스토리를 관리하는 커스텀 훅
 * ChatHistoryContext를 사용하여 전역 상태로 채팅 히스토리를 관리하고,
 * 세션 스토리지를 통해 페이지 새로고침 시에도 채팅 기록을 유지합니다.
 *
 * @returns {Object} 채팅 히스토리 관련 상태와 함수들
 */
const useChatHistory = () => {
  const { debug } = useDebug('useChatHistory');
  // ChatHistoryContext에서 상태와 함수들을 가져옵니다.
  const { chatHistory, setChatHistory, pageId, isSessionStorage } =
    useChatHistoryContext();

  debug('*:Check chatHistory params: ', {
    pageId,
    chatHistory,
    isSessionStorage,
  });

  const getCurrentChatHistory = useCallback(
    (messageId) => {
      return chatHistory.filter((message) => message.id === messageId)[0];
    },
    [chatHistory],
  );

  /**
   * 텍스트 메시지를 채팅 히스토리에 추가하는 함수
   * @param {Object} params - 메시지 파라미터 객체
   * @param {string} params.promptId - 프롬프트 ID
   * @param {string} params.content - 메시지 내용
   * @param {string} [params.contentType='prompt'] - 메시지 타입 (기본값: 'prompt')
   * @param {boolean} [params.isUser=false] - 사용자 메시지 여부 (기본값: false)
   */
  const addTextMessage = useCallback(
    ({ promptId, content, contentType = 'prompt', isUser = false }) => {
      setChatHistory((prev) => [
        ...prev,
        {
          id: `message-${Date.now()}`,
          type: contentType, // text | prompt | error
          promptId,
          content, //
          isUser,
          timestamp: new Date(),
        },
      ]);
    },
    [setChatHistory],
  );

  /**
   * 이미지 생성 중임을 표시하기 위한 새로운 메시지를 생성하고 저장하는 함수
   * ! - 그룹 및 기준 값인 promptId를 생성하여 메시지 그룹을 구분한다.
   * @param {string} imageSize - 이미지 크기
   * @param {object} apiOptions - 이미지 생성 옵션
   * @returns {object} - 생성된 이미지 메시지의 ID와 promptId
   */
  const addImageGenerationMessage = useCallback(
    (imageSize, apiOptions) => {
      const timestamp = Date.now();
      // ! 그룹화 및 기준 값일 되는 promptId를 생성
      const promptId = `prompt-${timestamp}`;
      const messageId = `image-message-${timestamp}`;
      setChatHistory((prev) => [
        ...prev,
        {
          // * promptId는 메시지 그룹을 구분하는 기준이 되는 id값
          // - promt로 이미지를 생성하는 시점에 promptId를 생성하고, 이후 이미지 생성 메시지에서는 이 promptId를 사용해서 메시지 그룹을 구분한다.
          promptId,
          id: messageId,
          type: 'image',
          content: {
            images: [],
            aspectRatio: getAspectRatio(imageSize),
            generationTime: 0,
            isProcessing: true,
          },
          timestamp: new Date(),
        },
      ]);
      return { messageId, promptId };
    },
    [setChatHistory],
  );

  /**
   * 이미지 생성 메시지를 업데이트하는 함수
   * @param {string} promptId - 프롬프트 ID
   * @param {string} messageId - 업데이트할 메시지 ID
   * @param {object} apiOptions - API 옵션
   * @param {object} updateData - 업데이트할 데이터
   */
  const updateImageMessage = useCallback(
    ({ promptId, messageId, apiOptions, updateData }) => {
      setChatHistory((prev) =>
        prev.map((message) =>
          message.id === messageId
            ? {
                ...message,
                promptId, // promptId 값을 포함
                apiOptions: {
                  ...message.apiOptions, // 기존 apiOptions를 유지하면서 새로운 값을 추가
                  ...apiOptions,
                },
                content: {
                  ...message.content,
                  ...updateData,
                },
              }
            : message,
        ),
      );
    },
    [setChatHistory],
  );

  /**
   * 에러 메시지를 채팅 히스토리에 추가하는 함수
   * @param {string} promptId - 에러 메시지와 연관된 프롬프트 ID
   * @param {string} content - 에러 메시지 내용
   */
  const addErrorMessage = useCallback(
    ({ promptId, content }) => {
      const errorId = `error-${Date.now()}`;
      setChatHistory((prev) => [
        ...prev,
        {
          id: errorId,
          type: 'error',
          promptId,
          content,
          timestamp: new Date(),
        },
      ]);
      return errorId; // errorId를 반환합니다.
    },
    [setChatHistory],
  );

  /**
   * 에러 메시지를 추가하고 일정 시간 후 자동으로 제거하는 함수
   * @param {Object} params - 메시지 파라미터 객체
   * @param {string} params.promptId - 프롬프트 ID
   * @param {string} params.content - 에러 메시지 내용
   * @param {number} [params.duration=3000] - 메시지 표시 지속 시간 (밀리초)
   */
  const addTemporaryErrorMessage = useCallback(
    ({ promptId, content, duration = 3000 }) => {
      const errorId = addErrorMessage({ promptId, content });
      setTimeout(() => {
        setChatHistory((prev) => prev.filter((msg) => msg.id !== errorId));
      }, duration);
    },
    [addErrorMessage, setChatHistory],
  );

  /**
   * 특정 promptId에 해당하는 이미지 메시지와 프롬프트 메시지를 삭제하는 함수
   * - msg.promptId !== promptId: 이 조건은 지정된 promptId와 일치하지 않는 모든 메시지를 유지합니다.
   * msg.type === 'error': 이 조건은 promptId가 일치하더라도 'error' 타입의 메시지는 유지합니다.
   * 이렇게 하면:
   * - 지정된 promptId와 일치하는 'image', 'text', 'prompt' 타입의 모든 메시지가 삭제됩니다.
   * - 'error' 타입의 메시지는 promptId와 관계없이 항상 유지됩니다.
   * @param {string} promptId - 삭제할 메시지들의 promptId
   */
  const removeMessagesByPromptId = useCallback(
    (promptId) => {
      setChatHistory((prev) =>
        prev.filter((msg) => msg.promptId !== promptId || msg.type === 'error'),
      );
    },
    [setChatHistory],
  );

  // ! for TEST
  useEffect(() => {
    // debug('*:chatHistory changed: ', chatHistory);
  }, [chatHistory]);

  return {
    chatHistory, // 채팅 히스토리
    setChatHistory, // 채팅 히스토리를 설정하는 함수
    getCurrentChatHistory, // 현재 채팅 히스토리를 가져오는 함수
    addTextMessage, // 텍스트 메시지를 채팅 히스토리에 추가하는 함수
    addImageGenerationMessage, // 이미지 생성 메시지를 채팅 히스토리에 추가하는 함수
    updateImageMessage, // 이미지 생성 메시지를 업데이트하는 함수
    addErrorMessage, // 에러 메시지를 채팅 히스토리에 추가하는 함수
    removeMessagesByPromptId, // 특정 promptId에 해당하는 이미지 메시지와 프롬프트 메시지를 삭제하는 함수
    addTemporaryErrorMessage, // 에러 메시지를 추가하고 일정 시간 후 자동으로 제거하는 함수
  };
};

export default useChatHistory;
