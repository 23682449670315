import React, { useState, useCallback, useEffect, useRef } from 'react';
import useDebug from '@hooks/useDebug';
// contexts
import { useModal } from '@contexts/modal/ModalContext';
import { useImageAIContext } from '@contexts/ai/image/ImageAIContext';
import { useImageAIServiceContext } from '@contexts/ai/service/imageAI/ImageAIServiceContext';
// import { useImageAIChatContext } from '../contexts/ImageAIChatContext';

// chat plugin에서 생성된 이미지 목록을 관리를 위한 context
// INFO: ImageAI service의 CreatedImagesContext를 상속 + 확장하여 사용한다.
// ! - 기존의 ChatHistoryContext(useChatHistory)를 사용하는 부분을 대체한다.
import { useChatCreatedImagesContext } from '../contexts/ChatCreatedImagesContext';
// hooks
import useChatHistory from '../hooks/useChatHistory';

// components
import ImageAIChatHeader from './ImageAIChatHeader';
import ImageAIChatPrompt from './ImageAIChatPrompt';
import ImageAIChatHistory from './ImageAIChatHistory';
import ImageViewer from './ImageViewer';
import './ImageAIChatUI.css';

/**
 * ImageAIChatUI 컴포넌트
 * - 이미지 AI 채팅 화면을 구성하는 컴포넌트
 * INFO: ImageAI service에서 Create.jsx와 동일한 기능을 수행한다.
 * @param {object} config - 컴포넌트의 속성
 * @returns
 */
const ImageAIChatUI = ({ isDarkMode = true }) => {
  const { debug } = useDebug('ImageAIChatUI');
  const chatHistoryRef = useRef(null);
  const context = useImageAIContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const { alertMsg } = useModal();

  // TODO: isDarkMode 상태 관리 방법 변경 필요
  // ! - 테마 컨텍스트를 사용하는 방법으로 변경
  // const { isDarkMode, setIsDarkMode } = useTheme();

  const {
    // ! Create.jsx 컴포넌트에서 공통으로 사용하는 상태 및 함수
    prompt,
    setPrompt,
    promptSettings,
    modelList,
    setModelList,
    selectedModel,
    setPromptSettings,
    setSelectedModel,
    // TODO: 기본 설정 함수를 공통으로 사용하는 방법으로 변경
    // setDefaultSettings
    removeImageInfo,

    // !현재 컴포넌트에서만 사용하는 상태 및 함수
    imageInfo,
    addImageInfo,
    updateImageInfo,
    handleViewImage: originalHandleViewImage,
  } = useImageAIContext();

  // NOTICE: ImageAIChatContext 사용 중지
  // WARN: ImageAIServiceContext 동통으로 사용하도록 구조 수정 중
  // const {
  //   // 이미지 생성
  //   prepareImageGeneration,
  //   generateImage,
  //   isProcessing,
  //   // api
  //   apiType,
  //   defaultApiConfig,

  //   // ! 해당 컴포넌트에서만 사용하는 함수
  //   changeApiType,
  //   pageId,
  //   isDarkMode,
  //   // error,
  // } = useImageAIChatContext();
  const {
    prepareImageGeneration,
    generateImage,
    isProcessing,
    apiType,
    defaultApiConfig,
  } = useImageAIServiceContext();

  const { chatHistory, getCurrentChatHistory } = useChatHistory();

  // NOTICE: ChatCreatedImagesContext에서 관리하는 상태 및 함수
  // ! ChatCreatedImagesContext는 CreatedImagesContext를 확장하여 채팅 관련 기능 추가
  // ! 기존의 ChatHistoryContext(useChatHistory)를 사용하는 부분을 대체한다.
  const {
    // isCreatedImagesAuth,
    createdImages,
    isLoading,
    isError,
    error,
    isGenerating,
    fetchCreatedImages,
    handleSubmit,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetchCreatedImages,
    isAuthChecked,
    chatSpecificFunction,
  } = useChatCreatedImagesContext();

  const imageSizes = defaultApiConfig?.image_sizes;

  const setDefaultSettings = useCallback(() => {
    debug('i:*:setDefaultSettings called');
    // 기본 prompt 설정
    setPrompt('');
    // 기본 promptSettings 설정
    setPromptSettings(defaultApiConfig.api_options);
    // 기본 modelList 설정
    setModelList(defaultApiConfig.model_options);
    // 기본 selectedModel 설정
    setSelectedModel(
      defaultApiConfig.model_options.find((model) => model.default),
    );
  }, []);

  useEffect(() => {
    debug('Initial ImageAIChatUI params', {
      context,
      defaultApiConfig,
      promptSettings,
      apiType,
      selectedModel,
      modelList,
    });
    // 기본 promptSettings 설정
    setDefaultSettings();
  }, []);

  const scrollToTop = () => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = 0;
    }
  };

  /**
   * ! 프롬프트 제출 핸들러
   * ! - 모든 프롬프트 제출 시, CreatedImagesContext의 handleSubmit 호출
   * @param {object} selectedModel - 선택된 모델
   * @param {string} prompt - 프롬프트
   * @param {object} settings - 설정
   */
  const handlePromptSubmit = useCallback(
    async ({
      selectedModel,
      prompt,
      settings,
      originalPrompt,
      originalLanguage = 'en',
    }) => {
      // ! 이미지가 생성 중일 때는 추가 이미지 생성 방지
      // TODO: Queue를 통해서 이미지 생성 중인 경우에도 이미지라를 생성할 수 있는 기능 구현 필요
      if (isGenerating) {
        errorMsg = lang('이미지가생성중입니다');
        alertMsg(errorMsg);
        return false;
      }

      // ! for TEST: 이미지 생성 전, 최종적인 정보 확인
      debug('i:*:Check handlePromptSubmit called params', {
        selectedModel,
        prompt,
        settings,
        originalPrompt,
        originalLanguage,
        defaultApiConfig,
      });

      try {
        await handleSubmit({
          selectedModel,
          prompt,
          settings,
          originalPrompt,
          originalLanguage,
        });
        // 성공 시 설정 초기화
        setDefaultSettings({ defaultApiConfig });
      } catch (error) {
        // 오류 발생 시 현재 상태 유지
        debug('e:*:이미지 생성 중 오류 발생:', error);
        const errorMsg = error.message;
        alertMsg(errorMsg);
      }
    },
    [
      handleSubmit,
      isGenerating,
      setDefaultSettings,
      defaultApiConfig,
      debug,
      alertMsg,
    ],
  );

  const openImageViewerModal = (data) => {
    setModalData(data);
    setIsModalOpen(true);
    debug('openImageViewerModal', data);
  };

  const closeImageViewerModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  useEffect(() => {
    debug('modalData', { modalData, isModalOpen });
  }, [modalData, isModalOpen, debug]);

  // 현재 메시지의 정보를 return
  const getCurrentMessageInfo = useCallback((messageId, chatHistory) => {
    return chatHistory.find((message) => message.id === messageId);
  }, []);

  /**
   * 이미지 보기 함수
   * - 이미지 상의 fullscreen icon을 클릭했을 때 호출되는 함수
   * - Modal을 통해서 이미지와 이미지 생성 정보를 출력한다.
   * - Ref: ImageViewer.jsx
   * @param {string} messageId - 현재 메시지의 id
   * @param {object} currentImageInfo - 현재 이미지 정보
   * @param {object} currentImageEl - 현재 이미지 엘리먼트
   * @param {number} imageIndex - 현재 이미지 인덱스
   */
  const handleViewImage = useCallback(
    ({
      promptId,
      messageId,
      imageIndex,
      imageInfo,
      imageEl,
      currentImageInfo,
    }) => {
      const currentChatHistory = getCurrentChatHistory(messageId);
      const currentApiOptions = currentChatHistory.apiOptions;
      debug('handleViewImage called', {
        promptId,
        messageId,
        currentChatHistory,
        imageInfo,
        imageIndex,
        imageEl,
      });
      // originalHandleViewImage(messageId, imageInfo, imageEl, imageIndex);
      openImageViewerModal({
        promptId,
        messageId,
        apiMetadata: currentApiOptions, // 현재 이미지 정보
        imageMetadata: imageInfo, // 현재 이미지 정보
        imageEl: imageEl, // 현재 이미지 엘리먼트
        imageIndex, // 현재 이미지 인덱스
      });
    },
    [
      originalHandleViewImage,
      chatHistory,
      getCurrentChatHistory,
      openImageViewerModal,
      debug,
    ],
  );

  // 값들이 제대로 전달되는지 확인
  useEffect(() => {
    debug('ChatCreatedImagesContext values in UI:', {
      hasCreatedImages: Array.isArray(createdImages),
      createdImagesLength: createdImages?.length,
      isLoading,
      isError,
      error,
      isGenerating,
      hasNextPage,
      isFetchingNextPage,
      isAuthChecked,
    });
  }, [createdImages, isLoading, isError, isGenerating]);

  return (
    <>
      <div
        id='supereasy-image-ai-agent'
        className='g-image-ai-chat-wrapper flex h-full flex-col bg-light-bg dark:bg-dark-bg'
      >
        <ImageAIChatHeader
          apiType={apiType}
          // onApiTypeChange={handleApiTypeChange}
        />
        <ImageAIChatPrompt
          prompt={prompt}
          setPrompt={setPrompt}
          modelList={modelList}
          selectedModel={selectedModel}
          setSelectedModel={setSelectedModel}
          imageSizes={imageSizes}
          settings={promptSettings}
          setSettings={setPromptSettings}
          onSubmit={handlePromptSubmit}
          isProcessing={isProcessing}
        />
        <ImageAIChatHistory
          createdImages={createdImages}
          isLoading={isLoading}
          isGenerating={isGenerating}
          onSubmit={handlePromptSubmit}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
      </div>
      <ImageViewer
        isOpen={isModalOpen}
        onClose={closeImageViewerModal}
        data={modalData}
        isDarkMode={isDarkMode}
      />
    </>
  );
};

export default ImageAIChatUI;
