import React, { useState, useEffect, useCallback, useMemo } from 'react';
import useDebug from '@hooks/useDebug';
import { useImageAIContext } from '@contexts/ai/image/ImageAIContext';
import { modelConfigs } from '@config/api/ai/image/modelConfig';
import { ChevronUpDownIcon, CheckIcon } from '@heroicons/react/20/solid';

/**
 * ModelSettings 컴포넌트
 *
 * @param {Object} props - 컴포넌트 프로퍼티
 * @param {Array} props.modelList - 선택 가능한 모델 목록
 * @param {Object} props.selectedModel - 현재 선택된 모델 객체
 * @param {Function} props.setSelectedModel - 선택된 모델을 설정하는 함수
 */
const ModelSettings = ({
  modelList,
  selectedModel,
  setSelectedModel,
  settings,
  setSettings,
}) => {
  const { debug } = useDebug('ModelSettings');
  const [isOpen, setIsOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  // NOTICE: model 변경 시, 호출되는 함수
  // - 모델을 선택해서 변경 시에만 해당 함수가 호출되어야 한다.
  const { updateSettingsForModel } = useImageAIContext();

  // active가 true인 모델만 필터링
  const activeModels = modelList.filter((model) => model.active);

  // // selectedModel이 undefined일 경우 기본값 설정 (default가 true인 모델 또는 첫 번째 모델)
  // const [currentModel, setCurrentModel] = useState(() => {
  //   if (selectedModel) return selectedModel;
  //   const defaultModel =
  //     activeModels.find((model) => model.default) || activeModels[0];
  //   return defaultModel || null;
  // });

  // currentModel 상태 초기화를 useState 대신 useMemo로 변경
  // ! currentModel을 useState에서 useMemo로 변경하여 의존성이 변경될 때마다 자동으로 재계산되도록 했습니다.
  // - 상태 관리를 단순화하여 setCurrentModel을 제거했습니다.
  // - 초기화 로직을 하나의 useMemo 안에서 처리하도록 통합했습니다.
  const currentModel = useMemo(() => {
    if (selectedModel) return selectedModel;
    if (activeModels.length > 0) {
      const defaultModel =
        activeModels.find((model) => model.default) || activeModels[0];
      if (defaultModel && !selectedModel) {
        setSelectedModel(defaultModel);
      }
      return defaultModel;
    }
    return null;
  }, [selectedModel, activeModels, setSelectedModel]);

  // const [currentModelInfo, setCurrentModelInfo] = useState(null);

  const handleSelect = useCallback(
    (model) => {
      debug('handleSelect', model);
      setSelectedModel(model);
      setIsOpen(false);
      setIsSelected(true);
    },
    [setSelectedModel, setIsSelected],
  );

  // WARN: model 변경 시 현재 모델 및 선택된 모델에 따라서, promptSettings 확장 / 업데이트
  // ! - updateSettingsForModel() 함수를 통해서 모델 별, 설정 값( 설정 별 기본 값,범위 등)을 설정한다.
  useEffect(() => {
    if (selectedModel && isSelected) {
      // NOTICE: 모델 선택 select를 통해서 model 변경 시, 해당 선택된 model의 기본 값을 동적으로 업데이트 해야 한다.
      // - useImageAIContext() 훅에서 제공하는 updateSettingsForModel() 함수를 통해서 모델 별, 기본 설정 값( 설정 별 기본 값,범위 등)을 설정한다.
      // WARN: 모델 선택 select를 통해서model 변경 시에만, updateSettingsForModel() 함수가 호출되어야 한다.
      // - 그렇지 않으면, editImage 등으로 설정이 변경이 될 때, 해당 함수가 호출되어 모델에 따른 기본 설정 값으로 설정이 된다.
      // - 예를 들어) seed 값이 유지되지 않고, 초기화가 되는 등의 문제가 발생한다.
      // - 따라서 isSelected 상태를 통해서 조건을 추가한다.
      updateSettingsForModel(selectedModel);
      setIsSelected(false);
    }
  }, [isSelected, selectedModel]);

  // ! for TEST
  useEffect(() => {
    debug('ModelSettings params', {
      modelList,
      selectedModel,
      currentModel,
      // currentModelInfo,
      settings, // ! model 변경 시, 동적으로 변경되는 settings값을 확인
    });
  }, [modelList, selectedModel, currentModel, settings, debug]);

  if (!currentModel) return null; // 모델이 없는 경우 아무것도 렌더링하지 않음

  return (
    <div id='model-settings' className='relative w-full lg:w-40'>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className='relative w-full rounded-xl border border-light-100 bg-light-bg px-4 py-3.5 text-left shadow-[0px_7px_21px_0px_rgba(51,_51,_51,_0.05)] dark:border-dark-700/30 dark:bg-dark-bg dark:shadow-[0px_7px_21px_0px_rgba(0,_0,_0,_0.25)]'
      >
        <span className='block truncate'>{currentModel.text}</span>
        <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
          <ChevronUpDownIcon
            className='h-5 w-5 text-light-400 dark:text-dark-400'
            aria-hidden='true'
          />
        </span>
      </button>
      {isOpen && (
        <ul className='absolute z-10 mt-3 max-h-60 w-full overflow-auto rounded-xl border border-light-100 bg-light-bg p-2 text-sm shadow-lg focus:outline-none dark:border-dark-700/30 dark:bg-dark-900'>
          {activeModels.map((model) => (
            <li
              key={model.key}
              className={`relative cursor-default select-none rounded-md py-2 pl-4 pr-10 ${
                model.key === currentModel.key
                  ? 'bg-light-100 text-light-900 dark:bg-dark-700/30 dark:text-dark-100'
                  : 'text-light-900 transition duration-200 ease-in-out hover:bg-light-100 dark:text-dark-100 hover:dark:bg-dark-700/30'
              }`}
              onClick={() => handleSelect(model)}
            >
              <span
                className={`block truncate ${
                  model.key === currentModel.key ? 'font-medium' : 'font-normal'
                }`}
              >
                {model.text}
              </span>
              {model.key === currentModel.key && (
                <span className='absolute inset-y-0 right-0 flex items-center pr-3 text-light-600 dark:text-dark-300'>
                  <CheckIcon className='h-4 w-4' aria-hidden='true' />
                </span>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default React.memo(ModelSettings);
