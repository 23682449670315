import React, { useEffect } from 'react';
import useDebug from '@hooks/useDebug';
import * as ToggleGroup from '@radix-ui/react-toggle-group';
import {
  getAspectByRatioFromFalImageSize,
  getRatioFromByRatio,
} from '@utils/image/imageUtils';

const boxSizes = {
  xs: 'w-20 h-20',
  sm: 'w-24 h-24',
  md: 'w-28 h-28',
  lg: 'w-32 h-32',
  xl: 'w-36 h-36',
};
const fontSizes = {
  xs: 'text-[10px]',
  sm: 'text-[11px]',
  md: 'text-[12px]',
  lg: 'text-[13px]',
  xl: 'text-[14px]',
};

const ImageSizeSelectorRadioBox = ({
  options,
  value,
  onChange,
  size = 'md',
}) => {
  const { debug } = useDebug('ImageSizeSelectorRadioBox');

  const toggleGroupItemClasses = `
    group/item flex items-center justify-center 
    bg-light-100/25 dark:bg-dark-700/25 hover:bg-light-100/50 hover:dark:bg-dark-700/50 
    text-light-900 dark:text-dark-100 
    focus:z-10 focus:outline-none 
    data-[state=on]:bg-light-200/50 dark:data-[state=on]:bg-dark-500/50 
    rounded-md 
    p-2 
    transition duration-200 ease-in-out 
    ${boxSizes[size]}
  `;
  // const toggleGroupItemClasses = `
  //   bg-light-50/25 dark:bg-dark-900/50 text-light-900 dark:text-dark-100
  //   hover:bg-light-100/50 dark:hover:bg-dark-600/50
  //   focus:z-10 focus:outline-none
  //   data-[state=on]:bg-light-300/50 dark:data-[state=on]:bg-dark-400/50
  //   border-y border-r first:border-l border-light-100 dark:border-dark-700/30
  //   first:rounded-l-md last:rounded-r-md
  //   p-2 flex items-center justify-center
  //   group ${boxSizes[size]}
  // `;

  const renderRatioBox = (imageSize) => {
    const aspectRatio = getAspectByRatioFromFalImageSize(imageSize);
    const ratio = getRatioFromByRatio(aspectRatio);
    const [width, height] = aspectRatio.split('/').map(Number);

    debug('Check ratio: ', {
      aspectRatio,
      ratio,
      width,
      height,
    });

    const boxClasses = `
      w-full pb-[100%] relative
    `;

    const innerBoxClasses = `
      absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 
      flex items-center justify-center 
      ${fontSizes[size]} font-medium text-light-600 dark:text-dark-400 
      border-2 border-light-300 dark:border-dark-600 
      rounded-lg 
      group-hover/item:text-light-700 group-hover/item:dark:text-dark-300 
      group-hover/item:border-light-400 group-hover/item:dark:border-dark-300 
      group-data-[state=on]/item:text-light-800 group-data-[state=on]/item:dark:text-dark-100 
      group-data-[state=on]/item:border-light-500 group-data-[state=on]/item:dark:border-dark-300
    `;
    // const innerBoxClasses = `
    //   absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
    //   border border-light-300 rounded-lg
    //   flex items-center justify-center
    //   ${fontSizes[size]} font-normal
    //   group-hover:font-semibold
    //   group-hover:border-light-400
    //   group-data-[state=on]:border-light-500
    //   group-data-[state=on]:font-semibold
    //   dark:border-dark-700
    //   dark:group-hover:border-dark-400
    //   dark:group-data-[state=on]:border-dark-300
    // `;

    const aspectRatioStyle = {
      aspectRatio: `${width} / ${height}`,
      width: ratio < 1 ? '90%' : `${90 / ratio}%`,
      height: ratio > 1 ? '90%' : `${90 * ratio}%`,
    };

    return (
      <div className={boxClasses}>
        <div className={innerBoxClasses} style={aspectRatioStyle}>
          {width} : {height}
        </div>
      </div>
    );
  };

  useEffect(() => {
    debug('ImageSizeSelectorRadioBox', { options, value, size });
  }, [options, value, size]);

  return (
    <ToggleGroup.Root
      // className='inline-flex rounded-md shadow-sm'
      className='flex gap-2'
      type='single'
      value={value}
      onValueChange={onChange}
      aria-label='Image size'
    >
      {options.map((option) => (
        <ToggleGroup.Item
          key={option.value}
          className={toggleGroupItemClasses}
          value={option.value}
          aria-label={option.label}
        >
          {renderRatioBox(option.value)}
        </ToggleGroup.Item>
      ))}
    </ToggleGroup.Root>
  );
};

export default ImageSizeSelectorRadioBox;
