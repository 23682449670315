// src/hooks/useImageGeneration.js

import { useState, useCallback, useMemo, useEffect } from 'react';
import useDebug from '@hooks/useDebug';
import useImageApi from './useImageApi';

import { useImageAIContext } from '@contexts/ai/image/ImageAIContext';
// ! s3로 이미지 업로드를 위한 Context
// - Ref: useS3ImageHandler.js hook을 참조한다.
import { useS3ImageHandlerContext } from '@contexts/image/S3ImageHandlerContext';

/**
 * 이미지 생성 훅
 * - 이미지 생성 중 여부, 생성된 이미지, 생성 오류 등을 관리한다.
 * @param {string} initialApiType - 이미지 생성 API 유형
 * @returns {object} - 이미지 생성 상태, 이미지 생성 액션
 */
const useImageGeneration = (initialApiType = 'fluxFal') => {
  const { debug } = useDebug('useImageGeneration');
  const { callbacks = {} } = useImageAIContext() ?? {};
  const [pageId, setPageId] = useState(null);

  const [promptId, setPromptId] = useState(null);
  // const [imageId, setImageId] = useState(null);

  // ! 이미지 생성 후, s3로 이미지 업로드를 위한 훅
  const { isS3ImageUpload, uploadImageToS3, uploadMultipleImagesToS3 } =
    useS3ImageHandlerContext();

  // ! apiType에 따라서 이미지 생성 API를 선택하고, 이미지 생성 API 인스턴스를 생성하는 훅
  const { apiType, apiInstance, defaultApiConfig, changeApiType } =
    useImageApi(initialApiType);

  // isProcessing: 이미지 생성 중 여부
  const [isProcessing, setIsProcessing] = useState(false);

  // error: 이미지 생성 중 발생한 에러
  const [error, setError] = useState(null);

  // generatedImage: 생성된 이미지
  const [generatedImages, setGeneratedImages] = useState(null);

  // 안전한 콜백 실행 유틸리티 함수
  const safeCallback = useCallback(
    (callbackName, ...args) => {
      if (callbacks && typeof callbacks[callbackName] === 'function') {
        try {
          callbacks[callbackName](...args);
        } catch (error) {
          console.error(`Error in ${callbackName} callback:`, error);
        }
      }
    },
    [callbacks],
  );

  // ! for TEST
  // ! s3 업로드 설정 및 인스턴스 생성을 확인
  // useEffect(() => {
  //   debug('Check isS3ImageUpload: ', isS3ImageUpload);
  // }, [isS3ImageUpload]);

  // useEffect(() => {
  //   debug('Check apiInstance: ', apiInstance);
  // }, [apiInstance]);

  /**
   * 이미지 생성 준비
   * @param {object} apiOptions - 이미지 생성 옵션
   * @returns {object} - 이미지 생성 준비 상태, 이미지 사이즈(aspectRatio 값을 구하고, 실제 생성하는 이미지 크기의 imageMessage를 생성하기 위함)
   */
  const prepareImageGeneration = useCallback(
    (apiOptions) => {
      const timestamp = Date.now();
      const promptId = `prompt-${timestamp}`;
      const messageId = `image-message-${timestamp}`;
      setIsProcessing(true);
      return {
        imageSize: apiOptions.image_size,
        promptId,
        messageId,
      };
    },
    [setIsProcessing],
  );

  /**
   * 이미지 생성 결과와 S3 업로드 결과를 병합하는 함수
   * ! - generateImageResult: 이미지 생성 결과
   * ! - s3UploadResult: S3 업로드 결과
   * ! - mergedResult: 병합된 결과
   * ! -- generateImageResult에는, images와 imageInfo가 있다.
   * ! -- s3UploadResult에는, uploadResults가 있다.
   * ! -- images와 imageInfe 내에 존재하는 api로 부터 생성된 이미지 주소를 s3UploadResult내의 실제 s3의 이미지 주소로 대체를 한다.
   * @param {object} generateImageResult - 이미지 생성 결과
   * @param {object} s3UploadResult - S3 업로드 결과
   * @returns {object} - 병합된 결과
   */
  const mergeImageAndS3Results = (generateImageResult, s3UploadResult) => {
    const updatedImages = generateImageResult.images.map((image, index) => {
      const uploadData = s3UploadResult.data.uploadResults[index];
      return {
        ...image,
        src: uploadData.s3Meta.cdn_url || uploadData.s3Meta.object_url,
      };
    });

    const updatedImageInfo = generateImageResult.imageInfo.map(
      (info, index) => {
        const uploadData = s3UploadResult.data.uploadResults[index];
        return {
          ...info,
          url: uploadData.s3Meta.cdn_url || uploadData.s3Meta.object_url,
        };
      },
    );

    return {
      ...generateImageResult,
      images: updatedImages,
      imageInfo: updatedImageInfo,
      s3UploadResults: s3UploadResult.data.uploadResults,
    };
  };

  /**
   * 이미지 생성
   * - 현재 설정된 apiType에 따라서 해당 API 모듈 내의 generateImage 함수를 호출한다
   * - 예) fal api를 사용하는 경우: fluxFalApi.js 내의 generateImage 함수를 호출한다.
   * @param {string} prompt - 이미지 생성 프롬프트
   * @param {object} apiOptions - 이미지 생성 옵션
   * @param {string} messageId - 메시지 ID
   * @param {object} promptMetadata - 프롬프트 메타데이터
   * @returns {object} - 이미지 생성 결과
   */
  const generateImage = useCallback(
    async ({
      prompt,
      apiOptions,
      promptId,
      messageId,
      userId = null,
      promptMetadata,
    }) => {
      setIsProcessing(true);
      setError(null);
      setGeneratedImages(null);

      debug('*:generateImage START: ', {
        prompt,
        apiOptions,
        apiInstance,
        messageId,
        userId,
        isS3ImageUpload,
        uploadImageToS3,
        promptMetadata,
      });

      safeCallback('onGeneratingImage', {
        prompt,
        apiOptions,
        userId,
      });

      try {
        let result;
        switch (apiType) {
          case 'fluxReplicate':
          case 'openAI':
          case 'fluxFal':
            result = await apiInstance.generateImage({
              prompt,
              apiOptions,
              messageId,
            });
            break;
          default:
            throw new Error(`지원되지 않는 API 유형: ${apiType}`);
        }

        if (result.status !== 'success') {
          throw new Error(result.details || '이미지 생성 실패');
        }

        debug('*:image generated successfully: ', result);

        if (isS3ImageUpload) {
          const uploadResult = await uploadMultipleImagesToS3({
            promptId,
            images: result.data.images,
            apiMetadata: apiOptions,
            promptMetadata,
            userId,
          });

          if (uploadResult.status !== 'success') {
            throw new Error('S3 이미지 업로드 실패: ' + uploadResult.message);
          }

          const mergedResult = mergeImageAndS3Results(
            result.data,
            uploadResult,
          );
          debug('=:mergedResult: ', {
            generateImageResult: result.data,
            s3UploadResult: uploadResult,
            mergedResult,
          });

          setGeneratedImages(mergedResult.images);
          mergedResult.isS3ImageUploaded = true;

          // callback
          safeCallback('onGeneratedImage', {
            mergedResult,
          });

          return { status: 'success', data: mergedResult };
        } else {
          setGeneratedImages(result.data.images);
          result.data.isS3ImageUploaded = false;
          return { ...result };
        }
      } catch (err) {
        debug('이미지 생성 오류', err);
        setError(err.message);
        throw err;
      } finally {
        setIsProcessing(false);
      }
    },
    [
      setIsProcessing,
      setError,
      setGeneratedImages,
      apiType,
      apiInstance,
      debug,
      isS3ImageUpload,
      uploadMultipleImagesToS3,
      mergeImageAndS3Results,
      callbacks,
    ],
  );

  return useMemo(
    () => ({
      pageId,
      setPageId,
      prepareImageGeneration,
      generateImage,
      isProcessing, // API 서버로 이미지 생성 요청 중 여부
      setIsProcessing, // API 서버로 이미지 생성 요청 중 여부 설정
      error,
      generatedImages,
      currentApiType: apiType,
      defaultApiConfig, // defaultApiConfig를 반환값에 포함
      changeApiType,
      apiInstance,
      isS3ImageUpload,
      uploadMultipleImagesToS3,
      mergeImageAndS3Results,
    }),
    [
      pageId,
      setPageId,
      promptId,
      setPromptId,
      prepareImageGeneration,
      generateImage,
      isProcessing,
      setIsProcessing,
      error,
      generatedImages,
      apiType,
      changeApiType,
      defaultApiConfig, // 의존성 배열에도 추가
      apiInstance,
      isS3ImageUpload,
      uploadMultipleImagesToS3,
      mergeImageAndS3Results,
    ],
  );
};

export default useImageGeneration;
