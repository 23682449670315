import React, { useEffect, useMemo, useCallback, useRef } from 'react';
import useDebug from '@hooks/useDebug';
import { useTranslation } from 'react-i18next';
import ImageSizeSelectorRadioBox from '../ImageAISettings/ImageSizeSelectorRadioBox';
import RangeInput from '../ImageAISettings/RangeInput';
import SwitchInput from '../ImageAISettings/SwitchInput';
import NumberInput from '../ImageAISettings/NumberInput';
import SeedInput from '../ImageAISettings/SeedInput';
import MemoizedPopover from '@components/common/Popover/MemoizedPopover.jsx';
import { modelConfigs } from '@config/api/ai/image/modelConfig';
import { getPromptSettingsGuideConfig } from './promptSettingsGuideConfig';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import './PromptSettingsForChat.css';

const PromptSettingsForChat = ({
  imageSizes,
  settings,
  setSettings,
  selectedModel,
}) => {
  const { debug } = useDebug();
  const { t } = useTranslation([
    'components/service/imageAI/components/prompt/promptSettings',
  ]);
  const lang = t;

  const modelConfig = useMemo(
    () => modelConfigs[selectedModel.key],
    [selectedModel.key],
  );
  const guideConfig = useMemo(() => getPromptSettingsGuideConfig(t), [t]);

  const popoverOptions = useMemo(
    () => ({
      triggerOn: 'hover',
      style: { fontSize: 'xs' },
      placement: 'bottom-start',
      offset: 5,
      panel: {
        header: { use: true, close: false },
      },
    }),
    [],
  );

  const handleSettingChange = useCallback(
    (key, value) => {
      setSettings((prev) => ({ ...prev, [key]: value }));
      debug(`Setting changed: ${key} = ${value}`);
    },
    [setSettings, debug],
  );

  const renderSettingItem = useCallback(
    (key, config) => {
      if (!config.show) return null;

      const guideInfo = guideConfig[key];
      const commonProps = {
        value: settings[key],
        onChange: (value) => handleSettingChange(key, value),
        ...config,
      };

      const popoverProps = guideInfo
        ? {
            title: guideInfo.title,
            content: guideInfo.description,
            contentList: guideInfo.descriptions || [],
            options: popoverOptions,
          }
        : null;

      return (
        <div className='flex flex-col py-2'>
          {guideInfo && (
            <div className='mb-1.5 flex items-center gap-1'>
              <span className='text-sm font-medium text-light-700 dark:text-dark-200'>
                {guideInfo.title}
              </span>
              {popoverProps && (
                <MemoizedPopover {...popoverProps}>
                  <QuestionMarkCircleIcon className='h-4 w-4' />
                </MemoizedPopover>
              )}
            </div>
          )}
          <div className='w-full'>
            {(() => {
              switch (key) {
                case 'image_size':
                  return (
                    <ImageSizeSelectorRadioBox
                      options={imageSizes}
                      {...commonProps}
                    />
                  );
                case 'num_inference_steps':
                case 'guidance_scale':
                  return <RangeInput {...commonProps} />;
                case 'sync_mode':
                  return <SwitchInput {...commonProps} />;
                case 'num_images':
                  return <NumberInput {...commonProps} />;
                case 'seed':
                  return (
                    <SeedInput
                      {...commonProps}
                      onRandomize={() =>
                        handleSettingChange('seed', Date.now().toString())
                      }
                      lang={t}
                    />
                  );
                case 'strength':
                  return (
                    <input
                      type='hidden'
                      name='strength'
                      value={settings[key]}
                    />
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      );
    },
    [settings, guideConfig, handleSettingChange, imageSizes, t],
  );

  const renderSettingGroup = useCallback(
    (title, items) => (
      <div className='rounded-lg border border-light-100 bg-light-50/25 p-3 dark:border-dark-700/30 dark:bg-dark-900/50'>
        <h3 className='mb-2 text-sm font-semibold text-light-900 dark:text-dark-100'>
          {title}
        </h3>
        <div className='flex flex-col divide-y divide-light-50 dark:divide-dark-700/30'>
          {items.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      </div>
    ),
    [],
  );

  const settingGroups = useMemo(
    () => [
      {
        title: lang('이미지사이즈'),
        items: ['image_size'],
        condition: modelConfig.image_size.show,
      },
      {
        title: lang('세부설정'),
        items: ['num_inference_steps', 'guidance_scale', 'strength'].filter(
          (key) => modelConfig[key]?.show,
        ),
      },
      {
        title: lang('모드설정'),
        items: ['sync_mode'].filter((key) => modelConfig[key]?.show),
      },
      {
        title: lang('이미지설정'),
        items: ['num_images', 'seed'].filter((key) => modelConfig[key]?.show),
      },
    ],
    [modelConfig, lang],
  );

  const settingsPanelRef = useRef(null);

  useEffect(() => {
    if (settingsPanelRef.current) {
      const topPosition = settingsPanelRef.current.getBoundingClientRect().top;
      settingsPanelRef.current.style.setProperty(
        '--top-position',
        `${topPosition}px`,
      );
    }
  }, []);

  return (
    <div className='w-full rounded-lg bg-light-bg shadow-lg dark:bg-dark-input'>
      <div ref={settingsPanelRef} className='settings-scrollbar settings-panel'>
        <div className='flex flex-col gap-3 p-4'>
          {settingGroups.map(
            (group, index) =>
              group.condition !== false && (
                <React.Fragment key={index}>
                  {renderSettingGroup(
                    group.title,
                    group.items.map((key) =>
                      renderSettingItem(key, modelConfig[key]),
                    ),
                  )}
                </React.Fragment>
              ),
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(PromptSettingsForChat);
