import React, { useState, useEffect, useCallback } from 'react';
import useDebug from '@hooks/useDebug';
import { useTranslation } from 'react-i18next';
import { getServiceUrl } from '@config/service/serviceConfig';
import { useAuth } from '@contexts/auth/AuthContext';
import { useThemeContext } from '@contexts/theme/ThemeContext';
import { useImageAIContext } from '@contexts/ai/image/ImageAIContext';
import UserMenu from './UserMenu';
import LanguageSelect from './LanguageSelect';
import { Sparkles, Moon, Sun } from 'lucide-react';
import './style.css';

const ImageAIChatHeader = () => {
  const { debug } = useDebug('ImageAIChatHeader');
  const { user, logout } = useAuth();
  const { themeInfo, themeIs } = useThemeContext();
  const [isMember, setIsMember] = useState(false);
  const { isDarkMode, toggleDarkModeHandler } = useImageAIContext();
  const isCollapsed = true;

  const { t } = useTranslation([
    'components/service/imageAI/layout/navigation/navigation',
  ]);
  const lang = t;

  useEffect(() => {
    debug('i:*:themeChanged: ', { themeInfo, user, themeIs });
    if (!isMember && themeIs && themeIs.isMember && user) {
      debug('i:*:Check themeInfo and user: ', {
        themeInfo,
        themeIs,
        user,
      });
      setIsMember(true);
    } else {
      // WARNING: 회원 상태가 아닌 경우 초기화
      if (!themeInfo || !themeIs) {
        setIsMember(false);
      }
    }
  }, [themeInfo, themeIs, user, setIsMember, isMember]);

  const toggleDarkMode = useCallback(() => {
    debug('toggleDarkMode');
    toggleDarkModeHandler();
  }, [toggleDarkModeHandler]);

  const handleLogout = useCallback(async () => {
    setIsMember(false);
    await logout();
    // WARNING: ImageAIAgent에서는 로그아웃 후, redirect 처리를 하지 않는다.
    // window.location.href = getServiceUrl('logout');
  }, [logout, setIsMember]);

  return (
    <div className='g-ai-chat-header sticky top-0'>
      <div className='flex items-center justify-between bg-light-bg px-4 pt-4 text-black dark:bg-dark-bg dark:text-white'>
        <div className='ml-2 flex items-center gap-1.5'>
          <Sparkles size={22} className='fill-current text-red-500' />
          <h1 className='text-lg font-bold leading-none text-light-800 dark:text-dark-100'>
            Supereasy AI
          </h1>
        </div>

        <div className='mr-10 flex items-center gap-1.5'>
          <button
            onClick={toggleDarkMode}
            className='rounded-full p-2 transition-colors hover:bg-light-100 dark:hover:bg-dark-800'
            aria-label={
              isDarkMode ? 'Switch to light mode' : 'Switch to dark mode'
            }
          >
            {isDarkMode ? (
              <Moon size={20} className='text-light-100' />
            ) : (
              <Sun size={20} className='text-dark-800' />
            )}
          </button>
          <LanguageSelect isCollapsed={isCollapsed} isDarkMode={isDarkMode} />
          <UserMenu
            user={user}
            isMember={isMember}
            handleLogout={handleLogout}
            lang={lang}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageAIChatHeader;
