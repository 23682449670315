import React, {
  forwardRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { getFontSizeClasses, getIconSize } from './popoverStyleUtils';
import clsx from 'clsx';

const PopoverContent = forwardRef(
  (
    {
      options,
      onClose,
      colorMode,
      placementClasses,
      offsetStyle,
      isOpen,
      onMouseEnter,
      onMouseLeave,
      style = { fontSize: 'md' },
      popoverId,
    },
    ref,
  ) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      if (isOpen) {
        setIsVisible(true);
      } else {
        const timer = setTimeout(() => setIsVisible(false), 300);
        return () => clearTimeout(timer);
      }
    }, [isOpen]);

    const containerClasses = useMemo(
      () => clsx('absolute z-10 w-64', placementClasses),
      [placementClasses],
    );

    const contentClasses = useMemo(
      () =>
        clsx(
          'overflow-hidden rounded-lg shadow-lg ring-1 ring-light-100 dark:ring-dark-800 ring-opacity-5',
          'bg-light-50 dark:bg-dark-800',
          'text-light-primary dark:text-dark-primary',
          'transition-opacity duration-300 ease-in-out',
          {
            'opacity-100': isOpen,
            'opacity-0': !isOpen,
          },
        ),
      [isOpen],
    );

    const renderDivider = (item) => {
      const dividerClasses = clsx('mt-2', {
        'border-light-200 dark:border-dark-700':
          item.type === 'line' ||
          item.type === 'dotted' ||
          item.type === 'double',
        'border-light-300 dark:border-dark-600': !item.type,
        'border-t-2 border-dotted': item.type === 'dotted',
        'border-t-2 border-double': item.type === 'double',
      });

      return <hr className={dividerClasses} />;
    };

    const renderHeader = useCallback(() => {
      if (!options.header.use) return null;
      return (
        <div
          className={clsx(
            'flex items-center justify-between',
            getFontSizeClasses('padding-header', style.fontSize),
            'border-b border-light-200 dark:border-dark-600',
          )}
        >
          <div className='flex items-center'>
            {options.header.icon && (
              <span
                className={clsx(
                  'mr-2',
                  getFontSizeClasses('icon', style.fontSize),
                )}
              >
                {options.header.icon}
              </span>
            )}
            <h4
              className={clsx(
                'font-semibold',
                getFontSizeClasses('header', style.fontSize),
              )}
            >
              {options.header.title}
            </h4>
          </div>
          {options.header.close && (
            <button
              onClick={onClose}
              className={clsx(
                'text-light-400 hover:text-light-500 dark:text-dark-400 dark:hover:text-dark-300',
                getFontSizeClasses('icon', style.fontSize),
              )}
              aria-label='Close'
            >
              <XMarkIcon
                className={getFontSizeClasses('icon', style.fontSize)}
              />
            </button>
          )}
        </div>
      );
    }, [options.header, style.fontSize, onClose]);

    const renderContent = useCallback(() => {
      if (options.isContentList) {
        return options.contentList.map((item, index) => (
          <div
            key={index}
            className={clsx({
              'pt-2': item.divider,
              [getFontSizeClasses('padding-content', style.fontSize)]:
                !item.divider,
              'pb-1': !item.divider && index !== options.contentList.length - 1,
            })}
          >
            {item.divider ? (
              renderDivider(item)
            ) : (
              <div className='flex flex-col'>
                <div className='mb-1 flex items-center'>
                  {item.icon && (
                    <span className={clsx('mr-1 flex-shrink-0')}>
                      <item.icon
                        className={clsx(
                          'text-light-500 dark:text-dark-400',
                          getFontSizeClasses('icon', style.fontSize),
                        )}
                      />
                    </span>
                  )}
                  {item.title && (
                    <p
                      className={clsx(
                        'font-semibold',
                        getFontSizeClasses('header', style.fontSize),
                      )}
                    >
                      {item.title}
                    </p>
                  )}
                </div>
                <p
                  className={clsx(
                    'text-light-700 dark:text-dark-300',
                    getFontSizeClasses('content', style.fontSize),
                    // { 'ml-7': item.icon }, // Add left margin when there's an icon to align with the title
                  )}
                >
                  {item.content}
                </p>
              </div>
            )}
          </div>
        ));
      }
      return (
        <div
          className={clsx(
            getFontSizeClasses('padding-content', style.fontSize),
            getFontSizeClasses('content', style.fontSize),
            'text-light-700 dark:text-dark-300',
          )}
        >
          {options.content}
        </div>
      );
    }, [options, style.fontSize]);

    if (!isVisible) return null;

    return (
      <div
        ref={ref}
        className={containerClasses}
        style={offsetStyle}
        role='dialog'
        aria-modal='true'
        data-popover-content-id={popoverId}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className={contentClasses}>
          <div className='relative'>
            {renderHeader()}
            {renderContent()}
          </div>
        </div>
      </div>
    );
  },
);

export default React.memo(PopoverContent);
