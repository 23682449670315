import React from 'react';
import * as Slider from '@radix-ui/react-slider';

const widthSizes = {
  xs: 'w-[100px]',
  sm: 'w-[150px]',
  md: 'w-[200px]',
  lg: 'w-[250px]',
  xl: 'w-[300px]',
  default: 'w-full',
};

const heightSizes = {
  xs: 'h-3',
  sm: 'h-5',
  md: 'h-8',
  lg: 'h-10',
  xl: 'h-12',
};
const trackSizes = {
  xs: 'h-[2px]',
  sm: 'h-[3px]',
  md: 'h-[4px]',
  lg: 'h-[5px]',
  xl: 'h-[6px]',
};

const thumbSizes = {
  xs: 'size-3',
  sm: 'size-4',
  md: 'size-5',
  lg: 'size-6',
  xl: 'size-7',
};

const RangeInput = ({
  min = 0,
  max = 100,
  step = 1,
  value,
  onChange,
  size = {
    width: 'default',
    height: 'md',
    track: 'md',
    thumb: 'sm',
  },
}) => {
  const baseThumbClass = `block rounded-[10px] ${
    thumbSizes[size.thumb]
  } focus:outline-none`;
  const thumbClass = `${baseThumbClass} bg-light-500 shadow-light-300 hover:bg-light-700 hover:ring-4 hover:ring-light-300 focus:shadow-light-400 dark:bg-dark-200 dark:shadow-dark-700 dark:hover:bg-dark-50 dark:hover:ring-dark-400 dark:focus:shadow-dark-600 transition-all duration-200`;

  return (
    <div className='flex items-center space-x-4'>
      <Slider.Root
        className={`relative flex touch-none select-none items-center ${
          widthSizes[size.width]
        } ${heightSizes[size.height]}`}
        defaultValue={[value]}
        min={min}
        max={max}
        step={step}
        onValueChange={(values) => onChange(values[0])}
      >
        <Slider.Track
          className={`relative ${
            trackSizes[size.track]
          } grow rounded-full bg-light-200 dark:bg-dark-500`}
        >
          <Slider.Range className='absolute h-full rounded-full bg-light-400 dark:bg-dark-200' />
        </Slider.Track>
        <Slider.Thumb className={thumbClass} aria-label='Value' />
      </Slider.Root>
      <span className='text-sm font-medium text-light-700 dark:text-dark-100'>
        {value}
      </span>
    </div>
  );
};

export { RangeInput as default };
