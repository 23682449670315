import React from 'react';

const ImageArea = ({
  imageEl,
  imageMetadata,
  onClose,
  zoomLevel,
  onZoom,
  aspectRatio,
}) => {
  const getImageClass = () => {
    switch (zoomLevel) {
      case 2:
        return 'transform scale-125'; // 이미지 확대
      default:
        return 'transform scale-100'; // 기본 또는 전체 화면
    }
  };

  const getContainerClass = () => {
    switch (zoomLevel) {
      case 0:
        return 'relative h-full w-full mid:w-[calc(100%-300px)] transition-all duration-300';
      default:
        return 'relative h-full w-full transition-all duration-300';
    }
  };

  const getCursorClass = () => {
    switch (zoomLevel) {
      case 2:
        return 'cursor-zoom-out';
      default:
        return 'cursor-zoom-in';
    }
  };

  return (
    <div className={`${getContainerClass()} bg-white dark:bg-gray-900`}>
      <div
        className={`flex h-full w-full p-1 dark:bg-gray-900 md:p-5`}
        onClick={onZoom}
      >
        <div
          className={`flex h-full w-full items-center justify-center overflow-hidden dark:bg-gray-900`}
        >
          {' '}
          {/* 추가된 div wrapper */}
          <img
            src={imageMetadata.src}
            alt={imageMetadata.alt || ''}
            className={`max-h-full max-w-full ${getImageClass()} rounded-xl shadow-md transition-transform duration-300 dark:shadow-gray-700 ${getCursorClass()}`}
          />
        </div>
      </div>
      <button
        className='absolute right-4 top-4 z-10 flex h-8 w-8 items-center justify-center rounded-full bg-black bg-opacity-50 text-white transition-all duration-300 ease-in-out hover:bg-opacity-75'
        onClick={onClose}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          stroke='currentColor'
          className='h-6 w-6'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={2}
            d='M6 18L18 6M6 6l12 12'
          />
        </svg>
      </button>
    </div>
  );
};

export default ImageArea;
