import React, { useMemo, useState, useEffect, useCallback } from 'react';
import useDebug from '@hooks/useDebug';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@components/common/Tooltip';
import useImageActions from '@hooks/service/imageAI/useImageActions';
import useExploreImageActions from '@hooks/service/imageAI/useExploreImageActions';
import { useImageViewer } from '@contexts/ai/service/imageAI/ImageViewer/ImageViewerContext';
// 하위 컴포넌트
import ActionButton from './ActionButton';
// 아이콘
import {
  RotateCw,
  PenLine,
  Copy,
  ArrowDownToLine,
  ArrowUpFromLine,
  FilePen,
  CircleCheck,
  Maximize,
  Trash2,
} from 'lucide-react';

const iconMap = {
  regenerate: RotateCw,
  edit: PenLine,
  copy: Copy,
  download: ArrowDownToLine,
  upload: ArrowUpFromLine,
  autoPost: FilePen,
  apply: CircleCheck,
  fullscreen: Maximize,
  delete: Trash2,
};

const ImageActionButtons = ({
  pageId,
  promptId,
  messageId = null,
  imageId = null,
  imageIndex,
  imageInfo,
  imageEl,
  onSubmit,
  buttonSize = '16',
  isInline = false,
  actionProps = null,
  actions = [],
}) => {
  const { debug } = useDebug('ImageActionButtons');

  // 다국어 지원
  const { t } = useTranslation([
    'components/service/imageAI/components/imageActionButton',
  ]);
  const lang = t;

  const [isActionInProgress, setIsActionInProgress] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [statusType, setStatusType] = useState('normal');
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const [disabledTooltips, setDisabledTooltips] = useState({});

  const actionButtonsProps = {
    promptId,
    imageId,
    imageIndex,
    imageInfo,
    imageEl,
    onSubmit,
    actionProps,
  };

  const {
    regenerateImage,
    editImage,
    downloadImage,
    uploadImage,
    applyImage,
    copyPrompt,
    viewFullscreen,
    autoPostImage,
    deleteImage,
  } =
    pageId === 'Explore'
      ? useExploreImageActions(actionButtonsProps)
      : useImageActions(actionButtonsProps);

  const tooltipMap = {
    regenerate: lang('이미지재생성'),
    edit: lang('이미지편집'),
    copy: lang('이미지복사'),
    download: lang('이미지다운로드'),
    upload: lang('이미지업로드'),
    autoPost: lang('자동게시'),
    apply: lang('적용'),
    fullscreen: lang('전체화면'),
    delete: lang('이미지삭제'),
  };

  const buttons = useMemo(() => {
    const allButtons = [
      {
        name: 'Regenerate',
        icon: <RotateCw size={buttonSize} />,
        action: regenerateImage,
        active: true,
        tooltip: tooltipMap.regenerate,
      },
      {
        name: 'Edit',
        icon: <PenLine size={buttonSize} />,
        action: editImage,
        active: true,
        tooltip: tooltipMap.edit,
      },
      {
        name: 'Copy',
        icon: <Copy size={buttonSize} />,
        action: copyPrompt,
        active: true,
        tooltip: tooltipMap.copy,
      },
      {
        name: 'Download',
        icon: <ArrowDownToLine size={buttonSize} />,
        action: downloadImage,
        active: true,
        tooltip: tooltipMap.download,
      },
      {
        name: 'Upload',
        icon: <ArrowUpFromLine size={buttonSize} />,
        action: uploadImage,
        active: false,
        tooltip: tooltipMap.upload,
      },
      {
        name: 'AutoPost',
        icon: <FilePen size={buttonSize} />,
        action: autoPostImage,
        active: true,
        tooltip: tooltipMap.autoPost,
      },
      {
        name: 'Apply',
        icon: <CircleCheck size={buttonSize} />,
        action: applyImage,
        active: true,
        tooltip: tooltipMap.apply,
      },
      {
        name: 'Fullscreen',
        icon: <Maximize size={buttonSize} />,
        action: viewFullscreen,
        active: true,
        tooltip: tooltipMap.fullscreen,
      },
      {
        name: 'Delete',
        icon: <Trash2 size={buttonSize} />,
        action: deleteImage,
        active: true,
        tooltip: tooltipMap.delete,
      },
    ];

    return allButtons
      .filter((button) => actions.includes(button.name.toLowerCase()))
      .map((button) => ({
        ...button,
        active: true,
      }));
  }, [
    regenerateImage,
    editImage,
    copyPrompt,
    downloadImage,
    uploadImage,
    autoPostImage,
    applyImage,
    viewFullscreen,
    buttonSize,
    actions,
    tooltipMap,
  ]);

  const handleAction = useCallback(
    async (action, name, e) => {
      e.preventDefault();

      debug('i:*:handleAction 함수 호출됨', {
        action,
        name,
        promptId,
        messageId,
        isActionInProgress,
        actionProps,
      });

      if (isActionInProgress) return;

      if (typeof action !== 'function') {
        console.error('유효하지 않은 action 함수:', { action });
        setStatusMessage(`${name} is not a valid function`);
        setStatusType('error');
        setTimeout(() => setStatusMessage(''), 2000);
        return;
      }

      setIsActionInProgress(true);
      setStatusMessage(`${name}ing...`);
      setStatusType('normal');

      try {
        let result;
        if (name === 'Copy' || name === 'Edit') {
          debug('Check actionProps', { action, name, actionProps });
          result = await action(actionProps);
        } else {
          result = await action();
        }

        if (result) {
          debug('s:*:action 함수 호출 후 결과 값 확인', { result });

          if (typeof result === 'object' && result.hasOwnProperty('status')) {
            if (result.status === 'success') {
              setStatusMessage('');
              setStatusType('success');
            } else {
              setStatusMessage(result.message);
              setStatusType('error');
              setTimeout(() => setStatusMessage(''), 2000);
            }
          } else if (typeof result === 'string') {
            setStatusMessage(result);
            setTimeout(() => setStatusMessage(''), 2000);
          } else if (typeof result === 'boolean') {
            if (result) {
              if (name === 'Copy') {
                // WARN: 0.5초 동안 짧게만 출력
                setShowCopiedMessage(true);
                setDisabledTooltips({ ...disabledTooltips, Copy: true });
                // setTimeout(() => {
                //   setTimeout(() => setShowCopiedMessage(false), 500);
                // }, 500);
                setTimeout(() => {
                  setShowCopiedMessage(false);
                  setDisabledTooltips({ ...disabledTooltips, Copy: false });
                }, 1000);
              }
              setStatusMessage('');
              setStatusType('success');
            } else {
              setStatusMessage('Failed to process the image');
              setStatusType('error');
              setTimeout(() => setStatusMessage(''), 2000);
            }
          } else {
            console.error('유효하지 않은 result 타입:', { result });
            setStatusMessage('Failed to process the image');
            setTimeout(() => setStatusMessage(''), 2000);
          }
        }
      } catch (error) {
        console.error('action 함수 호출 중 에러 발생: ', error);
        setStatusMessage(error.message || `${name} failed`);
        setStatusType('error');
        setTimeout(() => setStatusMessage(''), 2000);
      } finally {
        setIsActionInProgress(false);
      }
    },
    [
      debug,
      promptId,
      messageId,
      isActionInProgress,
      imageIndex,
      imageInfo,
      imageEl,
      setIsActionInProgress,
      setStatusMessage,
      setStatusType,
      actionProps,
    ],
  );

  return (
    <div
      className={`flex ${
        isInline ? 'inline-flex gap-1' : 'w-full items-center justify-end'
      }`}
    >
      {buttons.map((button) => (
        <Tooltip
          key={`${pageId}-${button.name}`}
          content={button.tooltip}
          disabled={disabledTooltips[button.name]}
        >
          <span className='inline-block'>
            {/* Tooltip 트리거를 위한 wrapper */}
            <ActionButton
              icon={button.icon}
              onClick={(e) => handleAction(button.action, button.name, e)}
              disabled={isActionInProgress}
              tooltip={button.tooltip}
              name={button.name}
              showCopiedMessage={button.name === 'Copy' && showCopiedMessage}
            />
          </span>
        </Tooltip>
      ))}
    </div>
  );
};

export default ImageActionButtons;
