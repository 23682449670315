export const manualJwtDecode = (token) => {
  if (typeof token !== 'string') {
    throw new Error('Invalid token: Token must be a string');
  }

  const parts = token.split('.');
  if (parts.length !== 3) {
    throw new Error('Invalid token structure');
  }

  try {
    const base64Url = parts[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error('Failed to decode JWT:', error);
    throw new Error('Failed to decode token payload');
  }
};

export const verifyTokenClaims = (decodedToken) => {
  const currentTime = Math.floor(Date.now() / 1000);

  if (!decodedToken.exp) {
    throw new Error('Token is missing expiration');
  }

  if (decodedToken.exp < currentTime) {
    throw new Error('Token has expired');
  }

  // 추가적인 검증 로직을 여기에 구현할 수 있습니다.
  // 예: 발행자(iss) 확인, 대상(aud) 확인 등

  return true;
};
