// react-src/src/utils/image/imageUtils.js

import { API_CONFIG, isDevelopment, getApiPath } from '@api/apiConfig';
import { sendPost } from '@api/apiService';

// 가능한 비율 목록
export const aspectRatios = [
  { w: 1, h: 1 }, // 1:1
  { w: 2, h: 3 }, // 2:3
  { w: 3, h: 2 }, // 3:2
  { w: 4, h: 3 }, // 4:3
  { w: 3, h: 4 }, // 3:4
  { w: 16, h: 9 }, // 16:9
  { w: 9, h: 16 }, // 9:16
];

// 가장 가까운 비율을 찾는 함수
export const getAspectByRatio = (ratio) => {
  // `ratio`가 문자열인 경우, 숫자로 변환
  if (typeof ratio === 'string') {
    const [width, height] = ratio.split('/').map(Number);
    ratio = width / height;
  }

  let closestRatio = aspectRatios[0];
  let closestDiff = Math.abs(ratio - closestRatio.w / closestRatio.h);

  aspectRatios.forEach(({ w, h }) => {
    const currentRatio = w / h;
    const diff = Math.abs(ratio - currentRatio);
    if (diff < closestDiff) {
      closestRatio = { w, h };
      closestDiff = diff;
    }
  });

  return `${closestRatio.w}/${closestRatio.h}`;
};

// Tailwind CSS 클래스 형태로 반환하는 함수
export const getAspectByRatioClass = (aspectByRatio) => {
  return `aspect-[${aspectByRatio}]`;
};

export function extractImageInfoWithUrl(imageUrl) {
  const url = new URL(imageUrl);
  const fullFilename = url.pathname.split('/').pop();
  const extension = fullFilename.split('.').pop();
  const filename = fullFilename.substring(0, fullFilename.lastIndexOf('.'));

  return {
    url: imageUrl,
    fullFilename: fullFilename,
    filename: filename,
    extension: extension,
  };
}

/**
 * FluxFal 이미지 사이즈를 받아서 비율을 반환하는 함수
 * ! - byRatio 값을 return 하는 함수와 동일하게 만들어야 한다.
 * @param {string} imageSize - FluxFal 이미지 사이즈
 * @returns {number} - 비율
 */
export const getAspectByRatioFromFalImageSize = (imageSize) => {
  switch (imageSize) {
    case 'landscape_4_3':
      return '4/3';
    case 'landscape_16_9':
      return '16/9';
    case 'square_hd':
      return '1/1';
    case 'portrait_4_3':
      return '3/4';
    case 'portrait_16_9':
      return '9/16';
    default:
      return '1/1';
  }
};

/**
 * byRatio 문자열로부터 실제 비율(ratio)을 계산하는 함수
 * @param {string} byRatio - 비율을 나타내는 문자열 (예: '4/3', '16/9')
 * @returns {number} - 계산된 실제 비율 (세로/가로)
 */
export const getRatioFromByRatio = (byRatio) => {
  if (!byRatio || typeof byRatio !== 'string') {
    return 1; // 기본값으로 1:1 비율 반환
  }

  const [width, height] = byRatio.split('/').map(Number);
  if (!width || !height) {
    return 1; // 유효하지 않은 입력의 경우 1:1 비율 반환
  }

  return height / width;
};

/**
 * Base64 이미지 관련 유틸리티 함수들
 */

/**
 * Base64 문자열을 Blob으로 변환하는 함수
 * @param {string} base64Data - Base64 형식의 이미지 데이터
 * @param {string} mimeType - 이미지의 MIME 타입
 * @returns {Blob} 변환된 Blob 객체
 */
export function base64ToBlob(base64Data, mimeType) {
  try {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      byteArrays.push(new Uint8Array(byteNumbers));
    }

    return new Blob(byteArrays, { type: mimeType });
  } catch (error) {
    console.error('Error converting base64 to blob:', error);
    throw error;
  }
}

/**
 * MIME 타입으로부터 파일 확장자를 얻는 유틸리티 함수
 * @param {string} mimeType - MIME 타입
 * @returns {string} 파일 확장자
 */
export function getExtensionFromMimeType(mimeType) {
  const mimeToExt = {
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'image/gif': 'gif',
    'image/webp': 'webp',
    'image/svg+xml': 'svg',
  };
  return mimeToExt[mimeType] || 'png'; // 기본값으로 png 반환
}

/**
 * URL에서 파일명만 추출하는 유틸리티 함수
 * @param {string} url - 이미지 URL
 * @returns {string} 확장자를 제외한 파일명
 */
function getBasenameFromUrl(url) {
  const urlObj = new URL(url);
  const fullFilename = urlObj.pathname.split('/').pop();
  return fullFilename.substring(0, fullFilename.lastIndexOf('.')) || 'image';
}

/**
 * URL에서 base64 이미지 데이터와 확장된 메타데이터를 가져오는 함수
 * 실제 MIME 타입을 기준으로 파일 정보를 구성
 */
export async function getBase64FromUrl(imageUrl, options = {}) {
  const {
    apiUrl = getApiPath('imageDownload'),
    defaultMimeType = 'image/png',
    accessToken = null,
  } = options;

  try {
    // 기본 요청 설정
    const requestConfig = {
      url: apiUrl,
      data: {
        url: imageUrl,
        request_type: 'convert',
      },
      useBase64: true,
    };

    // accessToken이 있는 경우에만 config 추가
    if (accessToken) {
      requestConfig.config = {
        withCredentials: true,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
    }

    const result = await sendPost(requestConfig);

    if (result.status === 'success' && result?.data?.base64) {
      // 실제 MIME 타입 사용
      const data = result.data;
      const mimeType = data?.mime_type || defaultMimeType;

      // 실제 MIME 타입에 기반한 확장자 결정
      const extension = getExtensionFromMimeType(mimeType);

      // URL에서 기본 파일명 추출
      const baseFilename = getBasenameFromUrl(imageUrl);

      // 새로운 파일명 구성 (기존 파일명 + 실제 확장자)
      const fullFilename = `${baseFilename}.${extension}`;

      // Data URL 형식으로 base64 데이터 포맷팅
      const dataUrl = `data:${mimeType};base64,${data.base64}`;

      return {
        base64: dataUrl,
        rawBase64: data.base64,
        mimeType: mimeType,
        // 실제 MIME 타입 기준 파일 정보
        imageFilename: fullFilename,
        baseFilename,
        extension: extension,
        metadata: {
          originalUrl: imageUrl,
          timestamp: new Date().toISOString(),
          width: data.width || 0,
          height: data.height || 0,
          aspectRatio: data.aspect_ratio || '1/1',
          fileSizeKb: data.file_size_kb || 0,
          file: {
            fullFilename: fullFilename,
            filename: baseFilename,
            extension: extension,
            url: imageUrl,
            originalInfo: extractImageInfoWithUrl(imageUrl), // 원본 URL 기준 정보도 보존
          },
          original: {
            width: data.width,
            height: data.height,
            aspectRatio: data.aspect_ratio,
            fileSizeKb: data.file_size_kb,
            mimeType: data.mime_type,
          },
        },
      };
    }

    throw new Error('Failed to get base64 data');
  } catch (error) {
    console.error('Error fetching base64 data:', error);
    throw error;
  }
}

/**
 * 사용 예시:
 *
 * // 예: JPG 확장자로 저��된 실제 PNG 이미지의 경우
 * const imageData = await getBase64FromUrl('https://example.com/image.jpg');
 *
 * console.log(imageData);
 * {
 *   base64: "data:image/png;base64,iVBORw0...",
 *   mimeType: "image/png",
 *   fullFilename: "image.png",        // 실제 MIME 타입 기준
 *   filename: "image",
 *   extension: "png",                 // 실제 MIME 타입 기준
 *   metadata: {
 *     file: {
 *       fullFilename: "image.png",
 *       filename: "image",
 *       extension: "png",
 *       url: "https://example.com/image.jpg",
 *       originalInfo: {              // 원본 URL 기준 정보
 *         fullFilename: "image.jpg",
 *         filename: "image",
 *         extension: "jpg"
 *       }
 *     }
 *   }
 * }
 */

/**
 * 이미지 다운로드 시 사용 예시:
 *
 * const imageData = await getBase64FromUrl(imageUrl);
 * // 실제 MIME 타입에 맞는 확장자로 다운로드
 * downloadBase64Image(
 *   imageData.rawBase64,
 *   imageData.fullFilename,
 *   imageData.mimeType
 * );
 */

/**
 * Base64 이미지 데이터를 다운로드하는 함수
 * @param {string} base64Data - Base64 형식의 이미지 데이터
 * @param {string} filename - 저장할 파일 이름
 * @param {string} mimeType - 이미지의 MIME 타입
 */
export function downloadBase64Image(
  base64Data,
  filename,
  mimeType = 'image/png',
) {
  try {
    const blob = base64ToBlob(base64Data, mimeType);
    const blobUrl = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Blob URL 해제
    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error('Error downloading base64 image:', error);
    throw error;
  }
}

/**
 * 이미지 URL에서 이미지를 다운로드하는 함수
 * @param {string} imageUrl - 이미지 URL
 * @param {string} filename - 저장할 파일 이름
 * @returns {Promise<void>}
 */
export async function downloadImageFromUrl(imageUrl, filename) {
  try {
    const { base64, mimeType } = await getBase64FromUrl(imageUrl);
    await downloadBase64Image(base64, filename, mimeType);
  } catch (error) {
    console.error('Error downloading image from URL:', error);
    throw error;
  }
}
