export const modelConfigs = {
  'se-flux-pro': {
    num_inference_steps: {
      default: 28,
      min: 1,
      max: 50,
      show: true,
    },
    guidance_scale: {
      default: 3.5,
      min: 1,
      max: 20,
      step: 0.1,
      show: true,
    },
    safety_tolerance: {
      default: 2,
      options: [1, 2, 3, 4, 5, 6],
      show: true,
    },
    image_size: { show: true, default: 'landscape_4_3' },
    sync_mode: { show: true, default: false },
    num_images: {
      show: true,
      default: 1,
      max: 1,
      readonly: true, // 이 속성을 추가하여 읽기 전용으로 설정
    },
    seed: { show: true },
    constraints: {
      enable_safety_checker: true,
    },
  },
  'se-flux-dev': {
    num_inference_steps: {
      default: 28,
      min: 1,
      max: 50,
      show: true,
    },
    guidance_scale: {
      default: 3.5,
      min: 1,
      max: 20,
      step: 0.1,
      show: true,
    },
    image_size: { show: true, default: 'landscape_4_3' },
    sync_mode: { show: true, default: false },
    num_images: { show: true, default: 1, max: 4 },
    seed: { show: true },
    constraints: {
      enable_safety_checker: true,
    },
  },
  'se-flux-schnell': {
    num_inference_steps: {
      default: 12,
      min: 1,
      max: 12,
      show: true,
    },
    guidance_scale: {
      default: 3.5,
      min: 1,
      max: 20,
      step: 0.1,
      show: true,
    },
    image_size: { show: true, default: 'landscape_4_3' },
    sync_mode: { show: true, default: false },
    num_images: { show: true, default: 1, max: 4 },
    seed: { show: true },
    constraints: {
      enable_safety_checker: true,
    },
  },
  'se-flux-realism': {
    num_inference_steps: {
      default: 28,
      min: 1,
      max: 50,
      show: true,
    },
    guidance_scale: {
      default: 3.5,
      min: 1,
      max: 20,
      step: 0.1,
      show: true,
    },
    strength: {
      default: 1,
      min: 0.1,
      max: 1,
      step: 0.1,
      show: true,
    },
    image_size: { show: true, default: 'landscape_4_3' },
    sync_mode: { show: true, default: false },
    num_images: { show: true, default: 1, max: 4 },
    seed: { show: true },
    constraints: {
      enable_safety_checker: true,
    },
  },
  'se-flux-dev-image-to-image': {
    num_inference_steps: {
      default: 28,
      min: 1,
      max: 50,
      show: true,
    },
    guidance_scale: {
      default: 3.5,
      min: 1,
      max: 20,
      step: 0.1,
      show: true,
    },
    image_size: { show: false, default: 'landscape_4_3' },
    sync_mode: { show: true, default: false },
    num_images: { show: true, default: 1, max: 4 },
    seed: { show: true },
    constraints: {
      enable_safety_checker: true,
      requires_image_input: true,
    },
  },
};
