import React, { useMemo, useEffect } from 'react';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import Popover from '@components/common/Popover/Popover.jsx';

const defaultPopoverOptions = {
  triggerOn: 'click',
  style: {
    fontSize: 'xs',
  },
  placement: 'bottom-start',
  offset: 2,
  panel: {
    header: {
      use: true,
      close: false,
    },
  },
};

const MemoizedPopover = React.memo(
  ({
    title,
    content,
    contentList,
    placement,
    offset,
    options = {},
    children,
  }) => {
    const popoverOptions = useMemo(() => {
      const panelOptions = {
        header: {
          ...defaultPopoverOptions.panel.header,
          title,
        },
      };

      if (content) {
        panelOptions.content = content;
      }

      if (contentList && contentList.length > 0) {
        panelOptions.isContentList = true;
        panelOptions.contentList = contentList;
      }

      const finalOffset =
        offset || options.offset || defaultPopoverOptions.offset;

      return {
        ...defaultPopoverOptions,
        ...options,
        placement:
          placement || options.placement || defaultPopoverOptions.placement,
        offset: Math.max(1, Math.min(10, finalOffset)), // Ensure offset is between 1 and 10
        panel: {
          ...defaultPopoverOptions.panel,
          ...options.panel,
          ...panelOptions,
        },
      };
    }, [title, content, contentList, placement, offset, options]);

    useEffect(() => {
      console.log('MemoizedPopover rendered', popoverOptions);
    }, [popoverOptions]);

    return (
      <Popover options={popoverOptions}>
        {children || <QuestionMarkCircleIcon className='h-5 w-5' />}
      </Popover>
    );
  },
);

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.title === nextProps.title &&
    prevProps.content === nextProps.content &&
    prevProps.contentList === nextProps.contentList &&
    prevProps.placement === nextProps.placement &&
    prevProps.offset === nextProps.offset &&
    JSON.stringify(prevProps.options) === JSON.stringify(nextProps.options)
  );
};

export default React.memo(MemoizedPopover, areEqual);
