/**
 * 이미지 AI 채팅 프롬프트 컴포넌트
 * - 프롬프트 번역 기능
 * - 다국어 지원
 *
 * @license Commercial License
 * @author JB
 *
 * 이 소프트웨어는 커머셜 라이센스 하에 배포됩니다.
 * 상업적 사용을 포함한 모든 사용은 라이센스 조건에 따라야 합니다.
 * All rights reserved © JB
 *
 * 자세한 내용은 LICENSE 파일을 참조하십시오.
 */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Suspense,
} from 'react';
import useDebug from '@hooks/useDebug';
// 다국어
import { useTranslation } from 'react-i18next';
import i18n from '@config/lang/i18n'; // 기존 i18next 인스턴스 import

// api
// - AI Translator
import { translateText } from '@api/apiService';

// contexts
// 사용자 및 이미지 생성 권한 인증
import { useAuth } from '@contexts/auth/AuthContext';
// theme
import { useThemeContext } from '@contexts/theme/ThemeContext';
// modal
import { useModal } from '@contexts/modal/ModalContext';

// components
// NOTICE: /components/ImageAI/ImageAIPrompt/ 경로에 있는 컴포넌트를 공틍으로 사용한다.
import ModelSettings from '@components/ImageAI/ImageAIPrompt/ModelSettings';
import PromptInput from '@components/ImageAI/ImageAIPrompt/PromptInput';
import PromptSettingsForChat from '@components/ImageAI/ImageAIPrompt/PromptSettingsForChat';
// import PromptSettings from '@components/ImageAI/ImageAIPrompt/PromptSettings';
// icons
import { Globe } from 'lucide-react';

// 내부 컴포넌트
const ImageAIChatPromptContent = ({
  prompt,
  setPrompt,
  modelList,
  selectedModel,
  setSelectedModel,
  imageSizes,
  settings,
  setSettings,
  onSubmit,
  isProcessing,
}) => {
  const { debug } = useDebug('ImageAIChatPrompt');

  // 다국어 지원
  const { t } = useTranslation([
    'components/service/imageAI/components/prompt/prompt',
  ]);
  const lang = t;

  const { user } = useAuth();
  const { themeInfo, themeIs } = useThemeContext();

  // 설정 관련 상태
  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const settingsRef = useRef(null);
  const settingsButtonRef = useRef(null);

  // dialog Modal
  // - alertMsg, confirmMsg, closeModal 지원
  const { alertMsg, confirmMsg, closeModal } = useModal();

  const handleSubmit = useCallback(async () => {
    if (prompt.trim() && !isProcessing) {
      if (themeIs.isMember) {
        debug('handleSubmit called', {
          themeInfo,
          userId: user.mb_id,
          isMember: themeIs.isMember,
          prompt,
          selectedModel,
          settings,
          onSubmit,
        });

        let errorMsg = '';
        let finalPrompt = prompt;
        let originalLanguage = 'en';
        let originalPrompt = '';

        try {
          const translationResult = await translateText(prompt);
          // 번역 결과 값
          debug('s:*:Translation result:', translationResult);

          if (translationResult.detectedLanguage != 'en') {
            // NOTICE: en이 아닌 경우, 번역된 프롬프트를 저장
            finalPrompt = translationResult.translatedText;
            originalLanguage = translationResult.detectedLanguage;
            // NOTICE: 원본 프롬프트를 저장
            originalPrompt = prompt;
            setPrompt(finalPrompt);
          }
        } catch (error) {
          // error_code에 따른 처리
          switch (error.error_code) {
            case 'TEXT_TOO_SHORT':
              errorMsg = '텍스트가 너무 짧아 번역할 수 없습니다.';
              break;
            case 'LANGUAGE_DETECTION_UNRELIABLE':
              errorMsg =
                '언어 감지가 불확실합니다. 영문 프롬프트를 사용해 주세요.';
              break;
            case 'EMPTY_TRANSLATION':
              errorMsg =
                '번역 결과가 비어있습니다. 영문 프롬프트를 사용해 주세요.';
              break;
            case 'API_OVERLOADED':
              errorMsg =
                '번역 서비스가 현재 과부하 상태입니다. 잠시 후 다시 시도해 주세요.';
              break;
            case 'TRANSLATION_FAILED':
              errorMsg =
                '번역 중 오류가 발생했습니다. 영문 프롬프트를 사용해 주세요.';
              break;
            case 'REQUEST_SETUP_FAILED':
              errorMsg =
                '요청 설정 중 오류가 발생했습니다. 잠시 후 다시 시도해 주세요.';
              break;
            default:
              errorMsg =
                '알 수 없는 오류가 발생했습니다. 영문 프롬프트를 사용해 주세요.';
              break;
          }
          alertMsg(errorMsg);
          return false;
        }

        onSubmit({
          selectedModel,
          prompt: finalPrompt, // ! prompt에 finalPrompt를 저장
          settings,
          originalPrompt,
          originalLanguage,
        });
      } else {
        // TODO: 회원 가입 및 로그인 안내를 위한 모달 내의 컨텐츠 추가 필요
        const message = 'Member only feature';
        confirmMsg(message, {
          title: '확인해 주세요',
          confirmText: '지금, 시작하세요',
          onConfirm: () => {
            closeModal();
            debug('로그인 및 회원 가입을 진행합니다.');
            window.location.href = getLoginUrl();
          },
        });
      }

      // ! 만일  PromptSettings 패널이 열려 있을 경우, 제출 시 설정 패널 숨김
      setIsSettingsVisible(false);
    }
  }, [
    prompt,
    selectedModel,
    settings,
    onSubmit,
    isProcessing,
    themeIs.isMember,
    themeInfo,
    user,
  ]);

  // 설정 토글
  const toggleSettings = useCallback(() => {
    setIsSettingsVisible((prev) => !prev);
  }, []);

  // 설정 패널 숨김
  // - 외부 클릭 시 설정 패널 숨김
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        settingsRef.current &&
        !settingsRef.current.contains(event.target) &&
        settingsButtonRef.current &&
        !settingsButtonRef.current.contains(event.target)
      ) {
        setIsSettingsVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // ! for TEST: 초기 파라미터 확인
  useEffect(() => {
    debug('i:*:Intial ImageAIChatPrompt() params', {
      settings,
      selectedModel,
      modelList,
    });
  }, []);

  // ! for TEST: selectedModel 또는 settings 변경 시 로그 출력
  useEffect(() => {
    debug('i:*:selectedModel or settings changed: ', {
      selectedModel,
      settings,
    });
  }, [selectedModel, settings]);

  return (
    <div
      id='image-prompt-wrapper'
      className='g-ai-chat-prompt relative z-20 flex flex-row gap-2 bg-light-bg p-4 text-black dark:bg-dark-bg dark:text-white'
    >
      <div className='mb-4'>
        <ModelSettings
          modelList={modelList}
          selectedModel={selectedModel}
          setSelectedModel={setSelectedModel}
          settings={settings} // model 변경 시, 동적으로 해당 모델의 기본 값을 설정하기 위함
          setSettings={setSettings} // model 변경 시, 동적으로 해당 모델의 기본 값을 설정하기 위함
        />
      </div>
      <div className='grow'>
        <div>
          <PromptInput
            prompt={prompt}
            setPrompt={setPrompt}
            onSubmit={handleSubmit}
            isProcessing={isProcessing}
            isSettingsVisible={isSettingsVisible}
            toggleSettings={toggleSettings}
            settingsButtonRef={settingsButtonRef}
          />
          <div className='mt-1.5 inline-flex flex-wrap items-center gap-1 rounded-xl border border-light-200/50 bg-light-100/75 px-2 py-1 text-xs dark:border-dark-800/50 dark:bg-dark-800/75'>
            <Globe size={14} className='text-red-600' />
            <span className='bg-gradient-to-r from-red-600 to-orange-600 bg-clip-text font-medium text-transparent'>
              Supereasy AI Prompt.
            </span>
            <span className='text-gray-600 dark:text-dark-100'>
              {lang('160개의언어로이미지를생성해보세요')}
            </span>
          </div>
        </div>
        {isSettingsVisible && (
          <div
            ref={settingsRef}
            className='absolute left-0 right-0 top-full'
          >
            <PromptSettingsForChat
              imageSizes={imageSizes}
              // INFO: 선택된 model별 서로 다른 설정 값이 적용되기 위해서 selectedModel 전달
              selectedModel={selectedModel}
              settings={settings}
              setSettings={setSettings}
              isProcessing={isProcessing}
            />
          </div>
        )}
      </div>
    </div>
  );
};

/**
 * ImageAIChatPrompt 컴포넌트
 *
 * @param {Object} props
 * @param {function} props.onSubmit - 제출 시 호출되는 함수
 * @param {Object} props.settings - 현재 프롬프트 설정
 * @param {function} props.setSettings - 설정을 업데이트하는 함수
 * @param {boolean} props.isProcessing - 현재 처리 중인지 여부
 */
const ImageAIChatPrompt = ({
  prompt,
  setPrompt,
  modelList,
  selectedModel,
  setSelectedModel,
  imageSizes,
  settings,
  setSettings,
  onSubmit,
  isProcessing,
}) => {
  return (
    <Suspense fallback={<LoadingFallback />}>
      <ImageAIChatPromptContent
        prompt={prompt}
        setPrompt={setPrompt}
        modelList={modelList}
        selectedModel={selectedModel}
        setSelectedModel={setSelectedModel}
        imageSizes={imageSizes}
        settings={settings}
        setSettings={setSettings}
        onSubmit={onSubmit}
        isProcessing={isProcessing}
      />
    </Suspense>
  );
};

// 로딩 컴포넌트
const LoadingFallback = () => (
  <div className='flex justify-center p-4'>Loading...</div>
);

export default ImageAIChatPrompt;
