// AlertMsg.jsx
import React from 'react';
import Modal from './Modal';

const AlertMsg = ({
  message,
  isOpen,
  onClose,
  title = '확인해 주세요',
  buttonText = '확인',
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      width='sm'
      height='auto'
      position='center'
      animationIn='fade'
      animationOut='fade'
      modalOptions={{
        f: 1,
        fb: 1,
        fb_cancel: 1,
        fb_cancel_text: buttonText,
        fb_confirm: 0,
        ht_size: 'sm', // header title 크기
        mct_size: 'sm', // modal content 텍스트 크기
        mbt_size: 'sm', // modal button 텍스트 크기
        mb_shape: 'md', // modal button 모양
        // header, footer size
        h_size: 'md',
        f_size: 'sm',
        // header, footer border
        h_b: 0,
        f_b: 0,
      }}
    >
      <div className='p-4'>
        {typeof message === 'string' ? (
          <p className='text-gray-700'>{message}</p>
        ) : (
          message
        )}
      </div>
    </Modal>
  );
};

export default AlertMsg;
