import { ShortcutKeyValidator } from '@utils/shortcutKeyValidator';
import { defaultImageAIChatConfig } from '@plugins/ImageAIChat/config/imageAIChatConfig';

export const defaultOptions = {
  selector: {},
  classes: {},
  debugMode: true,
  pageId: 'default',
  onImageResult: () => {},
  imageOptions: {
    includeBase64: false,
    defaultMimeType: 'image/png',
  },
  styleOptions: {
    z_index: 1060,
  },
  autoCloseOnApply: true,
  shortcutKeys: ShortcutKeyValidator.getDefaultShortcutKeys(),
  imageAIConfig: {
    apiType: 'default',
    modelSettings: {},
    imageSettings: {},
  },
  S3ImageHandlerConfig: {
    aiChat: 'imageAIChat',
    pluginName: 'imageAIChat',
    options: {
      pageId: 'imageAIChat',
      s3Config: defaultImageAIChatConfig.s3Config,
      isS3UploadWithMetadata:
        defaultImageAIChatConfig.s3Config.isS3UploadWithMetadata,
      isS3Upload: defaultImageAIChatConfig.isS3Upload,
      isAutoPost: defaultImageAIChatConfig.isAutoPost,
    },
  },
  callbacks: {
    onImageAIAgentOpen: null,
    onImageAIAgentClose: null,
    onImageAIAgentToggle: null,
    onImageAIAgentReady: null,
    onGeneratingImage: null,
    onGeneratedImage: null,
    onApplyImage: null,
    onGenerateImageError: null,
    onApplyImageError: null,
    onAuthSuccess: null,
    onAuthError: null,
  },
};
