/*!
 * useImageActions
 * - 이미지 액션 버튼들을 관리하는 훅
 * - 이미지 액션 버튼들을 표시하고, 클릭 이벤트를 처리한다
 * - imageAI service에서 한정하여 사용하는 훅
 * @author JB
 * @license all rights reserved to JB
 */
import { useCallback } from 'react';
import useDebug from '@hooks/useDebug';
import { useImageAIContext } from '@contexts/ai/image/ImageAIContext';

// ! Explore.jsx 내에서 이미지 탐색 목록 관련 상태 및 액션을 관리하는 훅
import { useExploreImagesContext } from '@contexts/ai/service/imageAI/Explore/ExploreImagesContext';

// fullsceen ImageViewer 컴포넌트
// ! 서비스 내의 모든 이미지 보기를 공통으로 설정 및 관리하기 위한 훅
import { useImageViewer } from '@contexts/ai/service/imageAI/ImageViewer/ImageViewerContext';

/**
 * 탐색된 이미지 액션 버튼들을 관리하는 훅
 * * ExploreImageList 컴포넌트에서 사용되는 훅
 * - 탐색된 이미지 액션 버튼들을 표시하고, 클릭 이벤트를 처리한다
 * @param {string} promptId - 현재 메시지의 prompt id
 * @param {string} imageId - 현재 이미지의 id
 * @param {number} imageIndex - 현재 이미지의 index: Explore에서는 이미지 목록 내에서의 현재 이미지의 index
 * @param {object} imageInfo - 현재 이미지의 정보
 * @param {object} imageEl - 현재 이미지의 DOM 요소
 * @param {function} onSubmit - 이미지 생성을 위한 함수를 전달한다
 * @returns {object} - 탐색된 이미지 액션 버튼들을 관리하는 훅
 */
const useExploreImageActions = ({
  promptId, // 현재 메시지의 prompt id
  imageId, // 현재 이미지의 id
  imageIndex, // 현재 이미지의 index
  imageInfo, // 현재 이미지의 정보
  imageEl, // 이미지 DOM 요소
  onSubmit = null, // 이미지 생성을 위한 함수를 전달한다
}) => {
  const { debug } = useDebug('useImageActions');
  const {
    handleRegenerateImage, // 이미지 재생성
    handleEditImage, // 이미지 편집
    handleImageUpload, // 이미지 업로드
    handleImageAutoPost, // 이미지 자동 포스트
    handleImageDownload, // 이미지 다운로드
    handleApplyImage, // 이미지 적용
    handleViewImage, // 이미지 전체 화면 보기
  } = useImageAIContext();

  const {
    // ! 필요한 항목만 추가를 한다.
    exploreImages, // 탐색된 이미지 목록
    setExploreImages, // 탐색된 이미지 목록 설정
    isLoading, // 탐색 중 상태
    setIsLoading, // 탐색 중 상태 설정
    fetchExploreImages, // 탐색된 이미지 목록 가져오기
    getCurrentImageInfo, // 현재 탐색된 이미지 가져오기
    addImageGenerationList, // 생성된 이미지 목록 추가
    updateCreatedImageList, // 생성된 이미지 목록 업데이트
    removeCreatingImageByPromptId, // 생성된 이미지 목록 삭제
    filterImages, // 이미지 필터링
    loadMoreImages, // 이미지 더 불러오기
  } = useExploreImagesContext();

  // fullsceen ImageView를 위한 모달 open
  const { openImageViewerModal } = useImageViewer();

  const getDataIndex = () => {
    return imageEl ? imageEl.getAttribute('data-index') : 0;
  };

  /**
   * 이미지 재생성
   * - 이미지 재생성 버튼을 클릭하는 경우
   * @returns {void}
   */
  const regenerateImage = useCallback(() => {
    // ! 생성된 이미지 중, 현재 선택된 이미지의 정보를 가지고 온다.
    // ! - api_metadata, imags, prompt_metadata, tags 정보를 포함하고 있다.
    const currentImageInfo = getCurrentImageInfo({ promptId });
    debug('regenerateImage 함수 호출됨', {
      promptId,
      currentImageInfo,
    });

    return handleRegenerateImage({
      promptId, // 현재 메시지의 prompt id
      currentImageInfo,
      app: {
        type: 'service',
        name: 'imageAI',
      },
      onSubmit, // ! 이미지 생성 함수를 반드시 전달한다.
    });
  }, [promptId, handleRegenerateImage, getCurrentImageInfo, debug, onSubmit]);

  /**
   * 이미지 편집
   * - 이미지 편집 버튼을 클릭하는 경우
   * - 현재 이미지의 설정을 적용한 후, 이미지를 편집한다
   * - 선택한 이미지의 prompt, model, apiOptions를 적용한다
   * @returns {void}
   */
  const editImage = useCallback(() => {
    // ! 생성된 이미지 중, 현재 선택된 이미지의 정보를 가지고 온다.
    // ! - api_metadata, imags, prompt_metadata, tags 정보를 포함하고 있다.
    const currentImageInfo = getCurrentImageInfo({ promptId });
    debug('editImage 함수 호출됨', {
      promptId,
      currentImageInfo,
    });
    return handleEditImage({
      promptId, // 현재 메시지의 prompt id
      currentImageInfo,
      app: {
        type: 'service',
        name: 'imageAI',
      },
    });
  }, [
    promptId, // 현재 메시지의 prompt id
    handleEditImage, // 이미지 편집을 위한 상위 훅 함수
    getCurrentImageInfo, // 현재 생성된 이미지 가져오기
    debug, // 디버그 함수
  ]);

  /**
   * 이미지 다운로드
   * - 이미지 다운로드 버튼을 클릭하는 경우
   * @returns {void}
   */
  const downloadImage = useCallback(() => {
    let message = '';
    debug('downloadImage 함수 호출됨', {
      promptId,
      imageEl,
      imageId,
    });
    if (imageEl) {
      // useImageAIActions에서 이미지 다운로드 함수 호출
      const result = handleImageDownload({
        promptId,
        imageEl,
        imageIndex,
      });
      return result;
    } else {
      message = '이미지를 다운로드 할 수 없습니다.';
      return {
        status: 'error',
        message: message,
        details: 'No image element',
      };
    }
  }, [promptId, imageEl, imageId, imageIndex, debug, handleImageDownload]);

  /**
   * 이미지 업로드
   * - 이미지 업로드 버튼을 클릭하는 경우
   * @returns {void}
   */
  const uploadImage = useCallback(() => {
    debug('uploadImage 함수 호출됨', {
      promptId,
      imageIndex,
      imageInfo,
      imageEl,
      // selectedImageIndex,
    });
    // 현재 이미지 정보를 전달하여 업로드
    return handleImageUpload({
      promptId,
      imageIndex,
      currentImageInfo: imageInfo,
      currentChatHistory: getCurrentChatHistory(promptId),
    });
  }, [handleImageUpload, promptId, imageInfo, imageEl, imageIndex, debug]);

  /**
   * 이미지 자동 포스트
   * - 이미지 자동 포스트 버튼을 클릭하는 경우
   * @returns {void}
   */
  const autoPostImage = useCallback(() => {
    const currentImageInfo = getCurrentImageInfo({ promptId });
    // ! Explore 페이지에서는 이미지가 하나만 존재한다.
    const selectedImageInfo = currentImageInfo.image;
    debug('autoPostImage 함수 호출됨', {
      promptId,
      imageId, // Explore에서는 하나의 이미지만 존재하기 때문에 해당 imageId값을 사용하여 autoPostImage 함수를 호출한다.
      // ! Create에서는 하나의 prompt에 대해서 여러개의 이미지가 존재한다.
      // ! 따라서, Create에서는 하나의 프롬프트 내의 이미지 목록 내에서의 현재 선택된 이미지의 index값이다.
      // ! Explore에서는 Explore 전체 이미지 목록 내에서의 현재 이미지의 index값이다.
      imageIndex,
      imageInfo,
      imageEl,
      currentImageInfo,
      selectedImageInfo,
    });

    return handleImageAutoPost({
      promptId, // 현재 메시지의 prompt id
      imageId, // Explore에서는 하나의 이미지만 존재하기 때문에 해당 imageId값을 사용하여 autoPostImage 함수를 호출한다.
      imageIndex,
      currentImageInfo,
      // ! auto_post.php 파일에서 사용되는 변수, auto post를 위해서 s3_metadata 및 이미지 정보를 가져오기 위한 key값, 검색 조건을 명시한다.
      queryKey: 'image_id',
      app: {
        type: 'service',
        name: 'imageAI',
      },
    });
  }, [
    handleImageAutoPost,
    promptId,
    imageId,
    imageInfo,
    imageEl,
    imageIndex,
    debug,
  ]);

  /**
   * 이미지 적용
   * - 이미지 적용 버튼을 클릭하는 경우
   * @returns {void}
   */
  const applyImage = useCallback(
    () => handleApplyImage(mageEl, getDataIndex()),
    [handleApplyImage, imageEl],
  );

  /**
   * 이미지 전체 화면 보기
   * - 이미지 전체 화면 보기 버튼을 클릭하는 경우
   * @returns {void}
   */
  const viewFullscreen = useCallback(() => {
    const currentImageInfo = getCurrentImageInfo({ promptId });
    debug('viewFullscreen 함수 호출됨', {
      promptId,
      imageIndex,
      imageInfo,
      imageEl,
      currentImageInfo,
    });

    // ! exploreImages 배열에서는 이미지가 복수가 아니라, 하나의 이미지만 존재한다.
    let selectedImageInfo = currentImageInfo.image;

    // snake_case를 camelCase로 변환하는 함수
    const snakeToCamel = (str) =>
      str.replace(/_([a-z])/g, (g) => g[1].toUpperCase());

    // selectedImageInfo의 속성을 순회하며 snake_case를 camelCase로 변환
    const convertedImageInfo = Object.entries(selectedImageInfo).reduce(
      (acc, [key, value]) => {
        const camelKey = snakeToCamel(key);
        acc[camelKey] = value;
        return acc;
      },
      {},
    );

    // 변환된 정보로 selectedImageInfo 업데이트
    selectedImageInfo = convertedImageInfo;

    // ImageViewerContext에서 사용하는 모달 데이터 설정
    openImageViewerModal({
      promptId,
      apiMetadata: currentImageInfo.api_metadata, // 현재 이미지 정보
      imageMetadata: { ...imageInfo, ...selectedImageInfo }, // 현재 이미지 정보
      promptMetadata: currentImageInfo.prompt_metadata, // 현재 이미지 정보
      imageEl: imageEl, // 현재 이미지 엘리먼트
      imageIndex, // 현재 이미지 인덱스
    });
  }, [
    promptId,
    imageIndex,
    imageInfo,
    imageEl,
    openImageViewerModal,
    getCurrentImageInfo,
    debug,
  ]);

  const copyPrompt = useCallback(() => {
    const currentImageInfo = getCurrentImageInfo({ promptId });
    const prompt = currentImageInfo.prompt_metadata.base_prompt;
    navigator.clipboard.writeText(prompt);
  }, [promptId, getCurrentImageInfo]);

  return {
    regenerateImage,
    editImage,
    downloadImage,
    uploadImage,
    autoPostImage,
    applyImage,
    viewFullscreen,
    copyPrompt,
  };
};

export default useExploreImageActions;
