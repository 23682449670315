import createDebugger from '@utils/debug';
import { API_CONFIG, getApiPath, getCurrentServiceUrl } from './apiConfig';
import { sendGet, sendPost } from './apiService';

// NOTICE: manualJwtDecode: jwt token을 decode하는 함수
// ! - jwt-decode 라이브러리를 사용할 수 없어서 만든 함수
import { manualJwtDecode } from './authUtils';

// ! tokenVerification
import {
  verifyTokenClaims,
  TokenVerificationError,
  ERROR_CODES,
} from './tokenVerification';

const storeageKey = {
  accessToken: 'seAccessToken',
  refreshToken: 'seRefreshToken',
  crossOriginAccessToken: 'seCrossOriginAccessToken',
  crossOriginRefreshToken: 'seCrossOriginRefreshToken',
};

const { debug, setDebugMode } = createDebugger('authService');
setDebugMode(API_CONFIG.debug);

/**
 * JWT 토큰을 가져오는 함수
 * @returns {Promise<Object>} 토큰 및 사용자 정보
 */
export const getToken = async () => {
  try {
    const response = await sendGet({
      url: getApiPath('getToken'),
      config: { withCredentials: true },
      useImageAI: false,
    });

    if (response.status === 'success' && response.data && response.data.token) {
      console.log('getToken: ', response);
      localStorage.setItem('jwtToken', response.data.token);
      return response.data;
    } else {
      throw new Error('Token not received');
    }
  } catch (error) {
    if (!error.error_code || error.error_code !== 'NOT_LOGGED_IN') {
      console.error('Error fetching token:', error);
    }
    throw error;
  }
};

/**
 * 토�� 유효성 검사 함수
 * @param {string} token - 검사할 JWT 토큰
 * @returns {Promise<boolean>} 토큰 유효성 여부
 */
export const validateToken = async (token) => {
  try {
    const response = await sendPost({
      url: getApiPath('validateToken'),
      data: { token },
      config: { withCredentials: true },
      useImageAI: false,
    });
    return response.status === 'success';
  } catch (error) {
    console.error('Error validating token:', error);
    return false;
  }
};

/**
 * 토큰 갱신 함수
 * @returns {Promise<Object>} 갱신된 토큰 정보
 */
export const refreshToken = async () => {
  try {
    const refreshToken = getRefreshTokenFromLocalStorage();

    if (!refreshToken) {
      throw new Error('No refresh token available');
    }

    const response = await sendPost({
      url: getApiPath('refreshToken'),
      data: { refresh_token: refreshToken },
      config: { withCredentials: true },
      useImageAI: false,
    });

    if (response.status === 'success' && response.data && response.data.token) {
      const newAccessToken = response.data.token;
      const newRefreshToken = response.data.refresh_token; // 서버가 새 refresh token도 보내는 경우

      setAccessTokenToLocalStorage(newAccessToken);

      if (newRefreshToken) {
        setRefreshTokenToLocalStorage(newRefreshToken);
      }

      return response.data;
    } else {
      throw new Error('Failed to refresh token');
    }
  } catch (error) {
    console.error('Error refreshing token:', error);
    // 토큰 갱신 실패 시 로컬 스토리지의 토큰 제거
    removeAccessTokenFromLocalStorage();
    removeRefreshTokenFromLocalStorage();
    throw error;
  }
};

export const refreshUserToken = async () => {
  try {
    const response = await refreshToken(); // API 호출
    // INFO: response는 이미 상태 확인을 마친 후의 response.data 값을 가지고 있다.
    if (response.token) {
      const newToken = response.token;
      const newRefreshToken = response.refresh_token;
      const result = validateUserToken(newToken);

      // 새롭게 발행한 access token과 refresh token을 로컬 스토리지에 저장한다.
      setAccessTokenToLocalStorage(newToken);
      if (newRefreshToken) {
        setRefreshTokenToLocalStorage(newRefreshToken);
      }
      return result;
    } else {
      console.error('토큰 갱신 실패: 잘못된 응답', response);
      removeAccessTokenFromLocalStorage();
      removeRefreshTokenFromLocalStorage();
      throw new Error('토큰 갱신 실패: 잘못된 응답');
    }
  } catch (error) {
    console.error('토큰 갱신 실패:', error);
    removeAccessTokenFromLocalStorage();
    removeRefreshTokenFromLocalStorage();
    throw error;
  }
};

/**
 * 토큰을 검증하고 사용자 정보를 반환하는 함수
 * @param {string} token - 검증할 JWT 토큰
 * @returns {Object} 검증된 사용자 정보
 * @throws {TokenVerificationError} 토큰 검증 실패 시 에러
 */
export const validateUserToken = (token) => {
  try {
    // 1. 토큰 디코딩
    const decodedToken = manualJwtDecode(token);
    debug('i:*:');
    debug('Check decodedToken', token, decodedToken);

    // ! example
    // const options = {
    //   expectedIssuer: 'https://your-auth-server.com',
    //   expectedAudience: 'your-app-id',
    //   maxTokenAge: 3600, // 1시간
    //   allowedRoles: ['user', 'admin'],
    // };
    const options = {};

    // 2. 토큰 클레임 검증
    verifyTokenClaims(decodedToken, options);

    // ! for debug
    console.log('decodedToken', decodedToken);

    // 3. 검증 성공 시 사용자 정보 반환
    return {
      role: decodedToken.role || null,
      data: decodedToken.data,
    };
  } catch (error) {
    if (error instanceof TokenVerificationError) {
      console.error('Token verification failed:', error.code, error.message);

      // 에러 코드에 따른 처리
      switch (error.code) {
        case ERROR_CODES.EXPIRED:
          // 예: 리프레시 토큰으로 새 토큰 요청
          console.log('Token expired. Requesting new token...');
          // requestNewToken(); // 이 함수는 별도로 구현해야 합니다.
          break;
        case ERROR_CODES.INVALID_ROLE:
          console.log('User does not have the required role.');
          break;
        default:
          console.log('Token is invalid.');
      }

      throw error; // 에러를 상위로 전파
    } else if (error instanceof Error) {
      // JWT 디코딩 실패 등의 예외 처리
      console.error('Unexpected error during token validation:', error.message);
      throw new TokenVerificationError('TOKEN_INVALID', 'Invalid token format');
    }
  }
};

/**
 * 로그아웃 함수
 * @returns {Promise<Object>} 로그아웃 결과
 */
export const logoutProcess = async () => {
  console.log('i:*:start logout');
  try {
    const accessToken = getAccessTokenFromLocalStorage();

    const headers = {};
    if (accessToken) {
      debug('i:*:Check access token for logout: ', accessToken);
      headers['Authorization'] = `Bearer ${accessToken}`;
    } else {
      debug('e:*:logout() without access token');
      throw new Error('logout() without access token');
    }

    const response = await sendPost({
      url: getApiPath('logout'),
      config: {
        withCredentials: true,
        headers: headers,
      },
      useImageAI: false,
    });

    return response;
  } catch (error) {
    console.error('Error during logout:', error);
    throw error;
  }
};

/**
 * 사용자 정보를 가져오는 함수
 * @returns {Promise<Object>} 사용자 정보
 */
export const getUserInfo = async () => {
  try {
    const response = await sendGet({
      url: getApiPath('userInfo'),
      useImageAI: false,
      config: { withCredentials: true },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching user info:', error);
    throw error;
  }
};

export const setAccessTokenToLocalStorage = (token) => {
  localStorage.setItem(storeageKey.accessToken, token);
};

export const setRefreshTokenToLocalStorage = (token) => {
  localStorage.setItem(storeageKey.refreshToken, token);
};

export const getAccessTokenFromLocalStorage = () => {
  return localStorage.getItem(storeageKey.accessToken);
};

export const getRefreshTokenFromLocalStorage = () => {
  return localStorage.getItem(storeageKey.refreshToken);
};

export const removeAccessTokenFromLocalStorage = () => {
  return localStorage.removeItem(storeageKey.accessToken);
};

export const removeRefreshTokenFromLocalStorage = () => {
  return localStorage.removeItem(storeageKey.refreshToken);
};

//----------------------------------------------------------
// cross-origin 인증 관련 함수
//----------------------------------------------------------

/**
 * SSO 인증 코드를 JWT 토큰으로 교환하는 함수
 * @param {string} authCode - SSO 인증 코드
 * @returns {Promise<Object>} JWT 토큰 및 사용자 정보
 */
export const exchangeAuthCode = async (authCode) => {
  try {
    debug('auth_code 교환 시작:', authCode);

    const response = await sendPost({
      url: getApiPath('exchangeCode'),
      data: { auth_code: authCode },
      config: {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      },
      useImageAI: false,
    });

    if (response.status === 'success' && response.data && response.data.token) {
      debug('auth_code 교환 성공:', response.data);

      const { token, refresh_token } = response.data;

      // 토큰 저장
      setAccessTokenToLocalStorage(token);
      if (refresh_token) {
        setRefreshTokenToLocalStorage(refresh_token);
      }

      return response.data;
    } else {
      throw new Error('Token not received from auth code exchange');
    }
  } catch (error) {
    debug('auth_code 교환 실패:', error);
    // 실패 시 기존 토큰 제거
    removeAccessTokenFromLocalStorage();
    removeRefreshTokenFromLocalStorage();

    if (
      error.error_code === 'NOT_LOGGED_IN' ||
      error.error_code === 'INVALID_AUTH_CODE'
    ) {
      throw error;
    } else {
      console.error('Error exchanging auth code:', error);
      throw new Error('Failed to exchange auth code for token');
    }
  }
};

export const setCrossOriginAccessTokenToLocalStorage = (token) => {
  localStorage.setItem(storeageKey.accessToken, token);
};

export const setCrossOriginRefreshTokenToLocalStorage = (token) => {
  localStorage.setItem(storeageKey.refreshToken, token);
};

export const getCrossOriginAccessTokenFromLocalStorage = () => {
  return localStorage.getItem(storeageKey.accessToken);
};

export const getCrossOriginRefreshTokenFromLocalStorage = () => {
  return localStorage.getItem(storeageKey.refreshToken);
};

export const removeCrossOriginAccessTokenFromLocalStorage = () => {
  return localStorage.removeItem(storeageKey.accessToken);
};

export const removeCrossOriginRefreshTokenFromLocalStorage = () => {
  return localStorage.removeItem(storeageKey.refreshToken);
};

/**
 * cross-origin 인증 사용자 여부 확인 함수
 * - cross-origin 인증 토큰이 존재하는 경우 true 반환
 * @returns {boolean} 사용자 여부
 */
export const isCrossOriginUser = () => {
  return API_CONFIG.isCrossOrigin;
};

console.log('authService 확인');

// 필요에 따라 추가적인 인증 관련 함수들을 여기에 구현할 수 있습니다.
