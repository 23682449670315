import { getBase64FromUrl } from '@utils/image/imageUtils';
import { getAccessTokenFromLocalStorage } from '@api/authService';

export class CallbackManager {
  constructor(instance) {
    this.instance = instance;
    this.callbacks = {};
    this._externalCallback = null;
  }

  initialize() {
    this.initializeCallbacks();
  }

  initializeCallbacks() {
    const callbackNames = [
      'onGenerateImage',
      'onGeneratingImage',
      'onGeneratedImage',
      'onApplyImage',
      'onGenerateImageError',
      'onAuthSuccess',
      'onAuthError',
    ];

    callbackNames.forEach((name) => this.setupCallback(name));
  }

  setupCallback(name) {
    switch (name) {
      case 'onApplyImage':
        this.setupApplyImageCallback();
        break;
      case 'onGenerateImageError':
      case 'onAuthError':
        this.setupErrorCallback(name);
        break;
      case 'onAuthSuccess':
        this.setupAuthSuccessCallback();
        break;
      default:
        this.setupBasicCallback(name);
    }
  }

  setupApplyImageCallback() {
    this.callbacks.onApplyImage = async (data) => {
      this.instance.debug('i:*:Executing onApplyImage callback', {
        hasExternalCallback: !!this._externalCallback,
        hasOptionsCallback: !!this.instance.options.callbacks?.onApplyImage,
        autoCloseEnabled: this.instance.options.autoCloseOnApply,
        data,
      });

      try {
        const processedData = await this.processImageData(data);

        if (this._externalCallback) {
          this.instance.debug(
            'i:*:Executing external callback with processed data',
          );
          await this.executeCallback(this._externalCallback, processedData);
        }

        if (this.instance.options.callbacks?.onApplyImage) {
          this.instance.debug(
            'i:*:Executing options callback with processed data',
          );
          await this.executeCallback(
            this.instance.options.callbacks.onApplyImage,
            processedData,
          );
        }

        if (this.instance.options.autoCloseOnApply) {
          this.instance.debug('i:*:Auto closing after apply image');
          setTimeout(() => this.instance.closeAgent(), 100);
        }
      } catch (error) {
        this.instance.debug('e:*:Error in onApplyImage callback:', error);
        if (this.callbacks.onApplyImageError) {
          this.callbacks.onApplyImageError(error);
        }
      }
    };
  }

  setupErrorCallback(name) {
    this.callbacks[name] = (error) => {
      this.instance.debug(`e:*:${name} callback executing:`, error);

      this.executeCallback(this.instance.options.callbacks?.[name], error);

      if (this._externalCallback && name === 'onAuthError') {
        this.executeCallback(this._externalCallback, {
          type: 'auth_error',
          error,
        });
      }

      this.instance.debug(`e:*:${name}:`, error);
    };
  }

  setupBasicCallback(name) {
    this.callbacks[name] = (...args) => {
      this.executeCallback(this.instance.options.callbacks?.[name], ...args);
    };
  }

  setupAuthSuccessCallback() {
    this.callbacks.onAuthSuccess = async (tokenData) => {
      this.instance.debug('i:*:Auth success callback executing', {
        hasOptionsCallback: !!this.instance.options.callbacks?.onAuthSuccess,
        tokenData,
      });

      try {
        if (this.instance.options.callbacks?.onAuthSuccess) {
          await this.executeCallback(
            this.instance.options.callbacks.onAuthSuccess,
            tokenData,
          );
        }

        if (this._externalCallback) {
          await this.executeCallback(this._externalCallback, {
            type: 'auth_success',
            data: tokenData,
          });
        }
      } catch (error) {
        this.instance.debug('e:*:Error in auth success callback:', error);
        if (this.callbacks.onAuthError) {
          this.callbacks.onAuthError(error);
        }
      }
    };
  }

  async processImageData(imageData) {
    const context = this.instance._externalContext || {};
    const imageOptions =
      context.imageOptions || this.instance.options.imageOptions;

    try {
      if (!imageData || typeof imageData !== 'object') {
        throw new Error('Invalid imageData');
      }

      if (imageOptions.includeBase64) {
        this.instance.debug('i:*:Fetching base64 data for image');

        if (!imageData.imageUrl) {
          throw new Error('imageUrl is missing');
        }

        const accessToken = getAccessTokenFromLocalStorage();
        const base64Data = await getBase64FromUrl(imageData.imageUrl, {
          accessToken: accessToken || null,
        });
        imageData.base64Data = base64Data;
      }

      return imageData;
    } catch (error) {
      this.instance.debug('e:*:Error processing image data:', error);
      throw error;
    }
  }

  executeCallback(callback, ...args) {
    if (typeof callback === 'function') {
      try {
        return callback(...args);
      } catch (error) {
        this.instance.debug('e:*:Callback execution error:', error);
        console.error('Callback execution error:', error);
        return false;
      }
    }
    return false;
  }

  registerExternalCallback(callback) {
    if (typeof callback === 'function') {
      this._externalCallback = callback;
      this.instance.debug('i:*:External callback registered');
    }
  }

  executeExternalCallback(data) {
    if (this._externalCallback) {
      try {
        return this._externalCallback(data);
      } catch (error) {
        this.instance.debug('e:*:Error executing external callback:', error);
        return false;
      }
    }
    return false;
  }
}
