import React from 'react';
import { ArrowPathIcon } from '@heroicons/react/24/solid';

const SeedInput = ({ 
  value, 
  onChange, 
  onRandomize,
  lang,
}) => {
  return (
    <div className='relative'>
      <input
        type='text'
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className='bg-light-input dark:bg-dark-input w-full rounded-lg border-[0.5px] border-light-200 px-3 py-2 
                 text-light-900 shadow-[0px_7px_21px_0px_rgba(51,_51,_51,_0.05)] outline-none
                 focus:border-light-500
                 dark:border-dark-700 dark:text-dark-100 dark:shadow-[0px_7px_21px_0px_rgba(0,_0,_0,_0.25)] dark:focus:border-dark-300'
        placeholder={lang('시드를입력하거나무작위로선택하세요')}
      />
      <button
        onClick={onRandomize}
        className='absolute inset-y-0 right-0 flex items-center px-3 text-light-500 transition-colors 
                   duration-200 hover:text-light-700 dark:text-dark-400 dark:hover:text-dark-200'
      >
        <ArrowPathIcon className='h-5 w-5' />
      </button>
    </div>
  );
};

export { SeedInput as default };
