// src/config/apiConfigs.js
export const fluxFalConfig = {
  api_options: {
    api_provider: 'fal',
    model: 'dev',
    image_size: 'landscape_4_3',
    num_inference_steps: 28,
    guidance_scale: 3.5,
    sync_mode: false,
    num_images: 1,
    enable_safety_checker: true,
    // falAPI에서 이미지 생성 시, 각 생성 이미지에 대한 base64 데이터를 반환하는 옵션이다.
    // 이 옵션이 true일 경우, 이미지 생성 결과를 base64 데이터로 반환한다.
    isBase64: false,
  },
  model_options: [
    {
      model: 'fal-ai/flux-pro',
      key: 'se-flux-pro',
      text: 'Expert',
      active: true,
      default: false,
    },
    {
      model: 'fal-ai/flux/dev',
      key: 'se-flux-dev',
      text: 'Premium',
      active: true,
      default: false,
    },
    {
      model: 'fal-ai/flux/schnell',
      key: 'se-flux-schnell',
      text: 'Fast',
      active: true,
      default: true,
    },
    {
      model: 'fal-ai/flux-realism',
      key: 'se-flux-realism',
      text: 'Realism',
      active: true,
      default: false,
    },
    {
      model: 'fal-ai/flux/dev/image-to-image',
      key: 'se-flux-dev-image-to-image',
      text: 'Image to Image',
      active: true,
      default: false,
    },
  ],
  image_sizes: [
    { value: 'landscape_16_9', label: 'Landscape 16:9' },
    { value: 'landscape_4_3', label: 'Landscape 4:3' },
    { value: 'square_hd', label: 'Square HD' },
    { value: 'portrait_4_3', label: 'Portrait 4:3' },
    { value: 'portrait_16_9', label: 'Portrait 16:9' },
  ],
};

export const fluxReplicateConfig = {
  // FluxReplicate API 설정
};

export const openAIConfig = {
  // OpenAI API 설정
};

export const imageApisConfig = {
  fluxFal: { ...fluxFalConfig },
  fluxReplicate: { ...fluxReplicateConfig },
  openAI: { ...openAIConfig },
};
