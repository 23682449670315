import React, { createContext, useContext, useCallback, useState } from 'react';
import useDebug from '@hooks/useDebug';
import {
  CreatedImagesProvider,
  useCreatedImagesContext,
} from '@contexts/ai/service/imageAI/Create/CreatedImagesContext';

// 채팅 전용 컨텍스트 생성
const ChatCreatedImagesContext = createContext();

// 채팅 전용 훅
export const useChatCreatedImagesContext = () => {
  const context = useContext(ChatCreatedImagesContext);
  if (!context) {
    throw new Error(
      'useChatCreatedImagesContext must be used within a ChatCreatedImagesProvider',
    );
  }
  return context;
};

// 채팅 전용 Provider 컴포넌트
const ChatCreatedImagesProviderComponent = ({ children }) => {
  const { debug } = useDebug('ChatCreatedImagesContext');
  const baseContext = useCreatedImagesContext();

  // 채팅 전용 상태
  const [chatViewMode, setChatViewMode] = useState('chat'); // 'chat' | 'grid'
  const [selectedMessageId, setSelectedMessageId] = useState(null);

  // 채팅 전용 함수들
  const handleMessageSelect = useCallback((messageId) => {
    setSelectedMessageId(messageId);
  }, []);

  const toggleViewMode = useCallback(() => {
    setChatViewMode((prev) => (prev === 'chat' ? 'grid' : 'chat'));
  }, []);

  // 채팅용 handleSubmit 오버라이드
  const handleChatSubmit = useCallback(
    async (params) => {
      debug('i:*:ImageAIChat submit handler:', params);
      const {
        selectedModel,
        prompt,
        settings,
        originalPrompt = '',
        originalLanguage = 'en',
      } = params;

      // 기본 handleSubmit 실행
      const result = await baseContext.handleSubmit(
        selectedModel,
        prompt,
        settings,
        originalPrompt,
        originalLanguage,
      );

      debug('s:*:ImageAIChat submit handler result:', result);

      // // 채팅 전용 추가 처리
      // if (result.success) {
      //   setSelectedMessageId(result.messageId);
      //   // 채팅 전용 후처리...
      // }
      return result;
    },
    [baseContext.handleSubmit, debug],
  );

  const contextValue = {
    // 기본 CreatedImagesContext의 모든 값들
    ...baseContext,
    // 채팅 전용 상태 및 함수들
    chatViewMode,
    selectedMessageId,
    handleMessageSelect,
    toggleViewMode,
    // 오버라이드된 함수들
    handleSubmit: handleChatSubmit,
  };

  debug('ChatCreatedImagesContext values:', contextValue);

  return (
    <ChatCreatedImagesContext.Provider value={contextValue}>
      {children}
    </ChatCreatedImagesContext.Provider>
  );
};

// 최종 export되는 Provider - CreatedImagesProvider를 포함
export const ChatCreatedImagesProvider = ({ children }) => {
  return (
    <CreatedImagesProvider>
      <ChatCreatedImagesProviderComponent>
        {children}
      </ChatCreatedImagesProviderComponent>
    </CreatedImagesProvider>
  );
};
