// utils/assetPath.js
export const getAssetPath = (path) => {
  if (import.meta.env.DEV) {
    return `${path}`;
  }
  return `/assets/build/assets${path}`;
};

export const getCdnAssetPath = (path) => {
  const cdnUrl = 'https://cdn.supereasy.pro';
  return `${cdnUrl}/assets/${path}`;
};

export const getCdnAssetFontPath = (path) => {
  const cdnUrl = 'https://cdn.supereasy.pro';
  return `${cdnUrl}/assets/font/${path}`;
};

export const getCdnAssetImagesPath = (path) => {
  const cdnUrl = 'https://cdn.supereasy.pro';
  return `${cdnUrl}/assets/images/${path}`;
};

export const getCdnAssetVideosPath = (path) => {
  const cdnUrl = 'https://cdn.supereasy.pro';
  return `${cdnUrl}/assets/videos/${path}`;
};
