import React, { useEffect } from 'react';
import useDebug from '@hooks/useDebug';
import { useTranslation } from 'react-i18next';
import { getAspectByRatio } from '@utils/image/imageUtils';
import { useImageAIContext } from '@contexts/ai/image/ImageAIContext';
import { calculateAgo } from '@utils/timeUtils';
import { Copy } from 'lucide-react';

const PromptArea = ({
  imageMetadata,
  apiMetadata,
  promptMetadata,
  imageIndex,
}) => {
  const { debug } = useDebug('PromptArea');

  // 다국어 지원
  const { t } = useTranslation([
    'components/service/imageAI/components/imageViewer',
  ]);
  const lang = t;

  const { promptSettings, selectedModel } = useImageAIContext();

  useEffect(() => {
    debug('i:*:Check PromptArea params: ', {
      imageMetadata,
      apiMetadata,
      promptMetadata,
      imageIndex,
    });
  }, []);

  const copyPromptToClipboard = () => {
    navigator.clipboard.writeText(apiMetadata.prompt || lang('사용가능한프롬프트가없습니다'));
    alert(lang('프롬프트가클립보드에복사되었습니다'));
  };

  const createAt = calculateAgo(imageMetadata.createdAt, false);
  const createAtText =
    createAt.value === 0 ? 'Today' : `${createAt.value} ${createAt.period[0]}`;

  const displayImageMetadata = (imageMetadata) => {
    const { size, aspectRatio, metadata, contentType } = imageMetadata;
    const fileType = contentType.split('/')[1];
    const ratio = getAspectByRatio(aspectRatio);
    const imageSize =
      size / 1024 >= 1024
        ? `${(size / (1024 * 1024)).toFixed(1)}M`
        : `${Math.round(size / 1024)}KB`;

    return {
      size: {
        label: lang('크기'),
        value: imageSize,
      },
      type: {
        label: lang('타입'),
        value: fileType,
      },
      ratio: {
        label: lang('비율'),
        value: ratio,
      },
    };
  };

  const displayApiMetadata = (apiMetadata, selectedModel) => {
    const { model, guidance_scale, num_inference_steps } = apiMetadata;

    return {
      model: {
        label: lang('모델'),
        value: selectedModel.text,
      },
      guidance_scale: {
        label: lang('가이던스스케일'),
        value: guidance_scale,
      },
      num_inference_steps: {
        label: lang('추론단계수'),
        value: num_inference_steps,
      },
    };
  };

  return (
    <div className='flex h-full w-full flex-col overflow-y-auto border-l border-light-50 bg-light-bg px-6 py-4 dark:border-dark-900 dark:bg-dark-bg mid:w-[300px]'>
      
      {/* User name, Date */}
      <div className='my-1.5 flex items-center justify-between'>
        <span className='text-sm font-medium text-light-700 dark:text-dark-100'>
          {promptMetadata.user_id || lang('손님')}
        </span>
        <span className='text-[12px] text-light-400 dark:text-dark-500'>
          {createAtText}
        </span>
      </div>

      {/* Prompt area */}
      <div className='mb-4 mt-3'>
        <p className='text-[13px] text-light-700 dark:text-dark-100'>
          {promptMetadata.final_prompt || lang('사용가능한프롬프트가없습니다')}
        </p>
        <button
          onClick={copyPromptToClipboard}
          className='mt-3 inline-flex items-center gap-1.5 rounded-md bg-light-50 dark:bg-dark-800 px-2 py-1 text-xs leading-tight text-light-500 dark:text-dark-300 transition-color duration-200 ease-in-out hover:bg-light-100 hover:dark:bg-dark-700/50 hover:text-light-600 hover:dark:text-dark-100'
        >
          <Copy size={12} />
          <span>{lang('프롬프트복사')}</span>
        </button>
      </div>

      <div className='mt-auto'>
        {/* display Image Metadata */}
        <div className='mb-2 rounded-lg bg-light-50 px-3 py-2 dark:bg-dark-900'>
          <ul className='flex flex-col gap-1 text-[12px] text-light-500 dark:text-dark-500'>
            {Object.entries(displayImageMetadata(imageMetadata)).map(
              ([key, value]) => (
                <li key={key} className='flex justify-between'>
                  <span>{value.label}</span>
                  <span className='text-light-600 dark:text-dark-300'>
                    {value.value}
                  </span>
                </li>
              ),
            )}
          </ul>
        </div>

        {/* display Api Metadata  */}
        <div className='mb-2 rounded-lg bg-light-50 px-3 py-2 dark:bg-dark-900'>
          <ul className='flex flex-col gap-1 text-[12px] text-light-500 dark:text-dark-500'>
            {Object.entries(displayApiMetadata(apiMetadata, selectedModel)).map(
              ([key, value]) => (
                <li key={key} className='flex justify-between'>
                  <span>{value.label}</span>
                  <span className='text-light-600 dark:text-dark-300'>
                    {value.value}
                  </span>
                </li>
              ),
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PromptArea;
