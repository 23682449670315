import React from 'react';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

const ModalHeader = ({
  title,
  titlePrefix,
  options,
  onClose,
  headerClassName = '',
  titleClassName,
  titleTextClass, // 헤더 타이틀 텍스트 크기
  headerSizeClass, // 헤더 크기
  darkMode,
  darkModeClasses,
}) => {
  if (options.h === 0) return null;

  const borderClass = options.h_b ? 'border-b border-light-100' : '';

  return (
    <div
      className={`modal-header flex items-center justify-between ${borderClass} ${headerSizeClass} ${titleTextClass}`}
    >
      {options.ht !== 0 && (
        <Dialog.Title
          as='h3'
          className={`font-medium leading-6 text-gray-900 ${titleClassName}`}
        >
          {options.ht_prefix !== 0 && titlePrefix && (
            <span className='modal-title-prefix mr-2'>{titlePrefix}</span>
          )}
          {title}
        </Dialog.Title>
      )}
      {options.cb !== 0 && options.cb_out === 0 && (
        <button
          type='button'
          className='rounded-full p-1 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
          onClick={onClose}
        >
          <XMarkIcon className='h-5 w-5 text-gray-500' />
        </button>
      )}
    </div>
  );
};

export default ModalHeader;
