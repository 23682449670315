/**
 * 이미지 AI 채팅 히스토리 컴포넌트
 * - 생성된 이미지와 프롬프트 메시지를 날짜별로 그룹화하여 표시
 * - 무한 스크롤 기능 지원
 * - 이미지 생성 상태 표시
 * - 프롬프트 확장/축소 기능
 * - 다국어 지원
 *
 * @license Commercial License
 * @author JB
 *
 * 이 소프트웨어는 커머셜 라이센스 하에 배포됩니다.
 * 상업적 사용을 포함한 모든 사용은 라이센스 조건에 따라야 합니다.
 * All rights reserved © JB
 *
 * 자세한 내용은 LICENSE 파일을 참조하십시오.
 */

import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  Suspense,
} from 'react';
import useDebug from '@hooks/useDebug';
// 다국어
import { useTranslation } from 'react-i18next';
// 무한 스크롤
import InfiniteScroll from 'react-infinite-scroll-component';
// components
import EmptyMessage from './EmptyMessage';
import ImageMessage from './ImageMessage';
import PromptMessage from './PromptMessage';
// 날짜 포맷팅
import { format, isValid } from 'date-fns';
import { locales, dateFormats } from '@config/dateLocales';

// 로딩 컴포넌트
const LoadingFallback = () => (
  <div className='flex justify-center p-4'>Loading...</div>
);

/**
 * 이미지 AI 채팅 히스토리 컴포넌트의 내부 구현
 */
const ImageAIChatHistoryContent = ({
  createdImages,
  isLoading,
  isGenerating,
  onSubmit,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}) => {
  const { debug } = useDebug('ImageAIChatHistory');
  const { t, i18n } = useTranslation([
    'components/service/imageAI/components/create',
  ]);
  const lang = t;
  const currentLang = i18n.language;

  const [expandedPrompts, setExpandedPrompts] = useState({});
  const [expandedOriginalPrompts, setExpandedOriginalPrompts] = useState({});

  // 프롬프트의 확장 축소 함수
  const handleToggleExpand = useCallback((promptId, isOriginal = false) => {
    if (isOriginal) {
      setExpandedOriginalPrompts((prev) => ({
        ...prev,
        [promptId]: !prev[promptId],
      }));
    } else {
      setExpandedPrompts((prev) => ({
        ...prev,
        [promptId]: !prev[promptId],
      }));
    }
  }, []);

  // ! for TEST
  useEffect(() => {
    debug('i:Check props', {
      createdImages,
      isLoading,
      isGenerating,
      onSubmit,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
    });
  }, []);

  useEffect(() => {
    if (!isLoading && createdImages.length > 0) {
      debug('s:*:Loaded createdImages', { createdImages });
    }
  }, [createdImages, isLoading, debug]);

  // 추가 데이터 로드 함수
  const loadMoreMessages = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      debug('Fetching next page of messages');
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage, debug]);

  // 날짜별 메시지 그룹화
  const groupImagesByDate = useMemo(() => {
    if (!Array.isArray(createdImages)) {
      return {};
    }

    const groups = createdImages.reduce((groups, imageData) => {
      if (!imageData?.prompt_metadata?.prompt_created_at) {
        return groups;
      }

      const date = new Date(imageData.prompt_metadata.prompt_created_at);
      if (!isValid(date)) {
        return groups;
      }

      const formattedDate = format(date, 'yyyy-MM-dd');
      if (!groups[formattedDate]) {
        groups[formattedDate] = [];
      }
      groups[formattedDate].push(imageData);
      return groups;
    }, {});

    debug('i:*:Grouped messages by date:', groups);
    return groups;
  }, [createdImages, debug]);

  // 날짜를 키로 하는 객체를 날짜순으로 정렬된 배열로 변환
  const sortedDates = useMemo(() => {
    const sorted = Object.keys(groupImagesByDate).sort(
      (a, b) => new Date(b) - new Date(a),
    );
    debug('i:*:sortedDates', sorted);
    return sorted;
  }, [groupImagesByDate, debug]);

  // 날짜 포맷팅
  const formatDate = useMemo(() => {
    return (dateString) => {
      const date = new Date(dateString);
      if (!isValid(date)) return dateString;

      try {
        return format(date, dateFormats[currentLang] || dateFormats['en'], {
          locale: locales[currentLang] || locales['en'],
        });
      } catch (error) {
        console.error('Date formatting error:', error);
        return dateString;
      }
    };
  }, [currentLang, debug]);

  if (isLoading || !Array.isArray(createdImages)) {
    return <div className='flex justify-center p-4'>{lang('로딩중')}</div>;
  }

  if (!createdImages.length) {
    return (
      <EmptyMessage lang={lang} />
    );
  }

  return (
    <div className='scrollbar flex-1 overflow-y-auto bg-light-bg dark:bg-dark-bg'>
      <InfiniteScroll
        dataLength={createdImages.length}
        next={loadMoreMessages}
        hasMore={hasNextPage}
        loader={
          <div className='p-4 text-center text-light-500 dark:text-dark-500'>{lang('메시지를불러오는중')}</div>
        }
        scrollThreshold={0.7}
        className='flex flex-col'
      >
        <div className='flex flex-col gap-4 p-4'>
          {sortedDates.map((date) => (
            <div key={date} className='flex flex-col gap-4'>
              <div className='sticky top-0 text-center text-sm text-light-500 dark:text-dark-500'>
                {formatDate(date)}
              </div>
              {groupImagesByDate[date].map((imageData, index) => (
                <div
                  key={imageData.prompt_metadata.external_prompt_id || index}
                  className='flex flex-col gap-2'
                >
                  {/* AI 응답 (이미지) 메시지를 먼저 출력 */}
                  <ImageMessage
                    images={imageData.images}
                    apiMetadata={imageData.api_metadata}
                    promptMetadata={imageData.prompt_metadata}
                    isGeneratingImage={imageData.is_generating}
                    isGenerating={isGenerating}
                    onSubmit={onSubmit}
                    lang={lang}
                    currentLang={currentLang}
                  />

                  {/* 사용자 프롬프트 메시지를 나중에 출력 */}
                  <PromptMessage
                    // promptMetadata={imageData.prompt_metadata}
                    // isExpanded={
                    //   expandedPrompts[
                    //     imageData.prompt_metadata.internal_prompt_id
                    //   ]
                    // }
                    // onToggleExpand={() =>
                    //   handleToggleExpand(
                    //     imageData.prompt_metadata.internal_prompt_id,
                    //   )
                    // }
                    // lang={lang}
                    apiMetadata={imageData.api_metadata}
                    promptMetadata={imageData.prompt_metadata}
                    imageCount={imageData.images.length}
                    // 현재 생성 중인 target image 여부
                    isGeneratingImage={imageData.is_generating}
                    // 현재 이미지가 생성 중인지 여부
                    isGenerating={isGenerating}
                    // isExpanded={
                    //   expandedPrompts[
                    //     imageData.prompt_metadata.internal_prompt_id
                    //   ] || false
                    // }
                    // isOriginalExpanded={
                    //   expandedOriginalPrompts[
                    //     imageData.prompt_metadata.internal_prompt_id
                    //   ] || false
                    // }
                    onToggleExpand={(isOriginal) =>
                      handleToggleExpand(
                        imageData.prompt_metadata.internal_prompt_id,
                        isOriginal,
                      )
                    }
                    onSubmit={onSubmit}
                    lang={lang} // lang 함수 전달
                    currentLang={currentLang}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </InfiniteScroll>

      {isGenerating && (
        <div className='fixed bottom-0 left-0 right-0 bg-light-100/80 p-2 text-center dark:bg-dark-900/80'>
          {lang('이미지생성중')}
        </div>
      )}
    </div>
  );
};

/**
 * 이미지 AI 채팅 히스토리 컴포넌트
 * - Suspense로 감싸서 비동기 로딩 처리
 */
const ImageAIChatHistory = (props) => {
  return (
    <Suspense fallback={<LoadingFallback />}>
      <ImageAIChatHistoryContent {...props} />
    </Suspense>
  );
};

export default ImageAIChatHistory;
