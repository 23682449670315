import createDebugger from '@utils/debug';
import { API_CONFIG } from './apiConfig';

const { debug, setDebugMode } = createDebugger('tokenVerification');
setDebugMode(API_CONFIG.debug);

export class TokenVerificationError extends Error {
  constructor(code, message) {
    super(message);
    this.name = 'TokenVerificationError';
    this.code = code;
  }
}

export const ERROR_CODES = {
  MISSING_EXPIRATION: 'TOKEN_MISSING_EXP',
  EXPIRED: 'TOKEN_EXPIRED',
  INVALID_ISSUER: 'TOKEN_INVALID_ISSUER',
  INVALID_AUDIENCE: 'TOKEN_INVALID_AUDIENCE',
  MISSING_IAT: 'TOKEN_MISSING_IAT',
  TOKEN_TOO_OLD: 'TOKEN_TOO_OLD',
  INVALID_ROLE: 'TOKEN_INVALID_ROLE',
};

/**
 * 디코딩된 JWT 토큰의 클레임을 검증합니다.
 *
 * @param {Object} decodedToken - 디코딩된 JWT 토큰 객체
 * @param {Object} options - 검증 옵션
 * @param {string} options.expectedIssuer - 예상되는 발행자
 * @param {string} options.expectedAudience - 예상되는 대상
 * @param {number} options.maxTokenAge - 최대 허용 토큰 나이 (초)
 * @param {string[]} [options.allowedRoles] - 허용된 사용자 역할 목록 (선택적)
 * @returns {boolean} 토큰이 유효하면 true를 반환합니다.
 * @throws {TokenVerificationError} 검증 실패 시 에러 코드와 메시지를 포함한 에러를 던집니다.
 */
export const verifyTokenClaims = (decodedToken, options) => {
  const currentTime = Math.floor(Date.now() / 1000);

  // 1. 만료 시간 검증
  if (!decodedToken.exp) {
    throw new TokenVerificationError(
      ERROR_CODES.MISSING_EXPIRATION,
      'Token is missing expiration claim',
    );
  }
  debug('i:*:');
  debug('verifyTokenClaims 시작');
  debug('Check decodedToken & options', {
    decodedToken,
    options,
  });

  debug('Check expiration', {
    currentTime,
    currentTimeReadable: new Date(currentTime * 1000).toLocaleString(),
    exp: decodedToken.exp,
    expReadable: new Date(decodedToken.exp * 1000).toLocaleString(),
  });

  if (decodedToken.exp < currentTime) {
    throw new TokenVerificationError(ERROR_CODES.EXPIRED, 'Token has expired');
  }

  debug('Check Issuer', {
    expectedIssuer: options.expectedIssuer,
    iss: decodedToken.iss,
  });

  // 2. 발행자 검증
  // WARNING: options.expectedIssuer 값이 없으면, 인증을 하지 않는다. 검증은 현재 미사용
  if (options.expectedIssuer && decodedToken.iss !== options.expectedIssuer) {
    throw new TokenVerificationError(
      ERROR_CODES.INVALID_ISSUER,
      `Invalid token issuer. Expected ${options.expectedIssuer}, but got ${decodedToken.iss}`,
    );
  }

  debug('Check Audience', {
    expectedAudience: options.expectedAudience,
    aud: decodedToken.aud,
  });

  // 3. 대상 검증
  // WARNING: options.expectedAudience 값이 없으면, 인증을 하지 않는다. 검증은 현재 미사용
  if (
    options.expectedAudience &&
    decodedToken.aud !== options.expectedAudience
  ) {
    throw new TokenVerificationError(
      ERROR_CODES.INVALID_AUDIENCE,
      `Invalid token audience. Expected ${options.expectedAudience}, but got ${decodedToken.aud}`,
    );
  }

  debug('Check iat: 발행 시간 검증', {
    iat: decodedToken.iat,
    iatReadable: new Date(decodedToken.iat * 1000).toLocaleString(),
  });

  // 4. 토큰 발행 시간 검증
  // - token 발행 시간이 없으면, 유효한 token이 아니다.
  if (!decodedToken.iat) {
    throw new TokenVerificationError(
      ERROR_CODES.MISSING_IAT,
      'Token is missing issued at claim',
    );
  }

  debug('Check maxTokenAge', {
    maxTokenAge: options.maxTokenAge,
    tokenAge: currentTime - decodedToken.iat,
  });

  // 5. tokenAge 검증
  // - token 발행 시간이 최대 허용 시간을 초과하면, 유효한 token이 아니다.
  // WARNING: options.maxTokenAge 값이 없으면, 검증을 하지 않는다.
  const tokenAge = currentTime - decodedToken.iat;
  if (options.maxTokenAge && tokenAge > options.maxTokenAge) {
    throw new TokenVerificationError(
      ERROR_CODES.TOKEN_TOO_OLD,
      `Token is too old. Maximum allowed age is ${options.maxTokenAge} seconds`,
    );
  }

  debug('Check role', {
    allowedRoles: options.allowedRoles,
    role: decodedToken.role,
  });

  // 5. 사용자 역할 검증 (선택적)
  if (
    decodedToken.role &&
    options.allowedRoles &&
    !options.allowedRoles.includes(decodedToken.role)
  ) {
    throw new TokenVerificationError(
      ERROR_CODES.INVALID_ROLE,
      `User role ${decodedToken.role} is not allowed`,
    );
  }

  debug('verifyTokenClaims 완료');
  debug('i:*:');

  return true; // 모든 검증을 통과하면 true 반환
};

// * 사용 예:
// try {
//   const isValid = verifyTokenClaims(decodedToken, {
//     expectedIssuer: 'https://your-auth-server.com',
//     expectedAudience: 'your-app-id',
//     maxTokenAge: 3600, // 1시간
//     allowedRoles: ['user', 'admin'] // 선택적
//   });

//   if (isValid) {
//     console.log('Token claims are valid');
//   }
// } catch (error) {
//   if (error instanceof TokenVerificationError) {
//     console.error('Token verification failed:', error.code, error.message);
//     // 에러 코드에 따른 처리 로직
//     switch (error.code) {
//       case ERROR_CODES.EXPIRED:
//         // 만료된 토큰 처리
//         break;
//       case ERROR_CODES.INVALID_ROLE:
//         // 권한 없음 처리
//         break;
//       // 기타 에러 케이스 처리
//     }
//   } else {
//     console.error('Unexpected error:', error);
//   }
// }
