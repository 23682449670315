import React from 'react';
import useDebug from '@hooks/useDebug';
import { getAspectByRatio } from '@utils/image/imageUtils';
import { useImageAIContext } from '@contexts/ai/image/ImageAIContext';
import { calculateAgo } from '@utils/timeUtils';

const PromptArea = ({
  imageMetadata, // 현재 이미지 메타데이터
  apiMetadata, // 현재 이미지 메타데이터
  imageEl,
  messageId = null, // 현재 이미지 메시지 ID
}) => {
  const { debug } = useDebug();
  const { promptSettings, selectedModel } = useImageAIContext();
  debug('Check props: ', {
    imageMetadata,
    apiMetadata,
    imageEl,
    messageId,
    promptSettings,
    selectedModel,
  });

  const copyPromptToClipboard = () => {
    navigator.clipboard.writeText(apiMetadata.prompt || 'No prompt available');
    alert('Prompt copied to clipboard!');
  };

  const createAt = calculateAgo(imageMetadata.createdAt, false);
  const createAtText =
    createAt.value === 0 ? 'Today' : `${createAt.value} ${createAt.period[0]}`;

  const displayImageMetadata = (imageMetadata) => {
    const { width, height, aspectRatio, metadata, contentType } = imageMetadata;
    const fileType = contentType.split('/')[1];
    const ratio = getAspectByRatio(aspectRatio);

    return {
      size: {
        label: 'Size',
        value: `${width} * ${height}`,
      },
      type: {
        label: 'Type',
        value: fileType,
      },
      ratio: {
        label: 'Ratio',
        value: ratio,
      },
    };
  };

  const displayApiMetadata = (apiMetadata, selectedModel) => {
    const { model, guidance_scale, num_inference_steps } = apiMetadata;

    return {
      model: {
        label: 'Model',
        value: selectedModel.text,
      },
      guidance_scale: {
        label: 'Guidance Scale',
        value: guidance_scale,
      },
      num_inference_steps: {
        label: 'Inference Steps',
        value: num_inference_steps,
      },
    };
  };

  return (
    <div className='h-full w-full overflow-y-auto bg-white p-4 dark:bg-gray-800 mid:w-[300px]'>
      {/* 이미지 정보 */}
      <div className='mb-4 flex items-center justify-between'>
        <span className='text-sm font-medium text-gray-500 dark:text-gray-400'>
          {imageMetadata.author || 'Guest'}
        </span>
        <span className='text-[13px] font-medium text-gray-500 dark:text-gray-400'>
          {createAtText}
        </span>
      </div>
      {/* 프롬프트 영역 */}
      <div className='mb-4'>
        <p className='text-[13px] text-gray-700 dark:text-gray-300'>
          {apiMetadata.prompt || 'No prompt available'}
        </p>
        <button
          onClick={copyPromptToClipboard}
          className='font-heading focus-ring border-stroke-strong hover:border-stroke-stronger data-[state=open]:bg-surface-alpha-light mt-2 inline-flex h-6 items-center justify-center whitespace-nowrap rounded border bg-transparent px-2.5 text-[12px] font-medium text-gray-700 transition-all hover:text-gray-800 disabled:pointer-events-none disabled:opacity-50 dark:text-gray-300 dark:hover:text-gray-400'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='12'
            height='12'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            className='lucide lucide-files icon-xs me-1'
          >
            <path d='M20 7h-3a2 2 0 0 1-2-2V2'></path>
            <path d='M9 18a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h7l4 4v10a2 2 0 0 1-2 2Z'></path>
            <path d='M3 7.6v12.8A1.6 1.6 0 0 0 4.6 22h9.8'></path>
          </svg>
          Copy prompt
        </button>
      </div>
      {/* 이미지 메타데이터 */}
      <div className='mb-4 rounded bg-gray-100 p-1 dark:bg-gray-700'>
        <ul className='text-[13px] text-gray-500 dark:text-gray-400'>
          {Object.entries(displayImageMetadata(imageMetadata)).map(
            ([key, value]) => (
              <li key={key} className='flex justify-between'>
                <span className='font-medium'>{value.label}:</span>
                <span>{value.value}</span>
              </li>
            ),
          )}
        </ul>
      </div>
      {/* Api 메타데이터 */}
      <div className='mb-4 rounded bg-gray-100 p-1 dark:bg-gray-700'>
        <ul className='text-[13px] text-gray-500 dark:text-gray-400'>
          {Object.entries(displayApiMetadata(apiMetadata, selectedModel)).map(
            ([key, value]) => (
              <li key={key} className='flex justify-between'>
                <span className='font-medium'>{value.label}:</span>
                <span>{value.value}</span>
              </li>
            ),
          )}
        </ul>
      </div>
      {/* <div className='flex space-x-2'>
        {['Use', 'Image', 'Style', 'Prompt'].map((buttonText) => (
          <button
            key={buttonText}
            className='rounded bg-gray-100 px-3 py-1 text-xs font-medium text-gray-800 hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600'
          >
            {buttonText}
          </button>
        ))}
      </div> */}
    </div>
  );
};

export default PromptArea;
