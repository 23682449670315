import React, { useState, useEffect, useRef, useCallback } from 'react';
import useDebug from '@hooks/useDebug';
import { getAspectByRatio } from '@utils/image/imageUtils';

// NOTICE: ImageAI Service에서 사용하는 ImageActionButtons 컴포넌트를 공통으로 사용한다.
import ImageActionButtons from '@components/ImageAI/ImageActionButtons';
import ContentLoader from 'react-content-loader';

const loaderStyle = `
  .loader {
    border: 2px solid currentColor;
    border-top: 2px solid transparent;
    border-radius: 50%;
    width: 1em;
    height: 1em;
    animation: spin 1s linear infinite;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.5em;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ResponsiveImageGrid = ({
  promptId,
  messageId,
  images,
  onSubmit,
  onViewImage,
  selectionMode = 'hover', // 'click' | 'hover'
  isGeneratingImage = false,
}) => {
  const { debug } = useDebug('ResponsiveImageGrid');
  const imageRefs = useRef([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [hoveredImageIndex, setHoveredImageIndex] = useState(null);
  const [loadedImages, setLoadedImages] = useState([]);

  const getGridColumns = (imageCount, aspectRatio, screenSize) => {
    const ratio = getAspectByRatio(aspectRatio);

    return 'grid-cols-12';
  };

  const getImageSpan = (imageCount, aspectRatio, screenSize, index) => {
    const ratio = getAspectByRatio(aspectRatio);

    if (screenSize >= 1024) {
      // lg to xl
      if (imageCount === 1) {
        if (['2/3', '3/4', '9/16'].includes(ratio)) {
          // 세로 비율인 경우 col-span-9 적용
          return 'col-span-9 row-span-2';
        } else {
          // 기타 비율은 전체 너비 사용
          return 'col-span-12 row-span-2';
        }
      } else  {
        return 'col-span-6 row-span-2';
      }
    } else {
      // xs
      if (imageCount === 1) {
        return 'col-span-12 row-span-2';
      } else {
        return 'col-span-6 row-span-2';
      }
    }
    return '';
  };

  const getImageCount = (imageCount, aspectRatio, screenSize) => {
    const ratio = getAspectByRatio(aspectRatio);

    if (screenSize >= 1024) {
      if (imageCount <= 2) {
        return imageCount;
      } else {
        return 4;
      }
    } else {
      return 4;
    }
  };

  const screenSize = window.innerWidth;
  const gridColumns = getGridColumns(
    images.length,
    images[0].aspectRatio,
    screenSize,
  );
  const imageCount = getImageCount(
    images.length,
    images[0].aspectRatio,
    screenSize,
  );

  const ImageSkeleton = ({ aspectRatio }) => {
    // aspectRatio가 없을 경우 기본값 설정
    const defaultAspectRatio = '1/1';
    const ratio = aspectRatio || defaultAspectRatio;
    const [width, height] = ratio.split('/').map(Number);
    const viewBox = `0 0 ${width * 100} ${height * 100}`;

    return (
      <ContentLoader
        speed={2}
        width={width * 100}
        height={height * 100}
        viewBox={viewBox}
        backgroundColor='#f3f3f3'
        foregroundColor='#ecebeb'
      >
        <rect
          x='0'
          y='0'
          rx='3'
          ry='3'
          width={width * 100}
          height={height * 100}
        />
      </ContentLoader>
    );
  };

  const renderImage = (image, index) => {
    if (!image) return null;
    const isHovered = index === hoveredImageIndex;
    const isSelected = index === selectedImageIndex;
    return (
      <div
        key={index}
        className={`${image.className} ${getImageSpan(
          images.length,
          image.aspectRatio,
          window.innerWidth,
          index,
        )}`}
        onClick={() => handleImageClick(index)}
        onMouseEnter={() => handleImageHover(index)}
        onMouseLeave={handleImageLeave}
      >
        <ImageSkeleton aspectRatio={image.aspectRatio} />
        {!isGeneratingImage && (
          <>
            <img
              src={image.url}
              alt={image.alt}
              className='absolute inset-0 h-full w-full object-cover'
              data-prompt-id={promptId}
              ref={(el) => {
                imageRefs.current[index] = el;
              }}
            />
            {showActionButtons(index) && (
              <div className='absolute bottom-2 right-2 z-20'>
                <div className='rounded-lg bg-light-bg/90 p-1 shadow-md dark:bg-dark-bg/80'>
                  <ImageActionButtons
                    promptId={promptId}
                    imageIndex={index}
                    imageInfo={image}
                    imageEl={imageRefs.current[index]}
                    selectedImageIndex={selectedImageIndex}
                    // WARN: regenerate 버튼 클릭 시 호출되는 함수
                    // ! 'regenerate'버튼을 포함하는 경우, 반드시 onSubmit 함수를 전달해야 한다.
                    onSubmit={onSubmit}
                    actions={[
                      'regenerate',
                      'download',
                      'autopost',
                      'apply',
                      'fullscreen',
                      'delete',
                    ]}
                    onViewImage={onViewImage}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  //------------------------------------------------
  // 이미지 Handlers
  //------------------------------------------------

  const handleImageClick = useCallback(
    (index) => {
      if (images.length === 1) return;
      setSelectedImageIndex((prevIndex) =>
        prevIndex === index ? null : index,
      );
    },
    [images.length],
  );

  const handleImageHover = useCallback((index) => {
    setHoveredImageIndex(index);
  }, []);

  const handleImageLeave = useCallback(() => {
    setHoveredImageIndex(null);
  }, []);

  const showActionButtons = useCallback(
    (index) => {
      const isHovered = hoveredImageIndex === index;
      const isSelected = selectedImageIndex === index;
      return (
        (selectionMode === 'hover' && (isHovered || isSelected)) ||
        (selectionMode === 'click' && isSelected)
      );
    },
    [hoveredImageIndex, selectedImageIndex, selectionMode],
  );

  return (
    <div className='relative w-full'>
      <style>{loaderStyle}</style>
      <div className={`grid ${gridColumns} gap-1.5`}>
        {images.slice(0, imageCount).map(renderImage)}
      </div>
      {isGeneratingImage && (
        <div className='bg-opacity-10 dark:bg-opacity-80 absolute inset-0 flex items-center justify-center rounded-lg bg-light-bg dark:bg-dark-800'>
          <div className='z-10 text-center text-light-400 dark:text-dark-200'>
            <div className='text-xs font-bold'>
              Generating <span className='loader'></span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResponsiveImageGrid;
