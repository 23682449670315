import React, { useState, useEffect } from 'react';
import useDebug from '@hooks/useDebug';
// NOTICE: 플러그인 전용 번역 컨텍스트 사용
import { useAgentTranslation } from '../../contexts/AgentTranslationContext';
import * as Select from '@radix-ui/react-select';
import { CheckIcon } from '@radix-ui/react-icons';
import { Languages } from 'lucide-react';
import { HeaderMenuItem } from './HeaderMenuItem';
import { SUPPORTED_LANGUAGES } from './constants';

const LanguageSelect = ({ isCollapsed = true, isDarkMode = false }) => {
  // NOTICE: 플러그인 전용 번역 컨텍스트 사용
  // WARNING: 플러그인 전용 번역 컨텍스트 사용 시, 반드시 useAgentTranslation 사용해야 한다
  const { i18n, t } = useAgentTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [sortedLanguages, setSortedLanguages] = useState([]);
  const { debug } = useDebug('LanguageSelect');
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  useEffect(() => {
    const currentLangCode = i18n.language;
    debug('i:*:LanguageSelect', 'Current language:', currentLangCode);

    const reorderedLanguages = [
      ...SUPPORTED_LANGUAGES.filter(
        (lang) => lang.langCode === currentLangCode,
      ),
      ...SUPPORTED_LANGUAGES.filter(
        (lang) => lang.langCode !== currentLangCode,
      ),
    ];

    setSortedLanguages(reorderedLanguages);
    setSelectedLanguage(reorderedLanguages[0]);
  }, [i18n.language]);

  const handleLanguageChange = (value) => {
    i18n.changeLanguage(value);
    setIsOpen(false);
    setSelectedLanguage(
      SUPPORTED_LANGUAGES.find((lang) => lang.langCode === value),
    );
  };

  useEffect(() => {
    console.log('i:*:LanguageSelect', 'Selected language:', selectedLanguage);
  }, [selectedLanguage]);

  return (
    <Select.Root
      open={isOpen}
      onOpenChange={setIsOpen}
      value={selectedLanguage?.langCode}
      onValueChange={handleLanguageChange}
    >
      <Select.Trigger asChild>
        <div>
          <HeaderMenuItem
            item={{
              title: selectedLanguage
                ? `${selectedLanguage.language}`
                : t('common.languageSelect'),
              icon: selectedLanguage ? (
                <span className='inline-flex h-4 w-6 items-center justify-center'>
                  {selectedLanguage.icon && <selectedLanguage.icon />}
                </span>
              ) : (
                <Languages size={20} />
              ),
            }}
            isCollapsed={isCollapsed}
          />
        </div>
      </Select.Trigger>

      <Select.Portal>
        <Select.Content
          className={`z-[10200] overflow-hidden rounded-md border shadow-lg
          ${
            isDarkMode
              ? 'border-dark-700 bg-dark-800 text-dark-100'
              : 'border-gray-200 bg-light-bg text-light-700'
          }`}
          position='popper'
          side='top'
          align='end'
          sideOffset={5}
        >
          <Select.Viewport className='max-h-[300px] overflow-y-auto p-2'>
            {sortedLanguages.map((lang) => (
              <Select.Item
                key={lang.langCode}
                value={lang.langCode}
                className={`flex cursor-pointer items-center gap-2 rounded p-2 text-xs
                outline-none ${
                  isDarkMode ? 'hover:bg-dark-700' : 'hover:bg-light-100'
                }`}
              >
                <Select.ItemText>
                  <span className='inline-flex items-center gap-2'>
                    <span className='inline-flex h-4 w-6 items-center justify-center'>
                      <lang.icon />
                    </span>
                    {lang.language}
                  </span>
                </Select.ItemText>
                <Select.ItemIndicator className='ml-auto'>
                  <CheckIcon className='h-4 w-4' />
                </Select.ItemIndicator>
              </Select.Item>
            ))}
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

export default LanguageSelect;
