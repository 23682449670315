// src/contexts/ChatHistoryContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';

const ChatHistoryContext = createContext();

// 전역 옵션 설정
const options = {
  // 세션 스토리지 사용 여부: sessionStorage에 chatHistory를 저장할지 여부
  isSessionStorage: true,
};

export const ChatHistoryProvider = ({ children, pageId }) => {
  const [chatHistory, setChatHistory] = useState(() => {
    if (options.isSessionStorage) {
      const savedChatHistory = sessionStorage.getItem(`chatHistory-${pageId}`);
      return savedChatHistory ? JSON.parse(savedChatHistory) : [];
    }
    return [];
  });

  useEffect(() => {
    if (options.isSessionStorage) {
      sessionStorage.setItem(
        `chatHistory-${pageId}`,
        JSON.stringify(chatHistory),
      );
    }
  }, [chatHistory, pageId]);

  return (
    <ChatHistoryContext.Provider
      value={{
        chatHistory,
        setChatHistory,
        pageId,
        isSessionStorage: options.isSessionStorage,
      }}
    >
      {children}
    </ChatHistoryContext.Provider>
  );
};

export const useChatHistoryContext = () => useContext(ChatHistoryContext);

// 옵션을 변경할 수 있는 함수 (필요한 경우)
export const setChartHistoryOptions = (newOptions) => {
  Object.assign(options, newOptions);
};
