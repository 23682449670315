import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import useDebug from '@hooks/useDebug';
import {
  getToken,
  validateToken,
  refreshToken,
  validateUserToken, // 수신한 token을 검사하는 함수
  refreshUserToken, // token을 갱신하는 함수
  getAccessTokenFromLocalStorage, // 로컬 스토리지에서 accessToken을 가져오는 함수
  getRefreshTokenFromLocalStorage, // 로컬 스토리지에서 refreshToken을 가져오는 함수
  setAccessTokenToLocalStorage, // 로컬 스토리지에서 accessToken을 저장하는 함수
  setRefreshTokenToLocalStorage, // 로컬 스토리지에서 refreshToken을 저장하는 함수
  removeAccessTokenFromLocalStorage, // 로컬 스토리지에서 accessToken을 제거하는 함수
  removeRefreshTokenFromLocalStorage, // 로컬 스토리지에서 refreshToken을 제거하는 함수
  logoutProcess,
  //----------------------------------------------------------
  // NOTICEcross-origin 인증 관련 함수
  //----------------------------------------------------------
  exchangeAuthCode, // ! 새로 추가: auth_code를 JWT로 교환하는 함수
  setCrossOriginAccessTokenToLocalStorage,
  setCrossOriginRefreshTokenToLocalStorage,
  getCrossOriginAccessTokenFromLocalStorage,
  getCrossOriginRefreshTokenFromLocalStorage,
  removeCrossOriginAccessTokenFromLocalStorage,
  removeCrossOriginRefreshTokenFromLocalStorage,
  isCrossOriginUser,
} from '@api/authService';
// Axios의 Authorization 헤더 설정 및 제거
import { setAuthToken, removeAuthToken } from '@api/axiosConfig';
// JWT 디코딩 함수
import { manualJwtDecode } from '@api/authUtils';
// token 검증 관련 함수
import { TokenVerificationError, ERROR_CODES } from '@api/tokenVerification';
// auth 단계에서 사용하는 theme 관련 API: 테마 정보를 수신한다.
import { getThemeInfo } from '@api/theme/themeService';

// 커스텀 Hook import
import { useLocation, useNavigate } from '@hooks/useRouter';

const AuthContext = createContext(null);

// NOTICE: 플러그인 빌드 여부 확인
const isPluginBuild = import.meta.env.VITE_IS_PLUGIN_BUILD === 'true';

export const AuthProvider = ({ children }) => {
  const { debug } = useDebug('AuthProvider');
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [authInitialized, setAuthInitialized] = useState(false);
  const [themeInfo, setThemeInfo] = useState(null);

  /**
   * cross-origin SSO 인증 코드 처리
   */
  const processAuthCode = useCallback(
    async (authCode) => {
      if (!authCode) return false;

      debug('i:*:SSO auth_code 발견:', authCode);
      try {
        const response = await exchangeAuthCode(authCode);
        if (response.token) {
          setAccessTokenToLocalStorage(response.token);
          if (response.refresh_token) {
            setRefreshTokenToLocalStorage(response.refresh_token);
          }

          setCrossOriginAccessTokenToLocalStorage(response.token);
          if (response.refresh_token) {
            setCrossOriginRefreshTokenToLocalStorage(response.refresh_token);
          }

          await initializeAuth();
          return true;
        }
      } catch (error) {
        debug('e:*:auth_code 교환 실패:', error);
        notLoggedInAuth();
      }
      return false;
    },
    [debug],
  );

  // auth_code 처리를 포함한 초기화
  useEffect(() => {
    initializeAuth();
  }, []);

  /**
   * initializeAuth: 인증 초기화 함수로, 토큰을 확인하고 유효한지 검증 후 사용자 정보를 설정한다.
   * 만약 토큰이 없으면 getToken을 통해 새 토큰을 받아온다.
   */
  const initializeAuth = async () => {
    debug('i:*:initializeAuth 시작: ', {
      isCrossOriginUser: isCrossOriginUser(),
      location,
    });

    let token = getAccessTokenFromLocalStorage();

    // WARN: cross-origin 인증 사용자인 경우, getToken()를 통해서 token 생성을 하지 않는다.
    // ! - 반드시 AuthProvider()에서 auth_code + exchangeAuthCode()를 통해서 cross-origin 인증 토큰을 사용한다.
    if (!token && !isCrossOriginUser()) {
      debug('i:*:토큰 생성 시도');
      try {
        const response = await getToken();
        debug('i:*:getToken response:', response);

        // response.data 객체에서 token을 추출
        token = response.token;
        const refreshToken = response.refresh_token;

        if (token) {
          debug('Token extracted successfully:', token);
          setAccessTokenToLocalStorage(token);
          if (refreshToken) {
            setRefreshTokenToLocalStorage(refreshToken);
          }
        } else {
          throw new Error('토큰이 응답 데이터에 없습니다.');
        }
      } catch (error) {
        debug('토큰 검색 실패:', error);
        if (error.error_code === 'NOT_LOGGED_IN') {
          // ! 아직 로그인하지 않은 사용자인 경우, token이 존재하지 않는다.
          // ! 안전하게 invalidateAuth 처리: token을 local storage에서 제거한다.
          // ! setUser(null): 사용자 정보 초기화
          // WARN: 비 로그인 사용자인 경우, 이곳에서 themeInfo를 초기화 하지 않는다.
          debug('i:비 로그인 사용자 - NOT_LOGGED_IN');
          notLoggedInAuth();
        } else if (error.error_code === 'INVALID_USER') {
          // ! session은 존재하지만, 현재 사용자 정보와 session의 사용자 정보가 일치하지 않는 경우
          // ! 안전하게 logout 처리: token을 local storage에서 제거한다.
          // WARN: 잘못된 사용자인 경우, token, user, themeInfo를 초기화 한다.
          debug('e:잘못된 사용자 - INVALID_USER');
          logout();
        }

        // ! 실패 시, auth 초기화 완료 표시
        // ! - 실패 시에도, authInitialized를 true로 설정한다.
        setAuthInitialized(true);
        setLoading(false);
        return;
      }
    }

    // NOTICE: 토큰이 존재하거나, 신규로 토큰을 생성 시에만 token 검증을 진행한다.
    // - token이 존재하면 token 검증을 진행한다.
    // - token이 존재하지 않으면 token 생성 후, token 검증을 진행한다.
    // - token 생성이 싪패하면 token 검증을 하지 않는다.
    // ! - 비 로그인 회원인 경우에는 token 생성을 하지 않는다.
    try {
      const result = validateUserToken(token);
      debug('s:*:');
      debug('토큰 검증 성공');
      debug('validateUserToken result:', result);
      debug('s:*:');
      // ! result.data에 사용자 정보가 전달된다.
      setUser(result.data);
    } catch (error) {
      console.error('토큰 검증 실패:', error);

      if (error instanceof TokenVerificationError) {
        switch (error.code) {
          // !토큰이 만료되었거나 너무 오래된 경우에만 갱신 시도
          case ERROR_CODES.EXPIRED:
          case ERROR_CODES.TOKEN_TOO_OLD:
            try {
              const result = await refreshUserToken();
              setUser(result.data);
            } catch (refreshError) {
              console.error('토큰 갱신 실패:', refreshError);
              setUser(null);
              // 추가적인 에러 처리 (예: 로그아웃)
            }
            break;
          default:
            // 다른 종류의 토큰 검증 오류
            console.error('토큰 검증 오류:', error);
            setUser(null);
          // 필요한 경우 추가 처리
        }
      } else {
        // TokenVerificationError가 아닌 다른 오류
        setUser(null);
        console.error('예상치 못한 오류:', error);
      }
    } finally {
      // NOTICE: token이 존재하는 경우, token 검증 완료 후, auth 초기화 완료 표시
      // ! - 토큰 검증 실패 시에도, authInitialized를 true로 설정한다.
      setAuthInitialized(true); // 인증 초기화 완료 표시
    }

    // try {
    //   // 인증 초기화 후 theme 정보 가져오기
    //   await fetchThemeInfo();
    // } catch (error) {
    //   console.error('인증 초기화 중 오류 발생:', error);
    // } finally {
    //   setLoading(false);
    //   setAuthInitialized(true); // 인증 초기화 완료 표시
    // }
  };

  const fetchThemeInfo = async () => {
    try {
      const response = await getThemeInfo();
      setThemeInfo(response);
      // user 정보 업데이트
      if (response.user) {
        setUser((prevUser) => ({
          ...prevUser,
          ...response.user,
          ...response.member,
        }));
      }
      setIsLoggedIn(!!response.user);
    } catch (error) {
      console.error('테마 정보 가져오기 실패:', error);
      if (error.error_code === 'INVALID_USER') {
        invalidateAuth();
      }
    }
  };

  // 새로운 함수 추가: 인증이 필요한 API 호출 시 사용
  const getAccessToken = () => {
    return getAccessTokenFromLocalStorage();
  };

  // 새로운 함수 추가: 인증이 필요한 API 호출 시 사용
  const getRefreshToken = () => {
    return getRefreshTokenFromLocalStorage();
  };

  /**
   * logout: 로그아웃 함수로, 사용자 정보를 초기화하고 JWT 토큰을 제거한다.
   * TODO: 현재 로그아웃 후, 브라우저를 reload하면 다시 로그인 상태가 된다.
   * ! 로그아웃된 사용자는 다시 로그인을 하기 전까지는 인증 상태가 유지되지 않는다. 즉 로그인 처리를 하면 안된다.
   */
  const logout = useCallback(async () => {
    const userId = user?.mb_id;
    if (isCrossOriginUser()) {
      try {
        const response = await logoutProcess();
        debug('i:*:logoutProcess response:', response);
      } catch (error) {
        debug('e:*:logoutProcess 실패:', error);
      }
    }
    setUser(null);
    setIsLoggedIn(false);
    setThemeInfo(null);
    removeAccessTokenFromLocalStorage();
    removeRefreshTokenFromLocalStorage();
    debug('s:*:로그아웃 완료: ', userId);
  }, [user, logoutProcess, debug]); // 의존성 배열 단순화

  /**
   * 비회원 사용자에 대한 인증 처리
   */
  const notLoggedInAuth = () => {
    setUser(null); // 사용자 상태 초기화
    removeAccessTokenFromLocalStorage(); // 로컬 스토리지에서 토큰 삭제
    removeRefreshTokenFromLocalStorage(); // 로컬 스토리지에서 토큰 삭제
    // ? 사용자 정보를 local storage에 저장을 하는 것이 좋은가??
    debug('s:비회원 ( NOT_LOGGED_IN_USER) 인증 무효화 완료');
  };

  /**
   * 비인가, 비검증 사용에 대한 처리
   */
  const invalidateAuth = () => {
    setUser(null); // 사용자 상태 초기화
    removeAccessTokenFromLocalStorage(); // 로컬 스토리지에서 토큰 삭제
    removeRefreshTokenFromLocalStorage(); // 로컬 스토리지에서 토큰 삭제
    // ? 사용자 정보를 local storage에 저장을 하는 것이 좋은가??
    // removeUserFromLocalStorage(); // 로컬 스토리지에서 사용자 정보 삭제
    debug('s:잘못된 사용자( INVALID_USER) 인증 무효화 완료');
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        themeInfo,
        setThemeInfo,
        loading,
        isLoggedIn,
        setIsLoggedIn,
        logout,
        getAccessToken,
        getRefreshToken,
        invalidateAuth,
        authInitialized,
        fetchThemeInfo,
        processAuthCode, // auth_code 처리 함수를 context value로 노출
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

/**
 * useAuth: AuthContext의 값을 쉽게 사용할 수 있도록 하는 커스텀 훅.
 */
export const useAuth = () => useContext(AuthContext);
