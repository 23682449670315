import {
  US,
  KR,
  JP,
  DE,
  ES,
  SA,
  FR,
  BR,
  RU,
  CN,
  TW,
  BD,
  CZ,
  GR,
  FI,
  IN,
  ID,
  MY,
  TH,
  KG,
  LT,
  LV,
  NL,
  PL,
  TR,
  VN,
} from 'country-flag-icons/react/3x2';

export const SUPPORTED_LANGUAGES = [
  { icon: US, language: 'English', langCode: 'en' },
  { icon: KR, language: '한국어', langCode: 'ko' },
  { icon: JP, language: '日本語', langCode: 'ja' },
  { icon: DE, language: 'Deutsch', langCode: 'de' },
  { icon: ES, language: 'Español', langCode: 'es' },
  { icon: SA, language: 'العربية', langCode: 'ar' },
  { icon: FR, language: 'Français', langCode: 'fr' },
  { icon: BR, language: 'Português', langCode: 'pt' },
  { icon: RU, language: 'Русский', langCode: 'ru' },
  { icon: CN, language: '简体中文', langCode: 'zh-CN' },
  { icon: TW, language: '繁體中文', langCode: 'zh-TW' },
  { icon: BD, language: 'বাংলা', langCode: 'bn' },
  { icon: CZ, language: 'čeština', langCode: 'cs' },
  { icon: GR, language: 'ελληνικά', langCode: 'el' },
  { icon: FI, language: 'suomi', langCode: 'fi' },
  { icon: IN, language: 'हिन्दी', langCode: 'hi' },
  { icon: ID, language: 'Bahasa Indonesia', langCode: 'id' },
  { icon: MY, language: 'Bahasa Melayu', langCode: 'ms' },
  { icon: TH, language: 'ไทย', langCode: 'th' },
  { icon: KG, language: 'Кыргызча', langCode: 'ky' },
  { icon: LT, language: 'lietuvių kalba', langCode: 'lt' },
  { icon: LV, language: 'Latviešu valoda', langCode: 'lv' },
  { icon: NL, language: 'Nederlands', langCode: 'nl' },
  { icon: PL, language: 'polski', langCode: 'pl' },
  { icon: TR, language: 'Türkçe', langCode: 'tr' },
  { icon: VN, language: 'Tiếng Việt', langCode: 'vi' },
];
