export const HeaderMenuItem = ({ item, isCollapsed, onClick = null }) => {
  return (
    <div className='relative'>
      <button
        type='button'
        onClick={onClick}
        className={`group relative flex min-w-fit items-center gap-2 text-light-700 hover:text-light-900 dark:text-dark-300 dark:hover:text-dark-primary 
            ${isCollapsed ? 'w-[40px] justify-center' : 'w-full'} 
            transition duration-200 ease-in-out`}
      >
        <div className='relative z-10 flex items-center gap-2'>
          <span className='inline-flex h-6 w-6 items-center justify-center'>
            {item.icon}
          </span>
          <span
            className={`text-sm font-semibold ${isCollapsed ? 'hidden' : ''}`}
          >
            {item.title}
          </span>
        </div>
        {item.badge && (
          <span className='flex-center absolute left-3 top-0 z-20 flex h-3.5 w-auto min-w-fit shrink-0 rounded-full bg-red-600 px-1 text-[11px] font-bold text-white'>
            {item.badge}
          </span>
        )}
      </button>
    </div>
  );
};
