import React, { useEffect } from 'react';
import useDebug from '@hooks/useDebug';

const ImageArea = ({
  imageEl,
  imageMetadata,
  onClose,
  zoomLevel,
  onZoom,
  aspectRatio,
}) => {
  const { debug } = useDebug('ImageArea');
  const { cdnUrl, ObjectUrl } = imageMetadata;
  const imageUrl = cdnUrl || ObjectUrl;

  useEffect(() => {
    debug('Check imageArea params', {
      imageUrl,
      imageEl,
      imageMetadata,
      aspectRatio,
    });
  }, []);

  const getImageClass = () => {
    switch (zoomLevel) {
      case 2:
        return 'transform scale-125'; // 이미지 확대
      default:
        return 'transform scale-100'; // 기본 또는 전체 화면
    }
  };

  const getContainerClass = () => {
    switch (zoomLevel) {
      case 0:
        return 'relative h-full w-full mid:w-[calc(100%-300px)] transition-all duration-300';
      default:
        return 'relative h-full w-full transition-all duration-300';
    }
  };

  const getCursorClass = () => {
    switch (zoomLevel) {
      case 2:
        return 'cursor-zoom-out';
      default:
        return 'cursor-zoom-in';
    }
  };

  return (
    <div className={`${getContainerClass()} bg-light-bg dark:bg-dark-bg`}>
      {/* 이미지 영역 */}
      <div
        className={`h-full w-full bg-light-50/50 dark:bg-dark-bg`}
        onClick={onZoom}
      >
        <div
          className={`flex h-full w-full items-center justify-center overflow-hidden p-4`}
        >
          <img
            src={imageUrl}
            alt={imageMetadata.alt || ''}
            className={`max-h-full max-w-full ${getImageClass()} rounded-xl transition-transform duration-200 dark:shadow-dark-700 ${getCursorClass()}`}
          />
        </div>
      </div>

      {/* 닫기 버튼 */}
      <button
        className='absolute right-4 top-4 z-10 flex h-8 w-8 items-center justify-center rounded-full bg-transparent text-light-900 transition duration-200 ease-in-out hover:bg-light-100 dark:text-dark-50 hover:dark:bg-dark-700/75'
        onClick={onClose}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        >
          <path d='M18 6 6 18' />
          <path d='m6 6 12 12' />
        </svg>
      </button>
    </div>
  );
};

export default ImageArea;
