import React from 'react';
import useDebug from '@hooks/useDebug';
import {
  getAspectByRatio,
  getAspectByRatioClass,
  getAspectByRatioFromFalImageSize,
} from '@utils/image/imageUtils';
import ResponsiveImageGrid from './ResponsiveImageGrid';

/**
 * AI가 생성한 이미지 메시지 컴포넌트
 * - ResponsiveImageGrid를 사용하여 이미지 표시
 * - 채팅 UI에 맞게 스타일링
 */
const ImageMessage = ({
  images,
  apiMetadata,
  promptMetadata,
  isGeneratingImage,
  isGenerating,
  onSubmit,
  lang,
  currentLang,
}) => {
  const { debug } = useDebug('ImageMessage');

  if (!images || images.length === 0) {
    return null;
  }

  const apiByRatio =
    apiMetadata.api_provider === 'fal'
      ? getAspectByRatioFromFalImageSize(apiMetadata.image_size)
      : null;

  // 이미지 데이터 전처리
  const processedImages = images.map((image, index) => {
    const aspectByRatio = apiByRatio
      ? apiByRatio
      : getAspectByRatio(image.aspectRatio);
    const aspectRatioClass = getAspectByRatioClass(aspectByRatio);
    const imageUrl = image.cdn_url ? image.cdn_url : image.object_url;
    const imageParams = {
      url: imageUrl || null,
      alt: image.alt || '',
      aspectRatio: aspectByRatio,
      className: `relative overflow-hidden rounded-lg ${aspectRatioClass}`,
    };
    if (isGeneratingImage) {
      imageParams.isGeneratingImage = true;
      debug('Check imageParrams', imageParams);
    }

    return imageParams;
  });

  return (
    <div className='mr-auto flex w-full max-w-[85%] flex-col items-start justify-start gap-2 lg:max-w-[75%]'>
      <div className='w-full rounded-lg bg-light-50 p-2 shadow-sm dark:bg-dark-800/50 dark:shadow-dark-900/20'>
        <ResponsiveImageGrid
          images={processedImages}
          promptId={promptMetadata.external_prompt_id}
          onSubmit={onSubmit}
          isGeneratingImage={isGeneratingImage}
          selectionMode='hover'
        />
      </div>
    </div>
  );
};

export default ImageMessage;
