import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react';
import useDebug from '@hooks/useDebug';
// react query
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
// API 설정
import { API_CONFIG, getApiPath } from '@/api/apiConfig';
import { sendPost } from '@/api/apiService';
import { getAccessTokenFromLocalStorage } from '@/api/authService';
// 사용자 인증 context
import { useAuth } from '@contexts/auth/AuthContext';
// 테마 정보 관리를 위한 context
import { useThemeContext } from '@contexts/theme/ThemeContext';
// image AI service context
import { useImageAIServiceContext } from '@contexts/ai/service/imageAI/ImageAIServiceContext';

const CreatedImagesContext = createContext();

export const useCreatedImagesContext = () => useContext(CreatedImagesContext);

/**
 * CreatedImagesProvider 컴포넌트
 * @param {Object} param0 - 자식 컴포넌트
 * @returns {JSX.Element} - 자식 컴포넌트
 */
export const CreatedImagesProvider = ({ children }) => {
  const { debug } = useDebug('CreatedImagesContext');
  const queryClient = useQueryClient();
  const { user, authInitialized } = useAuth() || {};
  const { themeInfo, themeIs, themeConfig } = useThemeContext() || {};

  // 이미지 목록 수신 및 생성 권한 상태
  const [isCreatedImagesAuth, setIsCreatedImagesAuth] = useState(false);
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  const {
    pageId,
    prepareImageGeneration,
    generateImage,
    isProcessing,
    setIsProcessing,
    error,
    generatedImages,
    apiType,
    defaultApiConfig,
  } = useImageAIServiceContext();

  // INFO: 이미지 생성 권한 확인
  // - 이미지 생성 권한을 가진 사용자만, 이미지 목록 조회 및 이미지 생성 가능
  useEffect(() => {
    if (authInitialized && themeIs?.isMember !== undefined) {
      debug('권한 확인 상태:', {
        authInitialized,
        user,
        isMember: themeIs.isMember,
        isCreatedImagesAuth,
      });

      if (user && user.mb_id && themeIs.isMember) {
        debug('i:*:이미지 생성 권한 있음: : ', user.mb_id);
        setIsCreatedImagesAuth(true);
      } else {
        debug('i:s:이미지 생성 권한 없음, 이미지 목록 조회 불가');
        setIsCreatedImagesAuth(false);
      }
      setIsAuthChecked(true);
    }
  }, [authInitialized, themeIs?.isMember, user, debug]);

  /**
   * 생성된 이미지 목록을 가져오는 API 호출 함수
   * @param {Object} params - API 호출 파라미터
   * @param {number} [params.pageParam] - 현재 페이지 번호 (기본값: 1)
   * @returns {Promise<Object>} API 응답 데이터
   */
  const fetchCreatedImages = useCallback(
    async (params) => {
      if (!isCreatedImagesAuth) {
        debug(
          'e:*:fetchCreatedImages(): 이미지 생성 권한 없음: 이미지 목록 조회 불가',
        );
        return { list: [], nextPage: undefined, totalPages: 0, hasMore: false };
      }
      let errorMsg = '';

      const accessToken = getAccessTokenFromLocalStorage();

      const headers = {};
      // ! POOS, GET 방식에 상관없이, token을 전달할 때, header에 token을 포함하여 전달한다.
      // INFO:
      // - 요청에서 Authorization 헤더를 사용하는 것이 좋습니다.
      // - 1. 보안: URL 파라미터로 토큰을 전송하는 것보다 헤더를 통해 전송하는 것이 더 안전합니다. URL은 서버 로그나 브라우저 히스토리에 남을 수 있기 때문입니다.
      // - 2. RESTful 관행: Authorization 헤더를 사용하는 것은 RESTful API의 일반적인 관행입니다.
      // - 3. 유연성: 헤더를 사용하면 GET, POST, PUT 등 모든 HTTP 메소드서 일관된 방식으로 인증을 처리할 수 있습니다.
      if (accessToken) {
        headers['Authorization'] = `Bearer ${accessToken}`;
      } else {
        errorMsg = '사용자 생성 이미지 목록을 수신 시, 토큰이 필요합니다.';
        errorMsg += '토큰이 없습니다.';
        throw new Error(errorMsg);
      }

      debug('i:fetchCreatedImages(): 이미지 목록 수신을 시작합니다.: ', params);

      const { pageParam = 1 } = params || {};
      try {
        const limit = 20; // 한 페이지당 가져올 항목 수
        const result = await sendPost({
          url: getApiPath('imageAi'),
          data: {
            request_type: 'list',
            list_type: 'prompt_list',
            user_id: user.mb_id,
            page: pageParam,
            limit: limit,
          },
          config: {
            withCredentials: true,
            headers: headers,
          },
          useImageAI: true,
        });

        if (result.status === 'success') {
          // 서버에서 제공하는 경우 has_more와 total_pages 사용
          const serverHasMore =
            'has_more' in result.data ? result.data.has_more : null;
          const serverTotalPages =
            'total_pages' in result.data ? result.data.total_pages : null;

          // 라이언트에서 hasMore 계산
          const clientHasMore = result.data.list.length === limit;
          const returnValue = {
            list: result.data.list,
            nextPage: (serverHasMore !== null ? serverHasMore : clientHasMore)
              ? pageParam + 1
              : undefined,
            totalPages: serverTotalPages,
            hasMore: serverHasMore !== null ? serverHasMore : clientHasMore,
          };
          debug('i:fetchCreatedImages() - 이미지 목록 수신 결과: ', {
            received: result.data,
            returnValue,
          });
          return returnValue;
        } else {
          throw new Error(
            'Fetch created images failed: ' +
              (result.message || 'Unknown error'),
          );
        }
      } catch (error) {
        debug('e:*:fetchCreatedImages(): Error: ', error);
        throw error;
      }
    },
    [isCreatedImagesAuth, debug],
  );

  /**
   * * useInfiniteQuery 훅 사용
   * - 무한 스크롤을 위한 데이터 fetching 관리
   * WARN: enabled 옵션을 사용하여, 이미지 생성 권한이 없는 경우 쿼리 비활성화
   * ! - isCreatedImagesAuth가 true인 경우, 쿼리가 활성화된다.
   * INFO: 아래는 React Query의 동작 원리
   * 1. queryKey: 이 배열의 어떤 요소라도 변경되면, React Query는 이를 새로운 쿼리로 인식하고 queryFn을 다시 실행합니다. 여기서 isCreatedImagesAuth가 변경되면 쿼리가 다시 실행됩니다.
   * 2. queryFn: 이 함수 자체가 변경되면 (예: 의존성이 변경되어 새로운 함수 인스턴스가 생성되면), React Query는 이를 감지하고 필요에 따라 쿼리를 다시 실행할 수 있습니다.
   * 3. getNextPageParam: 이 함수도 변경되면 React Query가 감지하지만, 일반적으로 이 함수는 자주 변경되지 않습니다.
   * enabled: 이 옵션이 false에서 true로 변경되면, React Query는 자동으로 쿼리 실행합니다. true에서 false로 변경되면, 쿼리 실행이 중지됩니다.
   * 따라서, isCreatedImagesAuth 값이 변경될 때:
   * queryKey가 변경되어 새로운 쿼리로 인식됩니다.
   * enabled 옵션이 변경되어 쿼리의 활성화 상태가 변경됩니다.
   * 이러한 변경은 자동으로 감지되어 적절한 동작(쿼리 재실행 또는 중지)을 트리거합니다. 이는 마치 useEffect의 의존성 배열이 변경될 때 효과가 다시 실행되는 것과 유사한 방식으로 작동합니다.
   * 이 방식을 통해 React Query는 상태 변화에 따라 자동으로 데이터를 동기화하고, 필요한 시점에 쿼리를 실행하거나 지할 수 있게 됩니다.
   */
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    error: queryError,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['createdImages', isCreatedImagesAuth],
    queryFn: fetchCreatedImages,
    getNextPageParam: (lastPage) => lastPage.nextPage,
    enabled: isCreatedImagesAuth && isAuthChecked,
    initialData: { pages: [], pageParams: [] },
  });

  // ! isCreatedImagesAuth가 변경될 때 쿼리를 다시 실행
  useEffect(() => {
    if (isCreatedImagesAuth && isAuthChecked) {
      refetch();
    }
  }, [isCreatedImagesAuth, isAuthChecked, refetch]);

  const createdImages = useMemo(() => {
    if (!isCreatedImagesAuth || !isAuthChecked) return [];
    return data ? data.pages.flatMap((page) => page.list) : [];
  }, [data, isCreatedImagesAuth, isAuthChecked]);

  /**
   * 현재 생성된 이미지 가져오기
   * @param {string} promptId - 프롬프트 id
   * @returns {object|undefined} - 생성된 이미지 또는 undefined
   */
  const currentCreatedImage = useCallback(
    (promptId) => {
      if (!data) return undefined;
      return data.pages
        .flatMap((page) => page.list)
        .find((image) => image.prompt_metadata.external_prompt_id === promptId);
    },
    [data],
  );

  /**
   * 이미지 생성 목록에 새 항목 추가 (Optimistic UI 구현)
   *
   * @param {Object} params - 추가할 이미지 정보
   * @param {string} params.promptId - 프롬프트 id
   * @param {string} params.imageSize - 이미지 크기
   * @param {Object} params.apiOptions - 이미지 생성 옵션
   * @param {Object} params.promptMetadata - 프롬프트 메타데이터
   * @returns {Array} 업데이트된 이미지 목록
   */
  const addImageGenerationList = useCallback(
    ({ promptId, imageSize, apiOptions, promptMetadata }) => {
      const promptCreatedAt = new Date(
        parseInt(promptId.replace('prompt-', '')),
      )
        .toISOString()
        .replace('T', ' ')
        .slice(0, 19);

      const newImage = {
        prompt_metadata: {
          internal_prompt_id: null,
          external_prompt_id: promptId,
          base_prompt: promptMetadata.base_prompt,
          final_prompt: promptMetadata.final_prompt,
          original_prompt: promptMetadata.original_prompt,
          original_language: promptMetadata.original_language,
          user_id: user.mb_id,
          prompt_created_at: promptCreatedAt,
        },
        api_metadata: {
          api_provider: apiOptions.api_provider || 'fal',
          model: apiOptions.model,
          image_size: apiOptions.image_size,
          num_inference_steps: apiOptions.num_inference_steps,
          seed: apiOptions.seed,
          guidance_scale: apiOptions.guidance_scale,
          sync_mode: apiOptions.sync_mode ? 1 : 0,
          enable_safety_checker: apiOptions.enable_safety_checker ? 1 : 0,
        },
        images: Array(apiOptions.num_images || 1).fill({}),
        is_generating: true,
      };

      queryClient.setQueryData(
        ['createdImages', isCreatedImagesAuth],
        (old) => {
          if (!old) return { pages: [{ list: [newImage] }], pageParams: [1] };
          return {
            ...old,
            pages: [
              { ...old.pages[0], list: [newImage, ...old.pages[0].list] },
              ...old.pages.slice(1),
            ],
          };
        },
      );

      debug('addImageGenerationList', { promptId, imageSize, apiOptions });
    },
    [queryClient, isCreatedImagesAuth, user, debug],
  );

  /**
   * 생성된 이미지 목록 데이트 (서버 응답 반영)
   * @param {Object} params - 업데이트할 이미지 정보
   */
  const updateCreatedImageList = useCallback(
    ({ promptId, newImageData }) => {
      debug('i:updateCreatedImageList', { promptId, newImageData });
      queryClient.setQueryData(
        ['createdImages', isCreatedImagesAuth],
        (old) => {
          if (!old) return old;
          const updatedPages = old.pages.map((page) => ({
            ...page,
            list: page.list.map((image) =>
              image.prompt_metadata.external_prompt_id === promptId
                ? {
                    ...image,
                    prompt_metadata: {
                      ...image.prompt_metadata,
                      internal_prompt_id: newImageData.internal_prompt_id,
                    },
                    api_metadata: {
                      ...image.api_metadata,
                      ...newImageData.apiOptions,
                    },
                    images: newImageData.images.map((img, index) => ({
                      title: null,
                      s3_key:
                        newImageData.s3UploadResults[index]?.s3Meta?.s3_key ||
                        img.src.split('/').pop(),
                      cdn_url:
                        newImageData.s3UploadResults[index]?.s3Meta?.cdn_url ||
                        img.src,
                      image_id: img.id,
                      is_public: 1,
                      object_url:
                        newImageData.s3UploadResults[index]?.s3Meta?.object_url,
                      content_type:
                        newImageData.s3UploadResults[index]?.s3Meta
                          ?.content_type || img.content_type,
                      created_at: newImageData.s3UploadResults[index]?.s3Meta
                        ?.last_modified
                        ? newImageData.s3UploadResults[
                            index
                          ].s3Meta.last_modified
                            .slice(0, 19)
                            .replace('T', ' ')
                        : new Date()
                            .toISOString()
                            .slice(0, 19)
                            .replace('T', ' '),
                    })),
                    tags: newImageData.tags || ['AI', 'Generated', 'Image'],
                    is_generating: false,
                  }
                : image,
            ),
          }));
          return { ...old, pages: updatedPages };
        },
      );

      // 쿼리 무효화 추가
      queryClient.invalidateQueries(['createdImages', isCreatedImagesAuth]);
    },
    [queryClient, isCreatedImagesAuth, debug],
  );

  /**
   * 생성 중인 이미지 제거 (에러 발생 시)
   * @param {string} promptId - 제거할 이미지의 promptId
   */
  const removeCreatingImageByPromptId = useCallback(
    (promptId) => {
      queryClient.setQueryData(
        ['createdImages', isCreatedImagesAuth],
        (old) => {
          debug('i:*:removeCreatingImageByPromptId: old: ', old);
          if (!old) return old;
          const updatedPages = old.pages.map((page) => ({
            ...page,
            // 해당 promptId를 가진 이미지를 목록에서 제거
            list: page.list.filter(
              (image) => image.prompt_metadata.external_prompt_id !== promptId,
            ),
          }));
          debug(
            'i:*:removeCreatingImageByPromptId: updatedPages: ',
            updatedPages,
          );
          return { ...old, pages: updatedPages };
        },
      );
    },
    [queryClient, isCreatedImagesAuth, debug],
  );

  /**
   * 이미지 생성 제출 핸들러
   * @param {Object} selectedModel - 선택된 모델 정보
   * @param {string} prompt - 입력된 프롬프트
   * @param {Object} settings - 이미지 생성 설정
   */
  const handleSubmit = useCallback(
    async (
      selectedModel,
      prompt,
      settings,
      originalPrompt = '', // ! 원본 프롬프트, 원본 프롬프트의 언어가 영여가 아닌 경우에만 존재한다.
      originalLanguage = 'en', // 원본 프롬프트의 언어
    ) => {
      if (!isCreatedImagesAuth) {
        debug('e:*:FATAL ERROR: 이미지 생성 권한이 없습니다.');
        throw new Error('이미지 생성 권한이 없습니다.');
      }
      const userId = user.mb_id;
      if (!userId) {
        debug('e:*:FATAL ERROR: 사용자 정보가 없습니다.');
        throw new Error('사용자 정보가 없습니다.');
      }

      setIsGenerating(true);
      let errorMsg = '';
      if (!settings.seed) {
        settings.seed = Date.now();
      }

      const apiOptions = {
        ...settings,
        prompt,
        model: selectedModel.model,
      };

      let basePrompt = apiOptions.prompt;
      if (originalPrompt !== 'en') {
        basePrompt = originalPrompt;
      }

      // 다국어 프롬프트 지원을 위해서 promptMetadata 추가
      const promptMetadata = {
        base_prompt: basePrompt,
        final_prompt: apiOptions.prompt,
        original_prompt: originalPrompt,
        original_language: originalLanguage,
      };

      debug('i:handleSubmit called:generateImage()', {
        selectedModel,
        prompt,
        settings,
        apiOptions,
        promptMetadata,
      });

      const { imageSize, promptId, messageId } =
        prepareImageGeneration(apiOptions);

      addImageGenerationList({
        promptId,
        imageSize,
        apiOptions,
        promptMetadata,
      });

      // 실제 이미지 생성 로직
      try {
        const result = await generateImage({
          prompt,
          apiOptions,
          promptId,
          messageId,
          promptMetadata,
          userId: user.mb_id,
        });
        if (result.status === 'success') {
          debug('s:handleSubmit:generateImage() success', result);
          updateCreatedImageList({ promptId, newImageData: result.data });
        } else {
          throw new Error(`이미지 생성 실패: ${result.details}`);
        }
      } catch (error) {
        errorMsg = `이미지를 생성할 수 없습니다.\n${error.message}`;
        debug('이미지 생성 실패: ', errorMsg);
        removeCreatingImageByPromptId(promptId);
      } finally {
        setIsGenerating(false);
      }
    },
    [
      isCreatedImagesAuth,
      user,
      prepareImageGeneration,
      generateImage,
      addImageGenerationList,
      updateCreatedImageList,
      removeCreatingImageByPromptId,
      debug,
    ],
  );

  useEffect(() => {
    debug('Check createdImages: ', createdImages);
  }, [createdImages]);

  /**
   * 이미지 삭제 후 상태 업데이트
   * @param {Object} params - 삭제 파라미터
   * @param {string} params.promptId - 프롬프트 ID
   * @param {string} params.imageId - 이미지 ID (특정 이미지만 삭제하는 경우)
   */
  const updateCreatedImagesAfterDelete = useCallback(
    ({ promptId, imageId }) => {
      debug('updateCreatedImagesAfterDelete called:', { promptId, imageId });

      queryClient.setQueryData(
        ['createdImages', isCreatedImagesAuth],
        (oldData) => {
          if (!oldData) return oldData;

          const updatedPages = oldData.pages.map((page) => {
            const updatedList = page.list.reduce((acc, imageData) => {
              // 해당 promptId를 찾음
              if (imageData.prompt_metadata.external_prompt_id === promptId) {
                // imageId가 제공된 경우 특정 이미지만 삭제
                if (imageId) {
                  // 이미지가 한 개만 남은 경우 프롬프트 전체 삭제
                  if (imageData.images.length <= 1) {
                    return acc;
                  }
                  // 특정 이미지만 필터링하여 삭제
                  const filteredImages = imageData.images.filter(
                    (img) => img.image_id !== imageId,
                  );
                  // 필터링된 이미지가 있는 경우만 추가
                  if (filteredImages.length > 0) {
                    acc.push({
                      ...imageData,
                      images: filteredImages,
                    });
                  }
                } else {
                  // imageId가 없는 경우 프롬프트 전체 삭제
                  return acc;
                }
              } else {
                // 다른 프롬프트는 그대로 유지
                acc.push(imageData);
              }
              return acc;
            }, []);

            return {
              ...page,
              list: updatedList,
            };
          });

          // 빈 페이지 제거
          const filteredPages = updatedPages.filter(
            (page) => page.list.length > 0,
          );

          return {
            ...oldData,
            pages: filteredPages,
          };
        },
      );

      // 쿼리 무효화 추가
      queryClient.invalidateQueries(['createdImages', isCreatedImagesAuth]);
    },
    [queryClient, isCreatedImagesAuth, debug],
  );

  // Context에 제공할 값들
  const value = {
    handleSubmit,
    createdImages,
    isLoading: status === 'loading',
    isError: status === 'error',
    error: queryError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    currentCreatedImage,
    fetchCreatedImages,
    refetchCreatedImages: refetch,
    isGenerating,
    isCreatedImagesAuth,
    isAuthChecked,
    updateCreatedImagesAfterDelete,
  };

  return (
    <CreatedImagesContext.Provider value={value}>
      {children}
    </CreatedImagesContext.Provider>
  );
};
