import React from 'react';

const NumberInput = ({ min, max, value, onChange, readonly }) => {
  return (
    <input
      type='number'
      min={min}
      max={max}
      value={value}
      onChange={(e) => onChange(Number(e.target.value))}
      readOnly={readonly}
      className={`
        w-full rounded-lg border-[0.5px] px-3 py-2 
        shadow-[0px_7px_21px_0px_rgba(51,_51,_51,_0.05)] outline-none
        dark:shadow-[0px_7px_21px_0px_rgba(0,_0,_0,_0.25)]
        ${
          readonly
            ? 'cursor-not-allowed border-light-300 bg-light-100 text-light-700 dark:border-dark-600 dark:bg-dark-700 dark:text-dark-300'
            : 'border-light-200 bg-light-input text-light-900 focus:border-light-500 dark:border-dark-700 dark:bg-dark-input dark:text-dark-100 dark:focus:border-dark-300'
        }
      `}
    />
  );
};

export { NumberInput as default };
