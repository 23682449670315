export function extractImageInfoWithUrl(imageUrl) {
  const url = new URL(imageUrl);
  const fullFilename = url.pathname.split('/').pop();
  const extension = fullFilename.split('.').pop();
  const filename = fullFilename.substring(0, fullFilename.lastIndexOf('.'));

  return {
    url: imageUrl,
    fullFilename: fullFilename,
    filename: filename,
    extension: extension,
  };
}

/**
 * 이미지 URL을 PHP 서버로 전송하여 base64 데이터를 반환합니다.
 * ! - 사용 예시) https://image.com/image.jpg 인 경우, 해당 이미지 주소에 이미지를 요청하여 base64 데이터를 반환합니다.
 * @param {string} imageUrl - 이미지 URL
 * @returns {Promise<string>} - base64 데이터
 */
export async function fetchImageBase64(serverUrl, imageUrl) {
  const response = await fetch(serverUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ url: imageUrl }),
  });

  if (!response.ok) {
    throw new Error('Failed to fetch image base64 data');
  }

  const responseData = await response.json();
  if (responseData.status === 'error') {
    throw new Error(responseData.msg);
  }
  return responseData;
}

/**
 * MIME 타입에서 파일 확장자를 추출하는 함수
 * @param {string} mimeType - MIME 타입
 * @return {string} 파일 확장자 (점 제외)
 */
export function getImageExtensionFromMimeType(mimeType) {
  const mimeToExtMap = {
    'image/jpeg': 'jpg',
    'image/jpg': 'jpg',
    'image/png': 'png',
    'image/gif': 'gif',
    'image/bmp': 'bmp',
    'image/webp': 'webp',
    'image/svg+xml': 'svg',
    'image/tiff': 'tiff',
    'image/x-icon': 'ico',
  };

  // MIME 타입에서 확장자 추출
  const extension = mimeToExtMap[mimeType.toLowerCase()];

  // 알 수 없는 MIME 타입의 경우 기본값 반환
  return extension || 'unknown';
}

// 파일 확장자로부터 MIME 타입을 결정하는 헬퍼 함수
export function getImageMimeTypeFromExtension(extension) {
  const mimeTypes = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    webp: 'image/webp',
    // 필요에 따라 더 많은 타입을 추가할 수 있습니다.
  };
  return mimeTypes[extension.toLowerCase()] || 'application/octet-stream';
}

// Base64 데이터를 Blob으로 변환하는 함수
export const base64ToBlob = (base64Data, mimeType) => {
  // Base64 헤더 제거
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
};

// 추가적인 이미지 관련 유틸리티 함수들을 여기에 추가할 수 있습니다.
