import React, { useEffect } from 'react';
import useDebug from '@hooks/useDebug';
import * as Avatar from '@radix-ui/react-avatar';
import {
  Portal,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import {
  CircleUserRound,
  CreditCard,
  CloudUpload,
  LogIn,
  LogOut,
  UserRoundPlus,
} from 'lucide-react';
import { getLoginUrl, getSignUpUrl } from '@config/service/serviceConfig';

const UserMenu = ({
  user = null,
  isMember = false,
  handleLogout = null,
  lang,
}) => {
  const { debug } = useDebug('ImageAIChatHeader/UserMenu');

  useEffect(() => {
    debug('i:Check props: ', { user, isMember, handleLogout, lang });
  }, [user, isMember, handleLogout, lang]);

  const menuItems = [
    {
      icon: CircleUserRound,
      title: lang('프로필관리'),
      action: '/manage-profile',
      type: 'link',
      disabled: false,
      active: false,
    },
    {
      icon: CreditCard,
      title: lang('구독관리'),
      action: '/manage-subscription',
      type: 'link',
      disabled: false,
      active: false,
    },
    {
      icon: CloudUpload,
      title: lang('업로드관리'),
      action: '/manage-uploads',
      type: 'link',
      disabled: false,
      active: false,
    },
    {
      icon: LogOut,
      title: lang('로그아웃'),
      action: handleLogout,
      type: 'function',
      disabled: false,
      active: true,
    },
  ];

  const logoutMenuItems = [
    {
      icon: LogIn,
      title: lang('로그인'),
      action: getLoginUrl(),
      type: 'link',
      disabled: false,
      active: true,
    },
    {
      icon: UserRoundPlus,
      title: lang('회원가입'),
      action: getSignUpUrl(),
      type: 'link',
      disabled: false,
      active: true,
    },
  ];

  const currentMenuItems = isMember ? menuItems : logoutMenuItems;

  return (
    <Menu as='div' className='relative inline-block text-left'>
      {user && isMember ? (
        <MenuButton as='div'>
          <Avatar.Root className='inline-flex h-[40px] w-[40px] cursor-pointer select-none items-center justify-center overflow-hidden rounded-full align-middle'>
            <Avatar.Image
              className='h-full w-full rounded-[inherit] object-cover'
              src={
                user.mb_photo
                  ? user.mb_photo
                  : 'https://images.unsplash.com/photo-1492633423870-43d1cd2775eb?&w=128&h=128&dpr=2&q=80'
              }
              alt={user.mb_nick}
            />
            <Avatar.Fallback
              className='text-violet11 leading-1 flex h-full w-full items-center justify-center bg-white text-[15px] font-medium'
              delayMs={600}
            >
              {user.mb_nick.substring(0, 2).toUpperCase()}
            </Avatar.Fallback>
          </Avatar.Root>
        </MenuButton>
      ) : (
        <MenuButton
          as='button'
          className='rounded-lg bg-gradient-to-r from-red-500 to-orange-500 px-4 py-2 text-xs font-semibold text-white hover:from-red-600 hover:to-orange-600'
        >
          {lang('시작하기')}
        </MenuButton>
      )}

      <Portal>
        <Transition
          as={React.Fragment}
          enter='transition ease-out duration-200'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-150'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <MenuItems className='fixed right-[40px] top-[60px] z-[1070] mt-2 w-max min-w-52 origin-top-right rounded-md bg-light-50 shadow-lg ring-1 ring-light-100 focus:outline-none dark:bg-dark-900 dark:ring-dark-800'>
            <div className='p-2'>
              {currentMenuItems
                .filter((item) => item.active)
                .map(({ icon: Icon, title, action, type, disabled }, index) => (
                  <MenuItem key={index} disabled={disabled}>
                    {({ active }) =>
                      type === 'link' ? (
                        <a
                          href={action}
                          className={`transition duration-200 ease-in-out ${
                            active ? 'bg-light-200/50 dark:bg-dark-700/50' : ''
                          } ${
                            disabled
                              ? 'cursor-not-allowed opacity-50'
                              : 'cursor-pointer'
                          } group flex w-full items-center rounded-md p-2 text-[13px] font-medium text-light-700 dark:text-dark-200`}
                        >
                          <Icon
                            size={18}
                            className={`mr-2 shrink-0 ${
                              active
                                ? 'text-light-600 dark:text-dark-400'
                                : 'text-light-400 dark:text-dark-600'
                            }`}
                            aria-hidden='true'
                          />
                          <span className='shrink-0'>{title}</span>
                        </a>
                      ) : (
                        <button
                          onClick={action}
                          disabled={disabled}
                          className={`${
                            active ? 'bg-light-200/50 dark:bg-dark-700/50' : ''
                          } ${
                            disabled
                              ? 'cursor-not-allowed opacity-50'
                              : 'cursor-pointer'
                          } group flex w-full items-center rounded-md p-2 text-[13px] font-medium text-light-700 dark:text-dark-200`}
                        >
                          <Icon
                            size={18}
                            className={`mr-2 ${
                              active
                                ? 'text-light-600 dark:text-dark-400'
                                : 'text-light-400 dark:text-dark-600'
                            }`}
                            aria-hidden='true'
                          />
                          {title}
                        </button>
                      )
                    }
                  </MenuItem>
                ))}
            </div>
          </MenuItems>
        </Transition>
      </Portal>
    </Menu>
  );
};

export default UserMenu;
