import axios from 'axios';

const getEnvValue = (key) => {
  if (typeof import.meta !== 'undefined' && import.meta.env) {
    return import.meta.env[key];
  }
  return null;
};

export const getDevEnv = () => {
  try {
    return typeof __DEV_ENV__ !== 'undefined' ? __DEV_ENV__ : 'prod';
  } catch {
    return 'prod';
  }
};

// NOTICE: API Gateway URL 설정
// ! - imageAIAgent의 경우, 3rd party 서버에서 호출되므로, API Gateway URL을 사용한다.
const API_GATEWAY_URL = 'https://api.supereasy.pro/v1'; // API Gateway URL
const DIRECT_API_URL = 'https://supereasy.pro'; // 직접 호출 URL
const IMAGE_AI_SERVICE_URL = {
  dev: 'https://dev.supereasy.co.kr',
  dev2: 'https://dev2.supereasy.co.kr',
  prod: DIRECT_API_URL,
};

export const API_CONFIG = {
  url: {
    base: {
      dev: 'http://localhost',
      prod: getEnvValue('VITE_MODE') === 'development' ? '' : DIRECT_API_URL,
    },
    imageAI: {
      dev: 'http://localhost',
      prod: {
        dev: 'https://dev.supereasy.co.kr',
        dev2: 'https://dev2.supereasy.co.kr',
        prod: DIRECT_API_URL,
        api: API_GATEWAY_URL,
      },
    },
  },
  mode: getEnvValue('VITE_MODE') || 'production',
  debug:
    getEnvValue('VITE_DEBUG_MODE') === true ||
    getEnvValue('VITE_DEBUG_MODE') === 'true',
  headers: {
    // NOTICE: 이미지 AI API 호출 시, API Key 필요
    common: {
      'X-Api-Key': import.meta.env.VITE_IMAGE_AI_API_KEY || 'default-api-key',
    },
  },
  paths: {
    dev: {
      login: '/login',
      // 인증 및 로그인 / 세션 유지
      getToken: '/gtgf/server/api/auth/get_token.php',
      validateToken: '/gtgf/server/api/auth/validate_token.php', // 추가
      refreshToken: '/gtgf/server/api/auth/refresh_token.php', // 추가
      logout: '/gtgf/server/api/auth/logout.php', // 추가
      exchangeCode: '/gtgf/server/api/auth/exchange_code.php',

      // 사용자 정보
      userInfo: '/gtgf/server/api/auth/user_info.php', // 추가
      getThemeInfo: '/gtgf/server/api/theme/get_theme_info.php', // 추가

      // 이미지 API
      imageAi: '/gtgf/server/api/ai/image/gtgf_image_ai.php',
      // 현재 이미지에 대한 자동 post
      autoPost: '/gtgf/server/api/ai/post/auto_post.php',
      // imageDownload: '/gtgf/server/image/gtgf.base64.imagefetcher.php',
      imageDownload: '/gtgf/server/api/base64/gtgf_base64_imagefetcher.php',
      translate: '/v1/trans',
    },
    api: {
      login: '/login',

      // Auth 관련 엔드포인트
      // - v1/auth
      getToken: '/auth/get_token',
      validateToken: '/auth/validate_token',
      refreshToken: '/auth/refresh_token',
      exchangeCode: '/auth/exchange_code',
      logout: '/auth/logout',
      // Theme
      // - v1/theme
      getThemeInfo: '/theme/get_info',
      // AI Image
      // - v1/aiimage
      imageAi: '/ai/image',
      // AI Post
      // - v1/aipost
      autoPost: '/ai/post',
      // 기타 유틸리티
      // 이미지 다운로드
      // - v1/base64
      imageDownload: '/base64',
      translate: '/v1/trans',
    },
  },
};

export const getDevHost = () => {
  return getDevEnv();
};

// 현재 환경(개발/운영)을 결정하는 함수
export const isDevelopment = () => {
  return getEnvValue('VITE_MODE') === 'development';
};

// isCrossOrigin
export const isCrossOrigin = () => {
  if (!isDevelopment() && getDevEnv() === 'api') {
    return true;
  }
  return false;
};

// 현재 도메인 기반으로 API base URL 생성
export const getBaseUrl = () => {
  if (getEnvValue('VITE_MODE') === 'development') {
    return '';
  }

  if (getDevEnv() === 'api') {
    return API_GATEWAY_URL;
  }
  // return window.location.origin;
  return DIRECT_API_URL;
};

export const getCdnUrl = (path = '') => {
  if (path) {
    // WARN: path에 '/'를 포함해서 전달이 되어야 한다.
    return `${import.meta.env.VITE_CDN_URL}${path}`;
  }
  return import.meta.env.VITE_CDN_URL || '';
};

export const getApiPath = (path) => {
  const hostEnv = getDevEnv();
  console.log('request path:', path);
  console.log('hostEnv:', hostEnv);

  let pathValue = '';
  if (!isDevelopment() && hostEnv === 'api') {
    pathValue = API_CONFIG.paths.api[path];
  } else {
    pathValue = API_CONFIG.paths.dev[path];
  }
  console.log('getApiPath - path:', pathValue);
  return pathValue;
};

// NOTICE: 프로덕션 환경에서도 getDevEnv 값을 확인한다.
// - production 환경인데, getDevEnv 값이, dev, dev2인 경우, ''값을 return하여, 개발 서버에도 동작하도록 한다.
// 이미지 AI URL 선택
const getImageAIUrl = () => {
  const env = isDevelopment() ? 'dev' : 'prod';
  if (env === 'prod') {
    // WARN: production 환경은: prod, api, dev, dev2 가 존재한다.
    // ! 정확히 말해서 host 서버가 서로 다르다.
    const hostEnv = getDevEnv();
    const imageAIUrl = API_CONFIG.url.imageAI.prod[hostEnv];
    console.log('imageAIUrl:', imageAIUrl);
    return imageAIUrl;
  }
  return '';
};

// API 인스턴스 생성 함수
export const createApiInstance = (config = {}) => {
  // headers 설정을 외부에서 선언
  const headers = !isDevelopment()
    ? { ...API_CONFIG.headers.common, ...(config.headers || {}) }
    : config.headers || {};

  const instance = axios.create({
    baseURL: config.baseURL || getBaseUrl(),
    ...config,
    headers, // isDevelopment가 아닌 경우에만 headers 포함
  });

  // 요청 인터셉터 추가
  instance.interceptors.request.use((config) => {
    // 개발 환경에서 로깅
    if (API_CONFIG.debug) {
      console.log('API Request:', {
        url: config.url,
        method: config.method,
        baseURL: config.baseURL,
        headers: config.headers,
      });
    }
    return config;
  });

  // 응답 인터셉터 추가
  instance.interceptors.response.use(
    (response) => {
      if (API_CONFIG.debug) {
        console.log('API Response:', response.data);
      }
      return response;
    },
    (error) => {
      if (API_CONFIG.debug) {
        console.error('API Error:', error);
      }
      return Promise.reject(error);
    },
  );
  if (API_CONFIG.debug) {
    // baseURL이 제대로 설정되었는지 확인
    console.log('Created instance with baseURL:', {
      instance,
      baseURL: instance.defaults.baseURL,
      config,
      headers: instance.defaults.headers,
    });
  }

  return instance;
};

// 기본 API 인스턴스
export const apiInstance = createApiInstance();

// 이미지 AI용 API 인스턴스
export const imageAIInstance = createApiInstance({
  baseURL: getImageAIUrl(),
});

// 번역 API URL 선택
const getTranslationUrl = () => {
  const url = import.meta.env.VITE_API_URL;
  console.log('Translation API URL:', url);
  return url;
};

// 번역 API 인스턴스 생성
export const translationInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL || 'https://api.supereasy.co.kr:3000',
});

export const getCurrentServiceUrl = () => {
  const env = isDevelopment() ? 'dev' : 'prod';
  const hostEnv = getDevEnv();
  let serviceUrl = '';
  if (env === 'dev') {
    serviceUrl = 'localhost';
  } else {
    serviceUrl = IMAGE_AI_SERVICE_URL.prod[hostEnv];
  }
  console.log('getCurrentServiceUrl:', serviceUrl);
  return serviceUrl;
};

// isCrossOrigin 설정
API_CONFIG.isCrossOrigin = isCrossOrigin();

if (API_CONFIG.debug) {
  console.log('환경 변수 및 apiConfig 확인');
  console.log('Check import.meta.env: ', import.meta.env);
  console.log('Check __DEV_ENV__: ', __DEV_ENV__);
  console.log('Check __MODE__: ', __MODE__);

  // 생성된 translationInstance의 baseURL 확인
  console.log(
    'Translation instance baseURL:',
    translationInstance.defaults.baseURL,
  );

  // API_CONFIG 전체 값 로깅
  console.log('API_CONFIG:', {
    ...API_CONFIG,
    CurrentMode: getEnvValue('VITE_MODE'),
    BaseURL: getBaseUrl(),
    CDNURL: getCdnUrl(),
    isDevelopment: isDevelopment(),
    DevEnvironment: getDevEnv(),
    ImageAIURL: getImageAIUrl(),
    CurrentServiceURL: getCurrentServiceUrl(),
  });
}
