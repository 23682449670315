// src/plugins/ImageAIChat/config/imageAIChatConfig.js

// buildUrl 함수를 직접 구현
function buildUrl(path, baseUrl = '') {
  // 기본 URL이 제공되지 않은 경우 현재 도메인 사용
  if (!baseUrl) {
    baseUrl = window.location.origin;
  }

  // path가 이미 전체 URL인 경우 그대로 반환
  if (path.startsWith('http://') || path.startsWith('https://')) {
    return path;
  }

  // path가 /로 시작하지 않으면 추가
  if (!path.startsWith('/')) {
    path = '/' + path;
  }

  // baseUrl의 끝에 있는 /를 제거
  baseUrl = baseUrl.replace(/\/+$/, '');

  return baseUrl + path;
}

/**
 * ImageAI 서비스에서 사용하는 기본 설정정
 * @typedef {Object} ImageAIConfig
 * @property {Object} api_options - API 요청 옵션
 * @property {Object} inputFields - 입력 필드 활성화 여부
 * @property {Object} requiredFields - 필수 입력 필드 설정
 * @property {Object} clearInputAfterSubmit - 제출 후 입력 필드 초기화 설정
 * @property {Object} s3Config - S3 업로드 관련 설정
 * @property {Object} imageInfo - 생성된 이미지 정보 저장 객체
 */

/**
 * defaultImageAIChatConfig 기본 설정 값
 * - 해당 값은 현재 플러그인에서만 사용 / 적용되는 값이다. (플러그인 한정)
 * - 해당 값은 ImageAIProvider의 하위 컴포넌트에서 사용된다.
 * ! 나중에 불필요한 값을 정리해야 한다.
 * @type {defaultImageAIChatConfig}
 */
export const defaultImageAIChatConfig = {
  apiOptions: {
    LLM: [
      {
        provider: 'claude',
        model: 'claude-3-sonnet-20240229',
        max_tokens: 1000,
        temperature: 0.7,
        top_p: 1.0,
        frequency_penalty: 0.0,
        presence_penalty: 0.0,
      },
    ],
  },
  urls: {
    apiEndpoint: buildUrl(`/v1/messages11`),
    getImageBase64Url: buildUrl(
      '/gtgf/server/image/gtgf.base64.imagefetcher.php',
    ),
    instructionListUrl: buildUrl('/ai/instructions.php'),
    instructionPostUrl: buildUrl('/gtgf/server/blog/gtgf.post.php'),
  },
  // ! 해당 플러그인에서 사용하는, 이미지 S3 업로드 설정
  s3Config: {
    isS3UploadWithMetadata: true,
    isAutoPost: false,
    bucketName: 'supereasy-ai',
    prefix: {
      ai: 'ai',
      aiImage: 'ai/image',
      metadata: 'metadata',
    },
  },
  // 나머지 설정은 동일하게 유지...
  apiType: {
    image: 'fluxFal',
    LLM: 'claude',
  },
  isS3Upload: true,
  isAutoPost: false,
  stream: true,
  apiEnv: 'prod',
  pageId: 'imageAIChat',
  storageKey: {
    darkMode: 'imageAIChat_darkMode',
  },
  darkMode: {
    storageKey: 'imageAIChat_darkMode',
    targetElementId: 'supereasy-image-ai-chat',
  },
};
