import React, { createContext, useState, useContext, useCallback } from 'react';
import Modal from '@/components/common/Modal/Modal';
import AlertMsg from '@/components/common/Modal/AlertMsg';
import ConfirmMsg from '@/components/common/Modal/ConfirmMsg';

// 모달 컨텍스트 생성
const ModalContext = createContext();

/**
 * ModalProvider 컴포넌트
 *
 * 이 컴포넌트는 애플리케이션에서 모달을 관리하는 역할을 합니다.
 * 여러 모달을 스택 형태로 관리하며, 각 모달의 열림/닫힘 상태를 제어합니다.
 *
 * @param {Object} props
 * @param {React.ReactNode} props.children - 자식 컴포넌트들
 */
export const ModalProvider = ({ children }) => {
  // 모달 스택 상태 관리
  const [modalStack, setModalStack] = useState([]);
  // 현재 닫히고 있는 모달의 인덱스
  const [closingModalIndex, setClosingModalIndex] = useState(null);

  /**
   * 새로운 모달을 스택에 추가하는 함수
   *
   * @param {React.ComponentType} ModalComponent - 렌더링할 모달 컴포넌트
   * @param {Object} props - 모달 컴포넌트에 전달할 props
   */
  const openModal = useCallback((ModalComponent, props) => {
    setModalStack((prevStack) => [
      ...prevStack,
      { ModalComponent, props, isClosing: false },
    ]);
  }, []);

  /**
   * 가장 상위의 모달을 닫는 함수
   * 모달을 즉시 제거하지 않고, isClosing 상태를 true로 설정하여 애니메이션 실행
   */
  const closeModal = useCallback(() => {
    setModalStack((prevStack) => {
      const newStack = [...prevStack];
      const lastIndex = newStack.length - 1;
      if (lastIndex >= 0) {
        newStack[lastIndex] = { ...newStack[lastIndex], isClosing: true };
        setClosingModalIndex(lastIndex);
      }
      return newStack;
    });
  }, []);

  /**
   * 애니메이션이 완료된 후 모달을 실제로 제거하는 함수
   */
  const removeModal = useCallback(() => {
    setModalStack((prevStack) => prevStack.slice(0, -1));
    setClosingModalIndex(null);
  }, []);

  /**
   * 일반 모달을 열기 위한 함수
   *
   * @param {React.ReactNode} content - 모달 내용
   * @param {Object} options - 모달 옵션 (너비, 높이, 애니메이션 등)
   */
  const modal = useCallback(
    (content, options = {}) => {
      openModal(Modal, { ...options, children: content });
    },
    [openModal],
  );

  /**
   * 알림 메시지 모달을 열기 위한 함수
   *
   * @param {string} message - 알림 메시지
   * @param {Object} options - 추가 옵션
   */
  const alertMsg = useCallback(
    (message, options = {}) => {
      openModal(AlertMsg, { message, ...options });
    },
    [openModal],
  );

  /**
   * 확인 메시지 모달을 열기 위한 함수
   *
   * @param {string} message - 확인 메시지
   * @param {Function} onConfirm - 확인 시 실행할 콜백 함수
   * @param {Object} options - 추가 옵션
   */
  // const confirmMsg = useCallback(
  //   (message, onConfirm, options = {}) => {
  //     openModal(ConfirmMsg, { message, onConfirm, ...options });
  //   },
  //   [openModal],
  // );
  const confirmMsg = useCallback(
    (message, options = {}) => {
      openModal(ConfirmMsg, { message, ...options });
    },
    [openModal],
  );

  return (
    <ModalContext.Provider
      value={{ modal, alertMsg, confirmMsg, closeModal, removeModal }}
    >
      {children}
      {/* 모달 스택을 순회하며 각 모달 렌더링 */}
      {modalStack.map(({ ModalComponent, props, isClosing }, index) => (
        <ModalComponent
          key={index}
          {...props}
          isOpen={!isClosing}
          onClose={() => {
            if (props.onClose) props.onClose();
            closeModal();
          }}
          afterLeave={() => {
            if (index === closingModalIndex) {
              removeModal();
            }
          }}
          zIndex={1060 + index} // 모달이 열린 순서대로 z-index 증가
        />
      ))}
    </ModalContext.Provider>
  );
};

/**
 * 모달 컨텍스트를 사용하기 위한 커스텀 훅
 *
 * @returns {Object} 모달 관련 함수들 (modal, alertMsg, confirmMsg, closeModal)
 * @throws {Error} ModalProvider 외부에서 사용 시 에러 발생
 */
export const useModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

/**
 * 사용 방법:
 *
 * 1. 앱의 최상위 컴포넌트를 ModalProvider로 감싸기:
 *    <ModalProvider>
 *      <App />
 *    </ModalProvider>
 *
 * 2. 모달을 사용하고 싶은 컴포넌트에서 useModal 훅 사용:
 *    const { modal, alertMsg, confirmMsg, closeModal } = useModal();
 *
 * 3. 모달 열기:
 *    - 일반 모달: modal(<div>모달 내용</div>, { width: 'md', height: 'auto' });
 *    - 알림 모달: alertMsg('알림 내용');
 *    - 확인 모달: confirmMsg('확인 내용', () => console.log('확인됨'));
 *
 * 4. 모달 닫기:
 *    - closeModal() 호출 (주로 모달 내부의 닫기 버튼에 연결)
 *
 * 주의: removeModal은 내부적으로 사용되며, 직접 호출하지 않음
 */
