// serviceConfig.js
import createDebugger from '@utils/debug';
import {
  API_CONFIG,
  getDevEnv,
  getDevHost,
  isDevelopment,
} from '@api/apiConfig';

const API_GATEWAY_URL = 'https://api.supereasy.pro/v1'; // API Gateway URL
const DIRECT_API_URL = 'https://supereasy.pro'; // 직접 호출 URL

const { debug, setDebugMode } = createDebugger('serviceConfig');
setDebugMode(API_CONFIG.debug);

export const serviceConfig = {
  url: {
    base: {
      dev: 'http://localhost',
      prod: {
        dev: window.location.origin,
        dev2: window.location.origin,
        prod: DIRECT_API_URL,
        api: DIRECT_API_URL,
      },
    },
  },
  paths: {
    login: '/login',
    logout: '/logout',
    register: '/register',
  },
};

const AUTH_ROUTES = {
  LOGIN: 'login',
  REGISTER: 'register',
  DASHBOARD: 'dashboard',
};

const URL_CONFIG = {
  MAX_LENGTH: 2048,
  FORBIDDEN_PATTERNS: [
    'javascript:',
    'data:',
    'vbscript:',
    'file:',
    '<script',
    'onclick=',
    'onerror=',
  ],
};

export const getBaseUrl = () => {
  const env = isDevelopment() ? 'dev' : 'prod';
  const hostEnv = getDevEnv();
  let baseUrl = '';
  if (env === 'prod') {
    baseUrl = serviceConfig.url.base.prod[hostEnv];
  } else {
    baseUrl = serviceConfig.url.base[env];
  }
  debug('i:*:getBaseUrl: ', { env, hostEnv, baseUrl });
  return baseUrl;
};

export const getServiceUrl = (path) => {
  const baseUrl = getBaseUrl();
  const serviceUrl = path ? `${baseUrl}${serviceConfig.paths[path]}` : baseUrl;
  debug('i:*:getServiceUrl: ', { baseUrl, path });

  return serviceUrl;
};

// 현재 URL 인코딩
export const getCurrentEncodedUrl = () => {
  const currentUrl = new URL(window.location.href);
  return encodeURIComponent(currentUrl.toString());
};

// 공통 URL 유효성 검증 함수
export const validateReturnUrl = (encodedUrl) => {
  try {
    const url = decodeURIComponent(encodedUrl);
    const urlObj = new URL(url);

    // 1. 프로토콜 검증
    if (!['http:', 'https:'].includes(urlObj.protocol)) {
      throw new Error('Invalid protocol');
    }

    // 2. URL 길이 검증
    if (url.length > URL_CONFIG.MAX_LENGTH) {
      throw new Error('URL too long');
    }

    // 3. 위험 패턴 검사
    if (
      URL_CONFIG.FORBIDDEN_PATTERNS.some((pattern) =>
        url.toLowerCase().includes(pattern),
      )
    ) {
      throw new Error('Potentially dangerous URL pattern detected');
    }

    return true;
  } catch (error) {
    console.error('ReturnUrl validation error:', error);
    return false;
  }
};

// 공통 URL 생성 함수
const createAuthUrl = (routeType) => {
  try {
    if (!AUTH_ROUTES[routeType]) {
      throw new Error(`Invalid route type: ${routeType}`);
    }

    // 기본 URL 생성
    const baseUrl = getServiceUrl(AUTH_ROUTES[routeType]);

    // 현재 URL 인코딩
    const returnUrl = getCurrentEncodedUrl();

    // URL 유효성 검증
    if (!validateReturnUrl(returnUrl)) {
      throw new Error('Invalid return URL');
    }

    // 최종 URL 생성
    const finalUrl = `${baseUrl}?url=${returnUrl}`;

    // 개발 환경에서 디버깅 정보 출력
    if (API_CONFIG.debug) {
      debug('i:*:createAuthUrl: ', {
        routeType,
        baseUrl,
        returnUrl: decodeURIComponent(returnUrl),
        finalUrl,
      });
    }

    return finalUrl;
  } catch (error) {
    console.error(`Error creating ${routeType} URL:`, error);
    // 오류 시 기본 URL 반환
    return getServiceUrl(AUTH_ROUTES[routeType]);
  }
};

// 로그인 URL 생성
export const getLoginUrl = () => createAuthUrl('LOGIN');

// 회원가입 URL 생성
export const getSignUpUrl = () => createAuthUrl('REGISTER');

// /**
//  * 로그인 URL 생성
//  * NOTICE: prod 환경에서, prod, api 환경에서는 모드 supereasy.pro를 사용한다.
//  * @returns 로그인 URL
//  */
// // 개선된 로그인 URL 생성 함수
// export const getLoginUrl = () => {
//   try {
//     // 기본 로그인 URL 생성
//     const loginUrl = getServiceUrl('login');

//     // 현재 URL을 리턴 URL로 사용
//     const returnUrl = getCurrentEncodedUrl();

//     // URL 유효성 기본 검증
//     if (!isValidReturnUrl(returnUrl)) {
//       throw new Error('Invalid return URL');
//     }

//     // 최종 URL 생성
//     const loginUrlWithReturnUrl = `${loginUrl}?url=${returnUrl}`;

//     // 디버깅을 위한 로그
//     if (API_CONFIG.debug) {
//       debug('i:*:getLoginUrl: ', {
//         baseUrl: loginUrl,
//         returnUrl: decodeURIComponent(returnUrl),
//         finalUrl: loginUrlWithReturnUrl,
//       });
//     }

//     return loginUrlWithReturnUrl;
//   } catch (error) {
//     debug('e:*:getLoginUrl: Error generating login URL:', error);
//     console.error('Error generating login URL:', error);
//     // 오류 시 기본 로그인 URL 반환
//     return getServiceUrl('login');
//   }
// };

// /**
//  * 회원가입 URL 생성
//  * NOTICE: prod 환경에서, prod, api 환경에서는 모드 supereasy.pro를 사용한다.
//  * @returns 회원가입 URL
//  */
// export const getSignUpUrl = () => {
//   const signUpUrl = getServiceUrl('register');
//   const returnUrl = getCurrentEncodedUrl();
//   const signUpUrlWithReturnUrl = `${signUpUrl}?url=${returnUrl}`;
//   debug('i:*:getSignUpUrl: ', { signUpUrl, returnUrl, signUpUrlWithReturnUrl });
//   return signUpUrlWithReturnUrl;
// };

// // 기본적인 클라이언트 측 URL 검증
// const isValidReturnUrl = (encodedUrl) => {
//   try {
//     const url = decodeURIComponent(encodedUrl);
//     const urlObj = new URL(url);

//     // 1. 프로토콜 검사
//     if (!['http:', 'https:'].includes(urlObj.protocol)) {
//       return false;
//     }

//     // 2. URL 길이 제한
//     if (url.length > 2048) {
//       return false;
//     }

//     // 3. 기본적인 위험 문자열 체크
//     const dangerousPatterns = [
//       'javascript:',
//       'data:',
//       'vbscript:',
//       'file:',
//       '<script',
//       'onclick=',
//       'onerror=',
//     ];

//     if (
//       dangerousPatterns.some((pattern) => url.toLowerCase().includes(pattern))
//     ) {
//       return false;
//     }

//     return true;
//   } catch (error) {
//     console.error('URL validation error:', error);
//     return false;
//   }
// };

// 디버깅용 서비스 정보 출력 (debug 모드일 때만)
if (API_CONFIG.debug) {
  debug('i:*:Check Service Config:', {
    environment: isDevelopment() ? 'development' : 'production',
    hostEnv: getDevEnv(),
    baseUrl: getBaseUrl(),
    loginUrl: getLoginUrl(),
    signUpUrl: getSignUpUrl(),
  });
}
