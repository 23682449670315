// FalApi.js

// 현재 환경이 로컬인지 프로덕션인지 확인
const isProduction =
  window.location.hostname !== 'localhost' &&
  window.location.hostname !== '127.0.0.1';

// API URL 설정
const API_URL = isProduction
  ? 'https://api.supereasy.co.kr:5000/api/generate-image'
  : 'http://localhost:5000/api/generate-image';
// const API_URL = isProduction
//   ? 'https://dev.supereasy.co.kr/api/generate-image'
//   : 'http://localhost:3000/api/generate-image';

export function constructApiRequest(apiOptions) {
  // API 요청 객체 생성
  const request = {
    model: apiOptions.model || 'fal-ai/flux/schnell',
    prompt: apiOptions.prompt || '',
    image_size: apiOptions.image_size || 'landscape_4_3',
    num_inference_steps: apiOptions.num_inference_steps || 28,
    seed: apiOptions.seed || null,
    guidance_scale: apiOptions.guidance_scale || 3.5,
    sync_mode: apiOptions.sync_mode || false,
    num_images: apiOptions.num_images || 1,
    enable_safety_checker:
      apiOptions.enable_safety_checker !== undefined
        ? apiOptions.enable_safety_checker
        : true,
    isBase64: apiOptions.isBase64 || false,
  };

  console.log('Constructed API request:', request);
  return request;
}

export async function callAPI(apiRequest) {
  console.log('Check apiRequest: ', apiRequest);
  console.log('Check API_URL: ', API_URL);
  const response = await fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(apiRequest),
  });

  if (!response.ok) {
    const errorText = await response.text();
    console.error('API Error Response:', errorText);
    let errorMessage = 'Unknown error';
    try {
      const errorJson = JSON.parse(errorText);
      errorMessage = errorJson.error?.message || errorJson.error || errorText;
    } catch (e) {
      errorMessage = errorText;
    }
    throw new Error(
      `HTTP error! status: ${response.status}, message: ${errorMessage}`
    );
  }

  return response.json();
}
