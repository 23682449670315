import React, { createContext, useContext, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import createImageAIAgentI18n from '../config/lang/i18n';
import { debugAgentTranslation } from '../utils/translationDebug';

const AgentTranslationContext = createContext();

// i18n 인스턴스를 컴포넌트 외부에서 한 번만 생성
const pluginI18n = createImageAIAgentI18n({ debug: false });

export const AgentTranslationProvider = ({ children }) => {
  const mainTranslation = useTranslation();

  const translationFunction = useCallback(
    (key, options = {}) => {
      try {
        const pluginTranslation = pluginI18n.t(key, {
          ...options,
          returnNull: true,
        });

        const mainTranslationResult = mainTranslation.t(key, options);

        // 디버그 로그 출력
        debugAgentTranslation(key, pluginTranslation, mainTranslationResult);

        if (!pluginTranslation) {
          return mainTranslationResult;
        }

        return pluginTranslation;
      } catch (error) {
        console.warn('Translation error:', error);
        return mainTranslation.t(key, options);
      }
    },
    [mainTranslation],
  );

  const value = useMemo(
    () => ({
      t: translationFunction,
      i18n: {
        ...pluginI18n,
        changeLanguage: (lng) => {
          pluginI18n.changeLanguage(lng);
          mainTranslation.i18n.changeLanguage(lng);
        },
        language: pluginI18n.language || mainTranslation.i18n.language,
      },
      getNamespacedT: (namespace) => (key) =>
        translationFunction(key, { ns: namespace }),
    }),
    [translationFunction, mainTranslation.i18n, pluginI18n.language],
  );

  return (
    <AgentTranslationContext.Provider value={value}>
      {children}
    </AgentTranslationContext.Provider>
  );
};

export const useAgentTranslation = () => {
  const context = useContext(AgentTranslationContext);
  if (context === undefined) {
    throw new Error(
      'useAgentTranslation must be used within a AgentTranslationProvider',
    );
  }
  return context;
};
