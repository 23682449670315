// const axios = require('axios'); // CommonJS 방식 (해결)
let axios;

if (typeof window === 'undefined') {
  // Node.js 환경
  const importDynamic = new Function('modulePath', 'return import(modulePath)');
  axios = importDynamic('axios').then((module) => module.default);
} else {
  // 브라우저 환경
  import('axios').then((module) => {
    axios = module.default;
  });
}

import { v4 as uuidv4 } from 'uuid';
// import crypto from 'crypto';
import CryptoJS from 'crypto-js';

// API Gateway 엔드포인트와 API 키 설정
const API_ENDPOINT =
  'https://h975xsiv7f.execute-api.ap-northeast-2.amazonaws.com/prod';
// @update 2024-11-17
const API_KEY = 'bV1E6i7zdY2OU7iM64XVX8aDNiJrmxJw1rH7FF2z';

// Base64 이미지에서 데이터와 MIME 타입을 추출하는 함수
export function extractBase64Data(base64Image) {
  // 입력값 검증: base64Image가 문자열인지 확인
  if (typeof base64Image !== 'string') {
    throw new Error('Invalid input: base64Image must be a string.');
  }

  // ';base64,' 문자열을 기준으로 분리
  const base64SeparatorIndex = base64Image.indexOf(';base64,');
  if (base64SeparatorIndex === -1) {
    throw new Error(
      'Invalid base64Image format: missing ";base64," separator.',
    );
  }

  const [metadata, base64Data] = base64Image.split(';base64,');

  // metadata 부분에서 MIME 타입 추출
  const metadataParts = metadata.split(':');
  if (metadataParts.length !== 2 || !metadataParts[1]) {
    throw new Error('Invalid metadata format in base64Image.');
  }

  const contentType = metadataParts[1];

  // base64Data가 존재하는지 확인
  if (!base64Data) {
    throw new Error('Invalid base64Image format: base64 data is missing.');
  }

  // base64Data가 올바른 base64 문자열인지 확인 (선택 사항)
  const base64Pattern = /^[A-Za-z0-9+/]+={0,2}$/;
  if (!base64Pattern.test(base64Data)) {
    throw new Error('Invalid base64 data in base64Image.');
  }

  return { base64Data, contentType };
}

// Base64 이미지를 버퍼로 변환하는 함수
export function convertBase64ToBuffer(base64Image) {
  const [metadata, base64Data] = base64Image.split(';base64,');
  const contentType = metadata.split(':')[1];

  // 브라우저 환경에서는 base64Data를 그대로 사용
  const buffer = base64Data;

  console.log('check metadata: ', metadata);
  console.log('check base64Data:  ', base64Data);
  console.log('check contentType: ', contentType);

  return { buffer, contentType };
}

// 버킷 이름을 받아 짧은 버킷 키를 생성하는 함수
export function generateShortBucketKey(bucketName) {
  const fullHash = CryptoJS.SHA256(bucketName).toString(CryptoJS.enc.Hex);
  return fullHash.substring(0, 16); // 앞에서 16자만 사용
}

// 파일 업로드 함수
export async function uploadFile(
  buffer,
  bucketName,
  key,
  contentType = 'application/octet-stream',
) {
  // buffer가 base64 문자열인지 확인하고, 필요하면 변환
  const fileContent =
    typeof buffer === 'string'
      ? buffer
      : buffer instanceof Uint8Array
      ? uint8ArrayToBase64(buffer)
      : Buffer.from(buffer).toString('base64');

  const payload = {
    fileName: key,
    fileContent: fileContent,
    bucketName: bucketName,
    contentType: contentType, // ContentType을 지정하여 올바르게 처리되도록 함
  };

  try {
    const response = await axios.post(`${API_ENDPOINT}/file-upload`, payload, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': API_KEY,
      },
    });
    console.log('File uploaded successfully:', response.data);
    return response.data;
  } catch (err) {
    console.error(
      'Error uploading file:',
      err.response ? err.response.data : err.message,
    );
    throw err;
  }
}

/**
 * 이미지 업로드 함수
 * 이미지 버퍼, 버킷 이름, 이미지 파일 이름, 이미지 컨텐츠 타입, 이미지 메타데이터를 받아 이미지를 업로드하는 함수
 * @param {Buffer|string} buffer 이미지 버퍼 또는 base64 문자열
 * @param {string} bucketName 버킷 이름
 * @param {string} imageFileName 이미지 파일 이름
 * @param {string} contentType 이미지 컨텐츠 타입
 * @param {object} metadata 이미지 메타데이터
 * @returns
 */
export async function uploadImage({
  buffer,
  bucketName,
  imageFileName,
  contentType = 'application/octet-stream',
  metadata = {},
  isAutoPost = false,
  fileUrl = null,
}) {
  let isMetadata = false;
  const bucketKey = generateShortBucketKey(bucketName);

  const payload = {
    fileName: imageFileName,
    bucketKey: bucketKey,
    contentType: contentType,
  };

  // * fileUrl이 제공된 경우와 buffer가 제공된 경우(base64 문자열)를 모두 처리
  if (fileUrl) {
    // fileUrl이 제공된 경우
    payload.fileUrl = fileUrl;
  } else if (buffer) {
    // buffer가 제공된 경우
    let fileContent;
    if (typeof buffer === 'string') {
      fileContent = buffer;
    } else if (buffer instanceof Uint8Array) {
      fileContent = uint8ArrayToBase64(buffer);
    } else if (buffer instanceof ArrayBuffer) {
      fileContent = uint8ArrayToBase64(new Uint8Array(buffer));
    } else {
      throw new Error('지원되지 않는 buffer 타입입니다');
    }
    payload.fileContent = fileContent;
  } else {
    throw new Error('fileUrl 또는 buffer 중 하나는 반드시 제공되어야 합니다');
  }

  if (metadata && Object.keys(metadata).length > 0) {
    payload.metadata = JSON.stringify(metadata);
    isMetadata = true;
  }

  if (isAutoPost) {
    payload.isAutoPost = isAutoPost;
  }

  try {
    const response = await axios.post(`${API_ENDPOINT}/images`, payload, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': API_KEY,
      },
    });
    const message = isMetadata
      ? '메타데이터와 함께 이미지가 성공적으로 업로드되었습니다'
      : '이미지가 성공적으로 업로드되었습니다';
    console.log(message, response.data);
    return response.data;
  } catch (err) {
    console.error(
      '이미지 업로드 중 오류 발생:',
      err.response ? err.response.data : err.message,
    );
    throw err;
  }
}

/**
 * ! 다중 파일 업로드 함수 호출을 위한 예제
 *
 
  const images = [
    { buffer: imageBuffer1, fileName: 'image1.jpg', contentType: 'image/jpeg' },
    { buffer: imageBuffer2, fileName: 'image2.png', contentType: 'image/png' },
  ];

  const bucketName = 'your-bucket-name';
  const metadata = { promptId: '12345', userId: 'user123' };
  const isAutoPost = true;

  try {
    const result = await uploadMultipleImages(images, bucketName, metadata, isAutoPost);
    console.log('Upload result:', result);
  } catch (error) {
    console.error('Upload failed:', error);
  }
*/

/**
 * 다중 이미지 업로드 함수
 * 여러 개의 이미지를 동시에 업로드하는 함수
 * @param {Array} images 이미지 객체 배열 [{buffer, fileName, contentType}, ...]
 * @param {string} bucketName 버킷 이름
 * @param {object} metadata 공통 메타데이터
 * @param {boolean} isAutoPost 자동 포스트 여부
 * @param {string} promptId 프롬프트 ID
 * @param {string} userId 사용자 ID
 * @param {object} promptMetadata 프롬프트 메타데이터
 * @returns {Promise<object>} 업로드 결과 객체
 */
export async function uploadMultipleImages({
  images,
  bucketName,
  metadata = {},
  isAutoPost = false,
  promptId = null,
  userId = null,
  promptMetadata = null,
  s3UploadMode = 'dev',
  s3UploadDevHost = null,
}) {
  const bucketKey = generateShortBucketKey(bucketName);

  if (!Array.isArray(images) || images.length === 0 || images.length > 4) {
    throw new Error('Invalid number of images. Must be between 1 and 4.');
  }

  const payload = {
    bucketKey: bucketKey,
    images: images.map((img) => {
      if (img.fileUrl) {
        return {
          fileName: img.fileName,
          fileUrl: img.fileUrl,
          contentType: img.contentType || 'application/octet-stream',
        };
      } else {
        return {
          fileName: img.fileName,
          fileContent:
            typeof img.buffer === 'string'
              ? img.buffer
              : img.buffer instanceof Uint8Array
              ? uint8ArrayToBase64(img.buffer)
              : Buffer.from(img.buffer).toString('base64'),
          contentType: img.contentType || 'application/octet-stream',
        };
      }
    }),
    metadata: JSON.stringify(metadata),
    isAutoPost: isAutoPost,
  };

  // promptId가 제공된 경우, promptId를 추가
  if (promptId) {
    payload.promptId = promptId;
  }

  // userId가 제공된 경우, userId를 추가
  if (userId) {
    payload.userId = userId;
  }

  // promptMetadata가 제공된 경우, promptMetadata를 추가
  if (promptMetadata) {
    payload.promptMetadata = JSON.stringify(promptMetadata);
  }

  if (s3UploadMode) {
    payload.s3UploadMode = s3UploadMode;
    payload.s3UploadDevHost = s3UploadDevHost;
  }

  console.log('Check payload of multiple images:', payload);

  try {
    const response = await axios.post(`${API_ENDPOINT}/images`, payload, {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': API_KEY,
      },
    });
    console.log('Multiple images uploaded successfully:', response.data);
    return response.data;
  } catch (err) {
    console.error(
      'Error uploading multiple images:',
      err.response ? err.response.data : err.message,
    );
    console.error('Check the payload of multiple images:', payload);
    throw err;
  }
}

// Uint8Array를 base64 문자열로 변환하는 유틸리티 함수
function uint8ArrayToBase64(u8Arr) {
  const CHUNK_SIZE = 0x8000; // 32768
  let index = 0;
  const length = u8Arr.length;
  let result = '';
  let slice;
  while (index < length) {
    slice = u8Arr.subarray(index, Math.min(index + CHUNK_SIZE, length));
    result += String.fromCharCode.apply(null, slice);
    index += CHUNK_SIZE;
  }
  return btoa(result);
}

// 이미지 삭제 함수
export async function deleteImage(imageKey, userId = null) {
  const bucketName = userId
    ? `supereasy-ai/ai/images/${userId}`
    : 'supereasy-ai/ai/images/public';

  try {
    const response = await axios.delete(`${API_ENDPOINT}/images/${imageKey}`, {
      headers: {
        'x-api-key': API_KEY,
      },
      params: {
        bucketName,
        fileName: imageKey,
      },
    });
    console.log('Image deleted successfully:', response.data);
    return response.data;
  } catch (err) {
    console.error(
      'Error deleting image:',
      err.response ? err.response.data : err.message,
    );
    throw err;
  }
}

// 이미지 조회 함수
export async function getImage(imageKey, userId = null) {
  const bucketName = userId
    ? `supereasy-ai/ai/images/${userId}`
    : 'supereasy-ai/ai/images/public';

  try {
    const response = await axios.get(`${API_ENDPOINT}/images/${imageKey}`, {
      headers: {
        'x-api-key': API_KEY,
      },
      params: {
        bucketName,
        fileName: imageKey,
      },
    });
    console.log('Image retrieved successfully:', response.data);
    return response.data;
  } catch (err) {
    console.error(
      'Error retrieving image:',
      err.response ? err.response.data : err.message,
    );
    throw err;
  }
}

// 이미지 메타데이터 조회 함수
export async function getImageMetadata(imageKey) {
  try {
    const response = await axios.get(
      `${API_ENDPOINT}/images/${imageKey}/metadata`,
      {
        headers: {
          'x-api-key': API_KEY,
        },
      },
    );
    console.log('Metadata retrieved successfully:', response.data);
    return response.data;
  } catch (err) {
    console.error(
      'Error retrieving metadata:',
      err.response ? err.response.data : err.message,
    );
    throw err;
  }
}

// 이미지 메타데이터 업데이트 함수
export async function updateImageMetadata(imageKey, metadata) {
  try {
    const response = await axios.put(
      `${API_ENDPOINT}/images/${imageKey}/metadata`,
      metadata,
      {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
      },
    );
    console.log('Metadata updated successfully:', response.data);
    return response.data;
  } catch (err) {
    console.error(
      'Error updating metadata:',
      err.response ? err.response.data : err.message,
    );
    throw err;
  }
}

// 이미지 메타데이터 삭제 함수
export async function deleteImageMetadata(imageKey) {
  try {
    const response = await axios.delete(
      `${API_ENDPOINT}/images/${imageKey}/metadata`,
      {
        headers: {
          'x-api-key': API_KEY,
        },
      },
    );
    console.log('Metadata deleted successfully:', response.data);
    return response.data;
  } catch (err) {
    console.error(
      'Error deleting metadata:',
      err.response ? err.response.data : err.message,
    );
    throw err;
  }
}

// 폴더 이미지 목록 조회 함수
export async function listFolderImages(folderName) {
  try {
    const response = await axios.get(
      `${API_ENDPOINT}/images/list/${folderName}`,
      {
        headers: {
          'x-api-key': API_KEY,
        },
      },
    );
    console.log('Images listed successfully:', response.data);
    return response.data;
  } catch (err) {
    console.error(
      'Error listing images:',
      err.response ? err.response.data : err.message,
    );
    throw err;
  }
}

// 폴더 내 모든 이미지 삭제 함수
export async function deleteFolderImages(folderName) {
  try {
    const response = await axios.delete(
      `${API_ENDPOINT}/images/list/${folderName}`,
      {
        headers: {
          'x-api-key': API_KEY,
        },
      },
    );
    console.log('All images in folder deleted successfully:', response.data);
    return response.data;
  } catch (err) {
    console.error(
      'Error deleting folder images:',
      err.response ? err.response.data : err.message,
    );
    throw err;
  }
}
