import createDebugger from '@utils/debug.js';
import {
  extractBase64Data,
  uploadImage, // 단일 파일 업로드
  uploadMultipleImages, // 다중 파일 업로드
} from '../../api/aws/s3/AWSS3Fileuploader.js';

import { generateUniqueFilename } from '../../utils/UtilFunctions.js';
import {
  fetchImageBase64, // base64 이미지를 가져오는 함수
  getImageMimeTypeFromExtension, // 파일 확장자에서 MIME 타입을 결정
  getImageExtensionFromMimeType, // MIME 타입에서 파일 확장자를 추출
} from '../../utils/image/ImageUtilFunctions.js';

class S3ImageHandler {
  static defaultOptions = {
    selector: {},
    url: {
      getBase64ImageUrl:
        window.location.protocol +
        '//' +
        window.location.hostname +
        '/gtgf/server/image/gtgf.base64.imagefetcher.php',
    },
    s3Config: {
      isS3Upload: true, // true로 설정하면, 이미지 생성 후, 자동으로 S3에 업로드한다.
      // 이미지를 S3에 업로드 시, 메타정보와 함께 저장 여부
      isS3UploadWithMetadata: true,
      // 이미지를 S3에 업로드 후, 자동으로 포스트 발생 여부
      isAutoPost: false,
      bucketName: 'supereasy-ai',
      prefix: {
        ai: 'ai',
        aiImage: 'ai/image',
        metadata: 'metadata',
      },
      region: 'ap-northeast-2',
    },
    s3UploadMode: 'dev',
    s3UploadDevHost: null,

    debugMode: true,
    debugSwitchOff: true,
    debugIdx: 0,
  };
  // constructor(blogAI) {
  //   this.blogAI = blogAI;
  //   this.initializeImageUpload();
  // }
  constructor(context) {
    this.aiChat = context.aiChat; // maker instance
    this.pluginName = `${context.pluginName}_S3ImageHandler`; // 디버그 함수
    this.element = context.element || null; // DOM 요소
    this.$el = this.element ? $(context.element) : null; // DOM 요소
    this.options = context.options; // 참조로 설정
    $.extend(true, S3ImageHandler.defaultOptions, this.options); // 병합

    // 인스턴스별 디버거 생성
    const { debug, setDebugMode, getDebugMode } = createDebugger(
      this.pluginName,
    );
    this.debug = debug;
    this.setDebugMode = setDebugMode;
    this.getDebugMode = getDebugMode;
    // 디버그 모드 설정
    this.setDebugMode(this.options.debugMode);

    this.init();
  }

  init() {
    const _self = this;
    const o = _self.options;
    const { s3Config } = o;
    const { isS3Upload, isS3UploadWithMetadata, isAutoPost } = s3Config;
    if (_self.preCheck()) {
      _self.debug('S3ImageHandler initialized: ', {
        _self,
        isS3Upload,
        isS3UploadWithMetadata,
        isAutoPost,
      });
    }
  }

  preCheck() {
    const _self = this;
    _self.debug('s:preCheck: ', { _self });

    return true;
  }

  /**
   * uploadImageToS3
   *
   * 이 함수는 주어진 이미지를 S3 버킷에 업로드하는 비동기 함수이다.
   * 이미지의 base64 데이터를 변환하고, 고유한 파일명을 생성하여 S3 버킷에 이미지를 업로드한다.
   * 또한 이미지 메타데이터와 자동 게시 여부 등의 옵션을 처리한다.
   *
   * @param {Object} img - 업로드할 이미지 객체. img.src는 base64 포맷이어야 한다.
   * @param {Object} generatedImageInfo - 이미지 정보 객체로, 확장자, 파일명, URL 등을 포함한다.
   * @param {Object} [apiMetadata=null] - API 메타데이터. 이미지 생성과 관련된 메타데이터가 전달될 수 있다.
   * @param {boolean} [isS3UploadWithMetadata=null] - 메타데이터를 포함한 업로드 여부. null이면 옵션값을 사용한다.
   * @param {boolean} [isAutoPost=null] - 자동 게시 여부. null이면 옵션값을 사용한다.
   *
   * @returns {Promise<void>} - 이미지가 S3에 성공적으로 업로드되면 완료되는 Promise.
   *`
   * @update: 2023.09.04
   */
  async uploadImageToS3(
    img,
    generatedImageInfo,
    apiMetadata = null,
    isS3UploadWithMetadata = null,
    isAutoPost = null,
    userId = null,
  ) {
    const _self = this;
    let o = this.options;
    let errorMsg = '';
    const { s3Config } = o;
    const { bucketName, region, prefix } = s3Config;

    // isAutoPost와 isS3UploadWithMetadata 값 설정 (기존 코드 유지)
    isAutoPost =
      isAutoPost !== null && isAutoPost !== undefined
        ? isAutoPost
        : o.isAutoPost;
    isS3UploadWithMetadata =
      isS3UploadWithMetadata !== null && isS3UploadWithMetadata !== undefined
        ? isS3UploadWithMetadata
        : o.isS3UploadWithMetadata;

    // generatedImageInfo에서 필요한 정보 추출 (기존 코드 유지)
    const { extension, filename, fullFilename, url } = generatedImageInfo;

    // 이미지가 base64 형식인지 확인
    const isBase64Image = img.src.startsWith('data:image/');

    let uploadParams = {};

    if (isBase64Image) {
      // base64 이미지 처리
      try {
        const { base64Data, contentType } = extractBase64Data(img.src);
        uploadParams = {
          buffer: base64Data,
          contentType: contentType,
          fileUrl: null,
        };
      } catch (error) {
        errorMsg = `Error extracting base64 data: ${error.message}`;
        console.error(errorMsg);
        return { status: 'error', message: errorMsg };
      }
    } else {
      // URL 이미지 처리
      uploadParams = {
        buffer: null,
        fileUrl: img.src,
        contentType: getImageMimeTypeFromExtension(extension),
      };
    }

    // 공통 파라미터 설정
    const newImageFilename = generateUniqueFilename() + '.' + extension;
    const imageFilename = _self.generateImageFilename(newImageFilename, userId);

    let metadata = isS3UploadWithMetadata
      ? apiMetadata !== null && apiMetadata !== undefined
        ? apiMetadata
        : o.api_options
      : {};

    // 디버그 로깅
    _self.debug('=:Check uploadImageToS3 parameters:', {
      ...uploadParams,
      imageFilename,
      bucketName,
      region,
      isS3UploadWithMetadata: o.isS3UploadWithMetadata,
      metadata,
      userId,
    });

    try {
      // S3 업로드 로직
      const savedImage = await uploadImage({
        ...uploadParams,
        bucketName,
        imageFileName: imageFilename,
        metadata,
        isAutoPost,
        userId,
      });

      _self.debug('=:uploadImageToS3: ', { savedImage });
      return {
        status: 'success',
        message: '이미지 업로드 성공',
        data: savedImage,
      };
    } catch (error) {
      console.error('이미지 업로드 중 오류 발생:', error.message);
      _self.debug('=:uploadImageToS3 오류: ', { error });
      return {
        status: 'error',
        message: '이미지 업로드 실패',
        details: error.message,
      };
    }
  }

  /**
   * uploadMultipleImagesToS3
   *
   * 이 함수는 여러 이미지를 S3 버킷에 업로드하는 비동기 함수입니다.
   * 각 이미지의 확장자를 추출하고, 고유한 파일명을 생성하여 S3 버킷에 이미지를 업로드합니다.
   * 또한 이미지 메타데이터와 자동 게시 여부 등의 옵션을 처리합니다.
   *
   * @param {Object} params - 업로드에 필요한 파라미터 객체
   * @param {string} params.promptId - 프롬프트 ID
   * @param {Array} params.images - 업로드할 이미지 배열
   * @param {Object} [params.apiMetadata=null] - API 메타데이터
   * @param {boolean} [params.isS3UploadWithMetadata=null] - 메타데이터와 함께 업로드할지 여부
   * @param {boolean} [params.isAutoPost=null] - 자동 게시 여부
   * @param {string} [params.userId=null] - 사용자 ID
   * @param {Object} [params.promptMetadata=null] - 프롬프트 메타데이터
   * @returns {Promise<Object>} 업로드 결과 객체
   *
   * @update: 2023.09.04
   */
  async uploadMultipleImagesToS3({
    promptId,
    images,
    apiMetadata = null,
    isS3UploadWithMetadata = null,
    isAutoPost = null,
    userId = null,
    promptMetadata = null,
  }) {
    const _self = this;
    let o = this.options;
    const { s3Config } = o;
    const { bucketName, region, prefix } = s3Config;

    // isAutoPost와 isS3UploadWithMetadata 값 설정
    isAutoPost = isAutoPost ?? false; // 기본값 false
    isS3UploadWithMetadata = isS3UploadWithMetadata ?? o.isS3UploadWithMetadata;

    // 메타데이터 설정
    let metadata = isS3UploadWithMetadata ? apiMetadata ?? o.api_options : {};

    try {
      const formattedImages = images.map((img) => {
        const extension = getImageExtensionFromMimeType(img.contentType);
        // 고유한 파일명 생성
        // INFO: userId가 있으면, filename을 생성 시, userId 를 포함하여 생성
        const newImageFilename = this.generateImageFilename(
          generateUniqueFilename() + '.' + extension,
          userId,
        );

        // * s3로 이미지 업로드 시의 두가지 방법을 지원.
        // * 1. 이미지 URL을 직접 전달하는 방법
        // - 이미지 URL(fileUrl)을 전달하는 경우, 서버 상에서 base64이미지를 서버 상에서 다운로드 받은 후, 버퍼로 변환하여 저장
        // * 2. base64 문자열을 전달하는 방법
        // - base64 문자열(fileContent)을 전달하는 경우, 서버 상에서 버퍼로 변환하여 저장
        if ((img.isBase64 === undefined || img.isBase64 === false) && img.src) {
          // URL 이미지 처리
          return {
            fileName: newImageFilename,
            fileUrl: img.src,
            contentType: img.contentType,
          };
        } else {
          // Base64 이미지 처리 (실제로는 이 경우가 없을 것 같지만, 안전을 위해 포함)
          return {
            fileName: newImageFilename,
            fileContent: img.src.split(',')[1], // base64 데이터 부분 추출
            contentType: img.contentType,
          };
        }
      });

      _self.debug('=:Check uploadMultipleImages parameters:', {
        images: formattedImages,
        bucketName,
        metadata,
        imageCount: formattedImages.length,
        isS3UploadWithMetadata,
        isAutoPost,
        promptId,
        userId,
        promptMetadata,
        s3UploadMode: o.s3UploadMode,
        s3UploadDevHost: o.s3UploadDevHost,
      });

      const result = await uploadMultipleImages({
        images: formattedImages,
        bucketName,
        metadata,
        isAutoPost,
        promptId,
        userId,
        promptMetadata,
        s3UploadMode: o.s3UploadMode,
        s3UploadDevHost: o.s3UploadDevHost,
      });

      _self.debug('=:uploadMultipleImagesToS3 result: ', result);
      return {
        status: 'success',
        message: '이미지 업로드 성공',
        data: result,
      };
    } catch (error) {
      console.error('이미지 업로드 중 오류 발생:', error.message);
      _self.debug('=:uploadMultipleImagesToS3 오류: ', { error });
      return {
        status: 'error',
        message: '이미지 업로드 실패',
        details: error.message,
      };
    }
  }

  // async uploadImageToS3(
  //   img,
  //   generatedImageInfo,
  //   apiMetadata = null,
  //   isS3UploadWithMetadata = null,
  //   isAutoPost = null,
  // ) {
  //   const _self = this;
  //   let o = this.options; // 옵션 값을 변수 o에 저장하여 간결하게 사용
  //   let errorMsg = '';
  //   const { s3Config } = o; // S3 설정에서 버킷 이름, 리전, 경로(prefix)를 가져옴
  //   const { bucketName, region, prefix } = s3Config;
  //   const { getBase64ImageUrl } = o.url;
  //   // ! isAutoPost 값이 명시적으로 전달되었을 경우 이를 사용, 아니면 옵션값 사용
  //   isAutoPost =
  //     isAutoPost !== null && isAutoPost !== undefined
  //       ? isAutoPost
  //       : o.isAutoPost;

  //   // ! isS3UploadWithMetadata 값이 명시적으로 전달되었을 경우 이를 사용, 아니면 옵션값 사용
  //   isS3UploadWithMetadata =
  //     isS3UploadWithMetadata !== null && isS3UploadWithMetadata !== undefined
  //       ? isS3UploadWithMetadata
  //       : o.isS3UploadWithMetadata;

  //   // * generatedImageInfo에서 이미지 확장자, 파일명, 전체 파일명, URL을 추출
  //   const { extension, filename, fullFilename, url } = generatedImageInfo;
  //   const isBase64Image = img.src.startsWith('data:image/');

  //   // base64 이미지가 아닌 경우, fetchImageBase64를 통해 base64 이미지 데이터를 가져옵니다.
  //   let base64Image = img.src;
  //   if (!isBase64Image) {
  //     try {
  //       const response = await fetchImageBase64(getBase64ImageUrl, img.src);
  //       if (response.status === 'error') {
  //         throw new Error(response.msg);
  //       }
  //       this.debug('Check response of fetchImageBase64: ', { response });
  //       base64Image = `data:${response.mime_type};base64,${response.base64}`;
  //     } catch (error) {
  //       console.error('이미지를 base64로 변환하는 데 실패했습니다:', error);
  //       throw error;
  //     }
  //   }

  //   // ! base64 데이타를 전송하고, 서버에서 버퍼로 변환하여 저정하도록 변경 업데이트
  //   // ! 아래의 buffer 변환은 참고 및 예시로 남겨둠
  //   // - const { buffer, contentType } = convertBase64ToBuffer(base64Image); // base64 -> buffer로 변환
  //   let base64Data = null;
  //   let contentType = null;
  //   try {
  //     // * extractBase64Data 함수는 주어진 base64 데이터를 분석하여 버퍼와 컨텐츠 타입을 반환
  //     // - base64Image값이 정상적인지 확인하는 코드 포함
  //     ({ base64Data, contentType } = extractBase64Data(base64Image));
  //   } catch (error) {
  //     errorMsg = `Error extracting base64 data: ${error.message}`;
  //     console.error(errorMsg);
  //     return {
  //       status: 'error',
  //       message: errorMsg,
  //     };
  //   }

  //   // * S3에 저장할 고유한 이미지 파일명 생성
  //   const newImageFilename = generateUniqueFilename() + '.' + extension; // 고유 파일명 생성
  //   const imageFilename = _self.generateImageFilename(newImageFilename); // 고유 파일명 사용

  //   let metadata = {};

  //   // * S3 업로드에 메타데이터가 포함될 경우 처리
  //   if (isS3UploadWithMetadata) {
  //     // ! 메타데이터가 있으면 apiMetadata를 사용하고, 없으면 옵션의 api_options 사용
  //     metadata =
  //       apiMetadata !== null && apiMetadata !== undefined
  //         ? apiMetadata
  //         : o.api_options;
  //   }

  //   // 디버그: 변환된 이미지와 관련 정보 출력
  //   _self.debug('=:Check uploadImageToS3 parameters:', {
  //     // buffer,
  //     base64Data, // !base64 데이타를 전송하고, 서버에서 버퍼로 변환하여 저장
  //     contentType,
  //     imageFilename,
  //     bucketName,
  //     region,
  //     isS3UploadWithMetadata: o.isS3UploadWithMetadata,
  //     metadata,
  //   });

  //   try {
  //     // S3 업로드 로직
  //     const savedImage = await uploadImage({
  //       buffer: base64Data, // !base64 데이타를 전송하고, 서버에서 버퍼로 변환하여 저장
  //       bucketName, // S3 버킷 이름
  //       imageFileName, // 이미지 파일명
  //       contentType, // 이미지 컨텐츠 타입
  //       metadata, // 이미지 메타데이터
  //       isAutoPost, // 자동 게시 여부
  //     });

  //     _self.debug('=:uploadImageToS3: ', { savedImage });
  //     return {
  //       status: 'success',
  //       message: '이미지 업로드 성공',
  //       data: savedImage,
  //     };
  //   } catch (error) {
  //     console.error('이미지 업로드 중 오류 발생:', error.message);
  //     _self.debug('=:uploadImageToS3 오류: ', { error });

  //     // 오류 객체 반환
  //     return {
  //       status: 'error',
  //       message: '이미지 업로드 실패',
  //       details: error.message,
  //     };
  //   }
  // }

  /**
   * generateImageFilename
   *
   * 이 함수는 주어진 이미지 파일명과 사용자 ID를 바탕으로 S3 버킷 내에서 사용할 전체 파일 경로를 생성한다.
   * 프리픽스와 사용자가 제공한 아이디를 기준으로 경로를 설정하며, 고유한 이미지 파일명을 생성한다.
   *
   * @param {string} imageFilename - 원본 이미지 파일명.
   * @param {string} [userId=''] - 사용자의 ID로, 경로에 추가될 수 있다. 기본값은 빈 문자열.
   *
   * @returns {string} - 완전한 이미지 파일 경로를 반환.
   *
   * @update: 2023.09.04
   */
  generateImageFilename(imageFilename, userId = '') {
    const _self = this; // this를 _self로 선언하여 내부에서 사용
    const o = this.options; // 옵션 값을 변수 o에 저장하여 간결하게 사용
    const { s3Config } = o; // S3 설정에서 버킷 정보와 프리픽스를 가져옴
    const { bucketName, region, prefix } = s3Config; // 버킷 이름, 리전, 프리픽스

    // ! supereasy-ai/ai/ 경로에 이미지를 저장하기 위해 파일명 형식을 변경
    imageFilename = `se_ai_${imageFilename}`; // 고유 파일명 형식으로 변환

    // * userId가 있는 경우 경로에 userId를 포함하여 파일 경로를 생성
    const fullImageFilename = userId
      ? `${prefix.aiImage}/${userId}/${imageFilename}` // userId가 있을 경우 경로에 포함
      : `${prefix.aiImage}/${imageFilename}`; // userId가 없을 경우 기본 경로 사용

    return fullImageFilename; // 최종 파일 경로 반환
  }

  debug(message, value = '') {
    this.debugLogger.debug(message, value);
  }
}

export default S3ImageHandler;
