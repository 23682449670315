import {
  enUS,
  ko,
  ja,
  fr,
  de,
  ru,
  zhCN,
  zhTW,
  hi,
  ar,
  es,
  pt,
  // ptBR,
  // ptPT,
  id,
  bn,
  vi,
  th,
  tr,
  it,
  pl,
  hu,
  uk,
  nl,
  ms,
  cs,
  el,
  fi,
} from 'date-fns/locale';

// 지원하지 않는 언어에 대해 기본 locale (enUS)를 사용합니다.
const defaultLocale = enUS;

export const locales = {
  en: enUS,
  ko: ko,
  ja: ja,
  fr: fr,
  de: de,
  ru: ru,
  'zh-CN': zhCN,
  'zh-TW': zhTW,
  hi: hi,
  ar: ar,
  es: es,
  pt: pt,
  // 'pt-BR': ptBR,
  // 'pt-PT': ptPT,
  // 지원하지 않는 언어이지만 가장 근접한 형식을 제공합니다.
  id: id,
  bn: defaultLocale, // 벵골어(Bengali)
  vi: vi,
  th: defaultLocale, // 태국어(Thai)
  tr: tr,
  it: it,
  pl: pl,
  hu: hu,
  uk: uk,
  nl: nl,
  ms: ms,
  cs: cs,
  el: defaultLocale, // 그리스어(Greek)
  fi: fi,
};

export const dateFormats = {
  en: 'd MMM yyyy', // 영어 - 25 Dec 2023
  ko: 'yyyy. MM. dd', // 한국어 - 2023. 12. 25
  ja: 'yyyy年MM月dd日', // 일본어 - 2023年12月25日
  fr: 'dd/MM/yyyy', // 프랑스어 - 25/12/2023
  de: 'dd.MM.yyyy', // 독일어 - 25.12.2023
  ru: 'dd.MM.yyyy', // 러시아어 - 25.12.2023
  'zh-CN': 'yyyy年MM月dd日', // 중국어 간체 (중국) - 2023年12月25日
  'zh-TW': 'yyyy年MM月dd日', // 중국어 번체 (대만) - 2023年12月25日
  hi: 'dd-MM-yyyy', // 힌디어 - 25-12-2023
  ar: 'dd/MM/yyyy', // 아랍어 - 25/12/2023
  es: 'dd/MM/yyyy', // 스페인어 - 25/12/2023
  pt: 'dd/MM/yyyy', // 포르투갈어 (브라질) - 25/12/2023
  // 'pt-BR': 'dd/MM/yyyy', // 포르투갈어 (브라질) - 25/12/2023
  // 'pt-PT': 'dd/MM/yyyy', // 포르투갈어 (포르투갈) - 25/12/2023
  id: 'dd/MM/yyyy', // 인도네시아어 - 25/12/2023
  bn: 'dd/MM/yyyy', // 벵골어 - 25/12/2023
  vi: 'dd/MM/yyyy', // 베트남어 - 25/12/2023
  th: 'dd/MM/yyyy', // 태국어 - 25/12/2023
  tr: 'dd.MM.yyyy', // 터키어 - 25.12.2023
  it: 'dd.MM.yyyy', // 이탈리아어 - 25.12.2023
  pl: 'dd.MM.yyyy', // 폴란드어 - 25.12.2023
  hu: 'dd.MM.yyyy', // 헝가리어 - 25.12.2023
  uk: 'dd.MM.yyyy', // 우크라이나어 - 25.12.2023
  nl: 'dd-MM-yyyy', // 네덜란드어 (네덜란드) - 25-12-2023
  ms: 'dd/MM/yyyy', // 말레이어 - 25/12/2023
  cs: 'dd.MM.yyyy', // 체코어 - 25.12.2023
  el: 'dd/MM/yyyy', // 그리스어 - 25/12/2023
  fi: 'd.M.yyyy', // 핀란드어 - 25.12.2023
};
