import React, { useEffect, useCallback, useRef, useState } from 'react';
import useDebug from '@hooks/useDebug';
import clsx from 'clsx';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import {
  sizeConfig,
  heightConfig,
  positionConfig,
  shapeConfig,
  getSize,
  getModalShape,
  getButtonShape,
  textSizeConfig,
  getTextSize,
  getAnimation,
  getDuration,
  headerSizeConfig,
  footerSizeConfig,
  getHeaderSize,
  getFooterSize,
  buttonShapeConfig,
  defaultModalOptions,
  mergeOptions,
} from './ModalConfig';
import ModalHeader from './components/ModalHeader';
import ModalBody from './components/ModalBody';
import ModalFooter from './components/ModalFooter';

const Modal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  titlePrefix,
  children,
  panelClassName = '',
  headerClassName = '',
  titleClassName = '',
  bodyClassName = '',
  contentClassName = '',
  footerClassName = '',
  buttonClassName = '',
  zIndex = 1060,
  // 모달 모양
  shape = '2xl', // 모달 모양, rounded-{size}
  // 모달 크기
  width = 'md', // 모달 너비
  height = 'md', // 모달 높이
  fullscreen = false,
  // 모달 위치
  position = 'center',
  // 모달 애니메이션
  animationIn = 'fade',
  animationOut = 'fade',
  duration = 'default',
  // header, footer size
  // INFO: padding 값으로 설정한다.
  headerSize = 'md', // header size, h_size
  footerSize = 'md', // footer size, f_size
  // text size
  headerTextSise = 'md',
  contentTextSize = '', // 모달 컨텐츠의 텍스트의 사이저, mct_size
  buttonTextSize = 'sm', // 모달 버튼의 텍스트 사이즈, mbt_size
  // border
  headerBorder = true,
  footerBorder = true,
  // button
  cancelText = '취소',
  confirmText = '확인',
  buttonShape = 'md',
  modalOptions = {},
  // callback
  onHide,
  onHidden,
  onShow,
  onShown,
  darkMode = false,
  // NOTICE: 모달 이름 접두사: superasy-ai-dialog, superasy-ai-panel, superasy-ai-container
  modalNamePrefix = 'superasy-ai',
  isIsolation = false,
  cssPrefixSelector = null,
}) => {
  const { debug } = useDebug('Modal');
  const options = mergeOptions(defaultModalOptions, modalOptions);
  const [isDraggableReady, setIsDraggableReady] = useState(false);
  const modalRef = useRef(null);
  const draggableRef = useRef(null);

  zIndex = options.z_index || zIndex;
  let isFullScreen = options.fs || fullscreen;
  const isFullWidth = width === 'full' || options.fs || fullscreen;
  const isFullHeight = height === 'full' || options.fs || fullscreen;
  if (isFullWidth && isFullHeight) {
    isFullScreen = true;
  }
  const fullscreenClass = isFullScreen ? 'w-screen h-screen' : '';

  const modalWidth = getSize(width, sizeConfig, isFullScreen);
  const modalHeight = getSize(height, heightConfig, isFullScreen);
  const positionClass = positionConfig[position] || positionConfig.center;
  const modalShape = getModalShape(options.m_shape || shape, isFullScreen);

  const getWidthClass = () => {
    if (isFullWidth) return 'w-screen';
    return 'w-full';
  };

  const getHeightClass = () => {
    if (isFullHeight) return 'h-screen';
    if (height === 'auto') return 'h-auto';
    return heightConfig[height] || heightConfig.md;
  };

  // 모달 콘텐츠를 감싸는 래퍼 요소의 스타일을 조건부로 설정
  const contentWrapperStyle = isIsolation
    ? { fontSize: '16px', isolation: 'isolate', contain: 'strict' }
    : {};

  const dialogClasses = clsx(
    `${modalNamePrefix}-dialog`,
    'fixed inset-0',
    'z-50 w-screen overflow-y-auto',
    darkMode ? 'dark' : '',
  );

  const panelClasses = clsx(
    `${modalNamePrefix}-panel`,
    'w-full bg-white shadow-xl',
    modalShape,
    panelClassName,
    'relative flex flex-col',
    getHeightClass(),
    isFullHeight ? 'h-full' : 'max-h-[calc(100vh-2rem)]',
  );

  const containerClasses = clsx(
    `${modalNamePrefix}-container`,
    'fixed inset-0',
    isFullHeight ? 'h-screen' : 'min-h-[calc(100vh-2rem)]',
  );

  const headerTitleTextClass = getTextSize(options.ht_size || headerTextSise);
  const contentTextClass = getTextSize(options.mct_size || contentTextSize);
  const modalButtonTextClass = getTextSize(options.mbt_size || buttonTextSize);
  const modalButtonShapeClass = getButtonShape(options.mb_shape || buttonShape);

  const headerSizeClass = getHeaderSize(options.h_size || headerSize);
  const footerSizeClass = getFooterSize(options.f_size || footerSize);

  const darkModeClasses = darkMode ? 'dark bg-gray-800 text-white' : '';

  const animationInConfig = getAnimation(animationIn, 'in');
  const animationOutConfig = getAnimation(animationOut, 'out');
  const transitionDuration = getDuration(duration);

  const effectiveDr = options.bd !== 0 ? 0 : options.dr;

  const initDraggable = useCallback(() => {
    if (
      effectiveDr === 1 &&
      draggableRef.current &&
      !fullscreen &&
      !options.fs
    ) {
      const $draggable = $(draggableRef.current);
      if ($draggable.data('ui-draggable')) {
        $draggable.draggable('destroy');
      }
      $draggable.draggable({
        handle: options.dr_handle || '.modal-header',
        containment: 'window',
        cursor: 'move',
      });
      console.log('Draggable initialized');
      setIsDraggableReady(true);
    } else {
      setIsDraggableReady(false);
    }
  }, [effectiveDr, options.dr_handle, fullscreen, options.fs]);

  const destroyDraggable = useCallback(() => {
    if (draggableRef.current) {
      const $draggable = $(draggableRef.current);
      if ($draggable.data('ui-draggable')) {
        $draggable.draggable('destroy');
        console.log('Draggable destroyed');
      }
    }
    setIsDraggableReady(false);
  }, []);

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        initDraggable();
      }, 100);
      return () => clearTimeout(timer);
    } else {
      destroyDraggable();
    }
  }, [isOpen, initDraggable, destroyDraggable]);

  useEffect(() => {
    if (isOpen) {
      initDraggable();
    }
  }, [options, isOpen, initDraggable]);

  useEffect(() => {
    debug('*:Modal options:', {
      options,
      confirmText,
      cancelText,
      modalWidth,
      modalHeight,
      positionClass,
      fullscreenClass,
      isFullWidth,
      isFullHeight,
      isFullScreen,
      headerTitleTextClass,
      contentTextClass,
      modalButtonTextClass,
      modalButtonShapeClass,
      headerSizeClass,
      footerSizeClass,
      animationInConfig,
      animationOutConfig,
      transitionDuration,
      headerSizeConfig,
      footerSizeConfig,
    });
  }, [
    options,
    confirmText,
    cancelText,
    modalWidth,
    modalHeight,
    positionClass,
    fullscreenClass,
    isFullHeight,
    headerSizeClass,
    footerSizeClass,
    headerTitleTextClass,
    contentTextClass,
    modalButtonTextClass,
    modalButtonShapeClass,
    animationInConfig,
    animationOutConfig,
    transitionDuration,
    headerSizeConfig,
    footerSizeConfig,
  ]);

  const handleBackdropClick = useCallback(
    (e) => {
      console.log('Backdrop clicked');

      console.log('options.bd_static:', options.bd_static);
      console.log('options.bd:', options.bd);

      if (options.bd_static === 1 && options.bd === 1) {
        console.log('Conditions met, calling onClose');
        onClose();
      } else {
        console.log('Conditions not met, modal stays open');
      }
    },
    [options.bd_static, options.bd, onClose],
  );

  return (
    <Transition appear={true} show={isOpen} as={Fragment} unmount={true}>
      <Dialog
        as='div'
        className={`${cssPrefixSelector || ''} ${dialogClasses}`}
        style={{ ...contentWrapperStyle, zIndex }} // contentWrapperStyle 적용
        onClose={handleBackdropClick}
        initialFocus={modalRef}
      >
        <div className={`${containerClasses}`}>
          {options.bd !== 0 && (
            <Transition.Child
              appear={false}
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-2000'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
              unmount={true}
            >
              <div
                className='bg-opacity-30 fixed inset-0 bg-black'
                aria-hidden='true'
                // onClick={handleBackdropClick}
              />
            </Transition.Child>
          )}
          <Transition.Child
            as={Fragment}
            enter={`${animationInConfig.enter} ${transitionDuration}`}
            enterFrom={animationInConfig.enterFrom}
            enterTo={animationInConfig.enterTo}
            leave={`${animationOutConfig.leave} ${transitionDuration}`}
            leaveFrom={animationOutConfig.leaveFrom}
            leaveTo={animationOutConfig.leaveTo}
          >
            <div
              className={clsx(
                positionClass,
                'h-full w-full',
                !(isFullWidth || isFullHeight) && 'p-4',
              )}
            >
              <div
                ref={draggableRef}
                className={clsx(
                  isDraggableReady && 'cursor-move',
                  isFullScreen ? fullscreenClass : getWidthClass(),
                )}
                style={{
                  width: isFullWidth ? '100%' : modalWidth,
                  height: isFullHeight ? '100%' : modalHeight,
                }}
              >
                <Dialog.Panel ref={modalRef} className={panelClasses}>
                  <ModalHeader
                    title={title}
                    titlePrefix={titlePrefix}
                    options={options}
                    onClose={onClose}
                    headerClassName={headerClassName}
                    headerSizeClass={headerSizeClass}
                    titleClassName={titleClassName}
                    titleTextClass={headerTitleTextClass}
                  />
                  <ModalBody
                    options={options}
                    onClose={onClose}
                    isFullHeight={isFullHeight}
                    modalHeight={modalHeight}
                    bodyClassName={clsx(
                      bodyClassName,
                      'flex-grow overflow-auto',
                    )}
                    darkMode={darkMode}
                    darkModeClasses={darkModeClasses}
                    contentTextClass={contentTextClass}
                  >
                    {children}
                  </ModalBody>
                  <ModalFooter
                    options={options}
                    // callback
                    onClose={onClose}
                    onConfirm={onConfirm}
                    // button text
                    confirmText={confirmText}
                    cancelText={cancelText}
                    // custom class
                    footerSizeClass={footerSizeClass}
                    footerClassName={footerClassName}
                    buttonClassName={buttonClassName}
                    buttonTextClass={modalButtonTextClass}
                    buttonShapeClass={modalButtonShapeClass}
                  />
                  {options.cb_out !== 0 && options.cb !== 0 && (
                    <button
                      type='button'
                      className='absolute right-0 top-0 z-10 -mr-4 -mt-4 rounded-full bg-white p-2 shadow-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                      onClick={onClose}
                    >
                      <XMarkIcon className='h-6 w-6 text-gray-600' />
                    </button>
                  )}
                </Dialog.Panel>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
