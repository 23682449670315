import createDebugger from '@utils/debug';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import { API_CONFIG, getCdnUrl } from '@api/apiConfig';

const { debug, setDebugMode } = createDebugger('i18n');
const debugMode = API_CONFIG.debug;
setDebugMode(debugMode);

const cdnBase = getCdnUrl('/pkg/v1');

// languageDetector 설정
// - order 배열에서 언어 감지 순서를 정의할 수 있습니다.
// - navigator보다 localStorage나 querystring을 우선시하면, 브라우저 언어보다 저장된 언어를 먼저 확인합니다.
const languageDetector = new LanguageDetector();
languageDetector.init({
  order: ['localStorage', 'querystring', 'cookie', 'navigator', 'htmlTag'],
  lookupLocalStorage: 'supereasyaiLng',
  caches: ['localStorage'],
  convertDetectedLanguage: (lng) => {
    if (lng === 'zh') return 'zh-CN';
    return lng;
  },
  checkWhitelist: true,
});

const namespaces = [
  // 'common/common',
  'components/service/imageAI/layout/sidebar/sidebar',
  'components/service/imageAI/layout/navigation/navigation',
  'components/service/imageAI/components/create',
  'components/service/imageAI/components/imageActionButton',
  'components/service/imageAI/components/imageViewer',
  'components/service/imageAI/components/loginGuide',
  'components/service/imageAI/components/prompt/prompt',
  'components/service/imageAI/components/prompt/promptInput',
  'components/service/imageAI/components/prompt/promptSettings',
  'components/service/imageAI/components/pricing',
  'components/service/imageAI/components/aiInfrastructure',
];

const i18nConfig = {
  fallbackLng: {
    'zh-CN': ['zh-CN', 'en'],
    'zh-TW': ['zh-TW', 'en'],
    default: ['en'],
  },
  // lng: localStorage.getItem('supereasyaiLng') || 'en',
  supportedLngs: [
    'en', // 영어 (English)
    'ko', // 한국어
    'ja', // 일본어 (日本語)
    'fr', // 프랑스어 (Français)
    'de', // 독일어 (Deutsch)
    'ru', // 러시아어 (Русский)
    'zh-CN', // 중국어 간체 (중국) (简体中文)
    'zh-TW', // 중국어 번체 (대만) (繁體中文)
    'hi', // 힌디어 (हिन्दी)
    'ar', // 아랍어 (العربية)
    'es', // 스페인어 (Español)
    'pt', // 포르투갈 (브라질) (Português)
    'id', // 인도네시아어 (Bahasa Indonesia)
    'bn', // 벵골어 (বাংলা)
    'vi', // 베트남어 (Tiếng Việt)
    'th', // 태국어 (ไทย)
    'tr', // 터키어 (Türkçe)
    'it', // 이탈리아어 (Italiano)
    'pl', // 폴란드어 (Polski)
    'hu', // 헝가리어 (Magyar)
    'uk', // 우크라이나어 (Українська)
    'nl', // 네덜란드어 (Nederlands)
    'ms', // 말레이어 (Bahasa Melayu)
    'cs', // 체코어 (čeština)
    'el', // 그리스어 (Ελληνικά)
    'fi', // 핀란드어 (Suomi)
  ],
  detection: {
    order: ['localStorage', 'querystring', 'navigator'],
    lookupLocalStorage: 'supereasyaiLng',
    caches: ['localStorage'],
    checkWhitelist: true,
  },
  debug: debugMode,
  ns: namespaces,
  defaultNS: 'common/common',
  backend: {
    loadPath: `${cdnBase}/locales/{{lng}}/{{ns}}.json`,
    allowMultiLoading: true,
    crossDomain: true,
    requestOptions: {
      mode: 'cors',
      credentials: 'omit',
      cache: 'default',
    },
    parse: (data) => {
      const parsedData = JSON.parse(data);

      // 플러그인 빌드에서는 이미 올바른 구조로 데이터가 있으므로
      // 추가 처리 없이 반환
      return parsedData;
    },
  },
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
};

debug('i:*:i18nConfig', i18nConfig);

i18n
  .use(HttpBackend)
  .use(languageDetector) // langueageDetector에서 설정한 order대로 언어를 찾음
  .use(initReactI18next)
  .init(i18nConfig);

// 디버그용 이벤트 리스너
if (debugMode) {
  i18n.on('loaded', (loaded) => {
    debug('s:*:i18n:loaded', loaded);
  });

  i18n.on('failedLoading', (lng, ns, msg) => {
    debug('e:*:i18n:failedLoading', { lng, ns, msg });
  });
}

export default i18n;
