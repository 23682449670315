// components/KeyboardShortcutHandler.jsx
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import useKeyboardShortcut from '@hooks/useKeyboardShortcut';

const KeyboardShortcutHandler = ({ shortcutKeys, onTrigger }) => {
  useKeyboardShortcut(shortcutKeys, onTrigger);
  return null;
};

KeyboardShortcutHandler.propTypes = {
  shortcutKeys: PropTypes.shape({
    key: PropTypes.string.isRequired,
    shiftKey: PropTypes.bool,
    altKey: PropTypes.bool,
    metaKey: PropTypes.bool,
    ctrlKey: PropTypes.bool,
  }).isRequired,
  onTrigger: PropTypes.func.isRequired,
};

// memo로 감싸서 불필요한 리렌더링 방지
export default memo(KeyboardShortcutHandler);
