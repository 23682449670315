/*!
 * FluxFalApi 1.0.0
 * @ update date: 2024.09.11
 * @ author JB, GTGF
 */
import createDebugger from '@utils/debug.js';
import { fluxFalConfig } from '@aiImageApiConfig/apiConfig';
// ! @src directory에서 필요한 module을 import
import {
  convertBase64ToBuffer,
  uploadImage,
  uploadMultipleImages,
} from '@src/js/api/aws/s3/AWSS3Fileuploader.js';

import {
  constructApiRequest,
  callAPI,
} from '@src/js/api/ai/flux/fal/FalApi.js';
import { generateUniqueFilename } from '@src/js/utils/UtilFunctions.js';
import { extractImageInfoWithUrl } from '@src/js/utils/image/ImageUtilFunctions.js';

class FluxFal {
  static defaultOptions = {
    api_options: { ...fluxFalConfig.api_options },
    model_options: { ...fluxFalConfig.model_options },

    isS3Upload: true, // true로 설정하면, 이미지 생성 후, 자동으로 S3에 업로드한다.
    isS3UploadWithMetadata: true, // true로 설정하면, 이미지 생성 후, 자동으로 S3에 업로드하고, 업로드된 이미지의 메타데이터를 추가한다.
    isAutoPost: true, // true로 설정하면, 이미지 생성 후, 자동으로 포스트를 생성한다.
    s3Config: {
      bucketName: 'supereasy-ai',
      prefix: {
        ai: 'ai',
        aiImage: 'ai/image',
        metadata: 'metadata',
      },
      region: 'ap-northeast-2',
    },
    url: {
      apiUrl: {
        dev: 'http://localhost:5000/api/generate-image',
        prod: 'https://api.supereasy.co.kr:5000/api/generate-image',
      },
    },

    // imageAI 플러그인에서 생성된 이미지의 messageId를 저장하는 변수
    currentMessageId: null,

    // 중복 이미지 생성 방지를 위한 변수
    isProcessing: false,
    debugMode: true,
  };

  constructor(element, options = {}, parent = {}) {
    this._id = Math.round(Math.random() * 99999);
    this.pluginName = 'FluxFalApi';
    this.element = element || null;
    this.$el = element ? $(element) : null;
    this._options = options;
    this.options = $.extend(true, {}, FluxFal.defaultOptions, options);

    // 해당 플러그인을 호출한 부모 instance
    if (Object.keys(parent).length) {
      this[parent.pluginName] = parent.plugin;
      delete parent.plugin;
      this.parent = parent;
      // pluginName 변경 설정
      this.pluginName = this.pluginName + '_' + parent.pluginName;
    }

    // 인스턴스별 디버거 생성
    const { debug, setDebugMode, getDebugMode } = createDebugger(
      this.pluginName,
    );
    this.debug = debug;
    this.setDebugMode = setDebugMode;
    this.getDebugMode = getDebugMode;
    // 디버그 모드 설정
    this.setDebugMode(this.options.debugMode);

    // 플러그인 초기화
    this.init();
  }

  init(mode = 'init') {
    const _self = this;
    const o = this.options;
    _self.debug('s:FluxFalApi START: ', o);
    _self.debug('Chcek this: ', this);
  }

  getDefaultConfig() {
    this.debug('Getting default config: ', { ...this.options.api_options });
    return { ...this.options.api_options };
  }

  setModel(model) {
    const _self = this;
    const o = this.options;
    let modelName = '';
    switch (model) {
      case 'dev':
        modelName = 'fal-ai/flux/dev';
        break;
      case 'dev_image':
        modelName = 'fal-ai/flux/dev/image-to-image';
        break;
      case 'realism':
        modelName = 'fal-ai/flux-realism';
        break;
      case 'schnell':
        modelName = 'fal-ai/flux/schnell';
        break;
    }
    // o.api_options.model = modelName;
    return modelName;
  }

  /**
   * API 옵션을 설정합니다.
   */
  setApiOptions() {
    const _self = this;
    const o = this.options;
    const {
      $el,
      $prompt,
      $imageSize,
      $numInferenceSteps,
      $seed,
      $guidanceScale,
      $syncMode,
      $numImages,
      $enableSafetyChecker,
      $model,
    } = _self;

    const apiOptions = {
      model: _self.setModel($model.val()) || 'fal-ai/flux/schnell',
      prompt: $prompt.val() || '',
      image_size: $imageSize.val() || 'landscape_4_3',
      num_inference_steps: parseInt($numInferenceSteps.val(), 10) || 28,
      seed: parseInt($seed.val(), 10) || Date.now(),
      guidance_scale: parseFloat($guidanceScale.val()) || 3.5,
      sync_mode: $syncMode.is(':checked') || false,
      num_images: parseInt($numImages.val(), 10) || 1,
      enable_safety_checker: $enableSafetyChecker.is(':checked') || true,
      isBase64: o.api_options.isBase64 || false,
    };
    o.api_options = $.extend(true, o.api_options, apiOptions);
    _self.debug('setApiOptions - FluxFal ', { o });
  }

  /**
   * FluxFal API를 호출하여 이미지를 생성
   * @param {string} prompt
   * @param {Object} apiOptions
   * @param {string} messageId
   * @returns
   */
  async generateImage({ prompt, apiOptions, messageId = '' }) {
    const _self = this;
    const o = this.options;
    const apiRequest = _self.constructApiRequest(apiOptions);
    if (o.isProcessing) {
      return;
    }
    o.isProcessing = true;
    o.currentMessageId = messageId;
    o.currentApiRequest = apiRequest;

    _self.debug('s:generateImage(): ', {
      messageId,
      prompt,
      apiOptions,
      apiRequest,
    });

    try {
      const result = await _self.callAPI(apiRequest);
      try {
        const { images, imageInfo } = await _self.handleResponse(result);
        return {
          status: 'success',
          msg: '이미지 생성 성공',
          data: { images, imageInfo, apiOptions: apiRequest },
        };
      } catch (handleError) {
        console.error('Error in handleResponse:', handleError);
        return {
          status: 'error',
          msg: '이미지 처리 중 에러 발생',
          details: handleError.message,
        };
      }
    } catch (callApiError) {
      console.error('Error in callAPI:', callApiError);
      return {
        status: 'error',
        msg: 'API 호출 중 에러 발생',
        details: callApiError.message,
      };
    } finally {
      // 이미지 생성 중인지 여부를 표시하는 변수를 초기화
      o.isProcessing = false;
    }
  }

  /**
   * API 요청 객체 생성
   * @param {Object} apiOptions - API 요청 옵션
   * @returns {Object} API 요청 객체
   */
  constructApiRequest(apiOptions) {
    const _self = this;
    // API 요청 객체 생성
    const request = {
      model: apiOptions.model || 'fal-ai/flux/schnell',
      prompt: apiOptions.prompt || '',
      image_size: apiOptions.image_size || 'landscape_4_3',
      seed: apiOptions.seed || Date.now(),
      guidance_scale: apiOptions.guidance_scale || 3.5,
      sync_mode: apiOptions.sync_mode || false,
      num_images: apiOptions.num_images || 1,
      enable_safety_checker:
        apiOptions.enable_safety_checker !== undefined
          ? apiOptions.enable_safety_checker
          : true,
      isBase64: apiOptions.hasOwnProperty('isBase64')
        ? apiOptions.isBase64
        : false, // 기본 값: false 설정
    };

    // model이 'fal-ai/flux/schnell'일 때 num_inference_steps 값을 12로 제한
    if (request.model === 'fal-ai/flux/schnell') {
      request.num_inference_steps = Math.min(
        apiOptions.num_inference_steps || 28,
        12,
      );
    } else {
      request.num_inference_steps = apiOptions.num_inference_steps || 28;
    }

    // model이 'fal-ai/flux/pro'일 때 safety_tolerance 설정
    if (request.model === 'fal-ai/flux/pro') {
      const validTolerances = [1, 2, 3, 4, 5, 6];
      const safetyTolerance = apiOptions.safety_tolerance;

      if (validTolerances.includes(safetyTolerance)) {
        request.safety_tolerance = safetyTolerance;
      } else {
        request.safety_tolerance = 2; // 기본값 설정
      }
    }

    // model이 'fal-ai/flux-realism'일 때 strength 설정
    if (request.model === 'fal-ai/flux-realism') {
      let strength =
        apiOptions.strength !== undefined ? parseFloat(apiOptions.strength) : 1;

      // 소수점 첫째 자리까지만 유지
      strength = Math.round(strength * 10) / 10;

      // 범위 체크 (0.1부터 1.0까지 가정)
      if (strength >= 0.1 && strength <= 1.0) {
        request.strength = strength;
      } else {
        request.strength = 1; // 범위를 벗어나면 기본값 1 설정
      }
    }

    _self.debug('Constructed API request:', request);
    return request;
  }

  /**
   * API 요청 객체를 전달하여, API 호출
   * @param {Object} apiRequest - API 요청 객체
   * @returns {Object} API 응답 객체
   */
  async callAPI(apiRequest) {
    const _self = this;
    const o = this.options;
    const { url } = o;
    const API_URL = url.apiUrl.prod;

    _self.debug('Check apiRequest: ', apiRequest);
    _self.debug('Check API_URL: ', API_URL);

    const response = await fetch(API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(apiRequest),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('API Error Response:', errorText);
      let errorMessage = 'Unknown error';
      try {
        const errorJson = JSON.parse(errorText);
        errorMessage = errorJson.error?.message || errorJson.error || errorText;
      } catch (e) {
        errorMessage = errorText;
      }
      throw new Error(
        `HTTP error! status: ${response.status}, message: ${errorMessage}`,
      );
    }

    return response.json();
  }

  async handleSubmit() {
    const _self = this;
    const { $el, options, $prompt, $imageSubmit } = _self;
    const o = options;
    const { selector } = o;
    const prompt = $prompt.val();
    let errorMsg = '';

    if (o.isProcessing) {
      return;
    }
    o.isProcessing = true;

    if (!prompt) {
      errorMsg = '프롬프트를 입력해주세요.';
      GTGF.alertMsg(errorMsg);
      return;
    }

    o.api_options.prompt = prompt;
    _self.setApiOptions();
    _self.debug('handleSubmit - FluxFal ', { prompt });

    // ! 생성 이미지 결과 처리(출력 처리)를 parentPlugin에서 처리하는 경우,
    if (o.imageResult.target === 'parentPlugin') {
      // ! 이미지 생성 결과를 부모 instance에서 처리하는 경우, 부모 instance에서 이미지 생성 전에 호출되는 함수
      // ! apiOptions를 부모 instance에서 사용할 수 있도록 전달
      // ! 만일, 부모 플러그인인 imageAI Chat인 경우, chatHistory에 이미지 Message 템플릿을 미리 출력하기 위함
      // -- 이미지 생성 상태(generating..., loader 표시)를 표시하기 위함
      const apiOptions = $.extend(true, {}, o.api_options);
      const messageId = await _self[
        _self.parent.pluginName
      ].handleBeforeGenerateImage(apiOptions);
      o.currentMessageId = messageId;
      _self.debug('handleSubmit - messageId: ', messageId);
    }

    // callServerAPI 호출하여 이미지 생성 시작
    _self.callServerAPI();
  }

  async callServerAPI() {
    const _self = this;
    const o = this.options;

    let apiOptions = o.hasOwnProperty('api_options') ? o.api_options : {};
    const apiRequest = constructApiRequest(apiOptions);

    _self.debug('s');
    _self.debug('callServerAPI', { apiRequest });
    _self.debug('e');

    try {
      const result = await callAPI(apiRequest);
      await _self.handleResponse(result);
    } catch (error) {
      console.error('Error in callServerAPI:', error);
      _self.handleError(error);
    } finally {
      // 이미지 생성 중인지 여부를 표시하는 변수를 초기화
      o.isProcessing = false;
    }
  }

  async handleResponse(response) {
    const _self = this;
    const o = _self.options;
    const { currentMessageId } = o;

    const images = [];
    const imageInfo = [];

    _self.debug('handleResponse', { response });

    // response 객체가 유효한지 확인
    if (response && response.images && response.images.length > 0) {
      response.images.forEach((image, index) => {
        const isBase64 = !!image.base64;
        const imageUrl = image.url;
        const base64Image = isBase64 ? image.base64 : null;
        const mimeType = isBase64
          ? image.base64.split(';')[0].split(':')[1]
          : image.content_type;

        // React 컴포넌트에서 사용할 이미지 정보 생성
        const imageData = {
          id: `${currentMessageId}-${index}`,
          src: imageUrl,
          width: image.width,
          height: image.height,
          aspectRatio: image.width / image.height,
          createdTime: Date.now(),
          createdAt: new Date().toISOString(),
          contentType: mimeType,
          isBase64,
          metadata: {
            contentType: mimeType,
            attributes: {
              'data-mime-type': mimeType,
              'data-is-base64': isBase64.toString(),
              'data-index': index.toString(),
              'data-message-id': currentMessageId,
            },
          },
        };

        if (isBase64) {
          imageData.base64Image = base64Image;
        }

        images.push(imageData);

        // 원본 이미지 정보를 imageInfo 배열에 추가 (base64 제외)
        const { base64, ...imageWithoutBase64 } = image;
        if (base64) {
          const { base64: _, ...imageInfoWithoutBase64 } = imageWithoutBase64;
          imageInfo.push(imageInfoWithoutBase64);
        } else {
          imageInfo.push(imageWithoutBase64);
        }
      });

      // 객체를 반환
      return { images, imageInfo };
    } else {
      throw new Error('이미지 생성에 실패했습니다.');
    }
  }

  // async handleResponse(response) {
  //   const _self = this;
  //   const o = _self.options;
  //   const { currentMessageId, currentApiRequest } = o;

  //   const images = [];
  //   const imageInfo = [];

  //   _self.debug('handleResponse', { response });

  //   // response 객체가 유효한지 확인
  //   if (response && response.images && response.images.length > 0) {
  //     response.images.forEach((image, index) => {
  //       // 이미지 엘리먼트 생성
  //       const img = document.createElement('img');
  //       if (image.base64) {
  //         img.src = image.base64;
  //         const mimeType = image.base64.split(';')[0].split(':')[1];
  //         img.setAttribute('data-mime-type', mimeType);
  //         img.setAttribute('data-is-base64', 'true');
  //       } else {
  //         img.src = `/api/proxy-image?url=${encodeURIComponent(image.url)}`;
  //         const fileExtension = image.url.split('.').pop().toLowerCase();
  //         img.setAttribute('data-file-extension', fileExtension);
  //         img.setAttribute('data-mime-type', image.content_type);
  //         img.setAttribute('data-is-base64', 'false');
  //       }
  //       img.alt = `생성된 이미지 ${index + 1}`;
  //       images.push(img);

  //       // 이미지 정보를 imageInfo 배열에 추가 (base64 제거)
  //       // const { base64, ...imageWithoutBase64 } = image;
  //       // imageInfo.push(imageWithoutBase64);
  //       imageInfo.push(image);
  //     });

  //     // 객체를 반환하도록 수정
  //     return { images, imageInfo };
  //   } else {
  //     throw new Error('이미지 생성에 실패했습니다.');
  //   }
  // }

  async uploadImageToS3(
    img,
    generatedImageInfo,
    apiMetadata = null,
    isS3UploadWithMetadata = null,
    isAutoPost = null,
  ) {
    const _self = this;
    const o = this.options;
    const { s3Config } = o;
    const { bucketName, region, prefix } = s3Config;

    // ! isAutoPost 값을 전달하여, options.isAutoPost 값 대신 사용 가능
    isAutoPost =
      isAutoPost !== null && isAutoPost !== undefined
        ? isAutoPost
        : o.isAutoPost;

    // ! isS3UploadWithMetadata 값을 전달하여, options.isS3UploadWithMetadata 값 대신 사용 가능
    isS3UploadWithMetadata =
      isS3UploadWithMetadata !== null && isS3UploadWithMetadata !== undefined
        ? isS3UploadWithMetadata
        : o.isS3UploadWithMetadata;

    // * 원본 이미지의 정보
    const { extension, filename, fullFilename, url } = generatedImageInfo;

    // * base64 이미지를 버퍼로 변환
    const base64Image = img.src;
    const { buffer, contentType } = convertBase64ToBuffer(base64Image);

    // * s3에 저장할 unique한 이미지 파일명 생성
    const newImageFilename = generateUniqueFilename() + '.' + extension;
    const imageFilename = _self.generateImageFilename(newImageFilename);
    let metadata = {};

    // * S3 업로드 기능이 활성화 되면, 이미지 생성 후, 생성된 이미지를 S3에 업로드
    if (isS3UploadWithMetadata) {
      // ! imageAI 플러그인에서 전달된 apiMetadata가 있으면, 그것을 사용하고, 없으면, o.api_options를 사용
      // ! imageAI 플러그인에서 이미지 생성 시점이 아닌, 다수의 이미지를 생성 후, 사용자의 선택으로 이미지를 업로드 할 수 있음.
      // ! 이때, 사용자가 선택한 이미지의 메타데이터를 추가하기 위해서, apiMetadata를 사용
      metadata =
        apiMetadata !== null && apiMetadata !== undefined
          ? apiMetadata
          : o.api_options;
    }

    console.log('Check img:', {
      buffer,
      contentType,
      imageFilename,
      bucketName,
      region,
      isS3UploadWithMetadata: o.isS3UploadWithMetadata,
      metadata,
    });

    const savedImage = await uploadImage(
      buffer,
      bucketName,
      imageFilename,
      contentType,
      metadata,
      isAutoPost,
    );

    _self.debug('s');
    _self.debug('Uploaded image:', savedImage);
    _self.debug('e');
  }

  generateImageFilename(imageFilename, userId = '') {
    const _self = this;
    const o = this.options;
    const { s3Config } = o;
    const { bucketName, region, prefix } = s3Config;
    // ! 비킷: supereasy-ai, 프리픽스: ai
    // ! supereasy-ai/ai/ 에 파일을 저장한다.
    // ! filename 형식: supereasy_ai_{seed}_{imageFilename}
    imageFilename = `se_ai_${imageFilename}`;
    const fullImageFilename = userId
      ? `${prefix.aiImage}/${userId}/${imageFilename}`
      : `${prefix.aiImage}/${imageFilename}`;
    return fullImageFilename;
  }

  setOptions(opts = {}) {
    const _self = this;
    let o = this.options;

    if (Object.keys(opts).length) {
      o = $.extend(true, o, opts);
    }
    return;
  }

  debug(message, value = '') {
    this.debugLogger.debug(message, value);
  }
}

if (typeof module === 'object' && module.exports) {
  // Node.js 환경 (CommonJS)
  module.exports = FluxFal;
} else if (typeof define === 'function' && define.amd) {
  // AMD 환경
  define(() => FluxFal);
} else {
  // ES6 모듈 혹은 전역 객체
  window.FluxFal = FluxFal;
}

export default FluxFal;

// /**
//  * Flux FAL API를 호출하여 이미지를 생성합니다.
//  * @param {Object} apiOptions - API 호출 옵션
//  * @returns {Promise<Object>} 생성된 이미지 정보
//  */
// export const generateImage = async (apiOptions) => {
//   // API 호출 로직
//   // ...

//   return response;
// };

// /**
//  * 생성된 이미지를 처리하고 필요한 경우 S3에 업로드합니다.
//  * @param {Object} imageData - 생성된 이미지 데이터
//  * @param {Object} options - 처리 옵션
//  * @returns {Promise<Object>} 처리된 이미지 정보
//  */
// export const processGeneratedImage = async (imageData, options) => {
//   const { isS3Upload, s3Config, isS3UploadWithMetadata, apiMetadata } = options;

//   let processedImageInfo = {
//     ...imageData,
//     // 추가 이미지 정보 처리
//   };

//   if (isS3Upload) {
//     const { buffer, contentType } = convertBase64ToBuffer(imageData.base64);
//     const s3ImageInfo = await uploadImageToS3(
//       buffer,
//       s3Config,
//       contentType,
//       isS3UploadWithMetadata ? apiMetadata : null,
//     );
//     processedImageInfo = { ...processedImageInfo, ...s3ImageInfo };
//   }

//   return processedImageInfo;
// };
